import apiInsight from '../api/InsightAPI';

// const propertyInsight = async id => {
//     const result = await apiInsight.propertyInsight(id);
//     return result;
// };


// const propertyAveragePriePsf = async id => {
//     const result = await apiInsight.propertyAveragePriePsf(id);
//     return result;
// };

// const overview = async id => {
//     const result = await apiInsight.overview(id);
//     return result;
// }

// Insight-v2 (bigData.transactions)
const transactionHistory = async id => {
    const result = await apiInsight.propertyTransactions(id);
    return result
}

export default {
    // propertyInsight,
    // propertyAveragePriePsf,
    // overview,
    transactionHistory
}