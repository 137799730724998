import apibase from './BaseAPI';
import lib from "../businesses/lib";

const newId = async () => {
    try {
        const res = await apibase.get(`/serviceMerchant/applications/newId`)
        return res.data
    } catch (err) {
        throw err
    }
}

const save = async (application) => {
    try {
        const res = await apibase.post(`/serviceMerchant/applications/save`, application)
        return res.data
    } catch (error) {
        throw error
    }
}

const isEmailUnique = async (email) => {
    const params = { email: email }
    try {
        const res = await apibase.post(`/serviceMerchant/applications/isEmailUnique`, params)
        return res.data
    } catch (error) {
        throw error
    }
}

const isContactUnique = async (contact) => {
    const params = { contact: contact }
    try {
        const res = await apibase.post(`/serviceMerchant/applications/isContactUnique`, params)
        return res.data
    } catch (error) {
        throw error
    }
}

//use API created from serviceMerchantService.js in backend
const uploadImages = async (params) => {
    try {
        const res = await apibase.post(`/serviceMerchants/${params.id}/uploadImage`, params)
        let imageUri = res.data.url
        return imageUri
    } catch (err) {
        throw err
    }
}

export default {
    newId: newId,
    save: save,
    isEmailUnique: isEmailUnique,
    isContactUnique: isContactUnique,
    uploadImages: uploadImages,
}