import apibase from './BaseAPI';

const requestDeleteAccount = async (email) => {
    const params = { email: email }
    try {
        const res = await apibase.post(`/users/requestDeleteAccount`, params)
        return res.data
    } catch (error) {
        throw error
    }
}

export default {
    requestDeleteAccount: requestDeleteAccount,
}