import React, { useState } from 'react';
import PropTypes from 'prop-types';
import lib from "../businesses/lib";
import { useTranslation } from 'react-i18next';


function DataSelectionInput(props) {
    const { t } = useTranslation();

    let values = [{ label: (props.title ? props.title : 'Please select one'), value: (props.title ? props.title : 'Please select one'),
                    translateKey: (props.title ? props.title : t('please-select-one')) }];
    props.values.forEach(obj => {
        values.push(obj);
    });
    let valueList = values.length > 0 && values.map((element, idx) => {
        return (
            <option key={idx} value={element.value}>{props.translate ? t(element.translateKey) : element.label}</option>
        )
    });

    const onChanged = (event) => {
        lib.log(event.target.value)
        if (props.callback) props.callback({
            id: props.callbackId,
            value: event.target.value
        });

    }

    return (
        <div className="form-group">
            <select className="form-control" id={props.title} name={props.title} onChange={onChanged} value={props.value}
                disabled={props.disabled}>
                {valueList}
            </select>
        </div >
    );
}

DataSelectionInput.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    values: PropTypes.array,
    callbackId: PropTypes.string,
    callback: PropTypes.func,
    disabled: PropTypes.bool,
    translate: PropTypes.bool,
};

export default DataSelectionInput;