import apibase from './BaseAPI';

const newId = async () => {
    const url = `/features/property/newId`;
    try {
        const result = await apibase.post(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const save = async (property) => {
    const res = await apibase.post(`/features/property/save`, property);
    return res.data;
};

const search = async (search, active, currentLength) => {
    const param = {
        search: search,
        active: active,
        currentLength: currentLength
    }
    const res = await apibase.post(`/features/property/search`, param);
    return res.data;
};

const id = async (id) => {
    const url = `/features/property/${id}`;
    try {
        const result = await apibase.get(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const validate = async (id) => {
    const param = {
        id: id
    }
    try {
        const res = await apibase.post(`/features/property/validate`, param);
        return res.data;
    } catch (err) {
        throw err;
    }
};

const remove = async (id) => {
    const param = {
        id: id
    }
    try {
        const res = await apibase.post(`/features/property/remove`, param);
        return res.data;
    } catch (err) {
        throw err;
    }
};

const webSearch = async (paging) => {
    let params = {};
    if (paging) params.paging = paging;
    const res = await apibase.post(`features/property/webSearch`, params);
    return res.data;
};

export default {
    save: save,
    search: search,
    id: id,
    newId: newId,
    validate: validate,
    remove: remove,
    webSearch: webSearch,
};

