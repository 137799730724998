import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link } from 'react-router-dom'
import SetupData from "../data/SetupData";
import propertyComponent from "../businesses/property";
import featurePropertyComponent from "../businesses/FeaturePropertyComponent";
import lib from "../businesses/lib";
import global from '../businesses/global';
import { MdClose } from "react-icons/md";
import "react-rater/lib/react-rater.css";
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";
import PropertySuggestion from "../components/PropertySuggestion";
import AgentSuggestion from "../components/AgentSuggestion";
import Avatar from "../components/Avatar";
import moment from "moment";
import {
    Row, Col, Card, Button, CardImg, CardBody, CardTitle, CardSubtitle, Container,
    Label, DropdownMenu, DropdownToggle, DropdownItem, UncontrolledButtonDropdown
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { trackPromise } from "react-promise-tracker";
import seoComponent from "../businesses/seoComponent";
import Multiselect from 'multiselect-react-dropdown';
import NumberFormat from "react-number-format";
import LoadingIndicator from "../components/LoadingIndicator";
import "../styles/pages/homeRevamp.scss";
import sharedComponent from "../businesses/sharedComponent";
import google from "../assets/img/store-play.png";
import apple from "../assets/img/store-apple.png";
import locIcon from "../assets/img/pin_rounded_circle.svg"
import Carousel from "react-elastic-carousel";
import SlickSlider from "react-slick";
import house from "../assets/img/housegrey.svg";
import car from "../assets/img/cargrey.svg";
import shower from "../assets/img/bathgrey.svg";
import bed from "../assets/img/bedgrey.svg";
import thumbup from "../assets/img/thumbup.svg";
import exploreArrow from "../assets/img/explore-arrow-right.svg";
import searchIcon from "../assets/img/search-icon.svg";
import landGreyIcon from "../assets/img/icon_land_grey.svg";
import mortgageCalculator from "../assets/img/mortgage-calculator.png";
import kl from "../assets/home/special-kl.png";
import johor from "../assets/home/special-johor.png";
import selangor from "../assets/home/special-selangor.png";
import penang from "../assets/home/special-penang.png";
import condo from "../assets/home/prop-condo.png";
import apartment from "../assets/home/prop-apartment.png";
import developer from "../assets/home/prop-developer.png";
import terrace from "../assets/home/prop-terrace.png";
import nextsixAppEmblem from "../assets/home/nextsix-app-emblem.png";
import searchLogComponent from "../businesses/SearchLogComponent";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setRefresh2 } from "../redux/actions/chartActions";
import { close } from "../redux/actions/emblemActions";
import BlogComponent from "../businesses/BlogComponent";
import clsx from "clsx";
import HomeMarkupList from "../components/SEO/HomeMarkupList"; //seo
// import greenKetupatLogo from "../assets/img/Green.svg";

// Services.sendFBMobilePropertySearchEvent(authUser.email, {
//   fb_content_type: [‘home_listing’, ‘product’],
//   fb_content_id: result?.data?.map(item => item._id),
//   fb_city: params.area ?? ‘’ ,
//   fb_region: params.state ?? ‘Wilayah Persekutuan Kuala Lumpur’,
//   fb_country: params.country ?? ‘Malaysia’,
//   content_type: ‘home_listing’,
//   content_ids: result?.data?.map(item => item._id),
//   city: params.area ?? ‘’,
//   region: params.state ?? ‘Wilayah Persekutuan Kuala Lumpur’,
//   country:params.country ?? ‘Malaysia’,
//   availability: params.for == “Buy” ? “for_sale” : “for_rent”
// }).then((fbResult)=>lib.log(“fbResult”, fbResult));

const HomeRevamp = (props) => {
    const dispatch = useDispatch();
    const refreshLang = useSelector(state => state.chart);
    const emblem = useSelector(state => state.emblem);
    const { t } = useTranslation();
    const [imageTimeStamp] = useState(new Date().getTime())
    const [featureProperties, setfeatureProperties] = useState([]);
    const [rentProperties, setRentProperties] = useState([]);
    const [saleProperties, setSaleProperties] = useState([]);
    const [advertisements, setAdvertisements] = useState([]);
    const ref = useRef();
    const [filter, setFilter] = useState();
    const [searchFor, setSearchFor] = useState("Property");
    const targetSelect = filter ? filter.target : []; //for display dropdown list
    const propertyTitleSelect = filter ? filter.titles : [];
    const [propertyTypeSelect, setPropertyTypeSelect] = useState([]);
    const bedValueSelect = filter ? filter.beds : [];
    const minPriceValueSelect = filter ? filter.price.rent : [];
    const maxPriceValueSelect = filter ? filter.price.buy : [];
    const auctionTypeSelect = filter?.bidNowPropertyTypes || [];
    const [target, setTarget] = useState(global.BUY); //For display dropdown label change
    const [propertyTitle, setPropertyTitle] = useState();
    const [beds, setBeds] = useState();
    const [minPrice, setMinPrice] = useState();
    const [maxPrice, setMaxPrice] = useState();

    const [newSearch, setNewSearch] = useState({
        target: '',
        query: { //textbox suggestion's returned value
            value: '',
            option: {
                quickSearchGroup: '',
                quickSearchValue: '', //optional
                quickSearchArea: '', //optional
                quickSearchState: '', //optional
                quickSearchProperty: '' //optional
            }
        },
        titleOrType: '',
        //title: '',
        types: [],
        //selectAllTypes: false,
        bed: '', //single selection
        minPrice: null,
        maxPrice: null,
    });
    const [states, setStates] = useState([]);
    const [areas, setAreas] = useState([]);
    const [availableAreas, setAvailableAreas] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    // const [selectedArea, setSelectedArea] = useState("");
    const [agentSearch, setAgentSearch] = useState({});
    const breakPoints = [
        { width: 1, itemsToShow: 1, itemsToScroll: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 3, itemsToScroll: 3 },
        { width: 1200, itemsToShow: 4, itemsToScroll: 4 },
    ];
    const getwidth = window.innerWidth;

    const [displayTarget, setDisplayTarget] = useState(null);
    const [displayTitle, setDisplayTitle] = useState(null)
    //const divBlog = useRef(null);

    useEffect(() => {
        if (refreshLang) {
            dispatch(setRefresh2(false));
        }
    }, [refreshLang]);

    const usePrevious = (value) => {
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    useEffect(() => {
        propertyTitleSelect.forEach((title) => {
            // console.log(title)
            if (newSearch.titleOrType === title.value) {
                setPropertyTypeSelect(title.type);
                return;
            }
        });
    }, [newSearch.titleOrType]);
    /////////////////
    useEffect(() => {

        (async () => {
            const filterData = await trackPromise(propertyComponent.getFilter());
            if (newSearch.target == global.AUCTION) {
                if (newSearch.titleOrType) {
                    const matchTitleTarget = filterData.bidNowPropertyTypes.find(({ value }) => value === newSearch.titleOrType);
                    if (matchTitleTarget?.title) setDisplayTitle(matchTitleTarget.title);
                }
            }
            else {
                let matchTarget = filterData.titles.find(({ value }) => value.toLowerCase() === propertyTitle);
                if (matchTarget) {
                    setDisplayTitle(matchTarget.title);
                    // console.log(matchTarget.label)
                }
            }

        })();

    }, [propertyTitle])
    //////////////   

    useEffect(() => {
        (async () => {
            const filterData = await trackPromise(propertyComponent.getFilter());
            let matchTarget = filterData.target.find(({ value }) => value.toLowerCase() === target);
            if (matchTarget) {
                setDisplayTarget(matchTarget.label);
                // console.log(matchTarget.label)
            }
        })();
        //console.log(`target changed: ${target}`);
    }, [target])

    const prevPropertyTitle = usePrevious(propertyTitle);

    useEffect(() => {
        if (propertyTitle !== prevPropertyTitle) assignNewSearchValue("types", [])

        // if (propertyTypeSelect && ((newSearch.types.length) === (propertyTypeSelect.length) || (newSearch.titleOrType && newSearch.types.length === 0))) {
        //     newSearch.selectAllTypes = true
        // }
        // else { newSearch.selectAllTypes = false }

    }, [newSearch])

    const goPropertySearch = () => {
        const newUrl = handlePropertySearch();
        if (newUrl) window.location = newUrl;
    };

    const handlePropertySearch = () => {
        return seoComponent.createSearchUrl(newSearch);
    };

    const handleAgentSearch = () => {
        let newUrl = seoComponent.createSearchAgentUrl(agentSearch);
        return window.location = newUrl;
    };

    const handleSeeMore = search => {
        let newUrl = seoComponent.createSearchPropertyUrl(search);
        window.location = newUrl;
    };

    const handleViewProperty = search => {
        let newUrl = seoComponent.createSearchPropertyUrl(search);
        window.location = newUrl;
    };

    const handleViewSpecialist = (search) => {
        let newUrl = seoComponent.createSearchAgentUrl(search);
        window.location = newUrl;
    };


    useEffect(() => {
        const getData = async () => {
            // const advertisementFound = await trackPromise(propertyComponent.getWebsiteBanner());
            // setAdvertisements(advertisementFound);
            // lib.log(advertisementFound)
            // const filterFound = await trackPromise(propertyComponent.getFilter());
            // setFilter(filterFound);
            // // lib.log(filterFound)
            // const activeFeatured = await trackPromise(featurePropertyComponent.webSearch());
            // setfeatureProperties(activeFeatured.data);
            // // lib.log(activeFeatured.data);
            // const RentFound = await trackPromise(propertyComponent.searchHomeLatest("Rent"));
            // setRentProperties(RentFound.data);
            // // lib.log(RentFound.data);
            // const SaleFound = await trackPromise(propertyComponent.searchHomeLatest("Buy"));
            // setSaleProperties(SaleFound.data);
            // // lib.log(SaleFound.data);

            const resultSet = await trackPromise(Promise.all([
                propertyComponent.getWebsiteBanner(),
                propertyComponent.getFilter(),
                featurePropertyComponent.webSearch(),
                propertyComponent.searchHomeLatest()
            ]));

            setAdvertisements(resultSet[0]);
            setFilter(resultSet[1]);
            setfeatureProperties(resultSet[2].data);
            setSaleProperties(resultSet[3].forSale);
            setRentProperties(resultSet[3].forRent);
        };
        getData();
        getAreas();

        if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
            window.fbq('track', 'PageView');
            console.log(`fbq('track')`);
        }
    }, []);

    const getAreas = async () => {
        const result = await trackPromise(sharedComponent.getAreas());
        // console.log(result)
        setAreas(result);
        let allStates = [];
        result.forEach((obj) => {
            allStates.push(obj.state);
        });
        setStates(allStates);
    };

    useEffect(() => {
        areas.forEach((obj) => {
            if (obj.state === selectedState || obj.state.toLowerCase() === agentSearch.state) {
                setAvailableAreas(obj.areas);
                return;
            }
        });
    }, [selectedState, areas, agentSearch.state]);

    const handlePopulateData = (property) => {
        localStorage.setItem('from', "listing");
        const newUrl = seoComponent.createPropertyDetailUrl(property);
        return newUrl;
    };

    const handleCallback = (cb) => {
        localStorage.setItem('selectedProperty', JSON.stringify(cb));
        assignNewSearchValue("state", "");
        assignNewSearchValue("area", "");
        assignNewSearchValue("freeText", "");
        assignNewSearchValue("propertyName", "");
        assignNewSearchValue("aiSearchDisplay", "");
        assignNewSearchValue("aiSearchContext", null);
        assignNewSearchValue("realestateMaster", null);
        // lib.log('suggestion cb')
        // lib.log(cb);

        const group = cb.value.option.quickSearchGroup;
        const groupConstant = seoComponent.propertyGroupConstant;
        if (group === groupConstant.groupState || group === groupConstant.groupArea) {
            assignNewSearchValue("state", cb.value.option.quickSearchState);
            if (group === groupConstant.groupArea) assignNewSearchValue("area", cb.value.option.quickSearchArea);
        }
        else if (group === groupConstant.groupProperty) {
            //do something with _id (if any)
            assignNewSearchValue("propertyName", cb.value.value);
        }
        else if (group === groupConstant.groupPropertyMaster) {
            assignNewSearchValue("realestateMaster", cb.value.option._id);
        }
        else if (group === groupConstant.groupNone) {
            assignNewSearchValue("freeText", cb.value.value);
        }
        else if (group === groupConstant.groupAiProperty) {
            assignNewSearchValue("aiSearchDisplay", cb.value.value);
            assignNewSearchValue("aiSearchContext", cb.value.option.aiSearchContext);
        }
    };

    const assignNewSearchValue = (name, value) => {
        // lib.log(name, value);
        setNewSearch((search) => ({
            ...search,
            [name]: value,
        }));

    };

    const handleNewSearchChange = (event) => {
        lib.log(event.target.name, event.target.value);

        console.log(`-> ${event.target.name}: ${event.target.value}`);
        if (event.target.name === "target") {
            setTarget(event.target.value)
            assignNewSearchValue("titleOrType", null)
            setPropertyTitle(null)
            assignNewSearchValue("types", null)
        }
        if (event.target.name === "target" && target !== event.target.value) {
            setMinPrice(null)
            setMaxPrice(null)
        }

        if (event.target.name === "titleOrType") {
            setPropertyTitle(event.target.value)

        }
        // if (event.target.name === "type") setPropertyType(event.target.value)
        if (event.target.name === "bed") setBeds(event.target.value)
        if (event.target.name === "minPrice") setMinPrice(event.target.value)
        if (event.target.name === "maxPrice") setMaxPrice(event.target.value)

        assignNewSearchValue([event.target.name], event.target.value);
    };

    const handleAgentCallback = (cb) => {
        // console.log("AGENT CALLBACK")
        // console.log(cb)
        assignAgentSearchValue(cb.id, cb.value)
    }

    const assignAgentSearchValue = (name, value) => {
        lib.log("AGENT SEARCH VALUE");
        lib.log(name, value);
        setAgentSearch((search) => ({
            ...search,
            [name]: value,
        }));

    };

    const handleAgentSearchChange = (event) => {
        lib.log(event.target.name, event.target.value);
        if (event.target.name === "state") setSelectedState(event.target.value)
        if (event.target.name === "state" && event.target.name !== selectedState) {
            // setSelectedArea("")
            setAvailableAreas([])
            assignAgentSearchValue("area", "")
        }
        // if (event.target.name === "area") setSelectedArea(event.target.value)
        assignAgentSearchValue([event.target.name], event.target.value);
    };

    //search for property or agent
    const handleSearchFor = (value) => {
        setSearchFor(value);
    };

    const handleButtonDropdown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    //for PROPERTY OR AGENT
    const searchForSelected = (selectedSearchFor) => {
        return selectedSearchFor === searchFor
            ? "active"
            : "";
    };

    const featurePropertyInfo = (
        <>{featureProperties.length > 0 ?
            <Container className="property-row-wrapper2">
                <div className="property-row-content2">
                    {featureProperties.length > 0 && (
                        <Fragment>
                            <div className="property-row-headerRow2">
                                <p className="property-row-header2">{t('feature-listing')}</p>
                            </div>
                            <Carousel
                                breakPoints={breakPoints}
                                enableSwipe={true}
                                showArrows={true}
                                enableAutoPlay={false}
                                pagination={false}
                                className="property-row-carousel2">
                                {featureProperties.map((properties, idx) => {
                                    let property = properties.property;
                                    if (property.carParks = 0) {
                                        property.carParks = null
                                    }
                                    return (
                                        <a href={handlePopulateData(property)} className="property-row-redirectLink2" key={idx}>
                                            <Card className={`mb-3 property-row-card2 ${property.isBestDeal ? "bestDeal-card" : "noborder"}`}
                                                style={{ cursor: "pointer" }}
                                                title={`View ${property.name}`}
                                            // onClick={() => handlePopulateData(property)}
                                            >
                                                <CardImg
                                                    className="property-row-cardImg2"
                                                    src={
                                                        property.images && property.images.length > 0
                                                            ? `${property.images[0]}?${imageTimeStamp}`
                                                            : ""
                                                    }
                                                    alt={property && (property.for == "Rent" || property.for == "Buy") && (property.type + " " + (property.for == "Rent" ? "For Rent" : "For Sale") + " in " + property.address?.area + " " + property.address?.state.replace(`Wilayah Persekutuan`, ``).trim())}
                                                />
                                                <div className="property-row-body2">
                                                    {/* unset justifyContent to aovid alignment changes  */}
                                                    <div className="property-row-tagGroup2" style={{ justifyContent: "unset" }}>
                                                        {property.isBestDeal &&
                                                            <div className="property-row-saleTag2 bestDeal">
                                                                <img loading="lazy" src={thumbup} className="bestDeal-icon" alt="" />
                                                                <span>Best Deal</span>
                                                            </div>
                                                        }
                                                        <div className="property-row-saleTag2">
                                                            {property.for === "Buy" ? t('sale') : t('rent')}
                                                        </div>
                                                        {property.rentType === 'Room Only' && <div className="property-row-saleTag2 rent-type">
                                                            {property.rentType}
                                                        </div>}
                                                        {/* <div className="property-row-createdTag2">
                                                {`${t('last-updated-on')} ` + moment(property.created).format("DD/MM/YYYY")}</div> */}
                                                    </div>
                                                    <div className="property-row-locGroup2">
                                                        <img loading="lazy" src={locIcon} className="locIcon2" alt="" />
                                                        <p className="property-row-locText2">{property.address.area}, {property.address.state}</p>
                                                    </div>
                                                    <div>
                                                        {property.for && property.for.toLowerCase() === "rent" && (
                                                            <div className="property-row-priceText2">
                                                                {`RM ${property.rent?.monthlyRental?.toLocaleString()}/${t('month')}`}
                                                            </div>
                                                        )}
                                                        {property.for && property.for.toLowerCase() === "buy" && (
                                                            <div className="property-row-priceText2">
                                                                RM {property.buy?.price?.toLocaleString()}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="property-row-nameText2">
                                                        {property.name}&nbsp;
                                                    </div>
                                                    <div className="property-row-iconsGroup2-wrapper">
                                                    {property.beds != 0 && property.beds && (
                                                        <span className="property-row-iconsGroup2">
                                                            <img loading="lazy" src={bed} alt="" className="property-row-iconImg2" />
                                                            <span className="property-row-iconText2">{property.beds}
                                                            </span></span>
                                                    )}
                                                    {property.baths && (
                                                        <span className="property-row-iconsGroup2">
                                                            <img loading="lazy" src={shower} alt="" className="property-row-iconImg2" />
                                                            <span className="property-row-iconText2">{property.baths}</span>
                                                        </span>
                                                    )}
                                                    {property.carParks != 0 && property.carParks && (
                                                        <span className="property-row-iconsGroup2">
                                                            <img loading="lazy" src={car} alt="" className="property-row-iconImg2" />
                                                            <span className="property-row-iconText2">{property.carParks}</span>

                                                        </span>
                                                    )}
                                                    {(() => {
                                                        if (property.title === 'Agricultural Land' || property.title === 'Commercial Land'
                                                            || property.title === 'Industrial Land' || property.title === 'Residential Land') {
                                                            if (property.landArea) {
                                                                return <span className="property-row-iconsGroup2">
                                                                    <img loading="lazy" src={landGreyIcon} alt="" className="property-row-iconImg2" />
                                                                    <span className="property-row-iconText2">{property.landArea} sqft</span>
                                                                </span>
                                                            }
                                                        } else {
                                                            if (property.buildup) {
                                                                return <span className="property-row-iconsGroup2">
                                                                    <img loading="lazy" src={house} alt="" className="property-row-iconImg2" />
                                                                    <span className="property-row-iconText2">{property.buildup} sqft</span>
                                                                </span>
                                                            }
                                                        }
                                                    })()}
                                                    </div>
                                                </div>
                                                {property.agent && (
                                                    <>
                                                        <div className="property-row-footer2">
                                                            <div className="property-row-footerGroup2">
                                                                <Avatar
                                                                    className="property-row-footerAvatar2"
                                                                    src={
                                                                        property.agent.image
                                                                            ? property.agent.image
                                                                            : SetupData.profile.imgURL
                                                                    }
                                                                    alt={property.agent && property.agent.area && property.agent.state && ((property.agent.nickname ? property.agent.nickname : property.agent.name) + " Property Agent in " + property.agent?.area + " " + property.agent?.state.replace(`Wilayah Persekutuan`, ``).trim())}
                                                                />
                                                                <div className="property-row-footerAgentInfo2">
                                                                    <span className="property-row-footerCompany2">{property.agent.companyName && property.agent.renId && property.agent.renId !== "Private Advertiser" ? property.agent.companyName : "Private Advertiser"}</span>
                                                                    <span className="property-row-footerAgentName2">{property.agent.nickname || property.agent.name}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </Card>
                                        </a>
                                    );
                                })
                                }
                            </Carousel>
                        </Fragment>
                    )}
                </div>
            </Container> : null}
        </>
    )

    const rentPropertyInfo = (
        <>{rentProperties.length > 0 ?
            <Container className="property-row-wrapper2">
                <div className="property-row-content2">

                    <Fragment>
                        <div className="property-row-headerRow2">
                            <p className="property-row-header2">{t('latest-rent')}</p>
                            <button className="property-row-seeMoreButton2" onClick={() => handleSeeMore({ target: "properties-for-rent" })}>{t('see-more')}</button>
                        </div>
                        <Carousel
                            breakPoints={breakPoints}
                            enableSwipe={true}
                            showArrows={true}
                            enableAutoPlay={false}
                            pagination={false}
                            className="property-row-carousel2"
                        >
                            {/* <PropertyCard property={rentProperties} type={"properties"} callback={handlePopulateData} showAgent={true}/> */}
                            {rentProperties.map((property, index) => {
                                return (
                                    <a href={handlePopulateData(property)} className="property-row-redirectLink2 property-row-redirectLink2-v2" key={index}>
                                        <Card
                                            className={`mb-3 property-row-card2 ${property.isBestDeal ? "bestDeal-card" : "noborder"}`}
                                            style={{ cursor: "pointer" }}
                                            title={`View ${property.name}`}
                                        // onClick={() => handlePopulateData(property)}
                                        >
                                            <CardImg
                                                className="property-row-cardImg2"
                                                src={
                                                    property.images && property.images.length > 0
                                                        ? `${property.images[0]}?${imageTimeStamp}`
                                                        : ""
                                                }
                                                alt={property && (property.for == "Rent" || property.for == "Buy") && (property.type + " " + (property.for == "Rent" ? "For Rent" : "For Sale") + " in " + property.address?.area + " " + property.address?.state.replace(`Wilayah Persekutuan`, ``).trim())}
                                            />
                                            <div className="property-row-body2 property-list-card-row-body2-v2">
                                                <div className="property-row-tagGroup2">
                                                    <div className="property-row-saleTag2-wrapper">
                                                    {property.isBestDeal &&
                                                    <div className="property-row-saleTag2 bestDeal">
                                                        <img loading="lazy" src={thumbup} className="bestDeal-icon" alt="" />
                                                        <span>Best Deal</span>
                                                    </div>
                                                    }
                                                    <div style={{display: "flex"}}>
                                                    <div className="property-row-saleTag2">
                                                        {property.for === "Buy" ? t('sale') : t('rent')}
                                                    </div>
                                                    {property.rentType === 'Room Only' && <div className="property-row-saleTag2 rent-type">
                                                        {property.rentType}
                                                    </div>}
                                                    </div>
                                                    </div>
                                                    <div className="property-row-createdTag2 property-row-createdTag2-v2">
                                                        {`${t('last-updated-on')} ` + moment(property.updated).format("DD/MM/YYYY")}</div>
                                                </div>
                                                <div className="property-row-locGroup2-wrapper">
                                                <div className="property-row-locGroup2">
                                                    <img loading="lazy" src={locIcon} className="locIcon2" alt="" />
                                                    <p className="property-row-locText2">{property.address.area}, {property.address.state}</p>
                                                </div>
                                                <div>
                                                    {property.for && property.for.toLowerCase() === "rent" && (
                                                        <div className="property-row-priceText2">
                                                            {`RM ${property.rent?.monthlyRental?.toLocaleString()}/${t('month')}`}
                                                        </div>
                                                    )}
                                                    {property.for && property.for.toLowerCase() === "buy" && (
                                                        <div className="property-row-priceText2">
                                                            RM {property.buy?.price?.toLocaleString()}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="property-row-nameText2">
                                                    {property.name}&nbsp;
                                                </div>
                                                <div className="property-row-iconsGroup2-wrapper">
                                                {property.beds != 0 && property.beds && (
                                                    <span className="property-row-iconsGroup2">
                                                        <img loading="lazy" src={bed} alt="" className="property-row-iconImg2" />
                                                        <span className="property-row-iconText2">{property.beds}
                                                        </span></span>
                                                )}
                                                {property.baths && (
                                                    <span className="property-row-iconsGroup2">
                                                        <img loading="lazy" src={shower} alt="" className="property-row-iconImg2" />
                                                        <span className="property-row-iconText2">{property.baths}</span>
                                                    </span>
                                                )}
                                                {property.carParks != 0 && property.carParks && (
                                                    <span className="property-row-iconsGroup2">
                                                        <img loading="lazy" src={car} alt="" className="property-row-iconImg2" />
                                                        <span className="property-row-iconText2">{property.carParks}</span>

                                                    </span>
                                                )}
                                                {(() => {
                                                    if (property.title === 'Agricultural Land' || property.title === 'Commercial Land'
                                                        || property.title === 'Industrial Land' || property.title === 'Residential Land') {
                                                        if (property.landArea) {
                                                            return <span className="property-row-iconsGroup2">
                                                                <img loading="lazy" src={landGreyIcon} alt="" className="property-row-iconImg2" />
                                                                <span className="property-row-iconText2">{property.landArea} sqft</span>
                                                            </span>
                                                        }
                                                    } else {
                                                        if (property.buildup) {
                                                            return <span className="property-row-iconsGroup2">
                                                                <img loading="lazy" src={house} alt="" className="property-row-iconImg2" />
                                                                <span className="property-row-iconText2">{property.buildup} sqft</span>
                                                            </span>
                                                        }
                                                    }
                                                })()}
                                                </div>
                                                </div>
                                            </div>
                                            {property.agent && (
                                                <>
                                                    <div className="property-row-footer2">
                                                        <div className="property-row-footerGroup2">
                                                            <Avatar
                                                                className="property-row-footerAvatar2"
                                                                src={
                                                                    property.agent.image
                                                                        ? property.agent.image
                                                                        : SetupData.profile.imgURL
                                                                }
                                                                alt={property.agent && property.agent.area && property.agent.state && ((property.agent.nickname ? property.agent.nickname : property.agent.name) + " Property Agent in " + property.agent?.area + " " + property.agent?.state.replace(`Wilayah Persekutuan`, ``).trim())}
                                                            />
                                                            <div className="property-row-footerAgentInfo2">
                                                                <span className="property-row-footerCompany2">{property.agent.companyName && property.agent.renId && property.agent.renId !== "Private Advertiser" ? property.agent.companyName : "Private Advertiser"}</span>
                                                                <span className="property-row-footerAgentName2">{property.agent.nickname || property.agent.name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </Card>
                                    </a>
                                )
                            })
                            }
                        </Carousel>
                    </Fragment>

                </div>
            </Container> : null}
        </>
    );

    const salePropertyInfo = (
        <>{saleProperties.length > 0 ?
            <Container className="property-row-wrapper2">
                <div className="property-row-content2">
                    <Fragment>
                        <div className="property-row-headerRow2">
                            {/* <p className="property-row-header2">{t('latest-sale')}  {((etaStart <= today) && (today <= etaEnd)) && <img src={greenKetupatLogo} className="home-revamp-ketupat"></img>} </p> */}
                            <p className="property-row-header2">{t('latest-sale')}</p>
                            <button className="property-row-seeMoreButton2" onClick={() => handleSeeMore({ target: "properties-for-sale" })}>{t('see-more')}</button>
                        </div>
                        <Carousel
                            breakPoints={breakPoints}
                            enableSwipe={true}
                            showArrows={true}
                            enableAutoPlay={false}
                            pagination={false}
                            className="property-row-carousel2">
                            {saleProperties.map((property, index) => {
                                return (
                                    <a href={handlePopulateData(property)} className="property-row-redirectLink2 property-row-redirectLink2-v2" key={index}>
                                        <Card
                                            className={`mb-3 property-row-card2 ${property.isBestDeal ? "bestDeal-card" : "noborder"}`}
                                            style={{ cursor: "pointer" }}
                                            title={`View ${property.name}`}
                                        // onClick={() => handlePopulateData(property)}
                                        >
                                            <CardImg
                                                className="property-row-cardImg2"
                                                src={
                                                    property.images && property.images.length > 0
                                                        ? `${property.images[0]}?${imageTimeStamp}`
                                                        : ""
                                                }
                                                alt={property && (property.for == "Rent" || property.for == "Buy") && (property.type + " " + (property.for == "Rent" ? "For Rent" : "For Sale") + " in " + property.address?.area + " " + property.address?.state.replace(`Wilayah Persekutuan`, ``).trim())}
                                            />
                                            <div className="property-row-body2-wrapper">
                                            <div className="property-row-body2">
                                                <div className="property-row-tagGroup2">
                                                    {property.isBestDeal &&
                                                        <div className="property-row-saleTag2 bestDeal">
                                                            <img loading="lazy" src={thumbup} className="bestDeal-icon" alt="" />
                                                            <span>Best Deal</span>
                                                        </div>
                                                    }
                                                    <div className="property-row-saleTag2">
                                                        {property.for === "Buy" ? t('sale') : t('rent')}
                                                    </div>
                                                    <div className="property-row-createdTag2 property-row-createdTag2-v2">
                                                        {`${t('last-updated-on')} ` + moment(property.updated).format("DD/MM/YYYY")}</div>
                                                </div>
                                                <div className="property-row-locGroup2-wrapper">
                                                <div className="property-row-locGroup2">
                                                    <img loading="lazy" src={locIcon} className="locIcon2" alt="" />
                                                    <p className="property-row-locText2">{property.address.area}, {property.address.state}</p>
                                                </div>
                                                <div>
                                                    {property.for && property.for.toLowerCase() === "rent" && (
                                                        <div className="property-row-priceText2">
                                                            {`RM ${property.rent?.monthlyRental?.toLocaleString()}/${t('month')}`}
                                                        </div>
                                                    )}
                                                    {property.for && property.for.toLowerCase() === "buy" && (
                                                        <div className="property-row-priceText2">
                                                            RM {property.buy?.price?.toLocaleString()}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="property-row-nameText2">
                                                    {property.name}&nbsp;
                                                </div>
                                                <div className="property-row-iconsGroup2-wrapper">
                                                {property.beds != 0 && property.beds && (
                                                    <span className="property-row-iconsGroup2">
                                                        <img loading="lazy" src={bed} alt="" className="property-row-iconImg2" />
                                                        <span className="property-row-iconText2">{property.beds}
                                                        </span></span>
                                                )}
                                                {property.baths && (
                                                    <span className="property-row-iconsGroup2">
                                                        <img loading="lazy" src={shower} alt="" className="property-row-iconImg2" />
                                                        <span className="property-row-iconText2">{property.baths}</span>
                                                    </span>
                                                )}
                                                {property.carParks != 0 && property.carParks && (
                                                    <span className="property-row-iconsGroup2">
                                                        <img loading="lazy" src={car} alt="" className="property-row-iconImg2" />
                                                        <span className="property-row-iconText2">{property.carParks}</span>
                                                    </span>
                                                )}
                                                {(() => {
                                                    if (property.title === 'Agricultural Land' || property.title === 'Commercial Land'
                                                        || property.title === 'Industrial Land' || property.title === 'Residential Land') {
                                                        if (property.landArea) {
                                                            return <span className="property-row-iconsGroup2">
                                                                <img loading="lazy" src={landGreyIcon} alt="" className="property-row-iconImg2" />
                                                                <span className="property-row-iconText2">{property.landArea} sqft</span>
                                                            </span>
                                                        }
                                                    } else {
                                                        if (property.buildup) {
                                                            return <span className="property-row-iconsGroup2">
                                                                <img loading="lazy" src={house} alt="" className="property-row-iconImg2" />
                                                                <span className="property-row-iconText2">{property.buildup} sqft</span>
                                                            </span>
                                                        }
                                                    }
                                                })()}
                                                </div>
                                            </div>
                                            </div>
                                            {property.agent && (
                                                <>
                                                    <div className="property-row-footer2">
                                                        <div className="property-row-footerGroup2">
                                                            <Avatar
                                                                className="property-row-footerAvatar2"
                                                                src={
                                                                    property.agent.image
                                                                        ? property.agent.image
                                                                        : SetupData.profile.imgURL
                                                                }
                                                                alt={property.agent && property.agent.area && property.agent.state && ((property.agent.nickname ? property.agent.nickname : property.agent.name) + " Property Agent in " + property.agent?.area + " " + property.agent?.state.replace(`Wilayah Persekutuan`, ``).trim())}
                                                            />
                                                            <div className="property-row-footerAgentInfo2">
                                                                <span className="property-row-footerCompany2">{property.agent.companyName && property.agent.renId && property.agent.renId !== "Private Advertiser" ? property.agent.companyName : "Private Advertiser"}</span>
                                                                <span className="property-row-footerAgentName2">{property.agent.nickname || property.agent.name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            </div>
                                        </Card>
                                    </a>
                                )
                            })
                            }
                        </Carousel>
                    </Fragment>
                </div>
            </Container> : null}
        </>
    );

    const PropertyTitleDropdown = (
        newSearch.target !== global.AUCTION ?
            <UncontrolledButtonDropdown className="property-search-paramsFilter2">
                <DropdownToggle
                    caret
                    color="transparent"
                    size="m"
                    className="property-search-paramsToggle2"
                    onClick={handleButtonDropdown}>
                    {propertyTitle ?
                        <Label className="property-search-paramsLabel2">{displayTitle}</Label> :
                        // display same decode way
                        <Label className="property-search-paramsLabel2">{t('property-title')}</Label>}
                </DropdownToggle>
                <DropdownMenu
                    className={
                        propertyTitleSelect.length > 4
                            ? "search-dropdown-menu-scroll"
                            : "search-dropdown-menu"}>
                    <DropdownItem
                        name="titleOrType"
                        value={""}
                        onClick={handleNewSearchChange}>
                        {t('property-title')}
                    </DropdownItem>
                    {propertyTitleSelect.map((select, idx) => {
                        return (
                            <DropdownItem key={idx}
                                name="titleOrType"
                                value={select.value}
                                onClick={handleNewSearchChange}>
                                {select.title}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </UncontrolledButtonDropdown> :
            <UncontrolledButtonDropdown className="property-search-paramsFilter2">
                <DropdownToggle
                    caret
                    color="transparent"
                    size="m"
                    className="property-search-paramsToggle2"
                    onClick={handleButtonDropdown}>
                    {propertyTitle ?
                        <Label className="property-search-paramsLabel2">{displayTitle}</Label> :
                        <Label className="property-search-paramsLabel2">{t('property-title')}</Label>}
                </DropdownToggle>
                <DropdownMenu
                    className={
                        auctionTypeSelect.length > 4 ?
                            "search-dropdown-menu-scroll" :
                            "search-dropdown-menu"}>
                    <DropdownItem
                        name="titleOrType"
                        value={""}
                        onClick={handleNewSearchChange}>
                        {t('property-title')}
                    </DropdownItem>
                    {auctionTypeSelect.map((select, idx) => {
                        return (
                            <DropdownItem key={idx}
                                name="titleOrType"
                                value={select.value}
                                onClick={handleNewSearchChange}>
                                {select.title}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
    )

    const BedroomDropdown = (
        <UncontrolledButtonDropdown className="search-filter">
            <DropdownToggle
                caret
                color="transparent"
                size="m"
                className="property-search-paramsToggle2"
                onClick={handleButtonDropdown}>
                {beds ?
                    <Label className="property-search-paramsLabel2">{beds === "studio" ? "Studio" : beds}</Label> :
                    <Label className="property-search-paramsLabel2">{t('bedrooms-any')}</Label>}
            </DropdownToggle>
            <DropdownMenu
                className={"search-dropdown-menu"}>
                <DropdownItem
                    name="bed"
                    value={""}
                    onClick={handleNewSearchChange}>
                    {t('bedrooms-any')}
                </DropdownItem>
                {bedValueSelect.map((select, idx) => {
                    return (
                        <DropdownItem key={idx}
                            name="bed"
                            value={select.value}
                            onClick={handleNewSearchChange}>
                            {select.label}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    )

    const MinPriceDropdown = (
        newSearch.target.toLocaleLowerCase() !== global.RENT ?
            <UncontrolledButtonDropdown className="search-filter">
                <DropdownToggle
                    caret
                    color="transparent"
                    size="m"
                    className="prop-search-paramsToggle3">
                    {minPrice ?
                        <Label className="prop-search-paramsLabel3">
                            <NumberFormat
                                displayType={"text"}
                                thousandSeparator={true}
                                value={minPrice}
                                prefix={"(Min) RM"}
                                readOnly />
                        </Label> :
                        <Label className="prop-search-paramsLabel3">{t('min-price-any')}</Label>}
                </DropdownToggle>
                <DropdownMenu
                    className={
                        maxPriceValueSelect.length > 4
                            ? "search-dropdown-menu-scroll"
                            : "search-dropdown-menu"}>
                    <DropdownItem
                        name="minPrice"
                        value={""}
                        onClick={handleNewSearchChange}>
                        {t('min-price-any')}
                    </DropdownItem>
                    {maxPriceValueSelect.map((select, idx) => {
                        return (
                            <DropdownItem
                                key={idx}
                                name="minPrice"
                                value={select.value}
                                onClick={handleNewSearchChange}
                                disabled={newSearch.maxPrice && Number(newSearch.maxPrice) < (select.value === "" ? select.value : Number(select.value))}
                            >
                                RM{select.label}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </UncontrolledButtonDropdown> :
            <UncontrolledButtonDropdown className="search-filter">
                <DropdownToggle
                    caret
                    color="transparent"
                    size="m"
                    className="prop-search-paramsToggle3">
                    {minPrice ?
                        <Label className="prop-search-paramsLabel3">
                            <NumberFormat
                                displayType={"text"}
                                thousandSeparator={true}
                                value={minPrice}
                                prefix={"(Min) RM"}
                                readOnly
                            />
                        </Label> :
                        <Label className="prop-search-paramsLabel3">{t('min-price-any')}</Label>}
                </DropdownToggle>
                <DropdownMenu
                    className={
                        minPriceValueSelect.length > 4
                            ? "search-dropdown-menu-scroll"
                            : "search-dropdown-menu"}>
                    <DropdownItem
                        name="minPrice"
                        value={""}
                        onClick={handleNewSearchChange}>
                        {t('min-price-any')}
                    </DropdownItem>
                    {minPriceValueSelect.map((select, idx) => {
                        return (
                            <DropdownItem key={idx}
                                name="minPrice"
                                value={select.value}
                                onClick={handleNewSearchChange}
                                disabled={newSearch.maxPrice && Number(newSearch.maxPrice) < (select.value === "" ? select.value : Number(select.value))}
                            >
                                RM{select.label}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
    )

    const MaxPriceDropdown = (
        newSearch.target.toLocaleLowerCase() === global.RENT ?
            <UncontrolledButtonDropdown className="search-filter">
                <DropdownToggle
                    caret
                    color="transparent"
                    size="m"
                    className="propsearch-paramsToggle3">
                    {maxPrice ?
                        <Label className="prop-search-paramsLabel3">
                            <NumberFormat
                                displayType={"text"}
                                thousandSeparator={true}
                                value={maxPrice}
                                prefix={"(Max) RM"}
                                readOnly
                            />
                        </Label> :
                        <Label className="prop-search-paramsLabel3">{t('max-price-any')}</Label>}
                </DropdownToggle>
                <DropdownMenu
                    className={
                        maxPriceValueSelect.length > 4
                            ? "search-dropdown-menu-scroll"
                            : "search-dropdown-menu"}>
                    <DropdownItem
                        name="maxPrice"
                        value={""}
                        onClick={handleNewSearchChange}>
                        {t('max-price-any')}
                    </DropdownItem>
                    {minPriceValueSelect.map((select, idx) => {
                        return (
                            <DropdownItem key={idx}
                                name="maxPrice"
                                value={select.value}
                                onClick={handleNewSearchChange}
                                disabled={newSearch.minPrice && Number(newSearch.minPrice) > (select.value === "" ? "" : Number(select.value))}>
                                RM{select.label}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </UncontrolledButtonDropdown> :
            <UncontrolledButtonDropdown className="search-filter">
                <DropdownToggle
                    caret
                    color="transparent"
                    size="m"
                    className="prop-search-paramsToggle3">
                    {maxPrice ?
                        <Label className="prop-search-paramsLabel3">
                            <NumberFormat
                                displayType={"text"}
                                thousandSeparator={true}
                                value={maxPrice}
                                prefix={"(Max) RM"}
                                readOnly
                            />
                        </Label> :
                        <Label className="prop-search-paramsLabel3">{t('max-price-any')}</Label>}
                </DropdownToggle>
                <DropdownMenu className={
                    maxPriceValueSelect.length > 4
                        ? "search-dropdown-menu-scroll"
                        : "search-dropdown-menu"}>
                    <DropdownItem
                        name="maxPrice"
                        value={""}
                        onClick={handleNewSearchChange}>
                        {t('max-price-any')}
                    </DropdownItem>
                    {maxPriceValueSelect.map((select, idx) => {
                        return (
                            <DropdownItem key={idx}
                                name="maxPrice"
                                value={select.value}
                                onClick={handleNewSearchChange}
                                disabled={newSearch.minPrice && Number(newSearch.minPrice) > (select.value === "" ? "" : Number(select.value))}
                            >
                                RM{select.label}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
    )

    const PriceRangeDropdown = (
        <UncontrolledButtonDropdown>
            <DropdownToggle
                caret
                color="transparent"
                size="m"
                className="property-search-paramsToggle2">
                {newSearch && newSearch.minPrice && newSearch.maxPrice ?
                    <Label className="property-search-paramsLabel2"> RM{lib.currencyFormatter(Number(newSearch.minPrice))} - RM{lib.currencyFormatter(newSearch.maxPrice)} </Label> :
                    newSearch && newSearch.minPrice ?
                        <Label className="property-search-paramsLabel2"> RM{lib.currencyFormatter(newSearch.minPrice)} +</Label> :
                        newSearch && newSearch.maxPrice ?
                            <Label className="property-search-paramsLabel2"> RM 0 - RM{lib.currencyFormatter(newSearch.maxPrice)} </Label> :
                            <Label className="property-search-paramsLabel2">{t('any-price')}</Label>
                }
            </DropdownToggle>
            <DropdownMenu className="search-dropdown-menu priceRange-dropdownMenu">
                <div className="priceRange-dropdownMenu-label"> {MinPriceDropdown} - {MaxPriceDropdown}</div>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    )

    const StateDropdown = (
        <UncontrolledButtonDropdown className="">
            <DropdownToggle
                caret
                color="transparent"
                size="m"
                className="property-search-paramsToggle2"
                onClick={handleButtonDropdown}>
                {agentSearch && agentSearch.state ?
                    <Label className="property-search-paramsLabel2">{agentSearch.state}</Label> :
                    <Label className="property-search-paramsLabel2">{t('state')}</Label>}
            </DropdownToggle>
            <DropdownMenu className={
                states.length > 4
                    ? "search-dropdown-menu-scroll"
                    : "search-dropdown-menu"}>
                <DropdownItem
                    name="state"
                    value={""}
                    onClick={handleAgentSearchChange}>
                    {t('state')}
                </DropdownItem>
                {states.map((select, idx) => {
                    return (
                        <DropdownItem key={idx}
                            name="state"
                            value={select}
                            onClick={handleAgentSearchChange}
                        >
                            {select}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    )

    const AreaDropdown = (
        <UncontrolledButtonDropdown className="">
            <DropdownToggle
                caret
                color="transparent"
                size="m"
                className="property-search-paramsToggle2"
                onClick={handleButtonDropdown}>
                {agentSearch && agentSearch.area ?
                    <Label className="property-search-paramsLabel2">{agentSearch.state !== "" ? agentSearch.area : "Area"}</Label> :
                    <Label className="property-search-paramsLabel2">{t('area')}</Label>}
            </DropdownToggle>
            <DropdownMenu className={
                availableAreas && availableAreas.length > 4
                    ? "search-dropdown-menu-scroll"
                    : "search-dropdown-menu"}>
                <DropdownItem
                    name="area"
                    value={""}
                    onClick={handleAgentSearchChange}>
                    {t('area')}
                </DropdownItem>
                {availableAreas && availableAreas.map((select, idx) => {
                    return (
                        <DropdownItem key={idx}
                            name="area"
                            value={select}
                            onClick={handleAgentSearchChange}
                        >
                            {select}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    )

    const AgentTypeDropdown = (
        <UncontrolledButtonDropdown className="">
            <DropdownToggle
                caret
                color="transparent"
                size="m"
                className="property-search-paramsToggle2"
                onClick={handleButtonDropdown}>
                {agentSearch && agentSearch.type ?
                    <Label className="property-search-paramsLabel2">{agentSearch.type}</Label> :
                    <Label className="property-search-paramsLabel2">{t('agent-type')}</Label>}
            </DropdownToggle>
            <DropdownMenu className={
                SetupData && SetupData.agent.type.length > 4
                    ? "search-dropdown-menu-scroll"
                    : "search-dropdown-menu"}>
                <DropdownItem
                    name="type"
                    value={""}
                    onClick={handleAgentSearchChange}>
                    {t('agent-type')}
                </DropdownItem>
                {SetupData && SetupData.agent.type.map((select, idx) => {
                    return (
                        <DropdownItem key={idx}
                            name="type"
                            value={select.value}
                            onClick={handleAgentSearchChange}>
                            {select.label}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    )

    const TargetDropdown = (
        <UncontrolledButtonDropdown className="property-search-targetFilter2">
            <DropdownToggle
                caret
                color="transparent"
                size="m"
                className="property-search-targetToggle2"
                onClick={handleButtonDropdown}>
                {target ?
                    <Label className="property-search-targetLabel2">{displayTarget}</Label> :
                    <Label className="property-search-targetLabel2">Buy</Label>}
            </DropdownToggle>
            <DropdownMenu className={
                targetSelect.length > 4
                    ? "search-dropdown-menu-scroll"
                    : "search-dropdown-menu"}>
                {targetSelect.map((select, idx) => {
                    return (
                        <DropdownItem key={idx}
                            name="target"
                            value={select.value}
                            onClick={handleNewSearchChange}
                        >
                            {select.label}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    )

    const PropertyTypeDropdown = (
        newSearch.target !== global.PROJECT && <Fragment>
            <div className="property-search-paramsBlock2">
                {PropertyTitleDropdown}
                {BedroomDropdown}
                {PriceRangeDropdown}
            </div>
            {propertyTitle && !refreshLang && propertyTypeSelect && newSearch.target !== global.AUCTION && <Multiselect
                options={propertyTypeSelect}
                isObject={false}
                hidePlaceholder={true}
                placeholder={t('property-types')}
                showCheckbox={true}
                showArrow={true}
                selectedValues={newSearch.types}
                onSelect={(event) => assignNewSearchValue("types", event)}
                onRemove={(event) => assignNewSearchValue("types", event)}
                customCloseIcon={<MdClose style={{ width: "25px", height: "15px" }} />}
                closeOnSelect={false}
                avoidHighlightFirstOption={true}
                id={"home2__paramSearch__propertyType"}
            />}
        </Fragment>
    )

    const PropertySearchBar = (
        <Fragment>
            <div className="property-search-barGroup2">
                <div className="property-search-bar2">
                    {TargetDropdown}
                    <PropertySuggestion
                        callback={handleCallback}
                        callbackId={"query"}
                        target={newSearch.target || seoComponent.seoConstant.defaultPropertyTarget}
                        searchEnter={goPropertySearch}
                    />
                    <button className="property-search-action2"
                        onClick={goPropertySearch}>
                        <img loading="lazy" alt="" src={searchIcon} />
                    </button>
                </div>
            </div>
            {PropertyTypeDropdown}
        </Fragment>
    )

    const AgentSearchBar = (
        <Fragment>
            <div className="property-search-barGroup2">
                <div className="property-search-bar2">
                    <div className="quicksearch">
                        <AgentSuggestion
                            callback={handleAgentCallback}
                            callbackId={"name"}
                            searchEnter={handleAgentSearch}
                        />
                    </div>
                    <button className="property-search-action2"
                        onClick={handleAgentSearch}>
                        <img loading="lazy" alt="" src={searchIcon} />
                    </button>
                </div>
            </div>
            <div className="property-search-paramsBlock2">
                {StateDropdown}
                {AreaDropdown}
                {AgentTypeDropdown}
            </div>
        </Fragment>
    )

    useEffect(() => {
        dispatch(close("open"));
    }, [])

    const closeBanner = () => {
        dispatch(close("close"));
    }

    // useEffect(() => {
    //     if (process.env.REACT_APP_BLOG === 'true') {
    //         const divElement = divBlog.current;

    //         // Add your script loading logic here
    //         const script = document.createElement('script');
    //         script.src = 'https://widgets.sociablekit.com/rss-feed/widget.js';
    //         script.async = true;
    //         script.defer = true;
    //         divElement.appendChild(script);

    //         // Cleanup: remove the script when the component unmounts
    //         return () => {
    //             divElement.removeChild(script);
    //         };
    //     }
    // }, [divBlog]);

    return (
        <>
            <HomeMarkupList />
            {
                getwidth < 768 && emblem === "open" &&
                <div className="emblem-wrapper">
                    <div className="emblem-left-content">
                        <Button className="emblem-close-btn" onClick={() => closeBanner()}><MdClose /></Button>
                        <img loading="lazy" className="emblem-logo" src={nextsixAppEmblem} alt="" />
                        <div className="emblem-left-textbox">
                            <span className="emblem-left-text-top">NextSix App</span>
                            <span className="emblem-left-text-btm">Find properties and agents faster</span>
                        </div>
                    </div>
                    <div className="emblem-right-content">
                        <a className="emblem-download-btn" href="https://apps.nextsix.com/users/reg">Use Now</a>
                    </div>
                </div>
            }
            <Header2 />
            <Container className="property-search-wrapper2">
                <div className="property-search-content2">
                    <div className="property-search-titleGroup2">
                        <span className="property-search-firstTitle2">
                            {t('lets-find-a-home')}
                        </span>
                        <span className="property-search-secondTitle2">
                            {t('search-confidently')}
                        </span>
                    </div>
                    <div className="property-search-elements2">
                        <div className="property-search-mainCategory2">
                            <Button
                                className={searchForSelected("Property") + " searchPropertyButton"}
                                value="Property"
                                onClick={() => handleSearchFor("Property")}
                            >
                                {t('property')}
                            </Button>
                            <Button
                                className={searchForSelected("Agent") + " searchPropertyButton"}
                                value="Agent"
                                onClick={() => handleSearchFor("Agent")}
                            >
                                {t('agent')}
                            </Button>
                        </div>
                        {searchFor === "Property" ?
                            PropertySearchBar :
                            AgentSearchBar
                        }
                        <div>

                        </div>
                    </div>
                </div>
            </Container>

            <Container className="whatsnew-section2">
                {advertisements && advertisements.length > 0 &&
                    <Fragment>
                        <p className="whatsnew-section-header2">{t('whats-new')}</p>
                        <SlickSlider
                            className="whatsnew-section-slider"
                            dotsClass="whatsnew-section-slider-dot slick-dots"
                            dots={true}
                            speed={1000}
                            slidesToShow={advertisements.length < 3 ? advertisements.length : 3}
                            slidesToScroll={advertisements.length < 3 ? advertisements.length : 3}
                            autoplaySpeed={3500}
                            autoplay={true}
                            pauseOnDotsHover={true}
                            pauseOnFocus={true}
                            pauseOnHover={true}
                            responsive={[
                                {
                                    breakpoint: 1200,
                                    settings: {
                                        slidesToShow: advertisements.length < 3 ? advertisements.length : 3,
                                        slidesToScroll: advertisements.length < 3 ? advertisements.length : 3,
                                    }
                                },
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: advertisements.length < 2 ? advertisements.length : 2,
                                        slidesToScroll: advertisements.length < 2 ? advertisements.length : 2,
                                    }
                                },
                                {
                                    breakpoint: 550,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1
                                    }
                                }
                            ]}
                        >
                            {advertisements.map((adv, idx) => {
                                return (
                                    <a key={idx} href={adv.campaignURL} target="_blank" rel="noopener noreferrer">
                                        <img loading="lazy"
                                            className="whatsnew-section-adImg2"
                                            src={adv.banner}
                                            alt=""
                                        />
                                    </a>
                                )
                            })}
                        </SlickSlider>
                    </Fragment>
                }
            </Container >
            <div className="app-banner-section container">
                <div className="home-search-sub2">
                    <div className="sub-mobile-app2">
                        <div>
                            <p className="gps-text2">GPS Your Agent.</p>
                            <p className="deserve-text2">#YouDeserveABetterDeal</p>
                        </div>
                        <div className="sub-mobile-app-button2">
                            <a href="https://apps.apple.com/my/app/nextsix/id1500782680"
                                rel="noopener noreferrer"
                                target="_blank"
                                className="sub-mobile-app-play2">
                                <img loading="lazy" src={apple} className="sub-mobile-app-img2" alt="" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.nextsix.property"
                                rel="noopener noreferrer"
                                target="_blank"
                                className="sub-mobile-app-ios2">
                                <img loading="lazy" src={google} className="sub-mobile-app-img2" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <Container className="mortgage-cal-landing-wrapper">
                <Card className="mortgage-cal-landing-card">
                    <Row>
                        <Col xl={6} lg={6} md={12} sm={12}>
                            <CardImg className="mortgage-cal-card-img" src={mortgageCalculator} alt="Mortgage Calculator" />
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12}>
                            <div className="mortgage-cal-description">
                                <div>
                                    <h2>Free calculator for property loan</h2>
                                    <p className="mortgage-cal-text">Our home loan eligibiliy calculator in Malaysia can help you to estimate your monthly mortgage repayment amount.</p>
                                </div>
                                <div>
                                    <Button href="/mortgage-calculator" className="mortgage-cal-btn"><span className="mortgage-cal-btn-text">Try It Now</span></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Container>
            <div className="property-listing">
                {featurePropertyInfo}
                {salePropertyInfo}
                {rentPropertyInfo}
            </div>

            {/* {process.env.REACT_APP_BLOG === 'true' && < Container className="whatsnew-section2">
                <p className="whatsnew-section-header2">{t('Introducing The Next Six Blog')}</p>
                <div ref={divBlog} className="sk-ww-rss-feed" data-embed-id={Math.random() > 0.5 ? "218577" : "219098"}  ></div>
            </Container >} */}
            <BlogSection />

            <Container className="exploring-section2">
                <div className="exploring-section-content2">
                    <div className="exploring-section-header2">
                        <p className="exploring-seciton-title2">{t('one-click-specialist')}</p>
                        <p className="exploring-seciton-subtitle2">{t('trusted-specialist-assist')}</p>
                    </div>
                    <div className="exploring-section-grid2">
                        <div className="exploring-section-imgBox2">
                            <img loading="lazy" className="exploring-section-img2"
                                src={kl}
                                alt="" />
                            <div className="exploring-section-textGroup2">
                                <div className="exploring-section-textHeader2">
                                    <p className="exploring-section-textLineOne2">{t('specialist-in')}</p>
                                    <p className="exploring-section-textLineTwo2">{t('kuala-lumpur')}</p>
                                </div>
                                <button className="exploring-section-viewButton2"
                                    onClick={() => handleViewSpecialist({ state: 'wilayah persekutuan kuala lumpur', type: 'all specialists' })}>
                                    <span className="exploring-section-viewButtonText2">{t('view-specialist')}</span>
                                    <img loading="lazy" className="exploring-section-exploreArrow2"
                                        src={exploreArrow}
                                        alt="" />
                                </button>
                            </div>
                        </div>
                        <div className="exploring-section-imgBox2">
                            <img loading="lazy" className="exploring-section-img2"
                                src={selangor}
                                alt="" />
                            <div className="exploring-section-textGroup2">
                                <div className="exploring-section-textHeader2">
                                    <p className="exploring-section-textLineOne2">{t('specialist-in')}</p>
                                    <p className="exploring-section-textLineTwo2">{t('selangor')}</p>
                                </div>
                                <button className="exploring-section-viewButton2"
                                    onClick={() => handleViewSpecialist({ state: 'selangor', type: 'all specialists' })}>
                                    <span className="exploring-section-viewButtonText2">{t('view-specialist')}</span>
                                    <img loading="lazy" className="exploring-section-exploreArrow2"
                                        src={exploreArrow}
                                        alt="" />
                                </button>
                            </div>
                        </div>
                        <div className="exploring-section-imgBox2">
                            <img loading="lazy" className="exploring-section-img2"
                                src={johor}
                                alt="" />
                            <div className="exploring-section-textGroup2">
                                <div className="exploring-section-textHeader2">
                                    <p className="exploring-section-textLineOne2">{t('specialist-in')}</p>
                                    <p className="exploring-section-textLineTwo2">{t('johor')}</p>
                                </div>
                                <button className="exploring-section-viewButton2"
                                    onClick={() => handleViewSpecialist({ state: 'johor', type: 'all specialists' })}>
                                    <span className="exploring-section-viewButtonText2">{t('view-specialist')}</span>
                                    <img loading="lazy" className="exploring-section-exploreArrow2"
                                        src={exploreArrow}
                                        alt="" />
                                </button>
                            </div>
                        </div>
                        <div className="exploring-section-imgBox2">
                            <img loading="lazy" className="exploring-section-img2"
                                src={penang}
                                alt="" />
                            <div className="exploring-section-textGroup2">
                                <div className="exploring-section-textHeader2">
                                    <p className="exploring-section-textLineOne2">{t('specialist-in')}</p>
                                    <p className="exploring-section-textLineTwo2">{t('penang')}</p>
                                </div>
                                <button className="exploring-section-viewButton2"
                                    onClick={() => handleViewSpecialist({ state: 'penang', type: 'all specialists' })}>
                                    <span className="exploring-section-viewButtonText2">{t('view-specialist')}</span>
                                    <img loading="lazy" className="exploring-section-exploreArrow2"
                                        src={exploreArrow}
                                        alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="exploring-section-content2 explore-prop2">
                    <div className="exploring-section-header2">
                        <p className="exploring-seciton-title2">{t('explore-property')}</p>
                        <p className="exploring-seciton-subtitle2">{t('your-next-property-here')}</p>
                    </div>
                    <div className="exploring-section-grid2">
                        <div className="exploring-section-imgBox2">
                            <img loading="lazy" className="exploring-section-img2"
                                src={apartment}
                                alt="" />
                            <div className="exploring-section-textGroup2">
                                <div className="exploring-section-textHeader2">
                                    <p className="exploring-section-textLineTwo2">{t('apartment-flat')}</p>
                                </div>
                                <button className="exploring-section-viewButton2"
                                    onClick={() => handleViewProperty({ titleOrType: "apartment-flat" })}>
                                    <span className="exploring-section-viewButtonText2">{t('view-property')}</span>
                                    <img loading="lazy" className="exploring-section-exploreArrow2"
                                        src={exploreArrow}
                                        alt="" />
                                </button>
                            </div>
                        </div>
                        <div className="exploring-section-imgBox2">
                            <img loading="lazy" className="exploring-section-img2"
                                src={condo}
                                alt="" />
                            <div className="exploring-section-textGroup2">
                                <div className="exploring-section-textHeader2">
                                    <p className="exploring-section-textLineTwo2">{t('condo-service-residence')}</p>
                                </div>
                                <button className="exploring-section-viewButton2"
                                    onClick={() => handleViewProperty({ titleOrType: "condo-serviced-residence" })}>
                                    <span className="exploring-section-viewButtonText2">{t('view-property')}</span>
                                    <img loading="lazy" className="exploring-section-exploreArrow2"
                                        src={exploreArrow}
                                        alt="" />
                                </button>
                            </div>
                        </div>
                        <div className="exploring-section-imgBox2">
                            <img loading="lazy" className="exploring-section-img2"
                                src={terrace}
                                alt="" />
                            <div className="exploring-section-textGroup2">
                                <div className="exploring-section-textHeader2">
                                    {/* <p className="exploring-section-textLineTwo2">Terrace/Link/ Townhouse</p> */}
                                    <p className="exploring-section-textLineTwo2">{t('terrace-link-townhouse-ux')}</p>
                                </div>
                                <button className="exploring-section-viewButton2" onClick={() => handleViewProperty({ titleOrType: "terrace-link-townhouse" })}>
                                    <span className="exploring-section-viewButtonText2">{t('view-property')}</span>
                                    <img loading="lazy" className="exploring-section-exploreArrow2"
                                        src={exploreArrow}
                                        alt="" />
                                </button>
                            </div>
                        </div>
                        <div className="exploring-section-imgBox2">
                            <img loading="lazy" className="exploring-section-img2"
                                src={developer}
                                alt="" />
                            <div className="exploring-section-textGroup2">
                                <div className="exploring-section-textHeader2">
                                    <p className="exploring-section-textLineTwo2">{t('developer-project')}</p>
                                </div>
                                <button className="exploring-section-viewButton2" onClick={() => handleViewProperty({ target: "projects-for-sale" })}>
                                    <span className="exploring-section-viewButtonText2">{t('view-property')}</span>
                                    <img loading="lazy" className="exploring-section-exploreArrow2"
                                        src={exploreArrow}
                                        alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <Container className="description-section">
                <h1 className="title">Save Time and Effort with Our Prop-tech GPS Search for Real Estate</h1>
                <p className="subtitle">Are you looking for a reliable prop-tech company with cutting-edge tools to help you find your dream property? Look no further than our rapidly expanding company, which pioneers a GPS search function that instantly connects property seekers with professional real estate agents and available properties nearby.</p>
                <p className="subtitle">Our innovative GPS search feature utilizes the latest technology to generate a list of properties in your desired location and the contact information of knowledgeable agents who can provide further assistance. Our feature saves you time and effort in your property search and benefits real estate agents looking to expand their business.</p>
                <p className="subtitle m-0">At our prop-tech company, we are committed to providing the latest technological advancements to the real estate industry. Our GPS search function is just one example of our dedication to innovation and customer satisfaction. Trust us to help you find your ideal property for <Link to="properties-for-sale/terrace-link-townhouse" target="_blank" rel="noopener noreferrer">sale</Link> or <Link to="properties-for-rent/terrace-link-townhouse" target="_blank" rel="noopener noreferrer">rent</Link> in Malaysia.</p>
            </Container>

            <Footer2 />
        </ >
    );
};

const BlogSection = () => {
    const [posts, setPosts] = useState([]);
    const [showToMore, setShowToMore] = useState(null);
    const [disablePrev, setDisablePrev] = useState(true)
    const slider = useRef(null);

    useEffect(() => {
        const getBlogPosts = async () => {
            const blogPosts = await BlogComponent.getLatest5Posts();
            setPosts(blogPosts);
        }
        getBlogPosts();
    }, [])

    const toBlogPost = (postUrl) => {
        window.open(postUrl, "_blank", "noopener, noreferrer");
    }

    const handleBeforeChg = (current, next) => {
        // if(next === current) {
        if (next === 0) setDisablePrev(curState => ({ ...curState, prev: true }));
        else if (next === 4) setShowToMore(true);
        else {
            setDisablePrev(false);
            setShowToMore(false);
        }
        // }
        // if(next === 0 && current === next) setDisableSlickArrow(curState => ({...curState, prev: true }))
        // else setDisableSlickArrow(curState => ({...curState, prev: false }))
        // if(next === 4 && current === next) setDisableSlickArrow(curState => ({...curState, next: true }))
        // else setDisableSlickArrow(curState => ({...curState, next: false }))
    }

    const handleNextSlide = () => {
        if (showToMore) return toBlogPost("https://blog.nextsix.com/");
        else return slider?.current?.slickNext();
    }

    const PostDetails = ({ post }) => (
        <div className="blog_entry" onClick={() => toBlogPost(post.url)}>
            <div className="blog_img"><img loading="lazy" src={post.image} /></div>

            <div className="blog_detail">
                <div className="blog_category">
                    {/* {post.categories.map(cate =>
                        <div>{cate}</div>
                    )} */}
                    {post.categories}
                </div>
                <div className="blog_title">{post.title}</div>
                <div className="blog_description">{post.description}</div>
                <div className="blog_date">{lib.formatDateMDY(post.published)}</div>
            </div>
        </div>
    )

    return (
        <>
            {posts && posts.length > 0 &&
                <div className={`blog_container container`}>
                    <div className="blog_container_inner">
                        <h3>Blog</h3>
                        <SlickSlider
                            className="hide-slick-next"
                            useCSS={false} //disable transition, state change causes transition not working properly
                            ref={slider}
                            infinite={false}
                            // speed={1000}
                            draggable={false}
                            beforeChange={handleBeforeChg}
                        >
                            {posts.map(post =>
                                <PostDetails post={post} key={post.guid} />
                            )}
                        </SlickSlider>
                        <span className={clsx("slick-prev", { "slick-disabled": disablePrev })} onClick={() => slider?.current?.slickPrev()} />
                        <span className={clsx("slick-next", { "slick-custom-next": showToMore })} onClick={handleNextSlide} />
                    </div>
                </div>
            }
        </>
    )
}

export default HomeRevamp;
