import apibase from './BaseAPI';

const agentPackage =async()=>{
    const res =await apibase.post('/agentPackages/signupKeyValue');
    return res.data
}

const agentPackages = async(hidePackage) => {
    const res = await apibase.post('/agentPackages/search', hidePackage);
    return res.data
}

export default{
    agentPackage:agentPackage,
    agentPackages: agentPackages,
}