import apiAgent from '../api/agentAPI';

const getAgentInfo = async (id, increaseView) => {
    const result = await apiAgent.getAgentInfo(id, increaseView);
    return result;
}

const email = async (agent) => {
    window.open(`mailto:${agent}?subject=Property%20Enquiry&body=Hi%2C%0D%0A%0D%0AI%20am%20interested%20in%20this%20property%20listing.%0D%0A%0D%0APlease%20contact%20me%2C%20thank%20you`)
}

const getSpecialist = async (id) => {
    const result = await apiAgent.getSpecialist(id);
    return result;
}

const isActive = (active) => {
    return (active === "In Service");
};

const webSearch = async (search, paging) => {
    const result = await apiAgent.webSearch(search, paging);
    return result;
}

const agentSuggestion = async (search) => {
    const result = await apiAgent.agentSuggestion(search);
    return result;
};

const anyRating = (rating) =>{
    return (rating !== 'No Rating Yet');
};

export default {
    getAgentInfo: getAgentInfo,
    email: email,
    getSpecialist: getSpecialist,
    isActive: isActive,
    webSearch: webSearch,
    agentSuggestion: agentSuggestion,
    anyRating: anyRating,
}


