import React from 'react';
import moment from "moment";
import lib from "../../businesses/lib";
import SetupData from "../../data/SetupData";
import house from "../../assets/img/housegrey.svg";
import car from "../../assets/img/cargrey.svg";
import shower from "../../assets/img/bathgrey.svg";
import bed from "../../assets/img/bedgrey.svg";
import {
  Card,
  CardImg,
} from "reactstrap";
import Avatar from "../../components/Avatar";
import locIcon from "../../assets/img/pin_rounded_circle.svg";
import { useTranslation } from 'react-i18next';
import style from '../../styles/pages/agentSearchPage.module.scss';
import pointer from "../../assets/agentSearch/greyPointer.svg";
import star from "../../assets/agentSearch/whiteStar.svg";
import building from "../../assets/agentSearch/greyBuilding.svg";
import agentComponent from "../../businesses/agent";
// import greenKetupatLogo from "../../assets/img/Green.svg"

const AgentCard = (props) => {
  const { t } = useTranslation();
  const agent = props.agent;
  const today = new Date()
  const etaStart = new Date('2023-04-21T16:00:00.000Z')
  const etaEnd = new Date('2023-04-30T16:00:00.000Z')

  // const handlePopulateData = () => {
  //   if (props.callback) props.callback(property, props.type);
  // };
//   <div className={`${style.agentCard}`}>
//     <div className={`${style.agentCard_firstBlock}`}>
//     <img className={`${style.agentCard_agentImg}`} src={agent.image ? agent.image : SetupData.defaultPfp} alt="" />
//     <div className={`${style.agentCard_currentStatus}`}>
//         <p className={`${style.agentCard_status}`}>{agent.status}</p>
//         {
//         agentComponent.anyRating(agent.rating) &&
//         <p className={`${style.agentCard_rank}`}>{agent.rating}<img className={`${style.agentCard_rankImg}`} alt="" src={star} /></p>
//         }
//     </div>
//     </div>
//     <div className={`${style.agentCard_secondBlock}`}>
//     <p className={`${style.agentCard_name}`}>{agent.nickname ? agent.nickname : agent.name}</p>
//     {/* {agent.properties} */}
//     <div className={agent.areaSpecialists && agent.areaSpecialists.length > 0 ? `${style.agentCard_asTag}` : `${style.displayNone}`}>
//         {agent.areaSpecialists && agent.areaSpecialists.length > 0 && agent.areaSpecialists.length < 2 && (
//         <>
//             <p className={`${style.agentCard_asTag_header}`}>Area Specialist</p>
//             {agent.areaSpecialists && agent.areaSpecialists.map((obj, i) => {
//             return (
//                 <p className={`${style.agentCard_asTag_text}`} key={i}>
//                 <img className={`${style.agentCard_asTag_img}`} alt="" src={pointer} />
//                 {obj.area}
//                 </p>
//             )
//             })}
//         </>
//         )}
//     </div>
//     <div className={agent.titledSpecialists && agent.titledSpecialists.length > 0 ? `${style.agentCard_tsTag}` : `${style.displayNone}`}>
//         {agent.titledSpecialists && agent.titledSpecialists.length > 0 && (
//         <>
//             <p className={`${style.agentCard_tsTag_header}`}>Title Specialist</p>
//             <img className={`${style.agentCard_tsTag_img}`} alt="" src={building} />
//             {agent.titledSpecialists && agent.titledSpecialists.map((obj, i) => {
//             return (
//                 <p className={`${style.agentCard_tsTag_text}`} key={i}>
//                 {obj.title}{i === agent.titledSpecialists.length - 1 ? "" : ", "}
//                 </p>

//             )
//             })}
//         </>
//         )}
//     </div>
//     <div className={`${style.agentCard_numberCompany} + mb-3`}>
//         {agent.mobile} &nbsp;|&nbsp;  <span >{agent.companyName && agent.renId && agent.renId !== "Private Advertiser" ? agent.companyName : "Private Advertiser"} </span>
//         {/* {agent.renId && agent.renId !== "Private Advertiser" && <span>{agent.renId}</span>} */}
//         {/* {agent.companyName} */}
//     </div>
//     <div className={`${style.agentCard_propCount}`}>
//         <p className={`${style.agentCard_propCount_data}`}>{t('sale')}: <span className={`${style.agentCard_propCount_number}`}>{agent.totalBuyRent ? agent.totalBuyRent.buy : "0"}</span></p>
//         <p className={`${style.agentCard_propCount_data}`}>&nbsp;&nbsp;|&nbsp; {t('rent')}: <span className={`${style.agentCard_propCount_number}`}>{agent.totalBuyRent ? agent.totalBuyRent.rent : "0"}</span></p>
//         <p className={`${style.agentCard_propCount_data}`}>&nbsp;&nbsp;|&nbsp; {t('auction')}: <span className={`${style.agentCard_propCount_number}`}>{agent.totalAuction ? agent.totalAuction.total : "0"}</span></p>
//     </div>
//     </div>
// </div>

  return (
    <>
      <Card
        className="noborder mb-3 agent-list-card-body"
        style={{ cursor: "pointer" }}
        title={`View ${agent.nickname ? agent.nickname : agent.name}`}
        // onClick={() => handlePopulateData(property)}
      >
        <CardImg
          className="property-row-cardImg2"
          src={
            agent.image ? agent.image : SetupData.defaultPfp
          }
          alt={agent && agent.area && agent.state && ((agent.nickname ? agent.nickname : agent.name) +" Property Agent in "+ agent?.area + " "+ agent?.state.replace(`Wilayah Persekutuan`, ``).trim() )}
        />
        <div className="agent-list-card-row-body2">
            <div className={`${style.agentCard_currentStatus}`}>
                {agent.status&&agent.status=="online"?<p className={`${style.agentCard_online_status}`}>{agent.status}</p>:<p className={`${style.agentCard_offline_status}`}>{agent.status}</p>}
                
                {
                agentComponent.anyRating(agent.rating) &&
                <p className={`${style.agentCard_rank}`}>{agent.rating}<img className={`${style.agentCard_rankImg}`} alt="" src={star} /></p>
                }
            </div>

            <p className={`${style.agentCard_name}`}>{agent.nickname ? agent.nickname : agent.name}</p>
           
            <div className={agent.areaSpecialists && agent.areaSpecialists.length > 0 ? `${style.agentCard_asTag}` : `${style.displayNone}`}>
                {agent.areaSpecialists && agent.areaSpecialists.length > 0 && agent.areaSpecialists.length < 2 && (
                <>
                    <p className={`${style.agentCard_asTag_header}`}>Area Specialist</p>
                    {agent.areaSpecialists && agent.areaSpecialists.map((obj, i) => {
                    return (
                        <p className={`${style.agentCard_asTag_text}`} key={i}>
                        <img className={`${style.agentCard_asTag_img}`} alt="" src={pointer} />
                        {obj.area}
                        </p>
                    )
                    })}
                </>
                )}
            </div>
            <div className={agent.titledSpecialists && agent.titledSpecialists.length > 0 ? `${style.agentCard_tsTag}` : `${style.displayNone}`}>
                {agent.titledSpecialists && agent.titledSpecialists.length > 0 && (
                <>
                    <p className={`${style.agentCard_tsTag_header}`}>Title Specialist</p>
                    <img className={`${style.agentCard_tsTag_img}`} alt="" src={building} />
                    {agent.titledSpecialists && agent.titledSpecialists.map((obj, i) => {
                    return (
                        <p className={`${style.agentCard_tsTag_text}`} key={i}>
                        {obj.title}{i === agent.titledSpecialists.length - 1 ? "" : ", "}
                        </p>

                    )
                    })}
                </>
                )}
            </div>
            <div className={`${style.agentCard_numberCompany} + mb-2`}>
                {agent.mobile} &nbsp;|&nbsp;  <span >{agent.companyName && agent.renId && agent.renId !== "Private Advertiser" ? agent.companyName : "Private Advertiser"} </span>
                {/* {agent.renId && agent.renId !== "Private Advertiser" && <span>{agent.renId}</span>} */}
                {/* {agent.companyName} */}
            </div>
            <div className={`${style.agentCard_propCount}`}>
                <p className={`${style.agentCard_propCount_data}`}>{t('sale')}: <span className={`${style.agentCard_propCount_number}`}>{agent.totalBuyRent ? agent.totalBuyRent.buy : "0"}</span></p>
                <p className={`${style.agentCard_propCount_data}`}>&nbsp;&nbsp;|&nbsp; {t('rent')}: <span className={`${style.agentCard_propCount_number}`}>{agent.totalBuyRent ? agent.totalBuyRent.rent : "0"}</span></p>
                {/* <p className={`${style.agentCard_propCount_data}`}>&nbsp;&nbsp;|&nbsp; {t('auction')}: <span className={`${style.agentCard_propCount_number}`}>{agent.totalAuction ? agent.totalAuction.total : "0"}</span></p> */}
            </div>

            
          {/* <div className="property-row-tagGroup2">
            <div className="property-row-saleTag2">
              {property.for ? <>{
                props.type === "properties" ?
                  (property.for.toLowerCase() === "buy"
                    ? lib.toTitleCase(t('sale'))
                    : lib.toTitleCase(t('rent')))
                  : lib.toTitleCase(t('auction'))
              }</>
                :
                lib.toTitleCase(t('auction'))
              }

            </div>
            <div className="property-row-createdTag2">
              {property.auctionDate ? <>{`${t('auction-date-time')} ` + moment(property.auctionDate ? property.auctionDate : property.created).format("DD/MM/YYYY")}</> 
              :
              <>{`${t('last-updated-on')} ` + moment(property.updated ? property.updated : property.created).format("DD/MM/YYYY")}</>
              }
              
            </div>
          </div> */}
     
           {/* {(props.idx==0 &&(etaStart<=today)&&(today<=etaEnd))&&<img src={greenKetupatLogo} className={`${style.agentCard_ketupat}`}></img> } */}

         

        </div>
      </Card>
    </>
  );
};

export default AgentCard;