import React, { useEffect, useRef, useState } from "react"
import '../styles/pages/merchantSignUpPage.scss'
import lib from "../businesses/lib"
import serviceMerchantApplicationComponent from "../businesses/serviceMerchantApplicationComponent"
import sharedComponent from "../businesses/sharedComponent"
import imageComponent from "../businesses/ImageComponent"
import fileComponent from "../businesses/fileComponent"
import merchantLogo from "../assets/merchantSignUp/msu-logo-merchant.svg"
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import Dropzone from 'react-dropzone'
import { Alert } from "react-bootstrap"
// import { BallTriangle } from 'react-loader-spinner'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const MerchantSignUpPage = () => {
    const history = useHistory()
    const [stateAreaOptions, setStateAreaOptions] = useState({})
    const [stateOptions, setStateOptions] = useState({})
    const [applicationId, setApplicationId] = useState(null)

    useEffect(() => {
        const fetchRequiredData = async () => {
            const res = await Promise.all([sharedComponent.getAreas(), serviceMerchantApplicationComponent.newId()])
            const stateAreaRes = res[0]
            setStateAreaOptions(stateAreaRes)
            const stateList = lib.stateOptionsV2(stateAreaRes)
            setStateOptions(stateList)

            const newIdRes = res[1].id
            setApplicationId(newIdRes)
        }
        fetchRequiredData()
    }, [])

    const Section1 = () => {
        return (
            <div className="msu-sec1">
                <div className="msu-logo" >
                    <img src={merchantLogo} />
                </div>
                {/* <div className="msu-sec1-wrapper"> */}
                <p className="msu-sec1-title">Welcome</p>
                <p className="msu-sec1-text">We just need a few key details from you to complete your first journey with us</p>
                {/* </div> */}
            </div>
        )
    }

    const SignUpForm = () => {
        const [promptError, setPromptError] = useState("");
        const [areaOptions, setAreaOptions] = useState({})
        const [selectInputFields, setSelectInputFields] = useState({
            type: "",
            state: "",
            area: ""
        })
        const [confirmModal, setConfirmModal] = useState(false)
        const [applicationDetails, setApplicationDetails] = useState({})
        const [canSave, setCanSave] = useState(false)

        const [businessCerts, setBusinessCerts] = useState([])
        const firstNameRef = useRef()
        const lastNameRef = useRef()
        const contactRef = useRef()
        const emailRef = useRef()
        const merchantNameRef = useRef()
        const addressRef = useRef()
        const cityRef = useRef()
        const postcodeRef = useRef()

        // useEffect(() => {
        //     console.log(businessCerts)
        // },[businessCerts])

        useEffect(() => {
            const saveApplication = async () => {
                const res = await serviceMerchantApplicationComponent.save(applicationDetails)
                if (res) {
                    setConfirmModal(false)
                    history.push('/thankyou', { fromSignUp: 'merchant' })
                }
            }

            if (canSave) saveApplication()
        }, [canSave, applicationDetails])

        const updateSelectInput = (name, val) => {
            setSelectInputFields((prevVal) => ({ ...prevVal, [name]: val }))
        }

        const handleSubmit = async () => {
            let application = {
                _id: applicationId,
                // _id: "65090c697014b71658d433e6", //testing purpose
                firstName: firstNameRef.current.value,
                lastName: lastNameRef.current.value,
                contact: contactRef.current.value,
                // contact: lib.addDashToPhoneNumber(contactRef.current.value),
                email: emailRef.current.value,
                businessCerts: businessCerts,
                name: merchantNameRef.current.value,
                type: selectInputFields.type,
                address: {
                    line1: addressRef.current.value,
                    city: cityRef.current.value,
                    postcode: postcodeRef.current.value,
                    state: selectInputFields.state,
                    area: selectInputFields.area,
                }
            }

            lib.log(application)
            const validateRes = await serviceMerchantApplicationComponent.validate(application)
            lib.log(validateRes)

            if (validateRes.ok) {
                setPromptError("")
                const newPhone = lib.addDashToPhoneNumber(contactRef.current.value)
                application.contact = newPhone
                setApplicationDetails(application)
                setConfirmModal(true)
            } else {
                setPromptError(validateRes.err)
            }
        }

        const handleConfirm = async () => {
            const bcImg = []
            const bcFile = []
            for (const bc of businessCerts) {
                const formData = new FormData()
                formData.append("files", bc)
                const fileUri = await fileComponent.upload(formData, `servicemerchants/${applicationDetails?._id}/businessCert`)
                const bcObj = { name: bc.path, size: bc.size, url: fileUri[0].url }
                if (bc.type.includes("pdf")) bcFile.push(bcObj)
                else if (bc.type.includes("image")) bcImg.push(bcObj)
            }
            const businessCertObj = { images: [...bcImg], files: [...bcFile] }
            setApplicationDetails((prevApplication) => ({ ...prevApplication, businessCerts: businessCertObj }))
            setCanSave(true)
            // applicationDetails.businessCerts = { ...businessCertObj }
            // lib.log(applicationDetails)
            // const res = await serviceMerchantApplicationComponent.save(applicationDetails)
            // if (res) {
            //     setConfirmModal(false)
            //     history.push('/thankyou', { fromSignUp: true })
            // }
            
            // const businessCertURIs = await serviceMerchantApplicationComponent.uploadImages({ _id: applicationDetails._id }, businessCerts)
            // if (businessCertURIs) {
            //     applicationDetails.businessCerts = businessCertURIs
            //     lib.log(applicationDetails)
            //     const res = await serviceMerchantApplicationComponent.save(applicationDetails)
            //     if (res) {
            //         setConfirmModal(false)
            //         history.push('/thankyou', { fromSignUp: true })
            //     }
            // }
        }

        const handleSelectedState = (name, val) => {
            updateSelectInput(name, val)
            if (selectInputFields.area) updateSelectInput("area", "")
            const areaList = lib.areaOptionsV2(stateAreaOptions, val)
            setAreaOptions(areaList)
        }

        return (
            <div className="msu-sec2">
                <p className="msu-sec2-title">Create Account</p>
                <div className="msu-input-wrapper1">
                    <MSUInputFields labelName="First Name" fieldId="firstName" inputRef={firstNameRef} />
                    <MSUInputFields labelName="Last Name" fieldId="lastName" inputRef={lastNameRef} />
                    <MSUInputFields labelName="Contact No" fieldId="contact" inputRef={contactRef} />
                    <MSUInputFields labelName="Email" fieldId="email" inputRef={emailRef} />
                </div>
                <div className="msu-sec2-title2">Company Information</div>
                <p className="msu-sec2-desc">These will be the key details you need to join Nextsix Merchant</p>
                <BusinessCert businessCerts={businessCerts} setBusinessCerts={setBusinessCerts} />
                <div className="msu-input-wrapper2">
                    <MSUInputFields labelName="Merchant Name" fieldId="name" inputRef={merchantNameRef} />

                    <MSUSelectInput labelName="Merchant Type" fieldId="type" inputVal={selectInputFields.type}
                        handleOnChg={updateSelectInput} options={merchantTypeOptions} />

                    <MSUInputFields extraClassname="msu-input-long" labelName="Company Address" fieldId="address" inputRef={addressRef} />
                    <MSUInputFields labelName="City" fieldId="city" inputRef={cityRef} />
                    <MSUInputFields labelName="Postcode" fieldId="postcode" inputRef={postcodeRef} />

                    <MSUSelectInput labelName="State" fieldId="state" inputVal={selectInputFields.state}
                        handleOnChg={handleSelectedState} options={stateOptions} />
                    <MSUSelectInput labelName="Area" fieldId="area" inputVal={selectInputFields.area}
                        handleOnChg={updateSelectInput} options={areaOptions} />
                </div>
                {promptError && <code className="msu-errorPrompt">{promptError}</code>}
                <button className="msu-btn-submit" onClick={handleSubmit}>Submit</button>
                <ConfirmModal confirmModal={confirmModal} handleConfirm={handleConfirm} setConfirmModal={setConfirmModal} />
            </div>
        )
    }

    return (
        <div className="msu-page-wrapper">
            <Section1 />
            <SignUpForm />
        </div>
    )
}

const MSUInputFields = ({ labelName, fieldId, extraClassname, inputRef, defaultVal }) => {

    return (
        <div className={`msu-input ${extraClassname}`}>
            <label className="msu-input-label" htmlFor={fieldId}>{labelName}*</label>
            <input className="msu-input-field" name={fieldId} ref={inputRef} defaultValue={defaultVal} />
        </div>
    )
}

const MSUSelectInput = ({ labelName, fieldId, inputVal, handleOnChg, options }) => {

    return (
        <div className="msu-input">
            <label className="msu-input-label" htmlFor={fieldId}>{labelName}*</label>
            <select className="msu-input-field" name={fieldId}
                value={inputVal} onChange={(e) => handleOnChg(e.target.name, e.target.value)}
            >
                <option value="" disabled>Choose</option>
                {options && options.length > 0 && options.map((option, i) => {
                    return (
                        <option key={i}>{option}</option>
                    )
                })}
            </select>
        </div>
    )
}

const BusinessCert = ({ businessCerts, setBusinessCerts }) => {
    const [rejectedFiles, setRejectedFiles] = useState([])
    const [rejectedMsg, setRejectedMsg] = useState("")

    const handleDrop = async (files) => {
        setRejectedMsg("")
        setRejectedFiles([])
        if (businessCerts.length + files.length <= 3)
            setBusinessCerts((prevFiles) => ([...prevFiles, ...files]))
            // const compressedFile = await imageComponent.compressImg(file)
            // setBusinessCerts((prevFiles) => ([...prevFiles, compressedFile]))
        else setRejectedMsg("Please upload maximum 3 business certificates only.")
    }

    const handleDropRejected = (files) => {
        setRejectedFiles(files)
    }

    const handleRemoveImg = (idx) => {
        const curImages = [...businessCerts] //method 1
        curImages.splice(idx, 1)
        setBusinessCerts(curImages)
        // react setState cannot directly modify an array, but can use spread syntax
        // **for eg: method 1 and method 2 will work but method 3 wont work
        // const curImages = businessCerts //method 2
        // curImages.splice(idx, 1)
        // setBusinessCerts([...curImages])

        // const curImages = businessCerts //method 3
        // curImages.splice(idx, 1)
        // setBusinessCerts(curImages)
    }

    const Reject = ({ rejectedFiles }) => {
        const files = rejectedFiles || [];
        return files.map((reject) => {
            return (
                <li key={reject.file.path}>
                    {reject.file.path} - {reject.errors[0].message}
                </li>
            );
        });
    };

    return (
        <div className="msu-input">
            <label className="msu-input-label">Business Certificate*</label>
            <Dropzone
                accept={imageComponent.dropzoneController.accept}
                maxSize={imageComponent.dropzoneController.maxSize}
                // maxFiles={3}
                multiple
                onDrop={handleDrop}
                onDropRejected={handleDropRejected}
            >
                {({ getRootProps, getInputProps }) => {
                    // const fileRejectionItems = fileRejections.map(({ file, errors }) => {
                    //     return (
                    //         <li key={file.path}>
                    //             {file.path} - {errors[0].message}
                    //         </li>
                    //     )
                    // });

                    return (
                        <section className=''>
                            <div {...getRootProps()} className="msu-input-upload-wrapper">
                                <input {...getInputProps()} />

                                {
                                    // (!loading) ?
                                    <>
                                        <div className="msu-input-upload"></div>
                                        {/* <div className='loader-wrapper'>
                                        <BallTriangle
                                            height={24}
                                            width={24}
                                            radius={5}
                                            color='#848890'
                                            wrapperClass='loader'
                                            ariaLabel='ball-triangle-loading'
                                        />
                                    </div> */}
                                    </>
                                }
                            </div>
                        </section>
                    )
                }}
            </Dropzone>
            {businessCerts && businessCerts.length > 0 &&
                <div className="msu-bc-container">
                    {businessCerts.map((bc, idx) => {
                        return (
                            // <div className="msu-img-wrapper" key={idx}>
                            //     <div className="msu-img-dlt" onClick={() => handleRemoveImg(idx)} />
                            //     <img src={bc} />
                            // </div>
                            <Alert variant="info" key={idx} dismissible onClose={() => handleRemoveImg(idx)}>
                                {bc.name}
                            </Alert>
                        )
                    })}
                </div>
            }
            <div className="msu-alert">
                <Alert variant="danger" show={rejectedMsg?.length > 0 || rejectedFiles?.length > 0}>
                    <h6 className="alert-heading">Rejected</h6>
                    <ul>
                        <Reject rejectedFiles={rejectedFiles} />
                        {rejectedMsg && <li key={rejectedMsg}>{rejectedMsg}</li>}
                    </ul>
                </Alert>
            </div>
        </div>
    )
}

const ConfirmModal = ({ confirmModal, handleConfirm, setConfirmModal }) => {
    return (
        <Modal isOpen={confirmModal}>
            <ModalHeader>Confirm Submit Form?</ModalHeader>
            <ModalFooter>
                <Button color="success" onClick={handleConfirm}>
                    Yes
                </Button>
                <Button color="danger" onClick={() => setConfirmModal(false)}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const merchantTypeOptions = [
    "Law Firm",
    "Banker",
    "Interior Design",
    "Home Renovation",
]

export default MerchantSignUpPage;