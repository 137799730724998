import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, InputGroup, Input, Col } from 'reactstrap';

const TextareaInput = (props) => {
    const generate = () => {
        const element = (
            <InputGroup>
                <Label for={props.name} xl={{ size: 2, offset: 1 }} sm={12}>{props.placeholder} :</Label>
                <Col xl={12} sm={12}>
                    <Input type="textarea" id={props.id} className="form-control" name={props.name} placeholder={props.placeholder} value={props.value}
                        onChange={onInputChanged} readOnly={props.readOnly || false} rows={5} maxLength={props.maxLength} />
                </Col>
            </InputGroup>
        );

        if (!props.noDiv) {
            return (
                // <div className="form-group">
                //     {element}
                // </div>
                <FormGroup row>
                    {element}
                </FormGroup>
            );
        }

        return element;
    }

    const onInputChanged = (event) => {
        if (props.callback) props.callback({
            id: props.callbackId,
            value: event.target.value
        });
    };

    return (
        generate()
    );
};

TextareaInput.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    callback: PropTypes.func,
    callbackId: PropTypes.string,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    noDiv: PropTypes.bool
};

export default TextareaInput;