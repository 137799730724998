import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Col } from 'reactstrap';

function SelectionInput(props) {
    let values = [(props.title ? props.title : 'Select One ..')];
    props.values.sort((a, b) => a.localeCompare(b)).forEach(value => {
        values.push(value);
    });
    if (props.auction) values = values.filter(val => val !== "Unknown")
    let valueList = values.length > 0 && values.map((val, idx) => {
        return (
            //disabled={val==props.title} selected={val==props.title && !props.val}
            <option key={idx} value={val} >{val}</option>
        )
    });

    const onChanged = (event) => {
        if (props.callback) props.callback({
            id: props.callbackId,
            value: event.target.value
        });
    }

    return (
        <Fragment>
            <Label for={props.name} xl={{ size: 2 }} sm={12}>{props.title}{(props.required) ? <span style={{ color: "red" }}>*</span> : ''} :</Label>
            <Col xl={3} sm={12}>
                <Input type="select" id={props.title} name={props.title} onChange={onChanged} disabled={props.readOnly ? true : null} value={props.value}>{valueList}</Input>
            </Col>
            {/* <label htmlFor={props.title}>{props.title}: </label>
            <select className="form-control" id={props.title} name={props.title} onChange={onChanged} value={props.value}>
                {valueList}
            </select> */}
        </Fragment>
    );
}

SelectionInput.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    values: PropTypes.array,
    callbackId: PropTypes.string,
    callback: PropTypes.func
};

export default SelectionInput;