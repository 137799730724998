import React from 'react';
import { GiBarbecue, GiRun, GiScissors, GiTennisRacket, GiTennisCourt, GiCctvCamera, } from "react-icons/gi";
import { FaSwimmingPool, FaSwimmer, FaDumbbell, FaParking, FaFortAwesome, FaBath, FaBaby } from "react-icons/fa";
import { IoIosBusiness } from "react-icons/io";
import { MdLocalGroceryStore, MdHotTub } from "react-icons/md";
import { RiHomeHeartLine } from "react-icons/ri";
import { GrCafeteria } from "react-icons/gr";

const SetupData = {
    // empty: {
    //     about: "This person too lazy....he/she did not leave any info about he/she"
    // },
    facilities: [
        {
            name: "BBQ",
            icon: <GiBarbecue className="mr-3 fontbig" />,
            translateKey: 'bbq'
        }, {
            name: "Business Centre",
            icon: <IoIosBusiness className="mr-3 fontbig" />,
            translateKey: 'business-center'
        },
        {
            name: "Club House",
            icon: <RiHomeHeartLine className="mr-3 fontbig" />,
            translateKey: 'club-house'
        }, {
            name: "Parking",
            icon: <FaParking className="mr-3 fontbig" />,
            translateKey: 'parking'
        },
        {
            name: "Gymnasium",
            icon: <FaDumbbell className="mr-3 fontbig" />,
            translateKey: 'gymnasium'
        }, {
            name: "Jacuzzi",
            icon: <FaBath className="mr-3 fontbig" />,
            translateKey: 'jacuzzi'
        },
        {
            name: "Jogging Track",
            icon: <GiRun className="mr-3 fontbig" />,
            translateKey: 'jogging-track'
        }, {
            name: "Mini Market",
            icon: <MdLocalGroceryStore className="mr-3 fontbig" />,
            translateKey: 'mini-market'
        },
        {
            name: "Nursery",
            icon: <FaBaby className="mr-3 fontbig" />,
            translateKey: 'nursery'
        }, {
            name: "Playground",
            icon: <FaFortAwesome className="mr-3 fontbig" />,
            translateKey: 'playground'
        },
        {
            name: "Salon",
            icon: <GiScissors className="mr-3 fontbig" />,
            translateKey: 'salon'
        }, {
            name: "Sauna",
            icon: <MdHotTub className="mr-3 fontbig" />,
            translateKey: 'sauna'
        },
        {
            name: "Squash Court",
            icon: <GiTennisRacket className="mr-3 fontbig" />,
            translateKey: 'squash-court'
        }, {
            name: "Swimming Pool",
            icon: <FaSwimmer className="mr-3 fontbig" />,
            translateKey: 'swimming-pool'
        },
        {
            name: "Wading Pool",
            icon: <FaSwimmingPool className="mr-3 fontbig" />,
            translateKey: 'wading-pool'
        }, {
            name: "Tennis Court",
            icon: <GiTennisCourt className="mr-3 fontbig" />,
            translateKey: 'tennis-court'
        },
        {
            name: "24hr Security",
            icon: <GiCctvCamera className="mr-3 fontbig" />,
            translateKey: '24hr-security'
        }, {
            name: "Cafeteria",
            icon: <GrCafeteria className="mr-3 fontbig" />,
            translateKey: 'cafeteria'
        },
    ],
    property: {
        // categories: ['Commercial', 'Residential'],
        // types: ['Apartment', 'Flat', 'Condominium', 'Serviced Residence', '1-sty Terrace/Link House', '2-sty Terrace/Link House',
        //     '3-sty Terrace/Link House', '4-sty Terrace/Link House', '2 & 3 Sty Terrace/Link House', '1.5-sty Terrace/Link House',
        //     '2.5-sty Terrace/Link House', '3.5-sty Terrace/Link House', '4.5-sty Terrace/Link House', '2-sty Superlink House',
        //     '3-sty Superlink House', 'Townhouse', 'Cluster House', "Bungalow", 'Bungalow Land', 'Semi-detached House', 'Villa',
        //     '3 Storey Semi-detached Homes', 'Residential Land', 'Office', 'Shop', 'Shop Office', 'Retail Office', 'Retail Space', 'SOFO',
        //     'SOHO', 'SOVO', 'Commercial Bungalow', 'Commercial Semi-D', 'Designer Suites', 'Business Center', 'Hotel/Resort', 'Commercial Land',
        //     'Suites', 'Luxury Suites', 'Factory', 'Semi-D Factory', 'Detached Factory', 'Link Factory', 'Warehouse', 'Light Industrial', 'Industrial Land',
        //     'Agricultural Land'],
        unitTypes: ['Corner', 'Duplex', 'Endlot', 'Intermediate', 'Penthouse', 'SOHO', 'Studio', 'Triplex', 'Unknown'],
        // titles: ['Aparment/Flat', 'Condo/Serviced Residence', 'SOHO', "SOVO", "Semi-D/Bungalow", "Terrace/Link/Townhouse", "Residential Land",
        //     "Shop/Office/Retail Space", "Hotel/Resort", "Commercial Land", "Suites", "Luxury Suites", "Factory/Warehouse", "Industrial Land",
        //     "Agricultural Land"],
        // tenures: ['Freehold', 'Leasehold', 'Lease Tenancy', 'Malay Reserved Land', 'Unknown'],
        // furnishings: ['Fully Furnished', 'Partly Furnished', 'Unfurnished'],
        // for: ['Buy', 'Rent'],
        // sizeMeasureCodes: ['Square Feet', 'Square Meters', 'Acres', 'Hectare', 'Unknown'],
        //titleTypes: ['Individual', 'Strata', 'Unknown'],
        landTitleTypes: ['Commercial', 'Industrial', 'Residential', 'Unknown'],
        // directions: ['North', 'North East', 'East', 'South East', 'South', 'South West', 'West', 'North West', 'Unknown'],
        // occupies: ['Owner Occupied', 'Tenanted', 'Vacant', 'Unknown'],
        beds: ['Studio', '1', '1 + 1', '1 + 2', '2', '2 + 1', '2 + 2', '3', '3 + 1', '3 + 2', '4', '4 + 1', '4 + 2', '5', '5 + 1', '5 + 2', '6', '6 + 1', '6 +', '7',
            '7 + 1', '7 +', '8', '8 + 1', '8 +', '9', '9 + 1', '9 +', '10', '10 + 1', '10 +', '11', '11 + 1', '11 +', '12', '12 + 1', '12 +', '13', '13 + 1', '13 +', '14',
            '14 + 1', '14 +', '15', '15 + 1', '16', '16 +', '17', '17 +', '18', '18 +', '19', '20 Above'
        ],
        // facilities: ['BBQ', 'Business Centre', 'Club House', 'Parking', 'Gymnasium', 'Jacuzzi', 'Jogging Track', 'Mini Market', 'Nursery', 'Playground',
        //     'Salon', 'Sauna', 'Squash Court', 'Swimming Pool', 'Wading Pool', 'Tennis Court', '24hr Security', 'Cafeteria'
        // ],
        // propertyListing: ['Projects', 'Subsale'],
        // rentPrice: ["500", "1000", "1500", "2000", "2500", "3000", "3500", "4000", "5000", "6000", "7000", "8000", "9000", "10000", "12000", "15000", "20000", "30000", "40000", "50000"],
        // buyPrice: ["50000", "100000", "150000", "200000", "250000", "300000", "350000", "400000", "500000", "600000", "700000", "800000", "900000", "1000000", "1500000", "2000000", "3000000", "4000000", "5000000", "6000000"],
    },
    defaultPfp: "https://iprofstore.blob.core.windows.net/general/agentDefault.png",
    defaultHouse: "https://iprofstore.blob.core.windows.net/general/house.jpg",
    defaultAuctionChop: "https://iprofstore.blob.core.windows.net/general/auction.png",
    files: {
        apiKey: "VBKV2rL8uDSLzMBsnDGRus5ZTe9M75rDgZr8fMRmgzHsX9uapZDG4jjM94q7JrmQ"
    },
    profile: {
        imgURL: "https://iprofstore.blob.core.windows.net/general/agentDefault.png"
    },
    googleMapAPIKey: "AIza-no-longer-in-used",
    sortColumn: {
        propertyList: [
            { label: "Default", value: "", translateKey: "default" },
            { label: "Price High-Low", value: "price", translateKey: "price-high-low" },
            { label: "Price Low-High", value: "price", translateKey: "price-low-high" },
            { label: "Built-up Size High-Low", value: "buildup", translateKey: "builtup-high-low" },
            { label: "Built-up Size Low-High", value: "buildup", translateKey: "builtup-low-high" },
        ],
    },
    ContactUsIdentity: [
        { label: "Property Agent", value: "Property Agent", translateKey: "property-agent" },
        { label: "Property Agency", value: "Property Agency", translateKey: "property-agency" },
        { label: "Property Owner/Seller", value: "Property Owner/Seller", translateKey: "property-owner-seller" },
        { label: "Tenant/Buyer/Investor", value: "Tenant/Buyer/Investor", translateKey: "tenant-buyer-investor" },
        { label: "Media", value: "Media", translateKey: "media" },
        { label: "Other", value: "Other", translateKey: "other" },
    ],
    agent: {
        type: [
            { label: "All Specialists", value: "All Specialists" },
            { label: "Area Specialist", value: "Area Specialist" },
            { label: "Titled Specialist", value: "Titled Specialist" },
            { label: "Agent", value: "Agent" },
        ],
    },
    mobileCountryCodes: [
        { value: "+60", country: "Malaysia" },
        { value: "+65", country: "Singapore" },
    ],
    SEOTitleOrType: [
        'residential',
        'commercial',
        'apartment-flat',
        'condo-serviced-residence',
        'terrace-link-townhouse',
        'semi-d-bungalow',
        'residential-land',
        'suites',
        'luxury-suites',
        'shop-office-retail-space',
        'hotel-resort',
        'commercial-land',
        'factory-warehouse',
        'industrial-land',
        'agricultural-land',
        'apartment',
        'flat',
        'condominium',
        'serviced-residence',
        '1-sty-terrace-link-house',
        '2-sty-terrace-link-house',
        '3-sty-terrace-link-house',
        '4-sty-terrace-link-house',
        '1-5-sty-terrace-link-house',
        '2-5-sty-terrace-link-house',
        '3-5-sty-terrace-link-house',
        '4-5-sty-terrace-link-house',
        '2-sty-superlink-house',
        '3-sty-superlink-house',
        'townhouse',
        'cluster-house',
        'bungalow',
        'bungalow-land',
        'semi-detached-house',
        'villa',
        '3-storey-semi-detached-homes',
        'office',
        'shop',
        'shop-office',
        'retail-office',
        'retail-space',
        'sofo',
        'soho',
        'sovo',
        'commercial-bungalow',
        'commercial-semi-d',
        'designer-suites',
        'business-center',
        'factory',
        'semi-d-factory',
        'detached-factory',
        'link-factory',
        'warehouse',
        'light-industrial'
    ]
}

export default SetupData;