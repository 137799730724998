
import React, { useState } from 'react';
import { MdPhotoCamera, MdOndemandVideo, Md3DRotation } from 'react-icons/md';
import FsLightbox from 'fslightbox-react';
import {
    Button
} from 'reactstrap';
import "../styles/pages/propertyDetailPage.scss";

const SliderImg = (props) => {
    const [toggler, setToggler] = useState(false);

    const GalleryType = () => {
        switch (props.type) {
            case "Image":
                return <MdPhotoCamera />

            case "Video":
                return <MdOndemandVideo />

            case "Vr":
                return <Md3DRotation />
        }
    }

    return (
        <>
            <Button className="slide-imgicon" onClick={() => setToggler(!toggler)}>
                {GalleryType()}{props.type == "Image" && <span>{props.sources.length}</span>}
            </Button>
            {props.custom ?
                <FsLightbox
                    toggler={toggler}
                    sources={[
                        <iframe width="1920" height="1080" src={props.sources} frameBorder="0" allow="accelerometer; autoplay;  gyroscope; picture-in-picture" allowFullScreen />
                    ]}
                />
                :
                <FsLightbox
                    toggler={toggler}
                    type="image"
                    sources={props.sources}
                />
            }
        </>
    );

}

export default SliderImg;