import lib from '../businesses/lib';
import apibase from './BaseAPI';

const getAgentInfo = async (id, increaseView) => {
    const param = {
        id: id,
        increaseView: increaseView
    }
    const res = await apibase.post(`/agents/id`, param);
    return res.data;
}

const webSearch = async (search, paging) => {
    const params = {
        search: search,
        paging: paging
    }
    lib.log(params)
    const res = await apibase.post(`/agents/web/search`, params);
    return res.data;
}

const agentSuggestion = async (search) => {
    const params = {
        search: search
    };
    const url = `/agents/web/suggestion`;
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

export default {
    getAgentInfo: getAgentInfo,
    webSearch: webSearch,
    agentSuggestion: agentSuggestion
};

