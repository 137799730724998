import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SmartText = (props) => {
    const [showLess, setShowLess] = useState(true);

    if (props.value.length < props.length) {
        return <p>{props.value}</p>;
    }
    const onClick123 = (e) => {
        e.preventDefault();
        setShowLess(!showLess)
    }
    return (
        <p>
            {showLess ? `${props.value.slice(0, props.length)}...` : props.value}
            <br />
            <b><a href="#" onClick={onClick123}>
                View {showLess ? "More" : "Less"}
            </a></b>
        </p>
    );
};

SmartText.propTypes = {
    value: PropTypes.string,
    length: PropTypes.number,
};

export default SmartText;

