import apibase from './BaseAPI';
import lib from "../businesses/lib";
import FileType from 'file-type';

const id = async (id) => {
    const url = `/auctionProps/${id}`;
    try {
        const result = await apibase.get(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const newId = async () => {
    const url = `/auctionProps/newId`;
    try {
        const result = await apibase.post(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const save = async (property) => {
    const url = `/auctionProps/save`;
    try {
        const result = await apibase.post(url, property);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const search = async (agentId, status, val, currentLength) => {
    const params = {
        agentId: agentId,
        status: status,
        search: val,
        currentLength: currentLength
    };
    const url = '/auctionProps/search';
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const uploadImage = async params => {
    const url = `/auctionProps/${params.id}/uploadImage`;
    let imageUri;
    try {
        const res = await apibase.post(url, params);
        imageUri = res.data.url;
    } catch (err) {
        lib.log(`${url} -> ${err.response.status}`);
    }

    return imageUri;
};

const newAuctionRequestUrl = async (agentId, auctionPropId) => {
    const params = {
        agent: agentId,
        _id: auctionPropId
    };

    const url = '/auctionProps/request/newUrl';
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const getAuctionRequestByKey = async (key) => {
    const params = {
        requestKey: key
    };

    const url = '/auctionProps/request/findKey';
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const findAuctionRequestUrl = async (agentId, auctionPropId) => {
    const params = {
        agent: agentId,
        _id: auctionPropId
    };

    const url = '/auctionProps/request/findUrl';
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const updateCoverPhoto = async (auctionPropId, images) => {
    const params = {
        _id: auctionPropId,
        images: images
    };

    const url = '/auctionProps/updateCoverPhoto';
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const auctionWebSearch = async (search, paging) =>{
    let params = {};
    if (paging) params.paging = paging;
    if (search) params.search = search;
    lib.log('auctionWebSearch');
    lib.log(params);
    const url ='/auctionProps/webSearch';
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw(err)
    }
}

const bidNowOtherAuction = async () =>{
    let params = {};
    // if (paging) params.paging = paging;
    lib.log('bidNowOtherAuction');
    lib.log(params);
    const url ='/auctionProps/bidNowOtherAuction';
    try {
        const result = await apibase.post(url);
        return result.data;
    } catch (err) {
        throw(err)
    }
}

export default {
    id: id,
    newId: newId,
    save: save,
    search: search,
    uploadImage: uploadImage,
    newAuctionRequestUrl: newAuctionRequestUrl,
    getAuctionRequestByKey: getAuctionRequestByKey,
    findAuctionRequestUrl: findAuctionRequestUrl,
    updateCoverPhoto: updateCoverPhoto,
    auctionWebSearch:auctionWebSearch,
    bidNowOtherAuction:bidNowOtherAuction,
};