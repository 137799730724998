import apiAgentApplication from "../api/AgentApplicationAPI";
import regex from "../businesses/regex";
import lib from "../businesses/lib";

const save = async (application) => {
  // console.log(application)
  return await apiAgentApplication.save(application);
};

const emailFound = async (email) => {
  return await apiAgentApplication.isEmailUnique(email);
};

const isMobileUnique = async (mobile) => {
  return await apiAgentApplication.isMobileUnique(mobile);
}

const validate = async (application) => {
  if (!application.name) return invalid("Please enter full name.");
  if (!regex.isAlphabet(application.name)) return invalid("Please input alphabet characters only for name.");
  if (!application.email) return invalid("Please enter email address.");
  if (!regex.isValidEmail(application.email)) return invalid("Invalid email address entered.");
  const uniqueEmail = await emailFound(application.email);
  if (uniqueEmail === false) return invalid("Sorry, this email has been taken.");
  if (!application.mobile) return invalid("Please enter mobile number.");
  if (!regex.isValidPhoneNumber(application.mobile)) return invalid("Invalid mobile number format.");
  const uniqueMobile = await isMobileUnique(application.mobile);
  if (!uniqueMobile) return invalid("Sorry, this mobile number has been taken.");
  // if (!regex.isValidMobile(application.mobile)) return invalid("Invalid mobile number format.");
  if (!application.package) return invalid("Please select a package.");
  if (application.package === "Select One ..") return invalid("Please select a package.");
  // if (!application.renId) return invalid("Please enter Ren ID.");
  if (application.renId && !regex.isAlphanumeric(application.renId)) return invalid("Only alphanumeric allow for Ren ID.");

  return {
    ok: true,
  };
};

const invalid = (err) => {
  return {
    ok: false,
    err: err,
  };
};

const validateSource = async (tag) => {
  return await apiAgentApplication.validateSource(tag)
}

export default {
  save: save,
  validate: validate,
  // emailFound:emailFound
  validateSource: validateSource,
};
