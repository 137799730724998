import React, { useState, useEffect, useRef } from "react";
import propertyComponent from "../businesses/property";
import projectComponent from "../businesses/projectComponent";
import developerComponent from "../businesses/DeveloperComponent";
import lib from "../businesses/lib";
import SliderImg from "../components/SliderImg";
import Gallery from "../components/Gallery";
import GoogleMapReact from "google-map-react";
import gps from "../assets/img/locationgrey.svg";
import house from "../assets/img/housegrey.svg";
import shower from "../assets/img/bathgrey.svg";
import bed from "../assets/img/bedgrey.svg";
import download from "../assets/img/icon-download-file.png";
import gpsgrey from "../assets/img/greypointer.svg";
import orangehouse from "../assets/img/orangehouse.svg";
import houseUnitOrange from "../assets/img/houseUnitOrange.svg";
import orangeshower from "../assets/img/orangebath.svg";
import orangebed from "../assets/img/orangebed.svg";
import back from "../assets/img/backarrowlogo.svg"
import SetupData from "../data/SetupData";
import LoadingIndicator from "../components/LoadingIndicator";
import {
  Row,
  Col,
  Card,
  Button,
  Container,
  Media,
  Modal,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import {
  IoMdPhonePortrait,
  IoLogoWhatsapp
} from "react-icons/io";
import {
  MdFileDownload,
} from "react-icons/md";
import { useParams, useHistory } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "react-image-gallery/styles/css/image-gallery.css";
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";


import "../styles/pages/propertyDetailPage.scss";
import seoComponent from "../businesses/seoComponent";
import PageError from "../components/Layout/PageError";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { trackPromise } from "react-promise-tracker";
import fileComponent from "../businesses/fileComponent";
import useScrollSpy from 'react-use-scrollspy';

// import string from '../businesses/string';
// import global from '../businesses/global';
// import DocumentMeta from 'react-document-meta';
import { useSelector, useDispatch } from 'react-redux';
import { setRefresh2 } from "../redux/actions/chartActions";
import TextInput from "../components/TextInput";
// import checkWithCircle from "../assets/agentApplication/icon check with circle.svg";
import checkWithCircle from "../assets/agentApplication/project_enquiry_success.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import nextsixAppEmblem from "../assets/home/nextsix-app-emblem.png";
import { MdClose } from "react-icons/md";
import { close } from "../redux/actions/emblemActions";
import mapPreview from "../assets/img/map-preview.png"
import MortgageCalculator from "../components/MortgageCalculator";
import biUserEngagementComponent from "../businesses/biUserEngagementComponent";
import string from "../businesses/string";


const ProjectDraftPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const emblem = useSelector(state => state.emblem);
  const getwidth = window.innerWidth;
  const [properties, setProperties] = useState([]);
  const [developerFound, setDeveloperFound] = useState([]);
  const [mode, setMode] = useState(true);
  const [pdfStoreyPlan, setPdfStoreyPlan] = useState(null)
  const [imgStoreyPlan, setImgStoreyPlan] = useState(null)
  let { project_info } = useParams();
  let getUrlInfo = project_info.split('-');
  let id = getUrlInfo[getUrlInfo.length - 1];//get last of array(property id)
  //const [meta, setMeta] = useState({});
  const selectedLang = useSelector(state => state.lang);
  const refreshLang = useSelector(state => state.chart);
  const [contactForm, setContactForm] = useState({});
  const [modal, setModal] = useState(false);
  const [modaltitle, setModalTitle] = useState("");
  const [errorPrompt, setErrorPrompt] = useState("");
  const [displayMap, setDisplayMap] = useState(false);

  const calculatorCallback = (data) => {
    props.history.push({
      pathname: '../mortgage-calculator',
      state: data
    })
  }

  useEffect(() => {
    fetchData();
  }, []);

  // const detectSource = () =>{
  //   let fromSource = localStorage.getItem('from');
  //   console.log(fromSource)


  //     if(fromSource && id){

  //       if(fromSource=="listing"){
  //          biUserEngagementComponent.biUserEngagement(fromSource,"property",id)
  //         //  console.log("success list")
  //       }
  //       else if (fromSource=="map"){
  //          biUserEngagementComponent.biUserEngagement(fromSource,"property",id)
  //         //  console.log("success map")
  //       }
  //     }
  //     else if (fromSource=="" && id) {
  //        biUserEngagementComponent.biUserEngagement("shared","property",id)
  //       //  console.log("success shared")
  //     }
  //     return localStorage.setItem('from', "");

  // }

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  useEffect(() => {
    if (refreshLang) {
      dispatch(setRefresh2(false));
    }
  }, [refreshLang]);

  const recaptchaLocalize = () => {
    let hl = 'en';
    if (selectedLang === 'bm') hl = 'ms';
    else if (selectedLang === 'zh') hl = 'zh-CN';
    return hl;
  };

  const inputCallback = (cb) => {
    assignValue(cb.id, cb.value);
  };

  const assignValue = (name, value) => {
    setContactForm((contactForm) => ({
      ...contactForm,
      [name]: value,
    }));
  };

  const toggle = () => {
    setModal(!modal);
  };

  const toggleModal = (modaltitle) => {
    setModal(!modal);
    setModalTitle(modaltitle);
  };

  const onOkClick = async (event) => {
    event.preventDefault();
    setModal(false);
    toggle();
    window.open("https://signup.nextsix.com/")
  };

  const onSubmitClick = async (event) => {
    event.preventDefault();
    if (contactForm) {
      const res = await projectComponent.validate(contactForm);
      if (res.ok) {
        lib.log("validation OK");
        const checkToken = await projectComponent.checktoken(contactForm.humankey);
        if (checkToken) {
          await trackPromise(projectComponent.save(contactForm));//here return send success

          //one if check 
          setContactForm("");
          setErrorPrompt(null);
          toggleModal("Interest Submitted");
          toggle();
        }
        else {
          setErrorPrompt("Detected You Are Not Human. Please Try To ReCaptcha Again");
        }


      } else {
        // toggleModal(res.err);
        setErrorPrompt(res.err);
      }
    } else {
      setErrorPrompt("Form Cannot Be Empty");
    }
  };

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -230,
  });
  // const processMetaData = (project, developer) => {
  //   const developerName = developer?.name ?? undefined;
  //   const title = `${project.name} for Sale | ${developerName ? `${developerName}` : `Reputated Developer`} | ${global.NEXTSIX}`;
  //   const description = `${project.name} by ${developerName ? `${developerName}` : `Reputated Developer`}`;
  //   const state = `${seoComponent.treatState(project.address?.state)}`;
  //   const area = `${seoComponent.treatArea(project.address?.area)}`;
  //   let canonical = `https://nextsix.com/projects-for-sale/malaysia`;
  //   if (state) {
  //     if (area) canonical = `https://nextsix.com/projects-for-sale/${state}/${area}`;
  //     else canonical = `https://nextsix.com/projects-for-sale/malaysia/${state}`;
  //   }
  //   const projectCoverPhoto = project.images[0] ?? `https://iprofstore.blob.core.windows.net/general/nextsix.png`;

  //   document.title = title;
  //   document.getElementsByTagName("meta")["description"].content = description;
  //   // setMeta({
  //   //   //title,
  //   //   //description,
  //   //   canonical,
  //   //   meta: {
  //   //     name: {
  //   //       keywords: "nextsix",
  //   //     },
  //   //     property: {
  //   //       'og:title': title,
  //   //       'og:description': description,
  //   //       'og:url': 'https://nextsix.com',
  //   //       'og:site_name': 'The Next Six Sdn Bhd',
  //   //       'og:image': projectCoverPhoto,
  //   //       'twitter:title': title,
  //   //       'twitter:description': description,
  //   //       'twitter:url': 'https://nextsix.com',
  //   //       'twitter:image': projectCoverPhoto,
  //   //     }
  //   //   }
  //   // });
  // };

  const fetchData = async () => {
    const propertyId = seoComponent.seoToState(id);
    //NXT-1876
    if (!lib.isMongoDbObjectId(propertyId)) return history.push('/error/404');
    const properties = await projectComponent.id(propertyId);
    if (!properties) return history.push('/error/404');
    // if (!lib.isMongoDbObjectId(propertyId)) return setMode(false);
    // const properties = await projectComponent.id(propertyId);
    // if (!properties) return setMode(false);
    setProperties(properties);
    // console.log(properties)
    if (properties._id) {
      assignValue("project", properties._id)
    }
    const developer = await developerComponent.searchById(properties.developer)
    if (developer._id) {
      setDeveloperFound(developer)
      assignValue("developer", developer._id)
    }

    if (properties.active == false) {
      let urltarget = "projects-for-sale"
      let urlstate = null

      const treatState = state => {
        return state.toLowerCase().replace(`wilayah persekutuan`, ``).trim().replace(` `, `-`);
      }

      if (properties.address.state) {
        urlstate = treatState(properties.address.state)
      }

      if (urltarget && urlstate)

        window.location = `/${urltarget}/${urlstate}`
    }


    //processMetaData(properties, developer);
    // detectSource();
  };

  useEffect(() => {
    if (properties.storeyPlan) {

      let storeyPlanPdf = properties.storeyPlan.filter(element => element.slice(-4).includes("pdf"))
      if (storeyPlanPdf) {
        setPdfStoreyPlan(storeyPlanPdf)
      }

      let storeyPlanJpg = properties.storeyPlan.filter(element => element.slice(-4).includes("jpg"))
      let storeyPlanPng = properties.storeyPlan.filter(element => element.slice(-4).includes("png"))
      let storeyPlanJpeg = properties.storeyPlan.filter(element => element.slice(-4).includes("jpeg"))
      let finalImg = storeyPlanJpg.concat(storeyPlanPng, storeyPlanJpeg)
      //  console.log(finalImg)
      if (finalImg && finalImg.length > 0) {
        setImgStoreyPlan(finalImg)
      }

    }
  }, [properties.storeyPlan])

  const onOverviewCategoryClick = (e) => {
    e.preventDefault()
    document.getElementById("overview").scrollIntoView();
  }

  const downloadSingleAttachment = async (event, url) => {
    event.preventDefault();
    const result = await trackPromise(fileComponent.download(url));
    if (result.binaryData && result.extension) {
      let blobData = new Blob([result.binaryData], { type: result.mime });
      var objectUrl = URL.createObjectURL(blobData);
      window.open(objectUrl);
    }
  };

  const handlePhone = () => {
    const phone = parsePhoneNumberFromString(
      properties?.developerContact,
      "MY"
    ).nationalNumber;
    window.open(`tel:+60${phone}`);
  };

  const handleWhatApps = () => {
    const phone = parsePhoneNumberFromString(
      properties?.developerWhatsapp,
      "MY"
    ).nationalNumber;
    window.open(
      `https://wa.me/60${phone}?text=Hi, Good day, I am interested in the ${properties.name} project`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  function verifyCaptcha(res) {
    if (res) {
      assignValue("human", true)
      assignValue("humankey", res)
      //  assignValue("disabled", true)

    }
  }

  // ReCAPTCHA Expired
  function expireCaptcha() {
    assignValue("human", false)
    assignValue("humankey", null)
    // assignValue("disabled", true)

  }

  // const FileRow = (props) => {
  //   return (
  //     <>
  //       {props.files && props.files.length > 0 && props.files.map((file, idx) => {
  //         return (
  //           <Row key={idx}>
  //             <Col xl={{ size: 2, offset: 1 }} sm={12} />
  //             <Col>
  //               <h6>
  //                 <GrDocument />{" "}
  //                 <a href="#" onClick={(event) => downloadSingleAttachment(event, file)}>
  //                   {props.names && props.names.length > 0 && props.names[idx] ? props.names[idx] : `Attachment ${idx + 1}`}
  //                 </a>
  //               </h6>
  //             </Col>
  //           </Row>
  //         );
  //       })}
  //     </>
  //   );
  // };

  const AttachmentItem = (props) => {
    let url = props.attachment;
    let extension = url.substring(url.lastIndexOf(".") + 1);

    return (
      <>
        <Row className="property-detail-download-item-row">
          {`${props.fileName}.${extension.toLowerCase()}`}

          <h4>
            <img loading="lazy" src={download}
              style={{ cursor: "pointer", width: "30px", height: "30px" }}
              onClick={(event) =>
                downloadSingleAttachment(
                  event,
                  props.attachment,
                  props.fileName
                )
              }
            />
          </h4>

        </Row>
      </>
    );
  };

  const AttachmentList = (props) => {
    return props.attachments.map((data, index) => {
      return (
        <AttachmentItem
          attachment={data}
          index={index}
          key={`${props.fileName}${index}`}
          fileName={`${props.fileName}${index + 1}`}
          callbackId={props.callbackId}
        // canRemove={props.canRemove}
        />
      );
    });
  };

  const DownloadRow = (props) => {
    return (
      <>
        {props.attachments && props.attachments.length > 0 && (

          <div className="property-detail-download-row">
            <AttachmentList
              attachments={props.attachments}
              fileName={props.fileName}
              callbackId={props.callbackId}
            // canRemove={props.canRemove}
            />
          </div>

        )}
      </>
    );
  };

  const ModelsMap = (map, maps) => {

    let dataArray = [properties];

    //Loop through the dataArray to create a marker per data using the coordinates in the json
    for (let i = 0; i < dataArray.length; i++) {
      // lib.log(dataArray[i])
      if (dataArray[i].loc) {
        if (dataArray[i].price) {
          const label = dataArray[i].price.min && "RM " + lib.currencyFormatter(dataArray[i].price.min)

          const labelimage =
            "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2280%22%20height%3D%2280%22%20viewBox%3D%220%200%2080%2080%22%3E%3Cpath%20fill%3D%22%234AA665%22%20stroke%3D%22%232C633C%22%20stroke-width%3D%22.3%22%20d%3D%22M50%2030%20L80%2030%20L80%200%20L0%200%20L0%2030%20L32%2030%20L38%2038%20L44%2030%20Z%22%2F%3E%3Ctext%20transform%3D%22translate(19%2018.5)%22%20fill%3D%22%23fff%22%20style%3D%22font-family%3A%20Arial%2C%20sans-serif%3Bfont-weight%3Abold%3Btext-align%3Aright%22%20font-size%3D%2212%22%20text-anchor%3D%22start%22%3E" + label + "%3C%2Ftext%3E%3C%2Fsvg%3E%0A%0A";

          const propertyPng = dataArray[i].images && dataArray[i].images.length > 0 ? dataArray[i].images[0] : SetupData.defaultHouse;
          const propertyFor = "NewProject";
          const price = dataArray[i].price?.min && " From RM " + parseFloat(dataArray[i].price?.min).toLocaleString();
          const numOfBed = dataArray[i].bed?.min && dataArray[i].bed?.max ? dataArray[i].bed.min + "-" + dataArray[i].bed.max : "-";
          const numOfBath = dataArray[i].bath?.min && dataArray[i].bath?.max ? dataArray[i].bath.min + "-" + dataArray[i].bath.max : "-";
          // const numOfCarPark = dataArray[i].carParks ? dataArray[i].carParks : "-";
          const numOfBuildUp = dataArray[i].buildup?.min ? "From " + dataArray[i].buildup.min : "-";
          const developerLogo = dataArray[i].developer?.logo || SetupData.defaultHouse;

          const contentinfo = "<div class='property-list-card'> <div class='property-map-card' ><div>" +
            "<img loading='lazy' src='" + propertyPng + "' class='info-card-image'/>" +
            "</div>" +
            "<br/>" +
            "<div class='property-list-pricelabel' >" +
            "<div class='property-list-statuslabel-slide'>" +
            "<span class='m-0 property-list-project-statuslabel'>" +
            propertyFor + "</span>" + "<span class='property-list-dev-company'>" +
            "<img loading='lazy' src='" + developerLogo + "' class='property-list-dev-company-logo'/>" +
            "</span>" +
            "</div>" +
            "<p class='mb-2 greytext property-list-address'>" + "<img loading='lazy' src='" + gps + "' class='locationlogo'/>" + dataArray[i].address.area + "," + dataArray[i].address.state + "</p>" +
            "<div class='property-list-price'>" + price + "</div>" + "<b class='property-list-card-name'>" + dataArray[i].name + "</b><br/><br/>" +
            "<span class='property-card-facilitylogo-padding'>" + "<img loading='lazy' src='" + bed + "' class='facilitylogo'/>" + " " + numOfBed + " "
            + "<img loading='lazy' src='" + shower + "' class='facilitylogo'/>" + " " + numOfBath + " "
            + "<img loading='lazy' src='" + house + "' class='facilitylogo'/>" + " " + numOfBuildUp + " sqft "
            + "</span>" +
            "</div>" +
            "</div></div>";

          const infowindow = new maps.InfoWindow({
            content: contentinfo,
            maxWidth: 370,
            maxHeight: 600,
          });

          const size = string.getWidth(label)

          let marker = new maps.Marker({
            position: {
              lat: dataArray[i].loc.coordinates[1],
              lng: dataArray[i].loc.coordinates[0],
            },
            map,
            label: {
              text: label,
              color: "#ffffff",
              fontWeight: "bold",
              className: "propertyDetail-list-Maplabel-infowindow"
            },
            // icon: {
            //   path: maps.SymbolPath.BACKWARD_CLOSED_ARROW,
            //   strokeColor: "#00A82A",
            //   scale: 6,
            //   strokeWeight: 3,
            //   origin: new maps.Point(0, 0),
            //   scaledSize: new maps.Size(20, 20)

            // },
            icon: {
              url: 'https://maps.gstatic.com/mapfiles/transparent.png',
              origin: new maps.Point(0, 0),
              scaledSize: new maps.Size(size, 28)
            }
          });

          //   marker.addListener("click", () => {
          //     handleProjectPopulateData(dataArray[i])
          //   });

          //   marker.addListener("mouseover", () => {
          //     infowindow.open({
          //       anchor: marker,
          //       map,
          //       shouldFocus: false,
          //     });
          //   });

          // marker.addListener("mouseout", () => {
          //   infowindow.close({
          //     anchor: marker,
          //     map,
          //     shouldFocus: false,
          //   });
          // })

          //   marker.addListener("mouseout", () => setTimeout(function () {
          //     infowindow.close({
          //       anchor: marker,
          //       map,
          //       shouldFocus: false,
          //     })
          //   }, 2000))
        }
      }
    }
  };

  const PropertyInfo = () => {
    return (
      <div className="noborder bg-white">
        <div>
          <Container>
            {/* <h5>Property Details</h5> */}
            <hr />
            <>
              {properties.buildYear &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('build-year')}</h3></Col>
                  <Col className="greytext">{properties.buildYear}</Col>
                </Row>}

              {properties.completion &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('completion')}</h3></Col>
                  <Col className="greytext">{properties.completion}</Col>
                </Row>}

              {properties.type &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('property-type')}</h3> </Col>
                  <Col className="greytext">{properties.type}</Col>
                </Row>}

              {properties.tenure &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('tenure')}</h3></Col>
                  <Col className="greytext">{properties.tenure}</Col>
                </Row>
              }

              {/* {properties.title &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('title-type')}</b></Col>
                  <Col className="greytext">{properties.title}</Col>
                </Row>
              } */}

              {properties.developer && properties.developer.name &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('developer-name')}</h3> </Col>
                  <Col className="greytext">{properties.developer.name}</Col>
                </Row>
              }

              {properties.units &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('total-unit')}</h3></Col>
                  <Col className="greytext">{properties.units}</Col>
                </Row>
              }

            </>
            <hr />
          </Container>
        </div>
      </div>
    );
  };

  const UrlNav = (props) => {
    const { property } = props;
    if (property.address?.state) {
      const urlProjects = `/projects-for-sale`;
      const urlState = `${urlProjects}/${seoComponent.treatState(property?.address?.state)}`;
      const urlArea = `${urlState}/${seoComponent.treatArea(property?.address?.area)}`;

      return <div className="url-path">
        <a href="/">Home</a>{' > '}
        <a href={`${urlProjects}`}>Projects</a>{' > '}
        <a href={urlState}>{property?.address?.state}</a>{' > '}
        <a href={urlArea}>{property?.address?.area}</a>{' > '}
        {property?.type ? `${property.type} > ` : undefined}
        {developerFound?.name ? `${developerFound.name} > ` : undefined}
        New Project
      </div>;
    }

    return null;
  }

  const ProjectInfo = (
    <div className="cr-page property-detail-bg-color">
      <div className="back-to-search-div">
        <Container>
          <div className="back-url-div">
            <div className="back-to-search-span" onClick={() => window.history.back()}>
              <span><img loading='lazy' src={back} className="img-back-logo" alt="" />{t('back')} </span>
            </div>

            {/* <div className="url-path">
              {properties && (properties.address?.state + " > " + properties.address?.area + " > " + properties.type + " > " + developerFound?.name + " > New Project")}
            </div> */}

            <UrlNav property={properties} />
          </div>
        </Container>
      </div>
      {properties.images && properties.images.length > 0 && (
        <>
          {/* <div className="bg-black"> */}
          <div>
            <Container className="property-detail-slider-image-container" >
              <SliderImg
                images={properties.images}
                vrLink={properties.vrLink}
                videoLink={properties.videoLink}
              />
            </Container>
            <Container className="property-detail-overlay-icon-bar">
              <div className="overlayicon">
                <div className="slideicons">
                  {properties.images && properties.images.length > 0 && (
                    <Gallery sources={properties.images} type="Image" />
                  )}
                  {properties.videoLink && (
                    <Gallery sources={properties.videoLink} type="Video" custom />
                  )}
                  {properties.vrLink && (
                    <Gallery sources={properties.vrLink} type="Vr" custom />
                  )}

                </div>
              </div>
            </Container>
          </div>
        </>
      )}

      <Container className="px-3 py-3">
        <section ref={sectionRefs[0]} id="overview" className="property-detail-section" />
        <div className="property-detail-category-div">
          {properties &&
            <a onClick={(e) => onOverviewCategoryClick(e)}><button className={activeSection === 0 ? "property-detail-category-button-focus" : "property-detail-category-button"}
            >Overview</button></a>}
          <hr className="property-detail-hr-line-under-button" />
        </div>
        <Card className="Card-PropertyDetail-Info-Styling">
          <Row>
            <Col xl="8" lg="8" md="12" sm="12" xs="12">
              <div className="property-detai-project-margin">
                <span className="property-row-saleTag3">
                  {t('new-project')}
                </span>

                <span className="lightgreytext  fontxs property-detail-publishdate">
                  <small>{`${t('last-updated-on')} ` + moment(properties.created).format("DD/MM/YYYY")}</small>
                </span>

              </div>

              <div className="property-detail-pricelabel">
                {properties.price && properties.price.min && properties.price.max ? <h3>
                  {`RM ${parseFloat(properties.price.min).toLocaleString()} - RM ${parseFloat(properties.price.max).toLocaleString()}`}
                </h3> : (properties.price && properties.price.min) ?
                  <h3>
                    {`${t('from')} RM ${parseFloat(properties.price.min).toLocaleString()}`}
                  </h3>
                  :
                  <h3>
                    Price not found
                  </h3>
                }
              </div>
              <p className="m-0 greytext property-detail-address">
                <img loading='lazy' src={gpsgrey} alt="" /> {propertyComponent.formProjectFullAddress(properties.address)}
              </p>
              <Row>
                <Col xl="10" lg="10" md="10" sm="12" xs="12">
                  <h3 >{properties.name}</h3>
                </Col>
              </Row>

              {properties && (
                <>
                  <Row className="Property-Icon-Info-Padding">
                    <Col lg="12" md="12" sm="12" className="align-self-center">
                      <Row className="iconfacilities">
                        {properties.bed?.min && (
                          <Col lg="2" md="2" sm="3" xs="6" className="mt-2 iconfacilities-padding">
                            <p className="fontsm m-0">
                              {/* <FaBed /> */}
                              {properties.bed.min && properties.bed.max && <><img loading='lazy' src={orangebed} alt="" />{" "}<span>{properties.bed.min + "-" + properties.bed.max}</span></>}
                              {properties.bed.min && !properties.bed.max && <><img loading='lazy' src={orangebed} alt="" />{" "}<span>{`${t('from')} ${properties.bed.min}`}</span></>}
                            </p>
                            <small className="m-0">
                              <p className="property-detail-logo-icon-description">{t('bedrooms')}</p>
                            </small>
                          </Col>
                        )}
                        {properties.bath?.min && (
                          <Col lg="2" md="2" sm="3" xs="6" className="mt-2 iconfacilities-padding">
                            <p className="fontsm m-0">
                              {/* <FaShower /> */}
                              {properties.bath.min && properties.bath.max && <><img loading='lazy' src={orangeshower} alt="" />{" "}<span>{properties.bath.min + "-" + properties.bath.max}</span></>}
                              {properties.bath.min && !properties.bath.max && <><img loading='lazy' src={orangeshower} alt="" />{" "}<span>{`${t('from')} ${properties.bath.min}`}</span></>}
                            </p>
                            <small className="m-0">
                              <p className="property-detail-logo-icon-description">{t('bathrooms')}</p>
                            </small>
                          </Col>
                        )}
                        {properties.buildup && (
                          <>
                            <Col lg="3" md="3" sm="3" xs="6" className="mt-2 iconfacilities-padding">
                              <p className="fontsm m-0">
                                {/* <IoMdQrScanner /> */}
                                <img loading='lazy' src={orangehouse} alt="" />
                                &nbsp;
                                {`${parseFloat(properties.buildup.min).toLocaleString()}-${parseFloat(properties.buildup.max).toLocaleString()}`}
                              </p>
                              <small className="m-0">
                                <p className="property-detail-logo-icon-description">{t('square-feet')}</p>
                              </small>
                            </Col>
                          </>
                        )}
                        {properties.units && (
                          <>
                            <Col lg="2" md="2" sm="3" xs="6" className="mt-2 iconfacilities-padding">
                              <p className="fontsm m-0">
                                {/* <IoMdQrScanner /> */}
                                <img loading='lazy' src={houseUnitOrange} alt="" />
                                &nbsp;
                                {`${parseFloat(properties.units).toLocaleString()}`}
                              </p>
                              <small className="m-0">
                                {/* totalUnit */}
                                <p className="property-detail-logo-icon-description">{t('total-unit')}</p>
                              </small>
                            </Col>
                          </>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <div>
                    {properties.brochures && properties.brochures.length > 0 && (
                      <>
                        {/* <FileRow files={properties.brochure} names={properties.fileNames} /> */}
                        <DownloadRow
                          title="Brochure"
                          attachments={properties.brochures}
                          fileName="brochures"
                          callbackId="brochures"
                        // canRemove={canWrite}
                        />
                      </>
                    )}
                    {pdfStoreyPlan && pdfStoreyPlan.length > 0 && (
                      <>
                        {/* <FileRow files={properties.brochure} names={properties.fileNames} /> */}
                        <DownloadRow
                          title="StoreyPlan"
                          attachments={pdfStoreyPlan}
                          fileName="storeyPlan"
                          callbackId="storeyPlan"
                        // canRemove={canWrite}
                        />
                      </>
                    )}
                  </div>
                  <div>
                    {properties.highlight && (
                      <div className="py-3 property-detail-bg-color description-border">
                        <Container>
                          <h2 className="property-detail-h2">{`${t('property-highlights')}:`}</h2>
                          <p className="m-0" style={{ whiteSpace: "break-spaces" }}>
                            {properties.highlight}
                          </p>
                        </Container>
                      </div>
                    )}

                    {imgStoreyPlan && imgStoreyPlan.length > 0 &&
                      <div className="property-detail-storey-plan-slider">
                        <h5 className="property-detail-storey-plan-slider-h5">Storey Plan</h5>
                        <Container className="property-detail-slider-image-container" >
                          <SliderImg
                            images={imgStoreyPlan}

                          />
                        </Container>
                        <Container className="property-detail-overlay-icon-bar">
                          <div className="overlayicon">
                            <div className="slideicons">
                              {imgStoreyPlan && imgStoreyPlan.length > 0 && (
                                <Gallery sources={imgStoreyPlan} type="Image" />
                              )}
                            </div>
                          </div>
                        </Container>
                      </div>
                    }

                    <div className="py-5">
                      <Container >
                        <hr />
                        {properties.loc && <>
                          {displayMap ?
                            <div className="Property-Detail-Map-Warp">
                              <GoogleMapReact
                                bootstrapURLKeys={{
                                  key: process.env.REACT_APP_GOOGLE_API_KEY
                                }}
                                defaultCenter={{
                                  lat: properties.loc.coordinates[1],
                                  lng: properties.loc.coordinates[0],
                                }}
                                defaultZoom={15}
                                yesIWantToUseGoogleMapApiInternals//
                                onGoogleApiLoaded={({ map, maps }) => ModelsMap(map, maps)}
                                options={{ gestureHandling: "greedy" }}
                              >
                              </GoogleMapReact>
                            </div>
                            :
                            <div className="Property-Detail-Map-Warp">
                              <img loading='lazy' alt="" onClick={() => setDisplayMap(true)} src={mapPreview} className="Property-Detail-Map-Warp-map-preview"></img>
                            </div>
                          }
                        </>}
                      </Container>
                      {/* <div id="mapp" className="Property-Detail-Map-Warp"></div> */}
                    </div>
                    <PropertyInfo />

                    {properties.facilities && properties.facilities.length > 0 && (
                      <Card className="noborder ">
                        <div className="py-3">
                          <Container>
                            <h5>{t('facilities-list')}</h5>
                            <hr />
                            <Row>
                              {properties.facilities &&
                                properties.facilities.length > 0 &&
                                properties.facilities.map((facility) => {
                                  return (
                                    <Col lg="4" md="4" sm="4" xs="12" key={facility}>
                                      <Media className="mt-2 mt-2">
                                        <Media left className="align-self-center">
                                          {propertyComponent.facilities(facility)}
                                        </Media>
                                        <Media body className="align-self-center">
                                          <p className="m-0">{t(propertyComponent.facilitiesTranslateKey(facility))}</p>
                                        </Media>
                                      </Media>
                                    </Col>
                                  );
                                })}
                            </Row>
                          </Container>
                        </div>
                      </Card>
                    )}
                  </div>
                </>
              )}
            </Col>
            {developerFound &&
              <Col>
                <Card className="AgentCard-Detail-Info" style={{ "position": "sticky", "top": "150px" }}>
                  <div className="developer-detail">
                    <img loading='lazy' src={developerFound.logo} className="developer-logo" alt="" /><br />
                    <div className="developer-name">
                      {developerFound.name}
                    </div>
                  </div>
                  <div className="developerCard-Button">
                    {properties.developerContact &&
                      <Button
                        className="developer-call-button"
                        onClick={handlePhone}
                      >
                        <IoMdPhonePortrait />
                        {" "}
                        <small>{properties?.developerContact}</small>
                      </Button>
                    }
                    <br />
                    {properties.developerWhatsapp &&
                      <Button
                        className="developer-call-button-whatsapp"
                        onClick={handleWhatApps}
                      >
                        <IoLogoWhatsapp />
                        WhatsApp
                      </Button>
                    }
                  </div>

                  <div className="project-enquiry-form-div">

                    <div className="contact-us-form-info">
                      <div className="contact-us-form-text">
                        <div className="contact-us-form-text-detail">
                          {/* {`${t('your-name')}*`}  */}
                        </div>
                        <TextInput
                          name="name"
                          placeholder="Full Name*"
                          callbackId="name"
                          callback={inputCallback}
                          readOnly={false}
                          value={contactForm.name || ""}
                        />
                      </div>
                    </div>
                    <div className="contact-us-form-text">
                      <div className="contact-us-form-text-detail">
                        {/* {`${t('your-email')}*`}  */}
                      </div>
                      <TextInput
                        name="email"
                        placeholder="Email*"
                        callbackId="email"
                        callback={inputCallback}
                        readOnly={false}
                        value={contactForm.email || ""}
                      />
                    </div>
                    <div className="contact-us-form-text">
                      <div className="contact-us-form-text-detail">
                        {/* {`${t('your-contact-number')}*`}  */}
                      </div>
                      <TextInput
                        name="mobile"
                        placeholder="Phone Number*"
                        callbackId="mobile"
                        callback={inputCallback}
                        readOnly={false}
                        value={contactForm.mobile || ""}
                      />
                    </div>

                    <div className="project-enquiry-errprompt-contact-us">
                      <code>
                        <small>
                          {errorPrompt ? (
                            errorPrompt
                          ) : (
                            <pre className="errprompt-spacing"></pre>
                          )}
                        </small>
                      </code>
                    </div>

                    <div className="project-enquiry-form-tnc">
                      By sending, I agree to disclose my personal infomation to the Developer/Agent to contact me about my enquiry: and to Nextsix's <a href="/tnc/agent/index.html">Terms of use</a> and <a href="/privacy-policy/en/index.html">Privacy Policy</a>
                    </div>

                    {
                      !refreshLang &&
                      <div className="project-enquiry-contact-us-recaptcha">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
                          onChange={verifyCaptcha}
                          onExpired={expireCaptcha}
                          hl={recaptchaLocalize()}
                        />
                      </div>
                    }

                    <div>
                      <Button onClick={onSubmitClick} className="contact-us-submit-button">
                        {/* {t('submit')} */}
                        I'm Interested
                      </Button>
                    </div>

                  </div>



                </Card>
              </Col>
            }
          </Row>
        </Card>
      </Container>

      <section id="calculator" className="property-detail-section" />
      <Container>
        {(properties.price) &&
          <Card className="Card-OtherDetail-Info-Styling">
            <div style={{ padding: "15px" }}>
              <h5 style={{ margin: "15px 0 45px" }}>Mortgage Calculator</h5>
              <MortgageCalculator callback={calculatorCallback} properties={properties} />
            </div>
          </Card>
        }
      </Container>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <div className="checkLogoWithCircle">
            <img loading='lazy' src={checkWithCircle} alt="circle" height="200px" width="200px"></img>
          </div>
          <h4 className="project-enquiry-checkTitle">{modaltitle}</h4>
          <p className="project-enquiry-checkParagraph">
            We will get back to you soon!.
          </p>
          <hr />
          <p className="project-enquiry-checkParagraph">
            Login or Create an account to save and keep track of this project.
          </p>
        </ModalBody>
        <ModalFooter>
          <div className="contact-us-modal-ok">
            <Button className="btn-orange" onClick={onOkClick}>
              Continue with App
              {/* go to app or app store btn */}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );

  // useEffect(() => {
  //   dispatch(close("open"));
  // }, [])

  const closeBanner = () => {
    dispatch(close("close"));
  }
  return (
    <>
      {
        getwidth < 768 && emblem === "open" &&
        <div className="emblem-wrapper">
          <div className="emblem-left-content">
            <Button className="emblem-close-btn" onClick={() => closeBanner()}><MdClose /></Button>
            <img loading='lazy' className="emblem-logo" src={nextsixAppEmblem} alt="" />
            <div className="emblem-left-textbox">
              <span className="emblem-left-text-top">NextSix App</span>
              <span className="emblem-left-text-btm">Find properties and agents faster</span>
            </div>
          </div>
          <div className="emblem-right-content">
            <a className="emblem-download-btn" href="https://apps.nextsix.com/users/reg">Use Now</a>
          </div>
        </div>
      }
      <Header2 />
      <LoadingIndicator />
      <Container fluid className="property-list-empty">
      </Container>
      {/* NXT-1876 */}
      {ProjectInfo}
      {/* {mode ? ProjectInfo : <PageError />} */}
      <Footer2 />
    </ >
  );
};

export default ProjectDraftPage;
