import React, { useRef, useState } from "react";
import '../styles/pages/deleteAccountPage.scss'
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";

import userComponent from "../businesses/userComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DeleteAccountPage = () => {
    const history = useHistory()
    const emailRef = useRef(null)
    const [success, setSuccess] = useState({ state: false })

    const backToHome = () => {
        history.push('/')
    }

    const SuccessPage = () => {
        return (
            <>
                <h2 className="dap-title">We're sorry to see you leaving</h2>
                <div className="dap-text text-center">
                    Please refer to <span className="dap-email">{success.email}</span> for final confirmation. <br />
                    Account deletion is final. There will be no way to restore your account.
                </div>
                <button className="dap-btn-ok" onClick={backToHome}>Ok</button>
            </>
        )
    }

    const FormPage = () => {
        const [errMsg, setErrMsg] = useState("")

        const handleConfirm = async () => {
            const email = emailRef.current.value
            if (email) {
                const res = await userComponent.requestDeleteAccount(email)
                if (res) setSuccess({ state: true, email: email })
                else setErrMsg("Email is not registered.")
            } else setErrMsg("Please key in email address.")
        }

        return (
            <>
                <h2 className="dap-title">Deleting account will do the following:</h2>
                <div className="dap-text">
                    <p>Delete your account from NextSix</p>
                    <p>Erase your message histories</p>
                    <p>Delete all your appointment histories</p>
                    <p>Delete your enquiries with merchants</p>
                </div>
                <input className="dap-input" id="email" ref={emailRef} placeholder="Enter email address" />
                {errMsg && <code className="dap-err-msg">{errMsg}</code>}
                <div className="dap-btn-wrapper">
                    <button className="dap-btn-confirm" onClick={handleConfirm}>Confirm</button>
                    <button className="dap-btn-outline" onClick={backToHome}>No, I have changed my mind</button>
                </div>
            </>
        )
    }

    return (
        <>
            <Header2 />
            <div className="dap-container">
                {success.state ? <SuccessPage /> : <FormPage />}
            </div>
            <Footer2 />
        </>
    )
}


export default DeleteAccountPage;