import apibase from "./BaseAPI";
import sharedComponent from "../businesses/sharedComponent";
import lib from "../businesses/lib";

const siteMapData = async () => {
  const stateAreas = await sharedComponent.getAreas();
  const states = lib.stateOptionsV2(stateAreas);
  const url = "/sitemap";

  try {
    const r = await apibase.get(url);
    const result = r.data.data;
    result.states = states;
    states.concat(result.popularSalesProperties);
    states.concat(result.popularRentProperties);
    return result;
  } catch (err) {
    throw err;
  }
};

export default {
  siteMapData: siteMapData,
};
