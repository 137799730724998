import apibase from './BaseAPI';

const id = async (masterId) => {
    const url = `/master/properties/${masterId}`;
    try {
        const result = await apibase.get(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

export default {
    id,
}