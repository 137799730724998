
import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import lib from '../businesses/lib'
import "../styles/pages/propertyDetailPage.scss"
const SliderImg = (props) => {
    const [imageTimeStamp] = useState(new Date().getTime());
    const [images, setImages] = useState([]);
    const [showVideo, setShowVideo] = useState({});
    const [showVR, setShowVR] = useState({});
    const [showPlayButton, setShowPlayButton] = useState(true);
    const [showFullscreenButton, setShowFullscreenButton] = useState(true);

    useEffect(() => {
        _getStaticImages();
    }, [])

    useEffect(() => {
        //lib.log(showVideo);
    }, [showVideo])

    const _getStaticImages = () => {
        let imageList = [];
        if (props.videoLink) {
            imageList.push({
                original: lib.getYoutubeId(props.videoLink),
                thumbnail: "https://iprofstore.blob.core.windows.net/general/nextsix-logovideo-thumb.png",
                embedUrl: props.videoLink,
                renderItem: _renderVideo.bind(this)
            })
        }
        if (props.vrLink) {
            imageList.push({
                original: '',
                thumbnail: "https://iprofstore.blob.core.windows.net/general/vr-thumb.png",
                embedUrl: props.vrLink,
                renderItem: _render360.bind(this)
            })
        }
        if (props.images && props.images.length > 0) {
            props.images.map(image => {
                imageList.push({
                    original: `${image}?${imageTimeStamp}`,
                    thumbnail: `${image}?${imageTimeStamp}`,
                    originalAlt: props.originalAlt
                })
            })
        }

        setImages(imageList);
    }

    const _toggleShowVideo = (url) => {
        showVideo[url] = !Boolean(showVideo[url]);
        setShowVideo([url], showVideo[url])

        // if (showVideo[url]) {    
        //     if (showPlayButton) {
        //         setShowPlayButton(false)
        //     }

        //     if (showFullscreenButton) {
        //         setShowFullscreenButton(false)
        //     }
        // }
    }

    const _toggleShowVR = (url) => {
        showVR[url] = !Boolean(showVR[url]);
        setShowVR(showVR[url])

        if (showVR[url]) {
            if (showPlayButton) {
                setShowPlayButton(false)
            }

            if (showFullscreenButton) {
                setShowFullscreenButton(false)
            }
        }
    }

    const _renderVideo = (item) => {
        return (
            <div>
                {
                    showVideo[item.embedUrl] ?
                        <div className='property-detail-video-wrapper'>
                            {/* <a
                                className='close-video'
                                onClick={() => _toggleShowVideo(item.embedUrl)}
                            >
                            </a> */}
                            <iframe
                                width="100%"
                                height="400"
                                allow="autoplay; encrypted-media"
                                src={item.embedUrl}
                                frameBorder='0'
                                // allow='autoplay'
                                allowFullScreen
                            >
                            </iframe>
                        </div>
                        :
                        <a onClick={() => _toggleShowVideo(item.embedUrl)}>
                            <div className='play-button'></div>
                            <img className='image-gallery-image' src={item.original} />
                        </a>
                }
            </div>
        );
    }

    const _render360 = (item) => {
        return (
            <div className="property-detail-view360">
                <iframe src={item.embedUrl}
                    frameBorder='0'
                    allow='encrypted-media'
                    allowFullScreen
                    title='video'
                    width="100%"
                    height="400"
                />
            </div>
        )
    }

    return (
        <section className='app'>
            <ImageGallery
                items={images}
                showBullets={true}
                showThumbnails={true}
                thumbnailPosition="right"
                // additionalClass="app-image-gallery"
                showPlayButton={true}
                showFullscreenButton={false}
                autoPlay={false}
                slideDuration={500}
                slideOnThumbnailOver={true}
                // className="sliderimagetry"
            />
        </section>
    );

}

export default SliderImg;