import React, { useState, useRef, useEffect } from 'react';
import NotificationSystem from 'react-notification-system';
import { MdFileDownload, MdDeleteForever, MdAddCircle } from 'react-icons/md';
import { GrDocument, GrAddCircle } from "react-icons/gr";
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import { trackPromise } from 'react-promise-tracker';
import FileDownload from 'js-file-download';
import InfiniteScroll from "react-infinite-scroll-component";
import {
    Row,
    Col,
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardFooter,
    Table,
    InputGroup,
} from 'reactstrap';

import LoadingIndicator from '../components/LoadingIndicator';
import SmartText from '../components/SmartText';
import Avatar from '../components/Avatar';
import workshopComponent from '../businesses/workshopComponent';
import fileComponent from '../businesses/fileComponent';
import lib from '../businesses/lib';
import SetupData from '../data/SetupData';
import agentComponent from '../businesses/agent';
import { useParams } from "react-router-dom";

const WorkshopPage = (parentProps) => {
    const MODE_SEARCH = 'search';
    const MODE_EDIT = 'edit';
    const MODE_LOADING = 'loading';
    const MODE_UNAUTHORIZED = 'unauthorized';
    const [mode, setMode] = useState(MODE_LOADING);
    const [modal, setModal] = useState(false);
    const [workshop, setWorkshops] = useState([]);
    const [search, setSearch] = useState('');
    const [loadMore, setLoadMore] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [previousSearch, setPreviousSearch] = useState('');
    const notificationWorkshopPage = useRef(null);
    const [empty, setEmpty] = useState("");
    const [imageTimestamp, setImageTimestamp] = useState(new Date().toString());
    let { id } = useParams();
    const ua = window.navigator.userAgent;


    useEffect(() => {
        window.scrollTo(0, 0);
        initialize();

    }, []);

    const initialize = async () => {
        try {
            const agent = await trackPromise(agentComponent.getAgentInfo(id));
            lib.log(agent);

            if (agent) {
                setMode(MODE_SEARCH);
                await performSearch();
            } else {
                setMode(MODE_UNAUTHORIZED);
            }
        }
        catch (err) {
            setMode(MODE_UNAUTHORIZED);
        }
    };

    const performSearch = async () => {
        setPreviousSearch(search);
        setImageTimestamp(new Date().toString());
        const agent = await trackPromise(agentComponent.getAgentInfo(id));
        lib.log(agent)
        const res = await trackPromise(workshopComponent.search(null, agent.companyName, search, 0));
        if (res) {
            setWorkshops(res.data);
            if (res.data.length === 0) setEmpty(true);
            else {
                setEmpty(false);
                setLoadMore(true);
                setTotalRecord(res.summary.record);
                if (res.data.length >= res.summary.record) setLoadMore(false);
            }
        }
        lib.log(res)
    };

    // const downloadSingleAttachment = async (event, url) => {
    //     event.preventDefault();
    //     const result = await trackPromise(fileComponent.download(url));
    //     if (result.binaryData && result.extension) {
    //         // let blobData = new Blob([result.binaryData]);
    //         // FileDownload(blobData, `${fileName}.${result.extension}`);
    //         let blobData = new Blob([result.binaryData], { type: result.mime });
    //         var objectUrl = URL.createObjectURL(blobData);

    //         // if (ua.match(/CriOS/) ||
    //         //     (ua.match(/Safari/) && ua.match(/Version\/(9|10|11|12)/))) {

    //             const link = document.createElement('a');

    //             link.setAttribute('href', objectUrl);
    //             //link.setAttribute("download", 'temp.pdf');

    //             const event = document.createEvent('MouseEvents');
    //             event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
    //             link.dispatchEvent(event);
    //         // }
    //         // else
    //         //     window.open(objectUrl);
    //     }
    // };

    const downloadSingleAttachment = async (event, url) => {
        event.preventDefault();
        const result = await trackPromise(fileComponent.download(url));
        if (result.binaryData && result.extension) {
            let blobData = new Blob([result.binaryData], { type: result.mime });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blobData);
                return;
            }

            var objectUrl = URL.createObjectURL(blobData);
            if (ua.match(/CriOS/) ||
                (ua.match(/Safari/) && ua.match(/Version\/(9|10|11|12|13|14)/))) {

                //alert(ua.toString());

                const link = document.createElement('a');
                link.setAttribute('href', objectUrl);
                //link.setAttribute("download", 'temp.pdf');

                const event = document.createEvent('MouseEvents');
                event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                link.dispatchEvent(event);
            }
            else
                window.open(objectUrl);
        }
    };

    const toggle = () => {
        setModal(!modal)
    };

    const handleLoadMore = async () => {
        let newArr = workshop.slice(0);
        const res = await trackPromise(workshopComponent.search(null, previousSearch, workshop.length));

        if (res) {
            res.data.forEach(element => {
                newArr.push(element);
            });
        }

        setWorkshops(newArr);

        if (newArr.length < totalRecord) setLoadMore(true);
        else setLoadMore(false);

    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    }

    const handleSearch = () => {
        performSearch();
    };

    const handleSearchEnter = (e) => {
        if (e.key.toLowerCase() === 'enter' && e.target.value) performSearch();
    };

    const handleNewWorkshop = async () => {
        setSearch('');
        setMode(MODE_EDIT);
    };

    const handleRemoveWorkshop = async (id) => {
        await workshopComponent.remove(id);
        performSearch();
        setModal(!modal)
    };

    const handleSubmit = async (workshop) => {
        await trackPromise(workshopComponent.save(workshop));
        performSearch();
        setMode(MODE_SEARCH);
    };

    const handleCancel = () => {
        performSearch();
        setMode(MODE_SEARCH);
    };

    const InfoDetails = (props) => {
        return (
            <>
                {/* images */}
                {
                    props.type == "images" && props.source && props.source.length > 0 && props.source.map((imgURL, idx) => {
                        return (<Col lg={4} sm={6} xs={12} key={idx}>
                            <CardImg top height="250px" width="auto" src={imgURL} alt={props.source.name} style={{ marginBottom: '1rem' }} />
                        </Col>)
                    })
                }
                {/* videos */}
                {
                    props.type == "videos" && props.source && props.source.length > 0 && props.source.map((url, idx) => {
                        return <Col lg={4} sm={6} xs={12} key={idx}>
                            <iframe src={url}
                                frameBorder='0'
                                allow='encrypted-media'
                                allowFullScreen
                                title='video'
                                width="100%"
                                height="250px"
                                style={{ marginBottom: '1rem' }}
                            />
                        </Col>
                    })
                }
            </>
        )
    }

    const FileRow = props => {
        return (<>
            {props.files && props.files.length > 0 && props.files.map((file, idx) => {
                return (<Row key={idx}>
                    <Col>
                        <h6><GrDocument /> <a href="#" data-url={file}>
                            {props.names && props.names.length > 0 && props.names[idx] ? props.names[idx] : `Attachment ${idx + 1}`}
                        </a></h6>
                    </Col>
                </Row>)
            })}
        </>)
    }

    const WorkshopRows = props => {
        const workshop = props.workshop;
        return workshop.map(workshop => {
            return (
                <Col key={workshop._id} xs={12} className="mb-3">
                    {/* <Card style={{ cursor: 'pointer' }} title={`Edit ${workshop.name}`}> */}
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={12}>
                                    <Avatar src={workshop.admin.images ? workshop.admin.images + "?" + imageTimestamp : SetupData.profile.imgURL} className="mb-2" />&nbsp;&nbsp;
                                    {workshop.admin.name}
                                </Col>
                            </Row>
                            <CardTitle><h3>{workshop.subject}</h3></CardTitle>
                            <CardSubtitle>
                                <SmartText value={workshop.caption} length={200} />
                            </CardSubtitle>
                            {/* <br /><Row><Col><GrDocument /></Col><Col>Document 1</Col></Row> */}
                            {
                                workshop.files && workshop.files.length > 0 &&
                                <>
                                    <FileRow files={workshop.files} names={workshop.fileNames} /><br />
                                </>
                            }
                            <span className="text-muted"><small>post on {lib.formatDateTime(workshop.created)}</small></span>
                        </CardBody>
                        {((workshop.images && workshop.images.length > 0) || (workshop.videos && workshop.videos.length > 0)) && <CardFooter className="text-muted">
                            <Row>
                                <InfoDetails source={workshop.images} type="images" />
                                <InfoDetails source={workshop.videos} type="videos" />
                            </Row>
                        </CardFooter>}

                    </Card>
                </Col>
            )
        });
    };

    const WorkshopTable = props => {
        return (
            <>
                <br />
                <Row>
                    <WorkshopRows workshop={props.workshop} />
                </Row>
                {/* {
                    loadMore &&
                    <button className="btn btn-primary" onClick={handleLoadMore}>Load More</button>
                } */}
            </>
        );
    };

    const tableListing = workshop && workshop.length > 0 &&
        <WorkshopTable workshop={workshop} />;

    const searchContent = (
        <>
            {
                empty &&
                <Card>
                    <CardBody>
                        <InputGroup>
                            {/* <input type="text" className="form-control" placeholder="Search Feed Subject Name ..."
                            onChange={handleSearchChange}
                            value={search}
                            onKeyUp={handleSearchEnter} />
                        <div className="input-group-btn">
                            <button className="btn btn-primary" onClick={handleSearch} >Search</button>
                            {
                                canWrite &&
                                <button className="btn btn-link btn-sm" onClick={handleNewWorkshop}>New feed</button>
                            }

                        </div> */}
                        </InputGroup>
                        <div className="alert alert-primary" role="alert">
                            No records found!
                        </div>
                    </CardBody>
                </Card>
            }
            <InfiniteScroll
                dataLength={workshop.length}
                next={handleLoadMore}
                hasMore={loadMore}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p className="text-muted text-center">
                        <small> Looks like you've reached the end </small>
                    </p>
                }

                style={{ overflow: "hidden" }}
            >
                {tableListing}
            </InfiniteScroll>
        </>
    );

    const validSection = (
        <div className="cr-page px-3">
            <LoadingIndicator />
            <div className="cr-page__header">
                <h1 className="h1 cr-page__title">Guidance</h1>
                <nav className="cr-page__breadcrumb" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">Nextsix</li>
                        <li className="active breadcrumb-item" aria-current="page">Guidance</li>
                    </ol>
                </nav>
            </div>

            {searchContent}
            <NotificationSystem ref={notificationWorkshopPage} style={NOTIFICATION_SYSTEM_STYLE} />
        </div>
    );

    const invalidSection = (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h2>
                        <img loading="lazy" alt="nextsix" src="https://iprofstore.blob.core.windows.net/general/nextsix.png" style={{ width: "120px", height: "120px" }} />
                    </h2>
                    <h2>401. Unauthorized</h2>
                </div>
            </div>
        </div>
    );

    const loadingSection = (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h2>Loading...</h2>
                </div>
            </div>
        </div>
    );

    const loadContent = () => {
        if (mode === MODE_LOADING)
            return loadingSection;
        else if (mode === MODE_SEARCH)
            return validSection;
        else if (mode === MODE_UNAUTHORIZED)
            return invalidSection;
    };

    return (
        loadContent()
    );
};

export default WorkshopPage;
