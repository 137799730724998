import apibase from "./BaseAPI";
import lib from "../businesses/lib";

const id = async (id) => {
  const url = `/projects/${id}`;
  try {
    const result = await apibase.get(url);
    return result.data;
  } catch (err) {
    throw err;
  }
};

const projectWebSearch = async (search, paging) => {
  let params = {};
  if (paging) params.paging = paging;
  if (search) params.search = search;
  lib.log('projectWebSearch');
  lib.log(params);
  const url = '/projects/web/search';
  try {
      const result = await apibase.post(url, params);
      return result.data;
  } catch (err) {
      throw err;
  }
};


const save = async (contactForm) => {
  try {
      const res = await apibase.post(`/projectEnquiry/save`, contactForm);
      lib.log(res);
      lib.log(res.data);
      return res.data;
  } catch (error) {
      lib.log(error)
  }
}

const checktoken = async (token) =>{
  const secret = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;
  const params = { 
      token: token,
      secret: secret
   }
  try{

      const response = await apibase.post(`/projectEnquiry/checktoken`,params)
      // console.log(response,"recap data")
      return response.data;
  }catch (error){
      lib.log(error)
  }
}

export default {
    id: id,
    projectWebSearch:projectWebSearch,
    save: save,
    checktoken:checktoken,
  };