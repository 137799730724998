import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import NumberFormat from "react-number-format";
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import "../styles/components/MortgageCalculator.scss";
// import greenKetupatLogo from "../assets/img/Green.svg"

const MortgageCalculator = (props) => {
  const properties = props.properties ? props.properties : {};
  const [propertyPrice, setPropertyPrice] = useState(props.data ? props.data.propertyPrice : 500000);
  const [interestRate, setInterestRate] = useState(props.data ? props.data.interestRate : 3.60);
  const [downPayment, setDownPayment] = useState(props.data ? props.data.downPayment : 10);
  const [loanTerm, setLoanTerm] = useState(props.data ? props.data.loanTerm : 35);
  const [options, setOption] = useState(props.data ? props.data.options : "percentage");
  const [loanAmount, setloanAmount] = useState();
  const [monthlyPayment, setMonthlyPayment] = useState();

  const handleDetailedCalculator = () => {
    props.callback({
      propertyPrice: propertyPrice,
      interestRate: interestRate,
      downPayment: downPayment,
      loanTerm: loanTerm,
      options: options
    })
  }

  const onChangeOption = (event) => {
    setOption(event.target.value);
    let propertyPriceNum = deFormat(propertyPrice);
    let downPaymentNum = deFormat(downPayment);
    let downPaymentPercentage = propertyPriceNum * (downPaymentNum / 100);
    let downPaymentFixed = downPaymentNum / propertyPriceNum * 100;
    if (options === "percentage") {
      setDownPayment(isNaN(downPaymentPercentage) ? 0 : downPaymentPercentage);
    } else {
      setDownPayment(isNaN(downPaymentFixed) ? 0 : downPaymentFixed);
    }
  }

  useEffect(() => {
    if(properties.buy && properties.for=='Buy') {
      setPropertyPrice(properties.buy.price);   // get price from realestates
    }
    if(properties.price?.min) {
      setPropertyPrice(properties.price.min);   // get min price from projects
    }
    calculateLoanAmount();
    calculateMonthlyPayment();    
  }, [properties])

  useEffect(() => {
    calculateLoanAmount();
  }, [propertyPrice, downPayment])

  useEffect(() => {
    calculateMonthlyPayment();
  }, [interestRate, loanTerm, loanAmount])

  const calculateLoanAmount = () => {
    let propertyPriceNum = deFormat(propertyPrice);
    let downPaymentNum = deFormat(downPayment);
    if (options === "percentage") {
      if (downPaymentNum > 100) {
        setDownPayment(100);
      }
      setloanAmount(propertyPriceNum - (propertyPriceNum * (downPaymentNum / 100)));
    } else {
      setloanAmount(propertyPriceNum - downPaymentNum);
    }
  }

  const calculateMonthlyPayment = () => {
    if (interestRate > 100) {
      setInterestRate(100);
    }
    let rate = deFormat(interestRate) / 12 / 100;
    let tenure = deFormat(loanTerm) * 12;
    let loan = loanAmount;
    let rateTenure = Math.pow(1 + rate, tenure);
    let total = (rate * loan * rateTenure) / (rateTenure - 1);
    setMonthlyPayment(isNaN(total) ? 0 : total);
  }

  const deFormat = (num) => {
    num = num.toString().replace(/\$|\,/g, '');
    return num;
  }

  
  // const today = new Date()
  // const etaStart = new Date('2023-04-21T16:00:00.000Z')
  // const etaEnd = new Date('2023-04-30T16:00:00.000Z')

  return (
    <div>
      <Row>
        <Col xl={4} lg={4} md={6} sm={6}>
          <label className="mortgage-label">
            Property Price (RM)
          </label>
          <NumberFormat
            thousandSeparator={true}
            allowNegative={false}
            decimalScale={2}
            className="form-control mortgage-input"
            name="propertyPrice"
            readOnly={false}
            value={propertyPrice}
            onInput={(e) => setPropertyPrice(e.target.value)}
          />
          <label className="mortgage-label">
            Interest Rate (%)
          </label>
          <NumberFormat
            thousandSeparator={true}
            allowNegative={false}
            decimalScale={2}
            className="form-control mortgage-input"
            name="interestRate"
            readOnly={false}
            value={interestRate}
            onInput={(e) => setInterestRate(e.target.value)}
          />
        </Col>
        <Col xl={4} lg={4} md={6} sm={6}>
          <label className="mortgage-label">
            Down Payment
          </label>
          <div className="input-group-append down-payment-box">
            <div>
              <NumberFormat
                thousandSeparator={true}
                allowNegative={false}
                decimalScale={2}
                className="form-control mortgage-input"
                name="downPayment"
                readOnly={false}
                value={downPayment}
                onInput={(e) => setDownPayment(e.target.value)}
              />
            </div>
            <div className="down-payment-toggle-group" onChange={onChangeOption}>
              <ToggleButtonGroup type="radio" name="options" defaultValue="percentage">
                <ToggleButton className={options === "fixed" ? "toggle-button-active" : "toggle-button"} value="fixed" checked={options === "fixed"}>RM</ToggleButton>
                <ToggleButton className={options === "percentage" ? "toggle-button-active" : "toggle-button"} value="percentage" checked={options === "percentage"}>%</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <label className="mortgage-label">
            Loan Term (Years)
          </label>
          <NumberFormat
            thousandSeparator={true}
            allowNegative={false}
            decimalScale={2}
            className="form-control mortgage-input"
            name="loanTerm"
            readOnly={false}
            value={loanTerm}
            onInput={(e) => setLoanTerm(e.target.value)}
          />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12}>
          <div className="total-amount-card">
            <label className="total-amount-label">Total payment per month will be</label>
            <div className="total-amount-text">
              <span className="total-amount-prefix">RM</span>
              <span className="total-amount-amount">
                <NumberFormat
                  displayType={"text"}
                  decimalScale={0}
                  thousandSeparator={true}
                  value={monthlyPayment}
                  readOnly />
              </span>
            
              {/* {((window.location.pathname == "/mortgage-calculator")&&(etaStart<=today)&&(today<=etaEnd))&&<span className="mortgageCalculator-ketupat-span"><img src={greenKetupatLogo} className="mortgageCalculator-ketupat"></img>  </span>} */}
              

            
            </div>
            <div className="loan-amount-wrapper">
              <div className="loan-amount">
                <p className="loan-amount-label">Loan Amount</p>
                <p className="loan-amount-text">
                  <NumberFormat
                    displayType={"text"}
                    decimalScale={0}
                    thousandSeparator={true}
                    value={loanAmount}
                    prefix={"RM "}
                    readOnly />
                </p>
              </div>
              {(window.location.pathname != "/mortgage-calculator") &&
                <div className="detailed-calculator">
                  <Button className="detailed-calculator-btn" color="primary" onClick={handleDetailedCalculator}><span className="detailed-calculator-btn-text">Detailed Calculator</span></Button>
                </div>
              }
            </div>
          </div>
        </Col>
      </Row>
      <div className="disclaimer">
        <p className="disclaimer-title">Disclaimer</p>
        <p className="disclaimer-text">The results from this calculator are provided as a general basis for information and do not constitute approval from any bank or financial institution. Any person acting in reliance of this information does so entirely at their own risk. The Next Six Sdn Bhd is not responsible for any loss arising directly or indirectly as a result of any action or omission made in reliance of any information presented.</p>
      </div>
    </div>
  );
}

export default MortgageCalculator;