import apibase from './BaseAPI';

const biUserEngagement = async (from, to, tid) => {
    const url = `/bi/users/anonymous/engage/from/${from}/to/${to}/${tid}`;

    try {
        const result = await apibase.post(url);
        // console.log(result)
        return result.data;
    } catch (err) {
        throw err;
    }
};

const biAgentLead = async id => {
    const url = `/bi/agents/${id}`;

    try {
        const result = await apibase.post(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const biPropertyLead = async id => {
    const url = `/bi/properties/${id}`;

    try {
        const result = await apibase.post(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

export default {
    biUserEngagement: biUserEngagement,
    biAgentLead,
    biPropertyLead,
};