import React from 'react';
import PropTypes from 'prop-types';

const TextInput = (props) =>{
    const generate = () =>{
        const element = (
            <input type="text" className="form-control" name={props.name} placeholder={props.placeholder}
                onChange={onInputChanged} value={props.value} readOnly={props.readOnly || false} />
        );

        if(!props.noDiv){
            return (
                <div className="form-group">
                    {element}
                </div>
            );
        }

        return element;
    }

    const onInputChanged = (event) =>{
        event.preventDefault();
        event.stopPropagation();
        if (props.callback) props.callback({
            id: props.callbackId,
            value: event.target.value
        });
    };

    return(
        generate()
    );
};

TextInput.propTypes ={
    name: PropTypes.string,
    placeholder: PropTypes.string,
    callback: PropTypes.func,
    callbackId: PropTypes.string,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    noDiv: PropTypes.bool
};

export default TextInput;