import apibase from './BaseAPI';

const search = async (id, companyName, search, currentLength) => {
    const param = {
        admin: id,
        companyName: companyName,
        search: search,
        currentLength: currentLength
    }
    const res = await apibase.post(`/workshops/search`, param);
    return res.data;
};

export default {
    search: search,
};

