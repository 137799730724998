import apibase from './BaseAPI';
import lib from "../businesses/lib";

const save = async (contactForm) => {
    try {
        const res = await apibase.post(`/contactus/save`, contactForm);
        lib.log(res);
        lib.log(res.data);
        return res.data;
    } catch (error) {
        lib.log(error)
    }
}

const checktoken = async (token) =>{
    const secret = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;
    const params = { 
        token: token,
        secret: secret
     }
    try{

        const response = await apibase.post(`/contactus/checktoken`,params)
        // console.log(response,"recap data")
        return response.data;
    }catch (error){
        lib.log(error)
    }
}

export default {
    save: save,
    checktoken:checktoken,
    // checkAgentDraftEmailDuplicate: checkAgentDraftEmailDuplicate,
}