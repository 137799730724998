import lib from '../businesses/lib';
import global from '../businesses/global';
import qs from 'qs';
import searchLogComponent from '../businesses/SearchLogComponent';
import moment from "moment";
import string from "../businesses/string";

const propertyGroupConstant = {
    groupNone: 'None',
    groupAiProperty: 'Recommended',
    groupArea: 'Area',
    groupState: 'State',
    groupProperty: 'Property',
    groupPropertyMaster: 'Property Master'
};

const seoConstant = {
    defaultPropertyTarget: 'properties-for-sale',
    allStates: 'all states',
    allAreas: 'all areas',
    allPropertyTitles: 'all titles',
    allPropertyTypes: 'all types',
    allAgentTypes: 'all types', // JACK - to be decided
    hidden: 'hidden'
};

const stateToSeo = (state) => {
    //1st replace symbol hyphen '-' to underscore '_'
    //2nd replace symbol slash '/' to vertical bar '|'
    //lastly replace '<space>' to symbol hyphen '-'
    if (state)
        return state.toString().toLowerCase()
            .split('-').join('_')
            .split('/').join('|')
            .split(' ').join('-');
};

const stateToHyphen = (state) => {
    //1st replace symbol hyphen '-' to underscore '_'
    //2nd replace symbol slash '/' to vertical bar '|'
    //lastly replace '<space>' to symbol hyphen '-'
    return state.toString().toLowerCase()
        .split('.').join('-')
        .split(' ').join('-')
        .split('/').join('-')

};

const seoToState = (seo) => {
    //1st replace symbol hyphen '-' to '<space>'
    //2nd replace symbol vertical bar '|' to slash '/'
    //3rd replace symbol underscore '_' to hyphen '-'
    return seo.split('-').join(' ')
        .split('|').join('/')
        .split('_').join('-');
};

const propertyTitleToSeo = (title) => {
    //1st replace symbol hyphen '-' to underscore '_'
    //2nd replace symbol slash '/' to comma ','
    //lastly replace '<space>' to symbol hyphen '-'
    return title.toString().toLowerCase()
        .split('-').join('_')
        .split('/').join(',')
        .split(' ').join('-');
};

const seoToPropertyTitle = (seo) => {
    //1st replace symbol hyphen '-' to '<space>'
    //2nd replace symbol comma '|' to slash '/'
    //3rd replace symbol underscore '_' to hyphen '-'
    return seo.split('-').join(' ')
        .split(',').join('/')
        .split('_').join('-');
};

const formattedPropertyType = (type) => {
    //replace symbol slash '/' to '<space>'
    return type.toString().toLowerCase()
        .replace(/[\/]/g, ' ');
};

const extractAlphanumeric = (val) => {
    //throw away any characters except a-z and 0-9
    return val.toString().toLowerCase()
        .replace(/[^a-z0-9]/g, '');
};

const extractAlphanumericSpace = (val) => {
    //throw away any characters except a-z and 0-9 and a space
    return val.toString().toLowerCase()
        .replace(/[^a-z0-9\s]/g, '')
        .replace(/\s+/g, ' ');
};

const encodeQueryParam = (val) => {
    if (Array.isArray(val)) return val.map(x => encodeURIComponent(stateToSeo(x)));
    return encodeURIComponent(stateToSeo(val));
};

const decodeQueryParam = (val) => {
    if (Array.isArray(val)) return val.map(x => seoToState(decodeURIComponent(x)));
    return seoToState(decodeURIComponent(val));
};

const urlParamsProperty = {
    target: seoConstant.defaultPropertyTarget,
    state: stateToSeo(seoConstant.allStates),
    area: stateToSeo(seoConstant.allAreas),
    title: propertyTitleToSeo(seoConstant.allPropertyTitles),
    types: stateToSeo(seoConstant.allPropertyTypes)
};

const urlParamsAgent = {
    state: stateToSeo(seoConstant.allStates),
    area: stateToSeo(seoConstant.allAreas),
    type: stateToSeo(seoConstant.allAgentTypes),
};

const qsStringifyOptions = {
    addQueryPrefix: true,
    arrayFormat: 'repeat'
};

const isAllTypeSelected = (title, types) => {
    lib.log(`isAllTypeSelected(${title}, ${types})`);

    if (title === 'apartment-flat' && types?.length >= 2) return true;
    else if (title === 'condo-serviced-residence' && types?.length >= 2) return true;
    else if (title === 'terrace-link-townhouse' && types?.length >= 11) return true;
    else if (title === 'semi-d-bungalow' && types?.length >= 6) return true;
    else if (title === 'shop-office-retail-space' && types?.length >= 12) return true;
    else if (title === 'factory-warehouse' && types?.length >= 6) return true;
    //case 'residential-land':
    //case 'hotel-resort':
    //case 'commercial-land': 
    //case 'suites' :
    //case 'luxury-suites': 
    //case 'industrial-land':
    //case 'agricultural-land':
    // case 'residential':
    // case 'commercial':

    return false;
};

const createSearchPropertyUrl = newSearch => {
    let queryParams = {};
    if (newSearch.bed) queryParams.bed = encodeQueryParam(newSearch.bed);
    if (newSearch.carParks) queryParams.carparks = encodeQueryParam(newSearch.carParks);
    if (newSearch.baths) queryParams.baths = encodeQueryParam(newSearch.baths);
    if (newSearch.minPrice) queryParams.min_price = encodeQueryParam(newSearch.minPrice);
    if (newSearch.maxPrice) queryParams.max_price = encodeQueryParam(newSearch.maxPrice);

    let urlParams = urlParamsProperty;
    if (newSearch.target) urlParams.target = newSearch.target;
    //console.log(`newSearch.target: ${newSearch.target}`);
    if (newSearch.area) urlParams.area = treatArea(newSearch.area);
    if (newSearch.state) urlParams.state = treatState(newSearch.state);
    if (newSearch.freeText) queryParams.query = encodeQueryParam(newSearch.freeText);
    if (newSearch.propertyName) queryParams.property = encodeQueryParam(newSearch.propertyName);

    //not needed anymore
    //if (newSearch.categoryListing) queryParams.listing_category = encodeQueryParam(newSearch.categoryListing);
    //alert(JSON.stringify(newSearch.types, null, 4));

    //alert(`length:` + newSearch.types?.length);
    if (newSearch.types?.length === 1) {
        /*** expecting Programmig-Value instead of Display-Value, so no conversion is required */
        urlParams.title_or_type = lib.toHyphen(newSearch.types[0]);
        // if (!newSearch.selectAllTypes && newSearch.types && newSearch.types.length > 0)
        //     urlParams.types = newSearch.types.map(x => stateToSeo(formattedPropertyType(x))).toString();
    } else if (newSearch.titleOrType) {
        urlParams.title_or_type = newSearch.titleOrType;
        // if (!newSearch.selectAllTypes && newSearch.types && newSearch.types.length > 0)
        //     urlParams.types = newSearch.types.map(x => stateToSeo(formattedPropertyType(x))).toString();
    }

    if (newSearch.types?.length > 1) { //doesnt matter if you have a title
        // alert(`types.length>1`);
        // alert(JSON.stringify(newSearch.types, null, 4));

        if (!isAllTypeSelected(newSearch.titleOrType, newSearch.types)) {
            let multiTypes = [];
            newSearch.types.forEach(type => { multiTypes.push(lib.toHyphen(type)) });
            queryParams.types = multiTypes.toString();
            //alert(queryParams.types);
            urlParams.types = multiTypes;
        }
    }

    // if (newSearch.title) {
    //     urlParams.title = propertyTitleToSeo(newSearch.title);
    //     if (!newSearch.selectAllTypes && newSearch.types && newSearch.types.length > 0)
    //         urlParams.types = newSearch.types.map(x => stateToSeo(formattedPropertyType(x))).toString();
    // }

    if (newSearch.furnishing) queryParams.furnishing = encodeQueryParam(newSearch.furnishing);
    if (newSearch.tenure) queryParams.tenure = encodeQueryParam(newSearch.tenure);
    if (newSearch.minBuildup) queryParams.min_buildup = encodeQueryParam(newSearch.minBuildup);
    if (newSearch.maxBuildup) queryParams.max_buildup = encodeQueryParam(newSearch.maxBuildup);
    if (newSearch.realestateMaster) queryParams.master = encodeQueryParam(newSearch.realestateMaster);

    if (newSearch.page) queryParams.page = encodeQueryParam(newSearch.page);
    if (newSearch.sort) queryParams.sort = encodeQueryParam(newSearch.sort);

    const queryString = qs.stringify(queryParams, qsStringifyOptions);

    //const newUrl = `/${urlParams.target}/${urlParams.state}/${urlParams.area}/${urlParams.title}/${urlParams.types}` + queryString;
    //lib.log(newUrl);

    // console.log(`{newSearch}`);
    // console.log(JSON.stringify(newSearch, null, 4));
    // alert(JSON.stringify(newSearch, null, 4));
    // console.log(`{urlParams}`);
    // console.log(JSON.stringify(urlParams, null, 4));
    // alert(JSON.stringify(urlParams, null, 4));
    let newUrl;
    if (newSearch.state) {
        if (newSearch.area) {
            if (newSearch.titleOrType) newUrl = `/${urlParams.target}/${urlParams.state}/${urlParams.area}/${urlParams.title_or_type}`;
            else newUrl = `/${urlParams.target}/${urlParams.state}/${urlParams.area}`;
        }
        else {
            if (newSearch.titleOrType) newUrl = `/${urlParams.target}/${urlParams.state}/${urlParams.title_or_type}`;
            else newUrl = `/${urlParams.target}/${urlParams.state}`;
        }
    } else {
        if (newSearch.titleOrType) newUrl = `/${urlParams.target}/${urlParams.title_or_type}`;
        else newUrl = `/${urlParams.target}`;
        // if (newSearch.titleOrType) newUrl = `/${urlParams.target}/${global.MALAYSIA}/${urlParams.title_or_type}`;
        // else newUrl = `/${urlParams.target}/${global.MALAYSIA}`;
    }

    newUrl += queryString;
    //alert(newUrl);

    return newUrl;
};

const createPropertyDetailUrl = (property) => {
    const urlParams = {
        //state: stateToSeo(property.address?.state || seoConstant.allStates),
        //area: stateToSeo(property.address?.area || seoConstant.allAreas),
        id: property._id,
        propertyName: lib.toHyphen(property?.name),
        // propertyName: lib.toHyphen(string.removeSpecialCharacters(property?.name)),
        propertyType: lib.toHyphen(property?.type),
        propertyFor: lib.toHyphen(property?.for),
        propertyAgentName: lib.toHyphen(
            property?.agent?.nickname?.trim().length > 0 ? property.agent.nickname : property.agent?.name)
        // propertyAgentName: lib.toHyphen(string.removeSpecialCharacters(
        //     property?.agent?.nickname?.trim().length > 0 ? property.agent.nickname : property.agent.name))
    }
    // let queryParams = {},
    //     queryString = '';
    // if (property.type) queryParams.type = encodeQueryParam(property.type);
    // if (property.beds) queryParams.beds = encodeQueryParam(property.beds);
    // if (property.for) queryParams.for = encodeQueryParam(property.for === 'Buy' ? 'Sale' : property.for);

    //if (Object.keys(queryParams).length > 0) queryString = qs.stringify(queryParams, qsStringifyOptions);

    let newUrl = `/property-listing/${urlParams.propertyName}-${urlParams.propertyType}-${urlParams.propertyFor === 'buy' ? 'sale' : 'rent'}-${urlParams.propertyAgentName}-${urlParams.id}`;

    return newUrl;
};

const createSearchProjectUrl = (newSearch) => {
    let queryParams = {};
    let urlParams = urlParamsProperty;
    if (newSearch.target) urlParams.target = newSearch.target;
    if (newSearch.area) urlParams.area = treatArea(newSearch.area);
    if (newSearch.state) urlParams.state = treatState(newSearch.state);
    if (newSearch.freeText) queryParams.query = encodeQueryParam(newSearch.freeText);
    if (newSearch.propertyName) queryParams.property = encodeQueryParam(newSearch.propertyName);

    if (newSearch.page) queryParams.page = encodeQueryParam(newSearch.page);
    if (newSearch.sort) queryParams.sort = encodeQueryParam(newSearch.sort);

    const queryString = qs.stringify(queryParams, qsStringifyOptions);
    //const newUrl = `/${urlParams.target}/${urlParams.state}/${urlParams.area}/${urlParams.title}/${urlParams.types}` + queryString;

    let newUrl;
    if (newSearch.state) {
        if (newSearch.area) newUrl = `/${urlParams.target}/${urlParams.state}/${urlParams.area}`;
        else newUrl = `/${urlParams.target}/${urlParams.state}`;
    } else newUrl = `/${urlParams.target}`;
    // } else newUrl = `/${urlParams.target}/${global.MALAYSIA}`;

    newUrl += queryString;

    return newUrl;
}

const createProjectDetailUrl = (property) => {
    const urlParams = {
        state: stateToSeo(property.address?.state || seoConstant.allStates),
        area: stateToSeo(property.address?.area || seoConstant.allAreas),
        id: stateToSeo(property._id),
        propertyName: lib.toHyphen(property?.name),
        propertyCompletion: lib.toHyphen(property.completion ? property.completion : "unknown"),
        propertyDeveloperName: lib.toHyphen((property.developer && property.developer.name) ? property.developer.name : "unknown"),
    }

    let queryParams = {}, queryString = '';
    if (property.type) queryParams.type = encodeQueryParam(property.type);
    if (property.developer?.name) queryParams.developername = encodeQueryParam(property.developer.name)

    if (Object.keys(queryParams).length > 0) queryString = qs.stringify(queryParams, qsStringifyOptions);

    let newUrl = `/project-listing/${urlParams.propertyName}-completed-by-${urlParams.propertyCompletion}-by-${urlParams.propertyDeveloperName}-${urlParams.id}`;
    // let newUrl = `/${global.PROJECT}/${urlParams.state}/${urlParams.area}`;
    // // if(property.masterName) newUrl += `/master/${stateToSeo(extractAlphanumeric(property.masterName))}`;
    // newUrl += `/${urlParams.id}` + queryString;

    //lib.log(newUrl);
    return newUrl;
};

const createSearchAuctionUrl = (newSearch) => {
    let queryParams = {};
    let urlParams = urlParamsProperty;

    lib.log(JSON.stringify(newSearch, null, 4));
    //alert(`check`);

    if (newSearch.target) urlParams.target = newSearch.target;
    if (newSearch.state) urlParams.state = treatState(newSearch.state);
    if (newSearch.titleOrType) {
        urlParams.title_or_type = newSearch.titleOrType;
        // if (!newSearch.selectAllTypes && newSearch.types && newSearch.types.length > 0)
        //     urlParams.types = newSearch.types.map(x => stateToSeo(formattedPropertyType(x))).toString();
    }
    // if (newSearch.auctionType) {
    //     const auctionType = treatAuctionType(newSearch.auctionType);
    //     if (auctionType !== UNKNOWN) queryParams.auctionType = auctionType;
    // }
    if (newSearch.freeText) queryParams.query = encodeQueryParam(newSearch.freeText);
    if (newSearch.propertyName) queryParams.property = encodeQueryParam(newSearch.propertyName);
    if (newSearch.minPrice) queryParams.min_price = encodeQueryParam(newSearch.minPrice);
    if (newSearch.maxPrice) queryParams.max_price = encodeQueryParam(newSearch.maxPrice);
    if (newSearch.minBuildup) queryParams.min_buildup = encodeQueryParam(newSearch.minBuildup);
    if (newSearch.maxBuildup) queryParams.max_buildup = encodeQueryParam(newSearch.maxBuildup);
    if (newSearch.minLandArea) queryParams.min_landarea = encodeQueryParam(newSearch.minLandArea);
    if (newSearch.maxLandArea) queryParams.max_landarea = encodeQueryParam(newSearch.maxLandArea);
    if (newSearch.auctionStart) queryParams.auction_start = moment(newSearch.auctionStart).format('YYYY-MM-DD');
    if (newSearch.auctionEnd) queryParams.auction_end = moment(newSearch.auctionEnd).format('YYYY-MM-DD');
    // if (newSearch.auctionType) {
    //     urlParams.title = propertyTitleToSeo(newSearch.auctionType);
    // }

    if (newSearch.page) queryParams.page = encodeQueryParam(newSearch.page);
    if (newSearch.sort) queryParams.sort = encodeQueryParam(newSearch.sort);

    const queryString = qs.stringify(queryParams, qsStringifyOptions);
    //const newUrl = `/${urlParams.target}/${urlParams.state}/${urlParams.area}/${urlParams.title}/${urlParams.types}` + queryString;//tbd

    let newUrl;
    // if (newSearch.state) newUrl = `/${urlParams.target}/${urlParams.state}`;
    // else newUrl = `/${urlParams.target}/${global.MALAYSIA}`;

    if (newSearch.state) {
        if (newSearch.titleOrType) {
            if (newSearch.titleOrType) newUrl = `/${urlParams.target}/${urlParams.state}/${urlParams.title_or_type}`;

        }
        else newUrl = `/${urlParams.target}/${urlParams.state}`;
    } else {
        if (newSearch.titleOrType) newUrl = `/${urlParams.target}/${urlParams.title_or_type}`;
        else newUrl = `/${urlParams.target}`;
        // if (newSearch.titleOrType) newUrl = `/${urlParams.target}/${global.MALAYSIA}/${urlParams.title_or_type}`;
        // else newUrl = `/${urlParams.target}/${global.MALAYSIA}`;
    }

    newUrl += queryString;
    // console.log(newUrl);
    // alert(newUrl);

    return newUrl;
}

const createAuctionDetailUrl = (property) => {
    const urlParams = {
        state: stateToSeo(property.address?.state || seoConstant.allStates),
        area: stateToSeo(property.address?.area || seoConstant.allAreas),
        id: stateToSeo(property._id),
        propertyName: lib.toHyphen(property?.name),
        propertyAuctionDate: moment(property.auctionDate).format('DD-MMM-YYYY'),
    }
    let newUrl = `/auction-listing/${urlParams.propertyName}-auction-on-${urlParams.propertyAuctionDate}-${urlParams.id}`;
    // const newUrl = `/auction/${urlParams.state}/${urlParams.area}/${urlParams.id}`;

    return newUrl;
};

const createAgentDetailUrl = (agent) => {
    let formattedName = seoConstant.hidden;
    if (agent?.nickname && extractAlphanumeric(agent?.nickname)) formattedName = extractAlphanumeric(agent?.nickname);
    else if (agent?.name && extractAlphanumeric(agent?.name)) formattedName = extractAlphanumeric(agent?.name);

    const urlParams = {
        name: stateToSeo(formattedName),
        id: stateToSeo(agent._id)
    }

    const newUrl = `/agent/${urlParams.name}/${urlParams.id}`;

    //lib.log(newUrl);
    return newUrl;
};

const createSearchAgentUrl = (search) => {
    let urlParams = urlParamsAgent;
    if (search.state) urlParams.state = stateToSeo(search.state || seoConstant.allStates);
    if (search.area) urlParams.area = stateToSeo(search.area || seoConstant.allAreas);
    if (search.type) urlParams.type = stateToSeo(search.type || seoConstant.allTypes);

    let queryParams = {};
    if (search.name) queryParams.name = encodeQueryParam(search.name);
    if (search.page) queryParams.page = encodeQueryParam(search.page);

    // console.log(queryParams)
    const queryString = qs.stringify(queryParams, qsStringifyOptions);
    const newUrl = `/agent/${urlParams.state}/${urlParams.area}/${urlParams.type}` + queryString;
    return newUrl;
};

const treatAreaQueryParam = area => {
    if (Array.isArray(area)) return area.map(x => treatArea(x));
    return treatArea(area);
};

const treatStateQueryParam = state => {
    if (Array.isArray(state)) return state.map(x => treatState(x));
    return treatArea(state);
};

const treatTitleQueryParam = title => {
    if (Array.isArray(title)) return title.map(x => x.replaceAll('/', '-').replaceAll(' ', '-').toLowerCase());
    return title.replaceAll('/', '-').replaceAll(' ', '-').toLowerCase();
};

const createAiSearchPropertyUrl = newSearch => {
    const urlParams = {
        target: newSearch.target || seoConstant.defaultPropertyTarget,
        aiSearchDisplay: newSearch.aiSearchDisplay.replaceAll('/', '-').replaceAll(' ', '-').toLowerCase()
    };

    let queryParams = {};
    if (newSearch.aiSearchContext) {
        const searchContext = newSearch.aiSearchContext;
        if (searchContext?.area) queryParams.area = treatAreaQueryParam(searchContext?.area);
        if (searchContext?.state) queryParams.state = treatStateQueryParam(searchContext?.state);
        if (searchContext?.title) queryParams.title = treatTitleQueryParam(searchContext?.title);
        //if (searchContext?.type) queryParams.type = encodeQueryParam(searchContext?.type);
        if (searchContext?.master) queryParams.master = Boolean(searchContext?.master).toString();
    }

    const queryString = qs.stringify(queryParams, qsStringifyOptions);
    const newUrl = `/ai-${urlParams.target}/${urlParams.aiSearchDisplay}` + queryString;
    return newUrl;
};

const createSearchUrl = search => {
    searchLogComponent.propertySearchLog(search);

    let newUrl;
    if (search.target === global.PROJECT)
        newUrl = createSearchProjectUrl(search);
    else if (search.target === global.AUCTION)
        newUrl = createSearchAuctionUrl(search);
    else if (search.aiSearchDisplay && search.aiSearchContext)
        newUrl = createAiSearchPropertyUrl(search);
    else
        newUrl = createSearchPropertyUrl(search);

    return newUrl;
}

const treatState = state => {
    return state.toLowerCase().replace(`wilayah persekutuan`, ``).trim().replace(` `, `-`);
}

const treatArea = area => {
    if (area === 'Layang-Layang') return 'layang-layang';
    else if (area === 'I-City') return 'i-city';
    else if (area === 'Bukit Tunku (Kenny Hills)') return 'bukit-tunku-kenny-hills';
    else if (area === 'Kampung Kerinchi (Bangsar South)') return 'kampung-kerinchi-bangsar-south';
    else if (area === 'Iskandar Puteri (Nusajaya)') return 'iskandar-puteri-nusajaya';
    else if (area === 'Pulau Indah (Pulau Lumut)') return 'pulau-indah-pulau-lumut';
    return area.replace('(', '').replace(')', '').replaceAll(' ', '-').toLowerCase();
}

const STATES_WITH_WP = `kuala lumpur;labuan;putrajaya;`;
const WILAYAH = `Wilayah Persekutuan`;
const beautifyState = state => {
    //console.log(`beautifyState(${state})`);
    state = state.replace('-', ' ');
    if (STATES_WITH_WP.indexOf(state + ';') >= 0) return string.capitalize(`${WILAYAH} ${state}`);
    return string.capitalize(`${state}`);
};

const beautifyArea = area => {
    //lib.log(`beautifyArea(${area})`);
    if (area === 'layang-layang') return 'Layang-Layang';
    else if (area === 'i-city') return 'I-City';
    else if (area === 'bukit-tunku-kenny-hills') return 'Bukit Tunku (Kenny Hills)';
    else if (area === 'kampung-kerinchi-bangsar-south') return 'Kampung Kerinchi (Bangsar South)';
    else if (area === 'iskandar-puteri-nusajaya') return 'Iskandar Puteri (Nusajaya)';
    else if (area === 'pulau-indah-pulau-lumut') return 'Pulau Indah (Pulau Lumut)';
    else if (area === 'kl-city') return 'KL City';
    else if (area === 'kl-eco-city') return 'KL Eco City';
    else if (area === 'kl-sentral') return 'KL Sentral';
    else if (area === 'sunway-spk') return 'Sunway SPK';
    else if (area === 'sierramas') return 'SierraMas';
    else if (area === 'usj-heights') return 'USJ Heights';
    else if (area === 'taman-ttdi-jaya') return 'Taman TTDI Jaya';
    else if (area === 'tldm-lumut') return 'TLDM Lumut';
    else if ('klcc;oug;ss2;usj;usm;'.indexOf(`${area};`) >= 0) return area.toUpperCase();
    return string.capitalize(area?.replace(/-/g, ' '));
};

const beautifyTitleOrType = titleOrType => {
    switch (titleOrType) {
        case 'apartment-flat': return 'Apartment/Flat';
        case 'condo-serviced-residence': return `Condo/Serviced Residence`;
        case 'terrace-link-townhouse': return `Terrace/Link/Townhouse`;
        case 'semi-d-bungalow': return `Semi-D/Bungalow`;
        case 'shop-office-retail-space': return `Shop/Office/Retail Space`;
        case 'hotel-resort': return `Hotel/Resort`;
        case 'factory-warehouse': return `Factory/Warehouse`;
        case '1-sty-terrace-link-house': return `1-sty Terrace/Link House`;
        case '2-sty-terrace-link-house': return `2-sty Terrace/Link House`;
        case '3-sty-terrace-link-house': return `3-sty Terrace/Link House`;
        case '4-sty-terrace-link-house': return `4-sty Terrace/Link House`;
        case '1-5-sty-terrace-link-house': return `1.5-sty Terrace/Link House`;
        case '2-5-sty-terrace-link-house': return `2.5-sty Terrace/Link House`;
        case '3-5-sty-terrace-link-house': return `3.5-sty Terrace/Link House`;
        case '4-5-sty-terrace-link-house': return `4.5-sty Terrace/Link House`;
        case '2-sty-superlink-house': return `2-sty Superlink House`;
        case '3-sty-superlink-house': return `3-sty Superlink House`;
        case 'semi-detached-house': return `Semi-detached House`;
        case '3-storey-semi-detached-homes': return `3 Storey Semi-detached Homes`;
        case 'commercial-semi-d': return `Commercial Semi-D`;
        case 'semi-d-factory': return `Semi-D Factory`;
        case 'sofo':
        case 'soho':
        case 'sovo':
            return titleOrType.toUpperCase();
        //     case 'townhouse':
        // case 'cluster-house':
        // case 'bungalow':
        // case 'bungalow-land':
        // case 'villa':
        // case 'office':
        // case 'shop':
        // case 'shop-office':
        // case 'retail-office':
        // case 'retail-space':
        // case 'commercial-bungalow':
        // case 'designer-suites':
        // case 'business-center':
        // case 'factory':
        // case 'detached-factory':
        // case 'link-factory':
        // case 'warehouse':
        // case 'light-industrial':
        // case 'residential':
        // case 'commercial':
        // case 'residential-land':
        // case 'suites':
        // case 'luxury-suites':
        // case 'commercial-land':
        // case 'industrial-land':
        // case 'agricultural-land':
        // case 'apartment':
        // case 'flat':
        // case 'condomimium':
        // case 'serviced-residence':
        default:
            return string.capitalize(titleOrType.split('-').join(' '));
    };
};

const UNKNOWN = 'unknown';
const treatAuctionType = auctionType => {
    lib.log(`treat(${auctionType})`);

    let treated = UNKNOWN;
    switch (auctionType) {
        case 'Apartment / Condo / SOHO':
            treated = 'apartment-condo-soho';
            break;
        case 'Factory / Warehouse':
            treated = 'factory-warehouse';
            break;
        case 'Flat':
            treated = 'flat';
            break;
        case 'Hotel / Resort / Clubhouse':
            treated = 'hotel-resort-clubhouse';
            break;
        case 'Land':
            treated = 'land';
            break;
        case 'Penthouse':
            treated = 'penthouse';
            break;
        case 'Semi D / Bungalow / Villa':
            treated = 'semi-d-bungalow-villa';
            break;
        case 'Service Suite':
            treated = 'service-suite';
            break;
        case 'Shop Office / Retail Space / Office':
            treated = 'shop-office-retail-space-office';
            break;
        case 'Terrace / Link / Townhouse':
            treated = 'terrace-link-townhouse';
            break;
        case 'Vegetable Stall':
            treated = 'vegetable-stall';
            break;
        case 'Water Villa':
            treated = 'water-villa';
            break;
        default:
            treated = UNKNOWN;
    }

    return treated;
}

const simplifyState = state => {
    state = state.replace(global.WILAYAH, '').trim();
    if (state === 'Kuala Lumpur') return 'KL';
    return state;
};

const normalizeState = state => {
    state = state.replace(global.WILAYAH, '').trim();
    return state;
};

const specialTreatArea = area => {
    switch (area) {
        case 'City Centre':
        case 'KL City':
            return 'KL';
        case 'Johor Bahru':
            return 'JB';
        case 'Taman Tun Dr Ismail':
            return 'TTDI';
        case 'Iskandar Puteri (Nusajaya)':
            return 'Nusajaya';
        case 'Melaka City':
            return 'Melaka';
        case 'Kota Kinabalu':
            return 'KK';
        case 'Bandar Sunway':
            return 'Sunway';
        case 'Bandar Utama':
            return 'BU';
        case 'Damansara Jaya':
            return 'DJ';
        case 'Damansara Utama':
            return 'DU';
        case 'Kota Damansara':
            return 'KD';
        case 'Kuala Kubu Baru':
            return 'KKB';
        case 'Petaling Jaya':
            return 'PJ';
        case 'Subang Jaya':
            return 'Subang';
        default: return null;
    }
};

const normalizeArea = area => {
    switch (area) {
        case 'City Centre':
        case 'KL City':
            return 'Kuala Lumpur';
        case 'Jalan Klang Lama':
            return 'Old Klang Road (Jalan Klang Lama)';
        case 'Kampung Kerinchi (Bangsar South)':
            return 'Bangsar South (Kerinchi)';
        case 'Salak Selatan':
            return 'Salak Selatan (Salak South)';
        case 'Iskandar Puteri (Nusajaya)':
            return 'Iskandar Puteri';
        case 'Kem Desa Pahlawan':
            return 'Desa Pahlawan';
        default: return area;
    }
}

const pruralTitleOrType = titleOrType => {
    switch (titleOrType) {
        case 'apartment-flat': return 'apartments';
        case 'condo': return 'condos';
        default:
            return `${titleOrType}s`;
    };
};

/*key1*/
const simplifyTitleOrType = titleOrType => {
    switch (titleOrType) {
        case 'residential': return 'Property';
        case 'commercial': return 'Commercial Property';
        case 'apartment-flat':
        case 'apartment':
            return 'Apartment';
        case 'flat': return 'Flat';
        case 'condo-serviced-residence':
        case 'condominium':
            return 'Condo';
        case 'serviced-residence': return 'Serviced Residence'
        case 'terrace-link-townhouse': return 'House';
        case '1-sty-terrace-link-house': return `1-storey Link House`;
        case '2-sty-terrace-link-house': return `2-storey Link House`;
        case '3-sty-terrace-link-house': return `3-storey Link House`;
        case '4-sty-terrace-link-house': return `4-storey Link House`;
        case '1-5-sty-terrace-link-house': return `1.5-storey Link House`;
        case '2-5-sty-terrace-link-house': return `2.5-storey Link House`;
        case '3-5-sty-terrace-link-house': return `3.5-storey Link House`;
        case '4-5-sty-terrace-link-house': return `4.5-storey Link House`;
        case '2-sty-superlink-house': return `2-storey Superlink House`;
        case '3-sty-superlink-house': return `3-storey Superlink House`;
        case 'townhouse': return 'Townhouse';
        case 'semi-d-bungalow': return `House`;
        case 'cluster-house': return 'Cluster House';
        case 'bungalow': return 'Bungalow';
        case 'bungalow-land': return 'Bungalow Land';
        case 'semi-detached-house': return 'Semi-D';
        case 'villa': return 'Villa';
        case '3-storey-semi-detached-homes': return '3-storey Semi-D';
        case 'residential-land': return 'Land';
        case 'shop-office-retail-space': return 'Commercial Property';
        case 'office': return 'Office';
        case 'shop': return 'Shop';
        case 'shop-office': return 'Shop Office';
        case 'retail-office': return 'Retail Office';
        case 'retail-space': return 'Retail Space';
        case 'soho':
        case 'sofo':
        case 'sovo':
            return titleOrType.toUpperCase();
        case 'commercial-bungalow': return 'Commercial Bungalow';
        case 'commercial-semi-d': return 'Commercial Semi-D';
        case 'designer-suites': return 'Designer Suites';
        case 'business-center': return 'Business Center';
        case 'hotel-resort': return 'Hotel';
        case 'commercial-land': return 'Land';
        case 'suites':
        case 'luxury-suites':
            return 'Suite';
        case 'factory-warehouse':
        case 'factory':
            return 'Factory';
        case 'semi-d-factory': return 'Semi-D Factory';
        case 'detached-factory': return 'Detached Factory';
        case 'link-factory': return 'Link Factory';
        case 'warehouse': return 'Warehouse';
        case 'light-industrial': return 'Light Industrial';
        case 'industrial-land': return 'Land';
        case 'agricultural-land': return 'Land';
        default:
            return string.capitalize(titleOrType.split('-').join(' '));
    };
};

/*key2*/
const customTitleOrType = titleOrType => {
    switch (titleOrType) {
        case 'residential': return 'Residential Property';
        case 'commercial': return 'Commercial Property';
        case 'apartment-flat':
        case 'apartment':
        case 'flat':
            return 'Apartment Flats';
        case 'condo-serviced-residence':
        case 'condominium':
            return 'Condo Serviced Residence';
        case 'serviced-residence':
            return 'Condo Serviced Residence';
        case 'terrace-link-townhouse':
        case '1-sty-terrace-link-house':
        case '2-sty-terrace-link-house':
        case '3-sty-terrace-link-house':
        case '4-sty-terrace-link-house':
        case '1-5-sty-terrace-link-house':
        case '2-5-sty-terrace-link-house':
        case '3-5-sty-terrace-link-house':
        case '4-5-sty-terrace-link-house':
        case '2-sty-superlink-house':
        case '3-sty-superlink-house':
        case 'townhouse':
            return 'Terrace Link Townhouse';
        case 'semi-d-bungalow':
        case 'cluster-house':
        case 'bungalow':
        case 'bungalow-land':
        case 'semi-detached-house':
        case 'villa':
        case '3-storey-semi-detached-homes':
            return 'Semi D Bungalow';
        case 'residential-land': return 'Residential Land';
        case 'shop-office-retail-space':
        case 'office':
        case 'shop':
        case 'shop-office':
        case 'retail-office':
        case 'retail-space':
            return 'Office & Retail Shop';
        case 'soho':
        case 'sofo':
        case 'sovo':
            return 'SOHO/SOFO/SOVO';
        case 'commercial-bungalow':
        case 'commercial-semi-d':
        case 'designer-suites':
        case 'business-center':
            return 'Office & Retail Shop';
        case 'hotel-resort': return 'Hotel Resort';
        case 'commercial-land': return 'Commercial Land';
        case 'suites': return 'Suites';
        case 'luxury-suites': return 'Luxury Suites';
        case 'factory-warehouse':
        case 'factory':
        case 'semi-d-factory':
        case 'detached-factory':
        case 'link-factory':
        case 'warehouse':
        case 'light-industrial':
            return 'Factory Warehouse';
        case 'industrial-land': return 'Industrial Land';
        case 'agricultural-land': return 'Agricultural Land';
        default:
            return string.capitalize(titleOrType.split('-').join(' '));
    };
};

const isTitle = titleOrType => {
    switch (titleOrType) {
        case 'apartment-flat':
        case 'condo-serviced-residence':
        case 'terrace-link-townhouse':
        case 'semi-d-bungalow':
        case 'residential-land':
        case 'shop-office-retail-space':
        case 'hotel-resort':
        case 'commercial-land':
        case 'suites':
        case 'luxury-suites':
        case 'factory-warehouse':
        case 'industrial-land':
        case 'agricultural-land':
        case 'residential':
        case 'commercial':
            return true;
        default: return false;
    }
};

//to check is property title or not
//if can check from queryParams, can pass in queryParams
const isTitleV2 = (val, titleOptions, queryParams = {}) => {
    const { types } = queryParams;
    if (types) return true; //if have types in queryParams, param is title
    else {//for normal title checking
        const result = titleOptions.find(({ value }) => value === val);
        if (result && result.title) return true;
        return false;
    }
}
//get title from type using seoPropertySetupOption.json
const getTitleFromType = (val, titleOptions) => {
    const toType = beautifyTitleOrType(val);
    const result = titleOptions.find(title => title.type?.includes(toType));
    if (result) return { titleOrType: result.value, types: [toType] }
    return {};
}

// const getTitleFromType=type=>{
//     switch (type) {
//         return 'apartment-flat';
//         return 'condo-serviced-residence';
//         return 'terrace-link-townhouse';
//         return 'semi-d-bungalow';
//         return 'residential-land';
//         return 'shop-office-retail-space';
//         return 'hotel-resort';
//         return 'commercial-land';
//         return 'suites';
//         return 'luxury-suites';
//         return 'factory-warehouse';
//         return 'industrial-land';
//         return 'agricultural-land';
//         default: return 'Unknown';
//     }
// };

const aOrAn = titleOrType => {
    const startsWith = titleOrType.substring(0, 1);
    if ('AEIOUaeiou'.indexOf(startsWith) >= 0) return 'an';
    return 'a';
};

const treatTitleOrType = titleOrType => {
    switch (titleOrType) {
        // case '1-sty Terrace/Link House': return '1-sty-terrace-link-house';
        // case '2-sty Terrace/Link House': return '2-sty-terrace-link-house';
        // case '3-sty Terrace/Link House': return '3-sty-terrace-link-house';
        // case '4-sty Terrace/Link House': return '4-sty-terrace-link-house';
        // case '1.5-sty Terrace/Link House': return '1-5-sty-terrace-link-house';
        // case '2.5-sty Terrace/Link House': return '2-5-sty-terrace-link-house';
        // case '3.5-sty Terrace/Link House': return '3-5-sty-terrace-link-house';
        // case '4.5-sty Terrace/Link House': return '4-5-sty-terrace-link-house';
        // case '2-sty Superlink House': return '2-sty-superlink-house';
        // case '3-sty Superlink House': return '3-sty-superlink-house';
        // case '3 Storey Semi-detached Homes': return '3-storey-semi-detached-homes';
        // case 'Semi-detached House': return 'semi-detached-house';
        // case 'Semi-D Factory': return 'semi-d-factory';
        // case 'Industrial Land': return 'industrial-land';
        // case 'Agricultural Land': return 'agricultural-land';
        default:
            return titleOrType?.toLowerCase()
                .split('/').join('-')
                .split('.').join('-')
                .split(' ').join('-');
    };
};

export default {
    propertyGroupConstant: propertyGroupConstant,
    seoConstant: seoConstant,
    stateToSeo: stateToSeo,
    seoToState: seoToState,
    encodeQueryParam: encodeQueryParam,
    decodeQueryParam: decodeQueryParam,
    urlParamsProperty: urlParamsProperty,
    qsStringifyOptions: qsStringifyOptions,
    createSearchPropertyUrl: createSearchPropertyUrl,
    seoToPropertyTitle: seoToPropertyTitle,
    formattedPropertyType: formattedPropertyType,
    createPropertyDetailUrl: createPropertyDetailUrl,
    //createSearchProjectUrl: createSearchProjectUrl,
    createProjectDetailUrl: createProjectDetailUrl,
    createAgentDetailUrl: createAgentDetailUrl,
    //createSearchAuctionUrl: createSearchAuctionUrl,
    createAuctionDetailUrl: createAuctionDetailUrl,
    createSearchAgentUrl: createSearchAgentUrl,
    //createAiSearchPropertyUrl: createAiSearchPropertyUrl,
    treatState,
    treatArea,
    treatAuctionType, //UNKNOWN,
    treatAreaQueryParam,
    treatStateQueryParam,
    treatTitleQueryParam,
    createSearchUrl,
    beautifyArea, beautifyState, beautifyTitleOrType,
    simplifyState, specialTreatArea, pruralTitleOrType,
    simplifyTitleOrType, customTitleOrType, aOrAn, isTitle,
    isTitleV2,
    getTitleFromType,
    normalizeState, normalizeArea, treatTitleOrType,
}