import apibase from './BaseAPI';
import lib from "../businesses/lib";

const save = async (application) => {
    try {
        const res = await apibase.post(`/agentapplication/save`, application);
        lib.log(res);
        lib.log(res.data);
        return res.data;
    } catch (error) {
        lib.log(error)
    }
}

const isEmailUnique = async (email) => {
    const params = { email: email }
    try {
        const res = await apibase.post(`/agentapplication/isEmailUnique`, params);
        lib.log("is email unique");
        lib.log(res.data);
        return res.data;
    } catch (error) {
        lib.log(error)
    }
}

const isMobileUnique = async (mobile) => {
    const params = { mobile: mobile }
    try {
        const res = await apibase.post(`/agentapplication/isMobileUnique`, params);
        return res.data;
    } catch (error) {
        lib.log(error)
    }
}

const validateSource = async (tag) => {
    try {
        const res = await apibase.get(`/myurl/checkTag/${tag}`);
        lib.log("source check");
        lib.log(res.data);
        return res.data;
    } catch (error) {
        lib.log(error)
    }
}

export default {
    save: save,
    isEmailUnique: isEmailUnique,
    isMobileUnique: isMobileUnique,
    validateSource: validateSource,
}