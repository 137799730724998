import apibase from './BaseAPI';

const searchById = async (id) => {
    const param = {
        id: id,
    }
    const res = await apibase.post(`/developers/id`, param);
    return res.data;
};
export default {
    searchById: searchById,
};