import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SetupData from '../data/SetupData';

import PropertyAddressInput from '../components/Input/PropertyAddressInput';
import SelectionInput from '../components/Input/SelectionInput';
import TextInput from '../components/Input/TextInput';
import TextareaInput from '../components/Input/TextareaInput';
import InputGroupLabel from '../components/Input/InputGroupLabel';
import FileInput from '../components/Input/FileInput';

import auctionPropertyComponent from '../businesses/auctionProperty';
import lib from '../businesses/lib';
import fileComponent from '../businesses/fileComponent'

import NotificationSystem, { propTypes } from 'react-notification-system';
import { MdInfo, MdRefresh, MdFileDownload, MdDeleteForever, MdCheckCircle } from 'react-icons/md';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import { trackPromise } from 'react-promise-tracker';
import LoadingIndicator from '../components/LoadingIndicator';
import SignatureCanvas from 'react-signature-canvas';
import FileDownload from 'js-file-download';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row,
  CardFooter,
  Input,
  InputGroup,
  FormText,
  Button, Alert,
  InputGroupAddon,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardImg,
} from 'reactstrap';

const AuctionRequestForm = props => {
  const [property, setProperty] = useState(props.property || {});
  const [agent, setAgent] = useState(props.agent || {});
  const [files, setFiles] = useState({});
  const [canSave, setCanSave] = useState(false);
  const [address, setAddress] = useState((props.property && props.property.address) ? props.property.address : {});
  const [categories, setCategory] = useState([]);
  const [types, setType] = useState([]);
  const [countries, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [areas, setAreas] = useState([]);
  const notificationAuctionRequestPage = useRef(null);
  const [refSign, setRefSign] = useState();
  const [requestUrl, setRequestUrl] = useState(props.auctionRequestUrl || null);
  const textboxUrlRef = useRef(null);
  const [copyText, setCopyText] = useState('Copy link');
  const [modal, setModal] = useState(false);
  const [modalCoverPhoto, setModalCoverPhoto] = useState(false);
  const [imageTimestamp, setImageTimestamp] = useState(new Date().toString());
  const [selectedCoverPhoto, setSelectedCoverPhoto] = useState(0);
  //--------------input change--------------  

  const onCheckboxChange = (event) => {
    assignValue([event.target.name], event.target.checked);
  };

  const inputCallback = (cb) => {
    assignValue(cb.id, cb.value);
  };

  const fileInputCallback = (cb) => {
    // if (cb.id === 'images') setNewImages(cb.value);
    setFiles(files => ({
      ...files,
      [cb.id]: cb.value
    }));
  };

  const onInputChange = (event) => {
    assignValue([event.target.name], event.target.value);
  };

  const assignValue = (name, value) => {
    setProperty(property => ({
      ...property,
      [name]: value
    }));
  };

  /* START: Events PropertyAddressInput UI component */

  const inputAddressCallback = (cb) => {
    assignAddressValue(cb.id, cb.value);
  };

  const assignAddressValue = (name, value) => {
    setAddress(address => ({
      ...address,
      [name]: value
    }));
  };

  const addressPreTemplate = () => {
    setCountry(lib.countryOptions(props.countries));

    if (address.country) {
      populateStateList(address.country);
      if (address.state) {
        populateAreaList(address.country, address.state)
      }
    }

    if (Object.keys(address).length > 0) {
      setAddressDefaultOption();
    }
  };

  const propertyPreTemplate = () => {
    setCategory(lib.categoryOptions(props.categories));

    if (property.title) {
      populateTypeList(property.title);
    }
  };

  const setAddressDefaultOption = () => {
    const defaultOptionList = lib.addressPreOptions(props.countries, address);
    Object.entries(defaultOptionList).forEach(([key, value]) => {
      assignAddressValue(key, value);
    });
  };

  const populateTypeList = (category) => {
    let typeList = lib.typeOptions(props.categories, category)
    setType(typeList);
    if (typeList.length === 1) assignValue('type', typeList[0]);
  };

  const populateStateList = (country) => {
    setStates(lib.stateOptions(props.countries, country));
  };

  const populateAreaList = (country, state) => {
    const impactedScope = lib.areaOptions(props.countries, country, state);
    Object.entries(impactedScope).forEach(([key, value]) => {
      if (key === 'areas') {
        setAreas(value);
      }
      else if (key === 'states') {
        setStates(value);
      }
    });
  };

  const inputStateCallback = (cb) => {
    populateAreaList(address.country, cb.value);
    assignAddressValue(cb.id, cb.value);
  };

  const inputCountryCallback = (cb) => {
    populateStateList(cb.value);
    if (address.state && address.area) {
      populateAreaList(cb.value, address.state);
    } else {
      setAreas([]);
    }
    assignAddressValue(cb.id, cb.value);
  };

  const inputCategoryCallback = (cb) => {
    assignValue('type', '');
    populateTypeList(cb.value);
    assignValue(cb.id, cb.value);
  };

  /* END: Events PropertyAddressInput UI component */

  //--------------useEffect()--------------
  useEffect(() => {
    addressPreTemplate();
    propertyPreTemplate();
  }, []);

  useEffect(() => {
    if (canSave) {
      if (props.submitCallback) props.submitCallback(property);
    }
  }, [canSave]);

  useEffect(() => {
    // lib.log('address.<new value>');
    // lib.log(address);
    assignValue('address', address);
  }, [address]);

  // useEffect(() => {
  //   lib.log(property);
  // }, [property]);

  // useEffect(() => {
  //   lib.log(files);
  // }, [files]);


  //--------------function()--------------

  const toggle = () => {
    setModal(!modal)
  };

  const uploadFiles = async (data, folder, name, propertyId) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      lib.log(typeof (data))
      Object.keys(data).map(idx => { lib.log(data[idx]); formData.append("files", data[idx]) });
      lib.log(formData)
      try {
        trackPromise(fileComponent.upload(formData, `auctions/${propertyId}/seller/${folder}`)).then((result) => {
          lib.log(result);
          if (property[name]) var value = [...property[name]]; else var value = []
          lib.log(value)
          result.map(result => value.push(result.url))
          lib.log(value)
          assignValue([name], value);
          lib.log(`Success!`);
          resolve(true)
        })
      } catch (err) {
        lib.log(err);
        reject(err)
      };
    })
  }

  const filterFiles = async (propertyId) => {
    // files : {
    //     Images : FileList
    // }
    // item = Images
    // files[item] = FileList
    // files[item][idx] = item in FileList
    return await Promise.all(Object.keys(files).map(item => {
      switch (item) {
        case "images":
          lib.log("images");
          return uploadFiles(files[item], "images", "images", propertyId)
        case "waterBill":
          lib.log("Water Bill")
          return uploadFiles(files[item], "waterBill", "waterBill", propertyId)
        case "tnbBill":
          lib.log("TNB");
          return uploadFiles(files[item], "tnbBill", "tnbBill", propertyId)
        case "quitRent":
          lib.log("quitRent");
          return uploadFiles(files[item], "quitRent", "quitRent", propertyId)
        case "ics":
          lib.log("ics");
          return uploadFiles(files[item], "ics", "ics", propertyId)
        case "ccris":
          lib.log("ccris");
          return uploadFiles(files[item], "ccris", "ccris", propertyId)
        case "spa":
          lib.log("TNB");
          return uploadFiles(files[item], "spa", "spa", propertyId)
        case "landSearch":
          lib.log("landSearch");
          return uploadFiles(files[item], "landSearch", "landSearch", propertyId)
        case "assignment":
          lib.log("assignment");
          return uploadFiles(files[item], "assignment", "assignment", propertyId)
        case "assessment":
          lib.log("assessment");
          return uploadFiles(files[item], "assessment", "assessment", propertyId)
        case "redemption":
          lib.log("redemption");
          return uploadFiles(files[item], "redemption", "redemption", propertyId)
        case "balance":
          lib.log("balance");
          return uploadFiles(files[item], "balance", "balance", propertyId)
        default: lib.log("Nothings happen")
      }
    }))
  }

  const onDraftClick = async (event) => {
    event.preventDefault();
    setCanSave(false);
    if (!property._id) property._id = await trackPromise(auctionPropertyComponent.newId());
    await filterFiles(property._id);
    setCanSave(true);
  }

  const onConfirmClick = async (event) => {
    toggle();
    event.preventDefault();
    setCanSave(false);
    const isSign = refSign.isEmpty();
    const res = auctionPropertyComponent.validate(property, isSign);
    if (res.ok) {
      await filterFiles(property._id);
      if (!property._id) {
        var newId = await trackPromise(auctionPropertyComponent.newId());
        assignValue("_id", newId)
      }
      if (!property.signDate) assignValue("signDate", new Date());
      assignValue("clearLink", true)
      assignValue("signature", refSign.getTrimmedCanvas().toDataURL('image/png'))
      assignValue("status", "Accepted")
      assignValue("accepted", { "on": new Date() })
      lib.log('validation ok');
      setCanSave(true);
    }
    else {
      notificationAuctionRequestPage.current.addNotification({
        title: <MdInfo />,
        message: res.err,
        level: 'error',
      });
    }
  };

  const onCancelClick = () => {
    if (props.cancelCallback) props.cancelCallback();
  };

  const generateAuctionRequestUrl = async () => {
    const auctionRequest = await trackPromise(auctionPropertyComponent.newAuctionRequestUrl(property.agent, property._id));
    if (auctionRequest) {
      assignValue('_id', auctionRequest.auctionProp);
      setRequestUrl(auctionRequest.link);
    }
  };

  const copyAuctionRequestUrl = (event) => {
    textboxUrlRef.current.select();
    document.execCommand('copy');
    setCopyText('Copied');
  };

  // const downloadAttachment = async (event, urls, fileName) => {
  //   event.preventDefault();
  //   for (let i = 0; i < urls.length; i++) {
  //     const result = await trackPromise(fileComponent.download(urls[i]));
  //     if (result.binaryData && result.extension) {
  //       let blobData = new Blob([result.binaryData]);
  //       FileDownload(blobData, `${fileName}${i + 1}.${result.extension}`);
  //     }
  //   }
  // };

  const downloadSingleAttachment = async (event, url, fileName) => {
    event.preventDefault();
    const result = await trackPromise(fileComponent.download(url));
    if (result.binaryData && result.extension) {
      let blobData = new Blob([result.binaryData]);
      FileDownload(blobData, `${fileName}.${result.extension}`);
      // let blobData = new Blob([result.binaryData], {type: result.mime});
      // var objectUrl = URL.createObjectURL(blobData);
      // window.open(objectUrl);
    }
  };

  const handleRemoveAttachment = (idx, callbackId) => {
    const attachmentList = [...property[callbackId]];
    attachmentList.splice(idx, 1);
    assignValue(callbackId, attachmentList);
  };

  const AttachmentItem = props => {
    let url = props.attachment;
    let extension = url.substring(url.lastIndexOf('.') + 1);

    return (
      <>
        <tr>
          <td>{`${props.fileName}.${extension.toLowerCase()}`}</td>
          <td style={{ textAlign: 'right' }}><h4>
            <MdFileDownload style={{ cursor: 'pointer' }} onClick={(event) => downloadSingleAttachment(event, props.attachment, props.fileName)} />
            {
              props.canRemove &&
              <>
                {` `}
                <MdDeleteForever style={{ cursor: 'pointer' }} onClick={() => handleRemoveAttachment(props.index, props.callbackId)} />
              </>
            }
          </h4></td>
        </tr>
      </>
    )
  };

  const AttachmentList = props => {
    return props.attachments.map((data, index) => {
      return (
        <AttachmentItem attachment={data} index={index} key={`${props.fileName}${index}`}
          fileName={`${props.fileName}${index + 1}`} callbackId={props.callbackId}
          canRemove={props.canRemove} />
      )
    });
  };

  const DownloadRow = props => {
    return (
      <>
        {props.attachments && props.attachments.length > 0 &&
          <tr>
            <td>
              {`${props.title} (${props.attachments.length})`}
              {
                props.callbackId === 'images' && props.attachments.length > 1 &&
                <>
                  <br /><br />
                  <a href="#" onClick={toggleCoverPhoto}>To set up your cover photo, click here</a>
                </>
              }
            </td>
            <td>
              <Table borderless hover>
                <tbody>
                  <AttachmentList attachments={props.attachments} fileName={props.fileName} callbackId={props.callbackId}
                    canRemove={props.canRemove} />
                </tbody>
              </Table>
            </td>
          </tr>}
      </>
    );
  };

  const PhotoRow = props => {
    return props.images.map((image, index) => {
      return (
        <Col key={index} lg={3} md={6} sm={6} xs={6} className="mb-3">
          <Card style={{ cursor: 'pointer' }} onClick={() => chooseCoverPhoto(index)}>
            <CardImg top height="150px" width="auto" src={image + "?" + imageTimestamp} alt="" />
            {
              index == selectedCoverPhoto &&
              <CardFooter tag="h3" className="text-center">
                <MdCheckCircle style={{ color: 'green', fontSize: 'xxx-large' }} />
              </CardFooter>
            }
          </Card>
        </Col>
      )
    });
  };

  const toggleCoverPhoto = () => {
    setSelectedCoverPhoto(0);
    setModalCoverPhoto(!modalCoverPhoto)
  };

  const chooseCoverPhoto = (index) => {
    setSelectedCoverPhoto(index);
  };

  const saveCoverPhoto = async () => {
    let photos = property.images;
    let makeCover = photos[selectedCoverPhoto];

    photos = photos.filter(item => {
      return item != makeCover
    });
    photos = [makeCover, ...photos];

    await trackPromise(auctionPropertyComponent.updateCoverPhoto(property._id, photos));
    assignValue('images', photos);
    toggleCoverPhoto();
    notificationAuctionRequestPage.current.addNotification({
      title: <MdInfo />,
      message: 'Cover photo saved successfully',
      level: 'success',
    });
  };


  return (
    <Card>
      <CardHeader>
        <img src="https://iprofstore.blob.core.windows.net/general/nextsix.png" width="80" height="70" className="pr-2" alt="The Next Six Sdn Bhd" />
        <img src="https://www.bidnow.my/images/BidNow-logo-black.png" alt="BidNow"></img>
        <strong>Owner Auction Sumission Form</strong>
      </CardHeader>
      <CardBody>
        {/* --Agent-- */}
        <h5>Agents Details</h5>
        <TextInput name="name" placeholder="Agent name" value={agent.name || ''} readOnly
          callbackId="name" callback={inputCallback} />
        <TextInput name="name" placeholder="Agent Contact" value={agent.mobile || ''} readOnly
          callbackId="name" callback={inputCallback} />
        {/* --Owner-- */}
        <h5>Owner Details</h5>
        <TextInput name="name" placeholder="Owner Name" value={property.ownerName || ''}
          callbackId="ownerName" callback={inputCallback} />
        <TextInput name="name" placeholder="Owner Contact" value={property.ownerMobile || ''}
          callbackId="ownerMobile" callback={inputCallback} />
        {/* ------------------------------------Property------------------------------------ */}
        {/* --address-- */}
        <h5>Property Details</h5>
        <TextInput name="name" placeholder="Property name" value={property.name || ''}
          callbackId="name" callback={inputCallback} />
        <PropertyAddressInput title="Property Address"
          countries={countries} states={states} areas={areas} address={address}
          callback={inputAddressCallback} stateCallback={inputStateCallback} countryCallback={inputCountryCallback} />
        <FormGroup>
          <TextInput name="location" placeholder="Coordinate" disabled="true" value={lib.displayLoc(property.loc)}
            style={{ background: 'lightyellow' }} />
          <Row><Col xl={{ size: 3, offset: 3 }} sm={12}><span className="help-block" ><small><code>auto refresh/generated when saved</code></small></span></Col></Row>
        </FormGroup>
        <Row>
          <Col xl={3} />
          <Col xl={9} >
            <Row>
              <Col xl={4} lg={4} md={6} sm={12}>
                <FormGroup check>
                  <Label check><Input type="checkbox" name="isAccess" checked={!!property.isAccess} onChange={onCheckboxChange} />Key/Access Card Provide?</Label>
                </FormGroup>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12}>
                <FormGroup check>
                  <Label check><Input type="checkbox" name="isCcris" checked={!!property.isCcris} onChange={onCheckboxChange} />CCRIS Report Provided?</Label>
                </FormGroup>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12}>
                <FormGroup check>
                  <Label check><Input type="checkbox" name="isLoan" checked={!!property.isLoan} onChange={onCheckboxChange} />Still Under Loan?</Label>
                </FormGroup>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12}>
                <FormGroup check>
                  <Label check><Input type="checkbox" name="isRenovated" checked={!!property.isRenovated} onChange={onCheckboxChange} />Renovated?</Label>
                </FormGroup>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12}>
                <FormGroup check>
                  <Label check><Input type="checkbox" name="isView" checked={!!property.isView} onChange={onCheckboxChange} />Available For Viewing?</Label>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <h5>Details Section</h5>
        <FormGroup row>
          <Col sm={1} />
          <SelectionInput title="Property Category" values={categories} name="title" value={property.title}
            callbackId={'title'} callback={inputCategoryCallback} />
          <SelectionInput title="Property Type" values={types} name="type" value={property.type}
            callbackId={'type'} callback={inputCallback} />
        </FormGroup>
        <FormGroup row>
          <Col sm={1} />
          <SelectionInput title="Tenure" values={["Freehold", "Leasehold"]} name="tenure" value={property.tenure}
            callbackId={'tenure'} callback={inputCallback} />
          <SelectionInput title="Title Type" values={["Master", "Strata"]} name="titleType" value={property.titleType}
            callbackId={'titleType'} callback={inputCallback} />
        </FormGroup>
        <FormGroup row>
          <Col sm={1} />
          <SelectionInput title="Land Title Type" values={SetupData.property.landTitleTypes} name="landTitleType" value={property.landTitleType}
            callbackId={'landTitleType'} callback={inputCallback} auction />
          <SelectionInput title="Unit Type" values={SetupData.property.unitTypes} name="unitType" value={property.unitType}
            callbackId={'unitType'} callback={inputCallback} auction />
        </FormGroup>
        <FormGroup row>
          <Col sm={1} />
          <SelectionInput title="Restriction" values={["Bumi-Lot", "Non-Bumi", "Native Land", "Indian Quota"]} name="restriction" value={property.restriction} callbackId={'restriction'} callback={inputCallback} />
          <SelectionInput title="Lot Type" values={["Intermediate", "Corner Lot", "End Lot"]} name="lot" value={property.lot} callbackId={'lot'} callback={inputCallback} />
        </FormGroup>
        <FormGroup row>
          <Col sm={1} />
          <SelectionInput title="Beds" values={SetupData.property.beds} name="bed" value={property.beds}
            callbackId={'beds'} callback={inputCallback} />
        </FormGroup>
        <FormGroup row>
          <InputGroup className="d-flex align-items-center">
            <Label for={props.name} xl={{ size: 2, offset: 1 }} sm={12}>Price :</Label>
            <Col xl={8} sm={12}>
              <Input type="number" name="price" placeholder="Current Price" value={property.price || ""}
                onChange={onInputChange} min="0" />
            </Col>
          </InputGroup>
        </FormGroup>
        <TextareaInput name="description" placeholder="Description" value={property.description || ''}
          callbackId="description" callback={inputCallback} />
        <FormGroup row>
          <Col sm={3} />
          <InputGroupLabel name="buildup" placeholder="Built-up size" value={property.buildup ? `${property.buildup}` : ''}
            callbackId="buildup" callback={inputCallback} label="Built-up" direction="left" />
          <InputGroupLabel name="landArea" placeholder="Land area" value={property.landArea ? `${property.landArea}` : ''}
            callbackId="landArea" callback={inputCallback} label="Land area" direction="left" />
        </FormGroup>
        <FormGroup row>
          <Col sm={3} />
          <InputGroupLabel name="carParks" placeholder="Number of car park" value={property.carParks ? `${property.carParks}` : ''}
            callbackId="carParks" callback={inputCallback} label="Car parks" direction="left" />
          <InputGroupLabel name="baths" placeholder="Number of bathroom" value={property.baths ? `${property.baths}` : ''}
            callbackId="baths" callback={inputCallback} label="Bathrooms" direction="left" />
        </FormGroup>
        <br />
        <Row>
          <Col xs={12}>
            <Label><strong>Attachments</strong></Label>
          </Col>
          <FileInput label="Property Images" name="images" accept="image/x-png,image/jpeg" callback={fileInputCallback} />
          <FileInput label="Water Statement" name="waterBill" accept="image/x-png,image/jpeg,application/pdf" callback={fileInputCallback} />
          <FileInput label="TNB Statement" name="tnbBill" accept="image/x-png,image/jpeg,application/pdf" callback={fileInputCallback} />
          <FileInput label="Quit Rent and Assessment" name="quitRent" accept="image/x-png,image/jpeg,application/pdf" callback={fileInputCallback} />
          <FileInput label="Owner New IC." name="ics" accept="image/x-png,image/jpeg,application/pdf" callback={fileInputCallback} />
          <FileInput label="CCRIS Report" name="ccris" accept="image/x-png,image/jpeg,application/pdf" callback={fileInputCallback} />
          <FileInput label="SPA" name="spa" accept="image/x-png,image/jpeg,application/pdf" callback={fileInputCallback} />
          <FileInput label="Land Search & Caveat Search" name="landSearch" accept="image/x-png,image/jpeg,application/pdf" callback={fileInputCallback} />
          <FileInput label="Deed of Assignment" name="assignment" accept="image/x-png,image/jpeg,application/pdf" callback={fileInputCallback} />
          <FileInput label="Assessment Tax" name="assessment" accept="image/x-png,image/jpeg,application/pdf" callback={fileInputCallback} />
          <FileInput label="Redemption Sum" name="redemption" accept="image/x-png,image/jpeg,application/pdf" callback={fileInputCallback} />
          <FileInput label="Balance Loan Sum" name="balance" accept="image/x-png,image/jpeg,application/pdf" callback={fileInputCallback} />
        </Row>
        <Row>
          <Col xs={12}>
            <Label><strong>Download Attachments</strong></Label>
          </Col>
          <Col sm={12}>
            <Table bordered>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Attachments</th>
                </tr>
              </thead>
              <tbody>
                <DownloadRow title="Property Photos" attachments={property.images} fileName="property" callbackId="images" canRemove={true} />
                <DownloadRow title="Water Statement" attachments={property.waterBill} fileName="water" callbackId="waterBill" canRemove={true} />
                <DownloadRow title="TNB Statement" attachments={property.tnbBill} fileName="tnb" callbackId="tnbBill" canRemove={true} />
                <DownloadRow title="Quit Rent and Assessment" attachments={property.quitRent} fileName="quitRent" callbackId="quitRent" canRemove={true} />
                <DownloadRow title="Owner New IC" attachments={property.ics} fileName="ownerIC" callbackId="ics" canRemove={true} />
                <DownloadRow title="CCRIS Report" attachments={property.ccris} fileName="ccris" callbackId="ccris" canRemove={true} />
                <DownloadRow title="SPA" attachments={property.spa} fileName="spa" callbackId="spa" canRemove={true} />
                <DownloadRow title="Land Search & Caveat Search" attachments={property.landSearch} fileName="landSearch" callbackId="landSearch" canRemove={true} />
                <DownloadRow title="Deed of Assignment" attachments={property.assignment} fileName="Deed_of_Assignment" callbackId="assignment" canRemove={true} />
                <DownloadRow title="Assessment Tax" attachments={property.assessment} fileName="Assessment_Tax" callbackId="assessment" canRemove={true} />
                <DownloadRow title="Redemption Sum" attachments={property.redemption} fileName="Redemption_Sum" callbackId="redemption" canRemove={true} />
                <DownloadRow title="Balance Loan Sum" attachments={property.balance} fileName="Balance_Loan_Sum" callbackId="balance" canRemove={true} />
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Label><MdInfo /> I acknowledged that all the above information are correct and true. </Label>
          </Col>
          <Col sm={12}>
            <SignatureCanvas ref={(ref) => { setRefSign(ref) }}
              penColor="darkgrey" backgroundColor="lightyellow"
              canvasProps={{ width: 300, height: 200, className: 'sigCanvas' }} />
            <Button color="default" onClick={() => { refSign.clear() }}><MdRefresh /> Clear</Button>
          </Col>
          {/* --name-- */}
          <Col sm={12} xs={12}>
            <FormText>Name</FormText>
            <Label>{property.ownerName || "Owner Name"}</Label>
          </Col>
          {/* --date-- */}
          <Col sm={6} xs={12}>
            <FormText>Date</FormText>
            <Input type="date" name="signDate" value={property.signDate ? lib.parseToLocale(property.signDate).datePart : new Date().toISOString().substr(0, 10)} onChange={onInputChange} />
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <button className="btn btn-primary" onClick={onDraftClick}>
          <i className="fa fa-dot-circle-o"></i> Save as Draft
        </button>
        &nbsp;
        <button className="btn btn-primary" onClick={toggle}>
          <i className="fa fa-dot-circle-o"></i> Submit to Agent
        </button>
      </CardFooter>
      {/* ------------------------------------Modal------------------------------------ */}
      <Modal
        isOpen={modal}
        toggle={toggle}>
        <ModalHeader toggle={toggle}>Confirm Submit Request ???</ModalHeader>
        <ModalBody>
          <p>Keep in mind that once submit to system: </p>
          <p>The link will expired AND the auction form will unable to modify </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onConfirmClick}>Confirm</Button>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal size="lg"
        isOpen={modalCoverPhoto}
        toggle={toggleCoverPhoto}>
        <ModalHeader toggle={toggleCoverPhoto}>Default Cover Photo</ModalHeader>
        <ModalBody>
          <p>Pick a photo & click <b>Save Changes</b> as default cover photo</p>
          <Row>
            <PhotoRow images={property.images} />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveCoverPhoto}>Save Changes</Button>
          <Button color="secondary" onClick={toggleCoverPhoto}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <NotificationSystem ref={notificationAuctionRequestPage} style={NOTIFICATION_SYSTEM_STYLE} />
      <LoadingIndicator />
    </Card>
  );
}

AuctionRequestForm.propTypes = {
  title: PropTypes.string,
  property: PropTypes.object,
  submitCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  canWrite: PropTypes.bool,
  agent: PropTypes.object,
};

export default AuctionRequestForm;
