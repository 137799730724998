import React, { useEffect, useState } from "react";
import agentApplicationComponent from "../businesses/AgentApplicationComponent";
import agentPackage from "../businesses/AgentPackageComponent";
import lib from "../businesses/lib";
import SetupData from "../data/SetupData";
import mapping from "../utils/mapping";

import "../styles/pages/agentApplicationPage.scss";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Container,
} from "reactstrap";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import TextInput from "../components/TextInput";
import DataSelectionInput from "../components/DataSelectionInput";
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";
import checkWithCircle from "../assets/agentApplication/icon check with circle.svg";
import Carousel from "react-elastic-carousel";
import vulcanLogo from "../assets/agentApplication/logo vulcan.svg";
import businessesTodayLogo from "../assets/agentApplication/logo bussiness today.svg";
import malaysiakiniLogo from "../assets/agentApplication/logo malaysiakini.svg";
import facebook from "../assets/agentApplication/icon facebook.svg";
import instagram from "../assets/agentApplication/icon instagram.svg";
import propertyGraphic from "../assets/agentApplication/icon property graphic.svg";
import free from "../assets/agentApplication/icon free.svg";
import essential from "../assets/agentApplication/icon essential.svg";
import pro from "../assets/agentApplication/icon pro.svg";
import proplus from "../assets/agentApplication/icon proplus.svg";
import gif from "../assets/agentApplication/website.gif";
import nextsixAppEmblem from "../assets/home/nextsix-app-emblem.png";
import { MdClose } from "react-icons/md";
import { close } from "../redux/actions/emblemActions";
import { useSelector, useDispatch } from 'react-redux';
// import greenKetupatLogo from "../assets/img/Green.svg";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "../components/LoadingOverlay";

const AgentApplicationPage = (props) => {
  const AgentPackagesMapping = mapping.AgentPackages
  const dispatch = useDispatch();
  const emblem = useSelector(state => state.emblem);
  const getwidth = window.innerWidth;
  const [application, setApplication] = useState({
    category: "General",
    source: undefined,
    reassigned: undefined
  });
  const [modal, setModal] = useState(false);
  const [modaltitle, setModalTitle] = useState("");
  const [errorPrompt, setErrorPrompt] = useState("");
  const [packageValue, setPackageValue] = useState([]);
  const [agentPackagesInfo, setAgentPackagesInfo] = useState([]);
  const [disableComfirmButton, setDisableComfirmButton] = useState(false);
  const [mobileCode, setMobileCode] = useState("+60");
  const history = useHistory()
  const { promiseInProgress } = usePromiseTracker();

  const inputCallback = (cb) => {
    let value

    if (cb.id === 'email' || cb.id === 'mobile') {
      value = cb.value.replace(/\s+/g, "")
    } else {
      value = cb.value
    }

    assignValue(cb.id, value);
  };

  const assignValue = (name, value) => {
    setApplication((application) => ({
      ...application,
      [name]: value,
    }));
  };

  const toggle = () => {
    setModal(!modal);
  };

  const toggleModal = (modaltitle) => {
    setModal(!modal);
    setModalTitle(modaltitle);
  };

  const getPackage = async () => {
    const res = await agentPackage.agentPackage();
    setPackageValue(res);
    assignValue("package", res[0].value);
    lib.log(res);
  };

  const setPackage = async (choice, selector) => {
    const res = await agentPackage.agentPackage()
    setPackageValue(res)
    assignValue("package", choice)
    lib.log(res)

    const header = document.getElementById("header");
    const el = document.querySelector(selector);
    const sticky = header.offsetHeight;
    const rect = el.getBoundingClientRect().top + window.pageYOffset - sticky;
    window.scrollTo({ top: rect, behavior: 'smooth' });
  }

  const getAgentPackagesInfo = async () => {
    const hidePackage = { hidePackage: ["campaign"] }
    const res = await agentPackage.agentPackages(hidePackage);
    setAgentPackagesInfo(res);
  }

  useEffect(() => {
    getPackage();
    getAgentPackagesInfo();
  }, []);

  // useEffect(()=>{
  //     lib.log(application)
  // },[application])

  const onComfirmClick = async (event) => {
    event.preventDefault();
    setModal(false)
    const applicationInfo = { ...application };
    const countryCode = mobileCode;
    const mobileNum = application.mobile;
    const combinedMobile = lib.combineMobileWithCountryCode(countryCode, mobileNum)
    applicationInfo.mobile = combinedMobile;
    // applicationInfo.mobile = lib.addDashToPhoneNumber(application.mobile)
    const res = await trackPromise(agentApplicationComponent.save(applicationInfo))
    setApplication("");
    setErrorPrompt(null);

    // SET THE REDIRECT HERE
    if (!promiseInProgress && res) {
      window.scroll({ top: 0, behavior: 'instant' });
      history.push('/thankyou', { fromSignUp: true })
    }


    // setDisableComfirmButton(true);
    // setModal(true)
    // toggleModal("Thank You! ");
  };
  // const onOkClick = async (event) => {
  //   event.preventDefault();
  //   setModal(true);
  //   setDisableComfirmButton(null);
  //   setModal(false);
  //   toggle();
  // };

  const onSubmitClick = async (event) => {
    event.preventDefault();
    if (application) {
      const applicationInfo = { ...application };
      const countryCode = mobileCode;
      const mobileNum = application.mobile;
      const combinedMobile = lib.combineMobileWithCountryCode(countryCode, mobileNum)
      applicationInfo.mobile = combinedMobile;
      const res = await agentApplicationComponent.validate(applicationInfo);
      if (res.ok) {
        lib.log("validation OK");
        setErrorPrompt(null);
        setDisableComfirmButton(false);
        toggleModal("Confirm Submit Form? ");
      } else {
        // toggleModal(res.err);
        setErrorPrompt(res.err);
      }
    } else {
      setErrorPrompt("Form Cannot Be Empty");
    }
  };

  // const showCurrentYear = () => {
  //   return new Date().getFullYear();
  // };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3, itemsToScroll: 1 },
    { width: 1024, itemsToShow: 4 },
  ];

  // useEffect(() => {
  //   dispatch(close("open"));
  // }, [])

  const closeBanner = () => {
    dispatch(close("close"));
  }

  const today = new Date()
  const etaStart = new Date('2023-04-21T16:00:00.000Z')
  const etaEnd = new Date('2023-04-30T16:00:00.000Z')


  // const signInURL = process.env.REACT_APP_SIGN_IN_URL;
  return (

    <>
      {
        getwidth < 768 && emblem === "open" &&
        <div className="emblem-wrapper">
          <div className="emblem-left-content">
            <Button className="emblem-close-btn" onClick={() => closeBanner()}><MdClose /></Button>
            <img loading="lazy" alt="" className="emblem-logo" src={nextsixAppEmblem} />
            <div className="emblem-left-textbox">
              <span className="emblem-left-text-top">NextSix App</span>
              <span className="emblem-left-text-btm">Find properties and agents faster</span>
            </div>
          </div>
          <div className="emblem-right-content">
            <a className="emblem-download-btn" href="https://apps.nextsix.com/users/reg">Use Now</a>
          </div>
        </div>
      }
      <Header2 />
      <Container className="page-head">
        <Row style={{ marginTop: "7%", width: "100%" }} className="page-head-row">
          <Col lg="6" md="6" sm="6" style={{ marginBottom: "20px" }}>
            <div style={{ marginBottom: "10px" }}>
              <p className="title">Tired of how property ads are done?</p>
            </div>
            <div style={{ marginBottom: "40px" }}>
              <p className="paragraph">
                {" "}
                "Connecting our users directly to a professional real estate
                agent's profile,has increased the agent's overall ads viewership
                by almost 38%"
              </p>
            </div>
            <div>
              <ul className="tick-list">
                <li>Get seen by the most suitable property seekers.</li>
                <li>Customers view all your ad listings at-one-go.</li>
                <li>Chat with potential customers instantly.</li>
                <li>
                  1,900 agents are on-board now. Start your profile today.
                </li>
                <li>
                  Let the customers find you & all your property ads. NOT JUST
                  one ad.
                </li>
              </ul>
            </div>
          </Col>
          <Col lg="6" md="6" sm="6" className="gif-col">
            <img loading="lazy" src={gif} className="gifStyling" alt="Hero GIF"></img>
          </Col>
        </Row>
      </Container>
      <div className="containerbackground">
        <Container className="ContainerStyleing">
          <Row>
            <Col
              style={{
                padding: "0 0 0 0",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <div className="packageheader">
                <p>
                  {" "}
                  Don't Believe It?{" "}
                  <a href="#form" className="packagelink">
                    Try It
                  </a>{" "}
                  For free. No Hidden Cost
                </p>
                {/* link to form */}
              </div>
              <div className="packageHeaderDescription">
                <p>
                  Our enthusiastic team members can't wait to help you kick
                  start this journey.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          {/* className="PackageContainerStyleing" */}
          <Row>
            <Col style={{ padding: "0 0 0 0", marginBottom: "50px" }}>
              <Carousel
                breakPoints={breakPoints}
                // enableAutoPlay
                // autoPlaySpeed={4000}
                showArrows={false}
                pagination={true}
                enableSwipe
                className="package-plan-wrapper"
              >
                {agentPackagesInfo.map((agentPackage, index) => (
                  <div key={`${index}-${agentPackage}`} className={agentPackage.tag === "free" ? "PackageBorder" : "PackageWithoutBorder"}>
                    <div>
                      {agentPackage.tag === "free" && <span className="tab">RECOMMENDED</span>}
                      <div className="PackagePlan">
                        <div>
                          <img loading="lazy" src={AgentPackagesMapping.imageMap[agentPackage.tag]} alt=""></img>
                        </div>
                        {/* check for parentheses in agent package name and replace it with empty string */}
                        <div className={`${agentPackage.tag}-plan-logo-description`}>{agentPackage.name.match(/(?:^|\s)\([^)]*\)/g) ? agentPackage.name.replace(/(?:^|\s)\([^)]*\)/g, "") : agentPackage.name}</div>
                        <div className={`${agentPackage.tag}-plan-title`}>
                          {agentPackage.tag === "free" ? "(FREE)" : 
                          `RM ${agentPackage.price}`}
                        </div> 
                        {agentPackage.tag === "free" && <div className="Free-Plan-Description">cancel anytime</div>}
                        <div className="Package-Period">
                          ({agentPackage.duration < 12 ? `${agentPackage.duration} month${agentPackage.duration !== 1 ? 's' : ''}` : 
                          `${Math.floor(agentPackage.duration / 12)} year${agentPackage.duration > 12 ? ` ${agentPackage.duration % 12} month${agentPackage.duration % 12 !== 1 ? 's' : ''}` : ''}`})
                        </div>
                      </div>
                    </div>
                    <div className="PackagePlanList">
                      <ul className="PackageTickList">
                        {Array.isArray(agentPackage.features) && agentPackage.features.map((feature, index) => (
                          <li key={`${index}-${feature}`}>{feature}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="PackageButtonDiv" style={agentPackage.tag === "free" ? { marginTop: "-10px"} : null}>
                      <Button 
                      className={agentPackage.tag === "free" ? "Start-Now-Button" : "Choose-Plan-Button"} 
                      onClick={() => setPackage(agentPackage._id, "#signupForm")}>
                        {agentPackage.tag === "free" ? "Start Now" : "Choose Plan"}
                      </Button>
                    </div>
                  </div>
                ))}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="formBackground" id="signupForm">
        <Container>
          <Row className="form">
            <Col lg="6" md="6" sm="6" style={{ marginTop: "30px" }}>
              <div className="formtitle">
                <p>How to get started? Just one easy step away.</p>
              </div>
              <div className="formdescription">
                Drop us your contact details and agent info here, and let our
                team do the rest of the heavy lifting.
              </div>
              {window.screen.width > 700 ? (
                <div className="house">
                  <img loading="lazy" src={propertyGraphic} alt="Form"></img>
                </div>
              ) : null}
            </Col>

            <Col lg="6" md="6" sm="6">
              <div
                style={{ width: "100%", marginLeft: "20px" }}
                className="formBackground2"
              >
                <div style={{ width: "100%" }}>
                  <TextInput
                    name="name"
                    placeholder="Full Name*"
                    callbackId="name"
                    callback={inputCallback}
                    readOnly={false}
                    value={application.name || ""}
                  />
                </div>

                <div style={{ width: "100%" }}>
                  <TextInput
                    name="email"
                    placeholder="Email*"
                    callbackId="email"
                    callback={inputCallback}
                    readOnly={false}
                    value={application.email || ""}
                  />
                </div>

                <div style={{ width: "100%" }} className="input-group form-group ">
                  <select className='form-control' style={{ maxWidth: "30%" }} value={mobileCode} onChange={(e) => setMobileCode(e.target.value)}>
                    {SetupData.mobileCountryCodes.map((item, i) => <option key={i} value={item.value}>{item.value}</option>)}
                  </select>
                  <TextInput
                    noDiv
                    name="mobile"
                    placeholder="Mobile Number*"
                    callbackId="mobile"
                    callback={inputCallback}
                    readOnly={false}
                    value={application.mobile || ""}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <DataSelectionInput
                    title={null}
                    values={packageValue}
                    value={application.package || ""}
                    callbackId={"package"}
                    callback={inputCallback}
                  />
                </div>

                <div style={{ width: "100%" }}>
                  <TextInput
                    name="renId"
                    placeholder="Ren ID"
                    callbackId="renId"
                    callback={inputCallback}
                    readOnly={false}
                    value={application.renId || ""}
                  />
                </div>
                <div className="errprompt">
                  <code>
                    <small>
                      {errorPrompt ? (
                        errorPrompt
                      ) : (
                        <pre style={{ height: "15px" }}></pre>
                      )}
                    </small>
                  </code>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button className="btn-orange-submit" onClick={onSubmitClick}>
                    Submit
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="feature-in">
        {/* className="ContainerStyleing" */}
        <Row>
          <Col>
            <p className="feature-text">Also Featured in</p>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="featureCardItem">
              <Carousel
                breakPoints={breakPoints}
                enableAutoPlay
                autoPlaySpeed={3000}
                enableSwipe
                showArrows={false}
                pagination={false}
              >
                <a href="https://vulcanpost.com/723561/nextsix-malaysia-app-gps-property-agent-search/?fbclid=IwAR1Xmbzu0eaXzaY40EgI_T7VPbzYWC49EiXt5WKAgW9wZnJ3ZCXjLIkmKHA">
                  <div>
                    <div className="featureContainerCard">
                      <div className="cardDescription">
                        {" "}
                        "Old property-hunting ways can go home,this M'sian app
                        lets you GPS listings&Agents"
                      </div>
                    </div>
                    <div>
                      <img loading="lazy" src={vulcanLogo} className="featureLogo" alt="feature logo"></img>
                    </div>
                  </div>
                </a>
                <a href="https://www.businesstoday.com.my/2020/12/04/elevating-the-property-search-game/?fbclid=IwAR0fDO-uzlVofciSwG77yinuIKW6ETJTvPK0qcT-Jnja4NDahBy4LvLsYzI">
                  <div>
                    <div className="featureContainerCard">
                      <div className="cardDescription">
                        {" "}
                        "Elevating the property search game"
                      </div>
                    </div>
                    <div>
                      <img
                        src={businessesTodayLogo}
                        className="featureLogo" alt="feature logo"
                      ></img>
                    </div>
                  </div>
                </a>
                <a href="https://www.malaysiakini.com/advertorial/552108?fbclid=IwAR3_4Zy7huUrgjaSRNBudSA90RRW--zAgMmwn0OBdCUQh5kchNFW-xJ42YY">
                  <div>
                    <div className="featureContainerCard">
                      <div className="cardDescription">
                        {" "}
                        "A Smarter,more efficient way to hunt for your ideal
                        property"
                      </div>
                    </div>
                    <div>
                      <img loading="lazy" src={malaysiakiniLogo} className="featureLogo" alt="feature logo"></img>
                    </div>
                  </div>
                </a>
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="containerbackground">
        <Container>
          <Row>
            <Col>
              {window.screen.width > 768 ? (
                <p className="aboutus">Something About Our Company</p>
              ) : (
                <p className="aboutus2">Something About Our Company</p>
              )}

              {window.screen.width > 1024 ? (
                <div className="aboutusDescription">
                  <p>
                    Nextsix is a Property-Tech Platform based in Malaysia and
                    the first to pioneer a GPS-driven search technology that
                    links homebuyers or tenants directly to professional agents
                    through{" "}
                    <span className="aboutusDescriptionHasttag">#GPSYourAgent</span>
                  </p>
                </div>
              ) : (
                <div className="aboutusDescription2">
                  <p>
                    Nextsix is a Property-Tech Platform based in Malaysia and
                    the first to pioneer a GPS-driven search technology that
                    links homebuyers or tenants directly to professional agents
                    through{" "}
                    <span className="aboutusDescriptionHasttag">#GPSYourAgent</span>
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <div className="containerbackground">
        <Container>
          <Row>
            {window.screen.width > 767 ? (
              <Col lg="6" md="6" sm="6" className="about-us-video-container">
                <iframe
                  className="responsive-iframe"
                  src="https://www.youtube.com/embed/qidABEqBwaA"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="about us"
                ></iframe>
              </Col>
            ) : (
              <Col
                lg="6"
                md="6"
                sm="6"
                className="about-us-video-mobile-container"
              >
                <iframe
                  className="responsive-iframe"
                  src="https://www.youtube.com/embed/qidABEqBwaA"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media;gyroscope; picture-in-picture"
                  allowFullScreen
                  title="about us"
                ></iframe>
              </Col>
            )}

            <Col lg="6" md="6" sm="6">
              <div className="aboutusVideoDescription">
                {" "}
                With soon-to-launch HomePro and HomeMall that encompass all
                real-estate related needs,its going to become your favourite
                Real Estate App.
              </div>
              <div className="aboutusVideoDescription2">
                Searching for properties has just become way easier.
              </div>
              <div className="aboutusVideoDescription">
                This also means for our dear agents - selling/renting your
                properties has just become way faster and more effective.{" "}
              </div>
              {window.screen.width > 1024 ? (
                <div className="Socialmedia-desktop">
                  <a href="https://www.facebook.com/NextsixMY">
                    {" "}
                    <img loading="lazy" src={facebook} className="socialmedia" alt="facebook icon"></img>
                  </a>{" "}
                  <a href="https://www.instagram.com/nextsixmy/">
                    <img loading="lazy" src={instagram} className="socialmedia" alt="instagram icon"></img>
                  </a>{" "}
                </div>
              ) : (
                <div className="CenterSocialmedia">
                  <a href="https://www.facebook.com/NextsixMY">
                    {" "}
                    <img loading="lazy" src={facebook} className="socialmedia" alt="facebook icon"></img>
                  </a>{" "}
                  <a href="https://www.instagram.com/nextsixmy/">
                    <img loading="lazy" src={instagram} className="socialmedia" alt="instagram icon"></img>
                  </a>{" "}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <Footer2 />

      {disableComfirmButton == null ||
        (!disableComfirmButton && (
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>{modaltitle} </ModalHeader>
            <ModalFooter>
              <Button color="success" onClick={onComfirmClick}>
                Yes
              </Button>
              <Button color="danger" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        ))}

      <LoadingOverlay />
    </>
  );
};

export default AgentApplicationPage;
