

const capitalize = str => {
    if (str.length > 0) return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    return '';
}

const getWidth = (label) => {
    var tempElement = document.createElement('span')

    var fontSize = '14px'
    tempElement.style.fontSize = fontSize
    tempElement.style.fontWeight = 'bold'
    tempElement.style.fontFamily = 'Roboto, Arial, sans-serif'
    tempElement.style.position = 'absolute'
    tempElement.style.visibility = 'hidden'
    tempElement.style.height = 'auto'
    tempElement.style.width = 'auto'
    tempElement.style.whiteSpace = 'nowrap'

    tempElement.textContent = label

    document.body.appendChild(tempElement)

    var textWidth = tempElement.offsetWidth

    document.body.removeChild(tempElement)

    return textWidth + 19
}

function removeSpecialCharacters(inputString) {
    //console.log(`from: ${inputString}`);
    return inputString?.trim()?.replace(/[^\u0020-\u007E]/g, '').replace(/[^a-zA-Z0-9]\s/g, '');
    //console.log(`to : ${friendlyString}`);

    //return friendlyString;
}

function replaceString(input, variable){
    // input must have "{{anything}}" in the string to replace with defined object variable(s)
    // "anything" should be only a word without special character
    return input.replace(/\{\{(\w+)\}\}/g, (match, key) => variable[key] || match);
}

export default {
    capitalize,
    getWidth,
    removeSpecialCharacters,
    replaceString
}