import serviceMerchantApplicationAPI from "../api/serviceMerchantApplicationAPI";
import regex from "./regex";
import lib from "./lib";

const newId = async () => {
    return await serviceMerchantApplicationAPI.newId()
}

const save = async (application) => {
    return await serviceMerchantApplicationAPI.save(application);
}

const isEmailUnique = async (email) => {
    return await serviceMerchantApplicationAPI.isEmailUnique(email)
}

const isContactUnique = async (contact) => {
    const formattedContact = lib.addDashToPhoneNumber(contact) //format as 01X-XXXXXXXX
    return await serviceMerchantApplicationAPI.isContactUnique(formattedContact)
}

const validate = async (application) => {
    if (!application.firstName) return invalid("Please enter first name.");
    if (!application.lastName) return invalid("Please enter last name.");
    if (!regex.isAlphabet(application.firstName) || !regex.isAlphabet(application.lastName))
        return invalid("Please input alphabet characters only for first name and last name.");
    
    if (!application.contact) return invalid("Please enter contact number.");
    if (!regex.isValidMobile(application.contact)) return invalid("Invalid contact number format.");
    else {
        const uniqueContact = await isContactUnique(application.contact);
        if (uniqueContact === false) return invalid("Sorry, this contact number has been taken.");
    }

    if (!application.email) return invalid("Please enter email address.");
    if (!regex.isValidEmail(application.email)) return invalid("Invalid email address.");
    const uniqueEmail = await isEmailUnique(application.email);
    if (uniqueEmail === false) return invalid("Sorry, this email has been taken.");

    if(!application.businessCerts?.length > 0) return invalid("Please upload your business certificate.")

    if (!application.name) return invalid("Please enter merchant name.");
    if (!application.type) return invalid("Please choose your merchant type.");
    if (!application.address?.line1) return invalid("Please enter company address.");
    if (!application.address?.city) return invalid("Please enter city.");
    if (!application.address?.postcode) return invalid("Please enter postcode.");
    if (!regex.isValidPostcode(application.address?.postcode)) return invalid("Invalid postcode.");
    if (!application.address?.state) return invalid("Please choose state.");
    if (!application.address?.area) return invalid("Please choose area.");

    return {
        ok: true,
    };
}

const invalid = (err) => {
    return {
        ok: false,
        err: err,
    };
};

const uploadImages = async (application, newImages) => {
    let imageUris = [];
    const images = application.businessCerts; //existing uploaded images
    if (newImages && newImages.length > 0) {
        let defaultFileNames = [];//align to newImages
        let idx = 0;
        for (let newImage = 0; newImage < newImages.length; newImage++) {
            while (true) {
                idx++;
                let defaultFileName = `businessCert${idx}`;
                let found = false;
                if (images && images.length > 0) {
                    for (let img = 0; img < images.length; img++) { //in all existing images
                        if (images[img].indexOf(defaultFileName) >= 0) { //check if defaultFileName exist
                            found = true;
                            break;
                        }
                    }
                }

                if (!found) {
                    defaultFileNames.push(defaultFileName);
                    break;
                }
            }
        }

        lib.log('will upload images as below .. ');
        lib.log(defaultFileNames);

        for (let img = 0; img < newImages.length; img++) {
            const newImage = newImages[img];
            const defaultFileName = defaultFileNames[img];
            const params = {
                id: application._id,
                // id: "65090c697014b71658d433e6", //testing purpose
                codebase64: newImage,
                fileName: defaultFileName
            };
            lib.log(params);
            const imageUri = await serviceMerchantApplicationAPI.uploadImages(params);
            lib.log(`> ${imageUri}`);
            if (imageUri) imageUris.push(imageUri);
        }
    }

    return imageUris;
};

export default {
    newId: newId,
    save: save,
    validate: validate,
    uploadImages: uploadImages,
}