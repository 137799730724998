import PropertyLandingAPI from "../api/PropertyLandingAPI";

const propertyLandingData = async (state, area) => {
  const result = await PropertyLandingAPI.propertyLandingData(state, area);
  return result.data;
};

const activeState = async () => {
  const result = await PropertyLandingAPI.activeState();
  return result;
};

export default {
  propertyLanding: propertyLandingData,
  activeState,
};
