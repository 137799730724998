import apibase from './BaseAPI';

export const getAreas = async () => {
    const url = `/locations/area`;
    try {
        const result = await apibase.get(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const validStateArea = async (params) => {
    // const params = {
    //     state,
    //     area
    // }
    const url = `/areas/validStateArea`;
    try {
        const result = await apibase.post(url, params);
        return result.data
    } catch (err) {
        throw err;
    }
}

export default {
    getAreas: getAreas,
    validStateArea,
}