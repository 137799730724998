import "../styles/pages/thankYouPage.scss";

import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { close } from "../redux/actions/emblemActions";
import { Row, Col, Button, Container, } from "reactstrap";
import Carousel from "react-elastic-carousel";
import { MdClose } from "react-icons/md";

// COMPONENT
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";

// IMAGE
import vulcanLogo from "../assets/agentApplication/logo vulcan.svg";
import businessesTodayLogo from "../assets/agentApplication/logo bussiness today.svg";
import malaysiakiniLogo from "../assets/agentApplication/logo malaysiakini.svg";
import facebook from "../assets/agentApplication/icon facebook.svg";
import instagram from "../assets/agentApplication/icon instagram.svg";
import thankImage from "../assets/agentApplication/thank-you-hero.svg";
import nextsixAppEmblem from "../assets/home/nextsix-app-emblem.png";
import allWinImage from "../assets/agentApplication/tq-allwin-img.svg";

const ThankYouPage = (props) => {
  const location = useLocation();
  const fromSignUp = location.state?.fromSignUp;
  const dispatch = useDispatch();
  const emblem = useSelector(state => state.emblem);
  const getwidth = window.innerWidth;

  const closeBanner = () => {
      dispatch(close("close"));
  }

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'instant' });
    if (!fromSignUp) {
      props.history.push('/')
    }
  }, [])

  return (
    <>
      {
        getwidth < 768 && emblem === "open" &&
        <div className="emblem-wrapper">
            <div className="emblem-left-content">
                <Button className="emblem-close-btn" onClick={() => closeBanner()}><MdClose /></Button>
                <img className="emblem-logo" src={nextsixAppEmblem} alt="" />
                <div className="emblem-left-textbox">
                    <span className="emblem-left-text-top">NextSix App</span>
                    <span className="emblem-left-text-btm">Find properties and agents faster</span>
                </div>
            </div>

            <div className="emblem-right-content">
                <a className="emblem-download-btn" href="https://apps.nextsix.com/users/reg">Use Now</a>
            </div>
        </div>
      }

      <Header2 />

      <div className="hero-wrapper">
        <Container className="hero-container">
          <Row className="hero-row">
            <Col lg="5" md="12" className="hero-left-col">
              <div className="hero-title">
                Thank You!
              </div>

              <div className="hero-subtitle">
                Thanks a bunch for your interest! <span>Our team will get back to you soon.</span>
              </div>

              <Link to="/" className="hero-btn">Back to home</Link>
            </Col>
        
            <Col lg="7" md="12" className="hero-right-col">
              <img src={thankImage} className="hero-img"></img>
            </Col>
          </Row>
        </Container>
      </div>

      {fromSignUp === 'merchant' ?
        <AllWinHomeProSection />
        : <AgentSignUpInfo />
      }
      
      <Footer2 />
    </>
  );
};

export default ThankYouPage;

const AgentSignUpInfo = () => {

  const FeaturedSection = () => {
    const breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 2, itemsToScroll: 2 },
      { width: 768, itemsToShow: 3 },
      { width: 1200, itemsToShow: 3 },
    ];

    return (
      <div className="featured-wrapper">
        <Container className="featured-container">
          <Row>
            <Col>
              <p className="featured-title">Also Featured in</p>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="featured-carousel-wrapper">
                <Carousel
                  breakPoints={breakPoints}
                  enableAutoPlay
                  autoPlaySpeed={3000}
                  enableSwipe
                  showArrows={false}
                  pagination={false}
                >
                  <a href="https://vulcanpost.com/723561/nextsix-malaysia-app-gps-property-agent-search/?fbclid=IwAR1Xmbzu0eaXzaY40EgI_T7VPbzYWC49EiXt5WKAgW9wZnJ3ZCXjLIkmKHA">
                    <div className="featured-card-wrapper">
                      <div className="featured-card-title">
                        {" "} “Old property-hunting ways can go home, this M’sian app lets you GPS listings & Agents”
                      </div>

                      <div className="featured-card-img-container">
                        <img src={vulcanLogo} className="featured-card-img" alt="feature logo"></img>
                      </div>
                    </div>
                  </a>

                  <a href="https://www.businesstoday.com.my/2020/12/04/elevating-the-property-search-game/?fbclid=IwAR0fDO-uzlVofciSwG77yinuIKW6ETJTvPK0qcT-Jnja4NDahBy4LvLsYzI">
                    <div className="featured-card-wrapper">
                      <div className="featured-card-title">
                        {" "} “Elevating the property search game.”
                      </div>

                      <div>
                        <img src={businessesTodayLogo} className="featured" alt="feature logo" ></img>
                      </div>
                    </div>
                  </a>

                  <a href="https://www.malaysiakini.com/advertorial/552108?fbclid=IwAR3_4Zy7huUrgjaSRNBudSA90RRW--zAgMmwn0OBdCUQh5kchNFW-xJ42YY">
                    <div className="featured-card-wrapper">
                      <div className="featured-card-title">
                        {" "} “A smarter, more efficient way to hunt for your ideal property”
                      </div>

                      <div>
                        <img src={malaysiakiniLogo} className="featured" alt="feature logo"></img>
                      </div>
                    </div>
                  </a>
                </Carousel>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  const AboutCompany = () => {
    return (
      <div className="about-wrapper">
        <Container className="about-container">
          <Row className="about-row-top">
            <Col>
              <p className="about-title">Something About Our Company</p>

              <p className="about-subtitle">
                Nextsix is a Property-Tech Platform based in Malaysia and
                the first to pioneer a GPS-driven search technology that
                links homebuyers or tenants directly to professional agents
                through{" "}
                <span>#GPSYourAgent</span>
              </p>
            </Col>
          </Row>

          <Row className="about-row-bottom">
            <Col lg="6" md="12" className="about-video-left">
              <iframe
                className="responsive-iframe"
                src="https://www.youtube.com/embed/qidABEqBwaA"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="about us"
              ></iframe>
            </Col>

            <Col lg="6" md="12" className="about-video-right">
              <div className="about-video-title-1">
                {" "}
                With soon-to-launch HomePro and HomeMall that encompass all
                real-estate related needs,its going to become your favourite
                Real Estate App.
              </div>

              <div className="about-video-title-2">
                Searching for properties has just become way easier.
              </div>

              <div>
                This also means for our dear agents - selling/renting your
                properties has just become way faster and more effective.{" "}
              </div>

              <div className="about-social-container">
                <a href="https://www.facebook.com/NextsixMY">
                  {" "}
                  <img src={facebook} className="about-social-icon" alt="social media icon"></img>
                </a>{" "}

                <a href="https://www.instagram.com/nextsixmy/">
                  <img src={instagram} className="about-social-icon ml-4" alt="social media icon"></img>
                </a>{" "}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <>
      <FeaturedSection />
      <AboutCompany />
    </>
  )
}

const AllWinHomeProSection = () => {
  return (
    <div className="tq-allwin container">
      <div className="tq-allwin-img">
        <img src={allWinImage} alt="all win with Nextsix"/>
      </div>
      <div className="tq-allwin-text">
        <div className="tq-allwin-title">All Win with Nextsix Home Pro</div>
        <p>Nextsix launched the Home Pro mainly to encounter the pain point in the market that home buyers always can’t find the person they need.</p>
        <p>So through Home Pro, our Professional Agents can recommend the right and professional service provider to their clients. </p>
        <p>We hope to achieve an all win situation in our platform. </p>
      </div>
    </div>
  )
}
