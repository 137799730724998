import axios from 'axios';
import SetupData from '../data/SetupData';
import apibase from './BaseAPI';
import FileType from 'file-type';


const buildFileSaveConfig = (formData, folder) => {
    return {
      url: process.env.REACT_APP_FILE_SAVE,
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'apikey': SetupData.files.apiKey,
        'folder': folder
      }
    };
};

const upload = (formData, folder) =>{
    return new Promise(async (resolve, reject) => {
        try{
            const result = await axios(buildFileSaveConfig(formData, folder));
            resolve(result.data);
        }catch(err){
            reject(err);
        }
    });   
};

const download = async (link) =>{
    const params ={
        url: link
    };

    /*IMPORTANT: FileType library can't process .doc, .xls, .ppt, .msi, .csv, .svg, .txt */

    const url = '/files/download';
    try {
        let final={binaryData:'', extension: '', mime: ''};
        const result = await apibase.post(url, params, {responseType: 'arraybuffer'});
        if(result.data){
            const fileType = await FileType.fromBuffer(result.data);
            final.binaryData = result.data;
            if(fileType) {
                final.extension = fileType.ext;
                final.mime = fileType.mime;
            }
            else{
                final.extension = link.substring(link.lastIndexOf('.')+1);
                final.mime = getMimeType(final.extension);
            } 
        }        
        return final;
    } catch (err) {
        throw err;
    }
};

const getMimeType = (extension) =>{
    let mimeType = '';

    switch (extension.toLowerCase()) {
        case "csv":
            mimeType = 'text/csv';
            break;
        case "doc":
            mimeType = 'application/msword';
            break;
        case "pdf":
            mimeType = 'application/pdf';
            break;
        case "ppt":
            mimeType = 'application/vnd.ms-powerpoint';
            break;
        case "txt":
            mimeType = 'text/plain';
            break;
        case "xls":
            mimeType = 'application/vnd.ms-excel';
            break;        
        default:
            mimeType = '';
    }

    return mimeType;
};

export default {
    upload: upload,
    download: download,
};