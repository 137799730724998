import React, { useState, useEffect, useRef } from "react";
import propertyComponent from "../businesses/property";
import agentComponent from "../businesses/agent";
import lib from "../businesses/lib";
import SliderImg from "../components/SliderImg";
import Gallery from "../components/Gallery";
import AgentInfoDraft from "../components/AgentInfoDraft";
import GoogleMapReact from "google-map-react";
// import gps from "../assets/img/locationgrey.svg";
// import house from "../assets/img/housegrey.svg";
// import car from "../assets/img/cargrey.svg";
// import shower from "../assets/img/bathgrey.svg";
// import bed from "../assets/img/bedgrey.svg";
import gpsgrey from "../assets/img/greypointer.svg";
import orangehouse from "../assets/img/orangehouse.svg";
import orangecar from "../assets/img/orangecar.svg";
import orangeshower from "../assets/img/orangebath.svg";
import orangebed from "../assets/img/orangebed.svg";
import back from "../assets/img/backarrowlogo.svg"
import landOrange from "../assets/img/icon_land_orange.svg";
import mapPreview from "../assets/img/map-preview.png"
import thumbup from "../assets/img/thumbup.svg";
import SetupData from "../data/SetupData";
import moment from "moment";
import Carousel from "react-elastic-carousel";
import LoadingIndicator from "../components/LoadingIndicator";
import {
  Row,
  Col,
  Card,
  Button,
  Container,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  IoLogoWhatsapp,
  IoMdPhonePortrait,
  IoIosListBox,
} from "react-icons/io";
import { MdRemoveRedEye } from "react-icons/md";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "react-image-gallery/styles/css/image-gallery.css";
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";
import "../styles/pages/propertyDetailPage.scss";
import { trackPromise } from "react-promise-tracker";
import NumberFormat from "react-number-format";
import seoComponent from "../businesses/seoComponent";
import PageError from "../components/Layout/PageError";
import PropertyCard from "../components/Layout/PropertyCard";
import insightComponent from "../businesses/InsightComponent";
import Chart from 'chart.js/auto';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setRefresh2 } from "../redux/actions/chartActions";
import "../styles/components/table.scss";
import useScrollSpy from 'react-use-scrollspy';
// import styled from "styled-components";
// import string from '../businesses/string';
// import global from '../businesses/global';
import nextsixAppEmblem from "../assets/home/nextsix-app-emblem.png";
import { MdClose } from "react-icons/md";
import { close } from "../redux/actions/emblemActions";
import MortgageCalculator from "../components/MortgageCalculator";
import biUserEngagementComponent from "../businesses/biUserEngagementComponent";
import style from '../styles/pages/agentSearchPage.module.scss'
import string from "../businesses/string";
import CustomBreadcrumb from "../components/Breadcrumb";
import { Table } from "react-bootstrap";

const PropertyDraftPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const emblem = useSelector(state => state.emblem);
  const getwidth = window.innerWidth;
  const refreshChart = useSelector(state => state.chart);
  const { t } = useTranslation();
  const [properties, setProperties] = useState([]);
  const [search, setSearch] = useState({ active: "Active" });
  const [agents, setAgent] = useState([]);
  const [mode, setMode] = useState(true);
  let { property_info } = useParams();
  let getUrlInfo = property_info.split('-')
  let id = getUrlInfo[getUrlInfo.length - 1]//get last of array(property id)
  const [otherListing, setOtherListing] = useState([]);
  const [otherSearches, setOtherSearches] = useState([]);
  const [selectedAgentId, setSelectedAgentId] = useState();
  // const [overviewInsight, setOverviewInsight] = useState({})
  const [similarListing, setSimilarListing] = useState([]);
  const [tabSelected, setTabSelected] = useState("History")
  const [roiData, setRoiData] = useState([
    //testing data do not remove
    // { 
    //   id: 2023,
    //   mean: 87220,
    //   year: 2023
    // },
    // {
    //   id: 2022,
    //   mean: 97220,
    //   year: 2022
    // },
    // {
    //   id: 2021,
    //   mean: 107220,
    //   year: 2021
    // }
  ])
  const [roiPercent, setRoiPercent] = useState(null)
  const [turnOver, setTurnover] = useState(null)
  const [levelOfAvg, setLevelOfAvg] = useState({})

  const [displayMap, setDisplayMap] = useState(false);
  const [insight, setInsight] = useState(null);

  //const [meta, setMeta] = useState({});

  const calculatorCallback = (data) => {
    props.history.push({
      pathname: '../mortgage-calculator',
      state: data
    })
  }

  const showCurrentYear = () => {
    return new Date().getFullYear();
  };

  const detectSource = () => {
    const fromSource = localStorage.getItem('from');
    if (fromSource && id) {
      if (fromSource == "listing") {
        biUserEngagementComponent.biUserEngagement(fromSource, "property", id)
      }
      else if (fromSource == "map") {
        biUserEngagementComponent.biUserEngagement(fromSource, "property", id)
      }
    }
    else if (fromSource == "" && id) {
      biUserEngagementComponent.biUserEngagement("shared", "property", id)
    }
    return localStorage.setItem('from', "");
  }

  const assignSearchValue = (name, value) => {
    setSearch((search) => ({
      ...search,
      [name]: value,
    }));
  };
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -230,
  });

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    //handleSearchData()
  }, [selectedAgentId])

  // useEffect(() => {
  //   if (refreshChart) {
  //     if (tabSelected == "Graft" && overviewInsight) {
  //       setTabSelected("ROI")
  //     }
  //     dispatch(setRefresh2(false));
  //   }
  // }, [refreshChart])

  const fbevent = (property) => {
    const tenure = property.tenure === 'Freehold' ? 'freehold' : 'leasehold';

    let propertyType = `other`;
    if (property.title) {
      if (property.title.indexOf('Suites') >= 0) propertyType = 'condo';
      else if (property.title.indexOf('Land') >= 0) propertyType = 'land';
      else if (property.title === 'Apartment/Flat') propertyType = 'apartment';
      else if (property.title === 'Condo/Serviced Residence') propertyType = 'condo';
      else if (property.title === 'Terrace/Link/Townhouse') propertyType = 'house';
      else if (property.title === 'Semi-D/Bungalow') propertyType = 'bungalow';
      else if (property.title === 'Shop/Office/Retail Space' || property.title === 'Factory/Warehouse') propertyType = 'manufactured';
    }

    let propertyName;
    if (property.type === 'Condominium' || property.type.indexOf('Suites') >= 0) propertyName = 'Condo';
    else if (property.type.indexOf('Superlink') >= 0) propertyName = `${property.type.substring(0, 1)}-Sty Superlink`;
    else if (property.type.indexOf("1-sty") >= 0 ||
      property.type.indexOf("1.5-sty") >= 0 ||
      property.type.indexOf("2-sty") >= 0 ||
      property.type.indexOf("2.5-sty") >= 0 ||
      property.type === '2 & 3 Sty Terrace/Link House') propertyName = 'Terrace/Link';
    else if (property.type === '3-sty Terrace/Link House') propertyName = '3-Sty Terrace/Link';
    else if (property.type === '3.5-sty Terrace/Link House') propertyName = '3.5-Sty Terrace/Link';
    else if (property.type === '4-sty Terrace/Link House') propertyName = '4-Sty Terrace/Link';
    else if (property.type === '4.5-sty Terrace/Link House') propertyName = '4.5-Sty Terrace/Link';
    else if (property.type === '3 Storey Semi-detached Homes') propertyName = `3-Sty Semi-D`;
    else if (property.type.indexOf("Land") >= 0) propertyName = `Land`;
    else if (property.type.indexOf("Bungalow") >= 0) propertyName = `Bungalow`;
    else if (property.type === 'Semi-detached House' || property.type === 'Commercial Semi-D') propertyName = `Semi-D`;
    else if (property.type.indexOf("Bungalow") >= 0) propertyName = `Bungalow`;
    else if (property.type.indexOf("Office") >= 0) propertyName = `Office`;
    else if (property.type.indexOf("Shop") >= 0 ||
      property.type === 'Retail Space' ||
      property.type === 'Business Center') propertyName = `Retail/Shop`;
    else if (property.type === 'SOHO' ||
      property.type === 'SOFO' ||
      property.type === 'SOVO') propertyName = 'SOHO/FO/VO';
    else if (property.type.indexOf("Factory") >= 0 ||
      property.type === 'Light Industrial' ||
      property.type === 'Warehouse') propertyName = `Factory/Warehouse`;
    else propertyName = property.type;

    let beds = 0;
    if (property.beds && property.beds !== 'Studio') {
      let idxSpace = property.beds.indexOf(' ');
      if (idxSpace > 0) beds = Number(property.beds.substring(0, idxSpace));//1 + 1, 12 + 1, etc
      else beds = Number(property.beds);//1, 2, 3, 4, 13, 14
    }

    const trackObj = {
      content_type: 'home_listing',
      content_ids: [property._id],
      description: property.name,
      availability: property.for === 'Buy' ? 'for_sale' : 'for_rent',
      price: property.for === 'Buy' ? property.buy.price : property.rent.monthlyRental,
      neighborhood: property.address?.city,
      city: property.address?.area,
      region: property.address?.state,
      country: property.address?.country,
      num_baths: property.baths,
      num_beds: beds,
      tenure_type: tenure,
      name: propertyName,
      property_type: propertyType,
    };

    lib.log(`trackObj`);
    lib.log(trackObj);

    if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
      window.fbq('track', 'ViewContent', trackObj);
      console.log(`fbq('track')`);
    }
  };

  // const processMetaData = (property, agent) => {
  //   const _for = property.for === 'Buy' ? 'sale' : 'rent';
  //   let title = `${property.name} for ${string.capitalize(_for)} | ${agent.name} | ${global.NEXTSIX}`;
  //   let description = `${property.name} for ${string.capitalize(_for)} by ${agent.name}`;
  //   if (property.for === 'Buy' || property.for === 'Rent') {
  //     title = `${property.name} for ${string.capitalize(_for)} | ${global.NEXTSIX}`;
  //     description = `${property.hightlight}`;
  //   }

  //   const state = `${seoComponent.treatState(property.address?.state)}`;
  //   const area = `${seoComponent.treatArea(property.address?.area)}`;
  //   const type = `${property.type?.replaceAll('.', '-').replaceAll('/', '-').replaceAll(' ', '-').toLowerCase()}`;
  //   let canonical = `https://nextsix.com/properties-for-sale/malaysia`;
  //   if (state) {
  //     if (area) {
  //       if (type) canonical = `https://nextsix.com/properties-for-${_for}/${state}/${area}/${type}`;
  //       else canonical = `https://nextsix.com/properties-for-${_for}/${state}/${area}`;
  //     }
  //     else {
  //       if (type) canonical = `https://nextsix.com/properties-for-${_for}/${state}/${type}`;
  //       else canonical = `https://nextsix.com/properties-for-${_for}/${state}`;
  //     }
  //   }
  //   const propertyCoverPhoto = property.images[0] ?? `https://iprofstore.blob.core.windows.net/general/nextsix.png`;

  //   document.title = title;
  //   document.getElementsByTagName("meta")["description"].content = description;
  //   setMeta({
  //     //title,
  //     //description,
  //     robots: `index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`,
  //     canonical,
  //     meta: {
  //       name: {
  //         keywords: "nextsix",
  //       },
  //       property: {
  //         'og:locale': 'en_MY',
  //         'og:type': 'website',
  //         'og:title': title,
  //         'og:description': description,
  //         'og:url': canonical,
  //         'og:site_name': global.NEXTSIX_SDN_BHD,
  //         'og:image': propertyCoverPhoto,
  //         'twitter:title': title,
  //         'twitter:description': description,
  //         'twitter:url': canonical,
  //         'twitter:image': propertyCoverPhoto,
  //       }
  //     }
  //   });
  // };

  const fetchData = async () => {
    const propertyId = seoComponent.seoToState(id);
    //NXT-1876
    if (!lib.isMongoDbObjectId(propertyId)) return history.push('/error/404');
    const properties = await propertyComponent.id(propertyId);
    if (!properties || properties.active === false) return history.push('/error/404');
    // if (!lib.isMongoDbObjectId(propertyId)) return setMode(false);
    // const properties = await propertyComponent.id(propertyId);
    // if (!properties || properties.active === false) return setMode(false);

    biUserEngagementComponent.biPropertyLead(properties._id);
    setProperties(properties);
    assignSearchValue("_for", properties.for)
    setSelectedAgentId(properties.agent)
    lib.log(properties);
    fbevent(properties);
    const agent = await agentComponent.getAgentInfo(properties.agent);
    setAgent(agent);

    //NXT-1876
    if (agent.active !== "In Service") return history.push('/error/404');
    // if (agent.active !== "In Service") return setMode(false);

    setSimilarListing(await propertyComponent.similarProperties(properties._id));
    setOtherListing(await propertyComponent.otherProperties(properties._id));
    // setOverviewInsight(await insightComponent.overview(id));
    handleOtherSearches(properties?.address?.state, properties?.address?.area)
    setInsight(await insightComponent.transactionHistory(id));

    //processMetaData(properties, agent);
    detectSource()
  };

  // useEffect(() => {
  //   if (overviewInsight?.roi) {
  //     // console.log(overviewInsight?.roi)
  //     // console.log("roiPercent", roiPercent)
  //     setRoiData(overviewInsight.roi.reverse())
  //   }
  //   if (overviewInsight?.transactionHistory && overviewInsight.transactionHistory.length > 0) {
  //     if (overviewInsight.transactionHistory.length > 9) {
  //       const d = new Date();
  //       const year = d.getFullYear();
  //       const month = d.getMonth();
  //       const day = d.getDate();
  //       const dateCompare = new Date(year - 2, month, day);

  //       let count = 0
  //       for (let i = 0; i < overviewInsight.transactionHistory.length; i++) {
  //         if (new Date(overviewInsight.transactionHistory[i].eventDate) > dateCompare) {
  //           count += 1
  //         }
  //       }
  //       if (count >= 10) {
  //         setTurnover("High")
  //       } else {
  //         setTurnover("Low")
  //       }
  //     }
  //     else setTurnover("Low")
  //   }
  // }, [overviewInsight])

  useEffect(() => {

    if (roiData && roiData.length == 1) {
      setRoiPercent("displayfail")
    }
    else if (roiData && roiData.length > 0) {
      if (roiData[0].year == showCurrentYear()) {
        if (roiData.length >= 3) {
          setRoiPercent(((roiData[1].mean - roiData[2].mean) / roiData[2].mean) * 100)
        }
        else if (roiData.length == 2) {
          setRoiPercent(0)
        }
      }
      // else if (roiData.length == 2){
      //   setRoiPercent(0)
      // }
      else {
        setRoiPercent(((roiData[0].mean - roiData[1].mean) / roiData[1].mean) * 100)
      }
      // setRoiPercent(((roiData[0].mean - roiData[roiData.length - 1].mean) / roiData[roiData.length - 1].mean) * 100)
    }

  }, [roiData])

  // useEffect(() => {
  //   if (overviewInsight.salesTrend && overviewInsight.salesTrend.dataSet && properties?.buy?.price) {
  //     const getAvgData = overviewInsight.salesTrend.dataSet[1].data[overviewInsight.salesTrend.dataSet[1].data.length - 1]
  //     const levelAvg = ((Number(properties.buy.price) - Number(getAvgData)) / Number(getAvgData))
  //     const percentAvg = Number(levelAvg.toFixed(2)) * 100
  //     lib.log(getAvgData)
  //     if (levelAvg > 0) {
  //       setLevelOfAvg({ level: "higher", percent: Number(percentAvg.toFixed(2)) })
  //     }
  //     else {
  //       setLevelOfAvg({ level: "lower", percent: Number(percentAvg.toFixed(2)) })
  //     }
  //   }

  // }, [overviewInsight?.salesTrend])

  // useEffect(() => {
  //   if (overviewInsight?.salesTrend && overviewInsight.salesTrend.periods && overviewInsight.salesTrend.periods.length >= 1 && overviewInsight.salesTrend.dataSet && tabSelected == "Graft") {
  //     showChart()
  //   }
  // }, [overviewInsight, tabSelected, activeSection === 1])

  let colorIdx = 0;
  const colors = ['rgb(255, 99, 132)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(54, 162, 235)',
    'rgb(153, 102, 255)',
    'rgb(231, 233, 237)'
  ];
  const nextColor = () => {
    const idx = colorIdx++ % colors.length;
    return colors[idx];
  }
  // const showChart = () => {
  //   document.querySelector("#chartReport").innerHTML = '<canvas id="saleTrendChart"></canvas>';//clear canvas
  //   let datasets = [];
  //   overviewInsight.salesTrend.dataSet.forEach(dataset => {
  //     const rgbColor = nextColor();
  //     datasets.push({
  //       label: dataset.type,
  //       data: dataset.data,
  //       fill: false,
  //       borderColor: rgbColor,
  //       backgroundColor: rgbColor,
  //       tension: 0.2
  //     });
  //   });

  //   const data = {
  //     labels: overviewInsight.salesTrend.periods,
  //     datasets: datasets
  //   };

  //   const config = {
  //     type: 'line',
  //     data: data,
  //     options: {
  //       plugins: {
  //         responsive: true,
  //         legend: {
  //           display: true,
  //           position: "top",
  //         }
  //       },
  //       scales: {
  //         y: {
  //           display: true,
  //           title: {
  //             display: true,
  //             text: "Price (RM)"
  //           }
  //         },
  //       }
  //     }
  //   };
  //   new Chart('saleTrendChart', config);
  // };

  const handlePopulateData = (property) => {
    // localStorage.setItem('from', "listing");
    const newUrl = seoComponent.createPropertyDetailUrl(property);
    return newUrl;
  };

  const handleSearchData = async () => {
    setOtherListing([]);
    if (selectedAgentId) {
      const result = await trackPromise(
        propertyComponent.search(selectedAgentId, search, { page: 0 })
      );

      if (result) {
        setOtherListing(result.data);
      }
    }
  };

  const handlePhone = () => {
    const phone = parsePhoneNumberFromString(
      agents.mobile,
      "MY"
    ).nationalNumber;
    window.open(`tel: +60${phone} `);
  };

  const handleWhatApps = () => {
    let wsText
    const url = encodeURIComponent(window.location.href)

    if (agents && url && properties) {
      wsText = `Hi ${agents?.nickname},  I'm interested in the Property For ${properties?.for === 'Rent' ? properties?.for : 'Sale'} on NextSix%0A%0A${url}%0A%0AI would appreciate it if you could provide me with more information about the property`
    } else {
      wsText = 'Hi, I would like to seek your assistance in searching for properties'
    }

    const phone = parsePhoneNumberFromString(
      agents.mobile,
      "MY"
    ).nationalNumber;

    window.open(
      `https://wa.me/60${phone}?text=${wsText}`,
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  const goToAgentListing = (agent) => {
    localStorage.setItem('from', "listing");
    const newUrl = seoComponent.createAgentDetailUrl(agent);
    window.location = newUrl;
  };

  const handleSeeMore = (search) => {
    let clone = {}

    if (search.for === "Buy") {
      clone.tenure = search.tenure
      clone.target = "properties-for-sale"
      if (search.realestateMaster) {
        clone.realestateMaster = search.realestateMaster
        // clone.maxPrice = search.buy.price + 50000
      }
      // else clone.maxPrice = search.buy.price + 100000
    } else {
      clone.target = "properties-for-rent"
      if (search.realestateMaster) {
        clone.realestateMaster = search.realestateMaster
        // clone.maxPrice = search.rent.monthlyRental + 500
      }
      // else clone.maxPrice = clone.rent.monthlyRental + 1000
    }

    let newUrl = seoComponent.createSearchPropertyUrl(clone);
    window.location = newUrl;
  };

  const handleOtherSearches = async (addressState, addressArea) => {
    const results = await propertyComponent.otherSearches(addressState, addressArea)
    if (results && results.length > 0) {
      results.sort((a, b) => b.count - a.count)
      const limitedResults = results.slice(0, 6)
      setOtherSearches(limitedResults)
    } else {
      setOtherListing([])
    }
  }

  const changeHref = (event, item) => {
    document.getElementById("otherSearches").href =
      `/properties-for-${item.for === "Buy" ? 'sale' : item.for.toLowerCase()}/${seoComponent.treatState(properties.address.state)}/${seoComponent.treatArea(properties.address.area)}${item.place ? `/${seoComponent.treatTitleOrType(item.place)}` : ""}`

    window.location.href = document.getElementById("otherSearches").href
  }

  const ModelsMap = (map, maps) => {
    //instantiate array that will hold your Json Data
    let dataArray = [properties];

    ////R&D circle map START
    const circleOptions = {
      center: {
        lat: dataArray[0].loc.coordinates[1],
        lng: dataArray[0].loc.coordinates[0],
      },
      map,
      radius: 1000,
      strokeColor: "#f09108",
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#d6d6d6',
      fillOpacity: 0.4,
      clickable: false
    };
    const propertyCircle = new maps.Circle(circleOptions);
    ////R&D circle map END

    ////R&D search nearby places START
    // lib.log('test places API')
    // let service = new maps.places.PlacesService(map);
    // lib.log(service);
    // service.nearbySearch({
    //   location: {
    //     lat: dataArray[0].loc.coordinates[1],
    //     lng: dataArray[0].loc.coordinates[0],
    //   }, 
    //   radius: 1500, //meter
    //   type: ["shopping_mall"] 
    // },
    // (results, status) =>{
    //   lib.log('places response')
    //   lib.log(status)
    //   if(status == 'OK'){
    //     lib.log('places ok');
    //     lib.log(results);

    //     results.forEach(data =>{
    //       const image = {
    //         url: data.icon,
    //         size: new maps.Size(71, 71),
    //         origin: new maps.Point(0, 0),
    //         anchor: new maps.Point(17, 34),
    //         scaledSize: new maps.Size(25, 25),
    //       };

    //       const marker = new maps.Marker({
    //         position: data.geometry.location,
    //         map,
    //         icon: image,
    //         title: data.name
    //       });
    //     })
    //   }
    // });
    ////R&D search nearby places END  


    //push your Json Data in the array
    // {
    //   properties.map((markerJson) => dataArray.push(markerJson));
    // }
    // lib.log(properties)

    // const sv = new maps.StreetViewService();
    // let panorama = new maps.StreetViewPanorama(
    //   document.getElementById("mapp")
    // );//try

    //Loop through the dataArray to create a marker per data using the coordinates in the json
    for (let i = 0; i < dataArray.length; i++) {
      lib.log(dataArray[i].loc)
      if (dataArray[i].loc) {
        if (dataArray[i].for && (dataArray[i].for.toLowerCase() === "rent" || dataArray[i].for.toLowerCase() === "buy")) {
          const label = dataArray[i].for.toLowerCase() === "rent" ? "RM " + lib.currencyFormatter(dataArray[i].rent.monthlyRental)
            : "RM " + lib.currencyFormatter(dataArray[i].buy.price)

          // const labelimage =
          //   "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2280%22%20height%3D%2280%22%20viewBox%3D%220%200%2080%2080%22%3E%3Cpath%20fill%3D%22%234AA665%22%20stroke%3D%22%232C633C%22%20stroke-width%3D%22.3%22%20d%3D%22M50%2030%20L80%2030%20L80%200%20L0%200%20L0%2030%20L32%2030%20L38%2038%20L44%2030%20Z%22%2F%3E%3Ctext%20transform%3D%22translate(19%2018.5)%22%20fill%3D%22%23fff%22%20style%3D%22font-family%3A%20Arial%2C%20sans-serif%3Bfont-weight%3Abold%3Btext-align%3Aright%22%20font-size%3D%2212%22%20text-anchor%3D%22start%22%3E" + label + "%3C%2Ftext%3E%3C%2Fsvg%3E%0A%0A";

          // const propertyPng = dataArray[i].images && dataArray[i].images.length > 0 ? dataArray[i].images[0] : SetupData.defaultHouse;
          // const propertyFor = dataArray[i].for.toLowerCase() === "buy" ? lib.toTitleCase("for sale") : lib.toTitleCase(dataArray[i].for);
          // const price = dataArray[i].for.toLowerCase() === "rent" ? "RM " +
          //   parseFloat(dataArray[i].rent.monthlyRental).toLocaleString() +
          //   "/month" : "RM " + parseFloat(dataArray[i].buy.price).toLocaleString();
          // const numOfBed = dataArray[i].beds ? dataArray[i].beds : "-";
          // const numOfBath = dataArray[i].baths ? dataArray[i].baths : "-";
          // const numOfCarPark = dataArray[i].carParks ? dataArray[i].carParks : "-";
          // const numOfBuildUp = dataArray[i].buildup ? dataArray[i].buildup : "-";

          // const contentinfo = "<div class='property-map-card' ><div>" +
          //   "<img src='" + propertyPng + "' class='info-card-image'/>" +
          //   "</div>" + "<br/>" + "<div class='pricelabel' >" + "<p class='lightgreytext m-1 fontxs'>" + "Publish on " +
          //   moment(dataArray[i].created).format("DD/MM/YYYY") + "</p>" +
          //   "<div class='statuslabel-slide'>" +
          //   "<div class='m-0 statuslabel'>" + propertyFor + "<span></span></div>" + "</div>" + "<h3>" + price + "</h3>" + "<h4>" + dataArray[i].name + "</h4>" +
          //   "<p class='mb-2 greytext fontxs'>" + "<img src='" + gps + "' class='locationlogo'/>" + dataArray[i].address.area + "," + dataArray[i].address.state + "</p>" +
          //   "<span class='property-card-facilitylogo-padding'>" + "<img src='" + bed + "' class='facilitylogo'/>" + " " + numOfBed + " "
          //   + "<img src='" + shower + "' class='facilitylogo'/>" + " " + numOfBath + " "
          //   + "<img src='" + car + "' class='facilitylogo'/>" + " " + numOfCarPark + " "
          //   + "<img src='" + house + "' class='facilitylogo'/>" + " " + numOfBuildUp + " sq. "
          //   + "</span>" +
          //   "</div>" + "</div>";


          // const contentinfo="<div class='property-list-card'> <div class='property-map-card' ><div>"+
          // "<img src='"+propertyPng+"' class='info-card-image'/>" +
          // "</div>"+
          // "<br/>"+
          // "<div class='property-list-pricelabel' >"+
          // "<div class='property-list-statuslabel-slide'>"+
          // "<span class='m-0 property-list-statuslabel'>"+
          // propertyFor+"</span>"+"<span class='property-list-date'>"+
          // "<span class='lightgreytext m-1'>"+
          // `${t('published-on')} ` +
          // moment(dataArray[i].created).format("DD/MM/YYYY")+"</span></span>"+
          // "</div>"+
          // "<p class='mb-2 greytext property-list-address'>"+"<img src='"+gps+"' class='locationlogo'/>"+dataArray[i].address.area+","+ dataArray[i].address.state+"</p>"+
          // "<div class='property-list-price'>"+price+"</div>"+ "<b class='property-list-card-name'>"+dataArray[i].name+"</b><br/><br/>"+
          // "<span class='property-card-facilitylogo-padding'>"+"<img src='"+bed+"' class='facilitylogo'/>"+" "+numOfBed+" "
          // +"<img src='"+shower+"' class='facilitylogo'/>"+" "+numOfBath+" "
          // +"<img src='"+car+"' class='facilitylogo'/>"+" "+numOfCarPark+" "
          // +"<img src='"+house+"' class='facilitylogo'/>"+" "+numOfBuildUp+" sqft "
          // +"</span>"+
          // "</div>"+
          // "</div></div>";

          // const infowindow = new maps.InfoWindow({
          //   content: contentinfo,
          //   maxWidth: 370,
          //   maxHeight: 800,
          //   minWidth: 230
          // });
          const size = string.getWidth(label)
          let marker = new maps.Marker({
            position: {
              lat: dataArray[i].loc.coordinates[1],
              lng: dataArray[i].loc.coordinates[0],
            },
            map,
            label: {
              text: label,
              color: "#ffffff",
              fontWeight: "bold",
              className: "propertyDetail-list-Maplabel-infowindow"
            },
            // icon: {
            //   path: maps.SymbolPath.BACKWARD_CLOSED_ARROW,
            //   strokeColor: "#00A82A",
            //   scale: 6,
            //   strokeWeight: 3,
            //   origin: new maps.Point(0, 0),
            //   scaledSize: new maps.Size(20, 20)

            // },
            icon: {
              url: 'https://maps.gstatic.com/mapfiles/transparent.png',
              origin: new maps.Point(0, 0),
              scaledSize: new maps.Size(size, 28)
            }
          });

          // marker.addListener("click", () => {
          //   infowindow.open({
          //     anchor: marker,
          //     map,
          //     shouldFocus: false,
          //   });
          //   //   handlePopulateData(dataArray[i]._id, "properties")
          // });

          // marker.addListener("mouseover", () => {
          //   infowindow.open({
          //     anchor: marker,
          //     map,
          //     shouldFocus: false,
          //   });
          // });

          // marker.addListener("mouseout", () => {
          //   infowindow.close({
          //     anchor: marker,
          //     map,
          //     shouldFocus: false,
          //   });
          // })

          // const getlocation={lat: dataArray[i].loc.coordinates[1],lng: dataArray[i].loc.coordinates[0]}
          // sv.getPanorama({ location: getlocation, radius: 100 }).then(processSVData);
          // function processSVData({ data }) {
          //   const location = data.location;
          //   panorama.setPano(location.pano);
          //   panorama.setPov({
          //     heading: 270,
          //     pitch: 0,
          //   });
          //   panorama.setVisible(true);
          // }

        }
      }
    }
  };

  const PropertyInfo = () => {
    return (
      <div className="noborder bg-white">
        <div>
          <Container>
            <h2 className="property-detail-h2">{t('property-details')}</h2>
            <hr />
            <>
              {properties.categoryListing &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('listing-category')}</h3></Col>
                  <Col className="greytext">{properties.categoryListing}</Col>
                </Row>}

              {properties.propertyListing &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('property-listing')}</h3></Col>
                  <Col className="greytext">{properties.propertyListing}</Col>
                </Row>}

              {properties.title &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('property-category')}</h3> </Col>
                  <Col className="greytext">{properties.title}</Col>
                </Row>}

              {properties.type &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('property-type')}</h3> </Col>
                  <Col className="greytext">{properties.type}</Col>
                </Row>}

              {properties.tenure && properties.tenure != "Unknown" &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('tenure')}</h3></Col>
                  <Col className="greytext">{properties.tenure}</Col>
                </Row>
              }

              {properties.titleType && properties.titleType != "Unknown" &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('title-type')}</h3></Col>
                  <Col className="greytext">{properties.titleType}</Col>
                </Row>
              }

              {properties.landTitleType && properties.landTitleType != "Unknown" &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('land-title-type')}</h3> </Col>
                  <Col className="greytext">{properties.landTitleType}</Col>
                </Row>
              }

              {properties.direction && properties.direction != "Unknown" &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('direction')}</h3></Col>
                  <Col className="greytext">{properties.direction}</Col>
                </Row>
              }

              {properties.occupied && properties.occupied != "Unknown" &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('occupied')}</h3> </Col>
                  <Col className="greytext">{properties.occupied}</Col>
                </Row>
              }

              {properties.unitType && properties.unitType != "Unknown" &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('unit-type')}</h3></Col>
                  <Col className="greytext">{properties.unitType}</Col>
                </Row>
              }

              {properties.furnishing &&
                <Row className="mb-3" >
                  <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><h3 className="property-detail-info-h3">{t('property-furnishing')}</h3></Col>
                  <Col className="greytext">{properties.furnishing}</Col>
                </Row>
              }
            </>
          </Container>
        </div>
      </div>
    );
  };

  // let breakOn = "medium";
  let tableClass = "table-container__table";

  // if (breakOn === "small") tableClass += " table-container__table--break-sm";
  // if (breakOn === "medium") tableClass += " table-container__table--break-md";
  // if (breakOn === "large") tableClass += " table-container__table--break-lg";


  //old tab button style
  //   const Tab = styled.button`
  //   padding: 10px 30px;
  //   cursor: pointer;
  //   opacity: 0.6;
  //   background: white;
  //   border: 0;
  //   outline: 0;
  //   border-bottom: 2px solid transparent;
  //   border: 1px solid grey;
  //   transition: ease border-bottom 250ms;
  //   width: inherit;
  //   height: 130px;
  //   justify-content: center;
  //   vertical-alignment: center;
  //   ${({ active }) =>
  //       active &&
  //       `
  //     border-bottom: 8px solid #f09108;
  //     box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
  //     opacity: 1;
  //   `}
  // `;

  const onOverviewCategoryClick = (e) => {
    e.preventDefault()
    document.getElementById("overview").scrollIntoView();
  }
  const onInsightCategoryClick = (e) => {
    e.preventDefault()
    document.getElementById("insight").scrollIntoView();
  }
  const onRecommendationCategoryClick = (e) => {
    e.preventDefault()
    document.getElementById("recommendation").scrollIntoView();
  }


  const DataNotFound = () => {
    return (
      <>
        <Container className="sale-trend-container">
          <Row>
            <Col xs={12} className="mb-3" id="chartReport">
              <div className="data-not-avaiable">
                <div className="data-not-avaiable-title">There is no data available at the moment</div>

              </div>
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  const SaleTrend = () => {
    return (
      <>
        <Container className="sale-trend-container">
          <Row className="sales-trend-gain-row2">
            <Col md={6} xs={12}>
              <p className="sales-trend-gain-text2">Price trend indicating transacted price over passed years. Actual market value may require to contact agent.</p>
            </Col>
            <Col md={6} xs={12}>
              {(levelOfAvg && !isNaN(levelOfAvg.percent)) ?
                <div className="sales-trend-gain-result2">
                  <p className="sales-trend-gain-percent2" style={{ "color": levelOfAvg.level == "higher" ? "#00A82A" : "red" }}>{Number(levelOfAvg.percent)} % {levelOfAvg.level} </p>
                  <p className="sales-trend-gain-percent-text">than average price</p>
                </div> :
                <div>
                  -
                </div>
              }
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="mb-3" id="chartReport">
              <canvas id="saleTrendChart"></canvas>
            </Col>
          </Row>
          <div>
            <p className="property-detail-table-disclaimer">
              <b>Disclaimer</b>
            </p>
            <p className="property-detail-table-disclaimer-paragraph">
              The  information,  specifications,  visual  presentations  and  data  contained  in  our  platform  may  be sourced  from  various  third  party  sources.  We  do  not  give  any  warranty  or  representation  and  shall not be deemed to have given any warranty or representation as to the accuracy or general veracity of  the  information  specifications,  visual  presentations  and  data,  and  we  do  not  assume  any responsibility,  liability  or  obligations  whatsoever  towards  any  user  of  our  platform  for  any  losses, costs, expenses or liabilities whatsoever that such user may suffer or incur by reason of reliance on such  information,  data  and  other  contents.  The  information,  data  and  the  contents  as  presented here  are  subject  to  updates  and  changes  without  prior  notice  but  we  do  not  guarantee  that  the information  and  data  are  up  to  date  and  neither  do  we  undertake  to  provide  such  updates.  As property represents a serious commitment, all users are advised to seek professional advice before making  any  commitments  and  we  assume  no  responsibility  for  any  decision  that  the  user  may ultimately make.
            </p>
          </div>
        </Container>
      </>
    )
  }

  const RoiOverView = (props) => {
    let roiDatas = props.roi
    return (
      <Container>
        <div className="property-detail-table-padding">
          <table className={tableClass}>
            <thead>
              <tr>
                <th>Year Of</th>
                <th>Average Price</th>
                <th>Calculated gain</th>
              </tr>
            </thead>
            <tbody>
              {roiDatas.map((insight, idx) => {
                let roiGain = 0;
                if ((idx + 1) !== roiDatas.length) {
                  roiGain = (((insight.mean - roiDatas[idx + 1].mean) / roiDatas[idx + 1].mean) * 100).toFixed(2);
                }
                return (
                  <tr key={insight._id} >
                    <td>{insight.year}</td>
                    <td><NumberFormat displayType={"text"} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} prefix={"RM "} value={insight.mean} readOnly /></td>
                    <td>{showCurrentYear() != insight.year && roiGain !== 0 && <div style={{ "color": roiGain > 0 ? "green" : "red" }}>{roiGain}% </div>}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div>
          <p className="property-detail-table-disclaimer">
            <b>Disclaimer</b>
          </p>
          <p className="property-detail-table-disclaimer-paragraph">
            The  information,  specifications,  visual  presentations  and  data  contained  in  our  platform  may  be sourced  from  various  third  party  sources.  We  do  not  give  any  warranty  or  representation  and  shall not be deemed to have given any warranty or representation as to the accuracy or general veracity of  the  information  specifications,  visual  presentations  and  data,  and  we  do  not  assume  any responsibility,  liability  or  obligations  whatsoever  towards  any  user  of  our  platform  for  any  losses, costs, expenses or liabilities whatsoever that such user may suffer or incur by reason of reliance on such  information,  data  and  other  contents.  The  information,  data  and  the  contents  as  presented here  are  subject  to  updates  and  changes  without  prior  notice  but  we  do  not  guarantee  that  the information  and  data  are  up  to  date  and  neither  do  we  undertake  to  provide  such  updates.  As property represents a serious commitment, all users are advised to seek professional advice before making  any  commitments  and  we  assume  no  responsibility  for  any  decision  that  the  user  may ultimately make.
          </p>
        </div>
      </Container>
    )
  }

  const TransactionHistoryTable = () => {
    const ITEMS_PER_PAGE = 20;

    const transHistoryData = insight ? insight.data.transactionsHistory : [];
    const totalData = insight ? insight.data.totalData : 0;
    const tableHeader = [
      {
        title: "Date",
        width: "10%"
      },
      {
        title: "Address",
        width: ""
      },
      {
        title: "Rooms",
        width: ""
      },
      {
        title: "Price",
        width: ""
      },
      {
        title: "Buildup (sq. ft.)",
        width: ""
      },
    ];

    const currentItems = transHistoryData?.slice(0, ITEMS_PER_PAGE);

    const renderTableRows = () => {
      return currentItems?.map((item) => {
        const { _id, date, rooms, address, price, buildup } = item;
        return (
          <tr key={_id}>
            <td>{moment(date).format("DD/MM/YYYY")}</td>
            <td>{address}</td>
            <td>{rooms === 0 ? '-' : rooms}</td>
            <td>
              {
                <NumberFormat
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"RM "}
                  value={price}
                  readOnly
                />
              }
            </td>
            <td>{buildup ? buildup.toFixed(2) : 0}</td>
          </tr>
        );
      });
    };

    return (
      <div className="property-detail-table-padding">
        <Table striped bordered hover className="transaction-history">
          <thead>
            <tr>
              {tableHeader.map((header, index) => (
                <th key={index} width={header.width}>
                  {header.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </Table>
        <p style={{ display: "flex", justifyContent: "end" }}>
          {currentItems.length +
            " out of " +
            totalData +
            " transactions"}
        </p>
      </div>
    );
  };

  const TransactionHistoryOverView = () => {
    return (
      <>
        <Container>
          <Row>
            <Col md={6} sx={12}>
              <p className="sales-trend-gain-text">Calculated Gain is formulized based on annualized average transacted price compared with previous year.</p>
            </Col>
            <Col md={6} sx={12}>
              <div className="sales-trend-gain-result">
                <p className="sales-trend-gain-title">Calculated Gain</p>
                {insight ? <p className="sales-trend-gain-percent" style={{ color: insight.data.roi > 0 ? "#00A82A" : "red" }}><NumberFormat displayType={"text"} fixedDecimalScale={true} decimalScale={2} value={insight.data.roi} suffix={"%"} readOnly /></p> : "-"}
                {/* {roiData[0]?.year == showCurrentYear() ? "-" : <p className="sales-trend-gain-percent" style={{ color: roiPercent > 0 ? "#00A82A" : "red" }}><NumberFormat displayType={"text"} fixedDecimalScale={true} decimalScale={2} value={roiPercent} suffix={"%"} readOnly /></p>} */}
              </div>
            </Col>
          </Row>
          <TransactionHistoryTable />
          {/* <th>Price per sqft</th> */}
          {/* <div className="property-detail-table-padding">
            <table className={tableClass}>
              <thead>
                <tr>
                  <th>Sold On</th>
                  <th>Address</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {overviewInsight.transactionHistory.map((insight, idx) => {
                  return (
                    <tr key={insight._id}>
                      <td>{moment(insight.eventDate).format("DD/MM/YYYY")}</td>
                      <td>{insight.formattedAddress}</td>
                      <td><NumberFormat displayType={"text"} thousandSeparator={true} prefix={"RM "} value={insight.price} readOnly /></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div> */}
          <div>
            <p className="property-detail-table-disclaimer">
              <b>Disclaimer</b>
            </p>
            <p className="property-detail-table-disclaimer-paragraph">
              The  information,  specifications,  visual  presentations  and  data  contained  in  our  platform  may  be sourced  from  various  third  party  sources.  We  do  not  give  any  warranty  or  representation  and  shall not be deemed to have given any warranty or representation as to the accuracy or general veracity of  the  information  specifications,  visual  presentations  and  data,  and  we  do  not  assume  any responsibility,  liability  or  obligations  whatsoever  towards  any  user  of  our  platform  for  any  losses, costs, expenses or liabilities whatsoever that such user may suffer or incur by reason of reliance on such  information,  data  and  other  contents.  The  information,  data  and  the  contents  as  presented here  are  subject  to  updates  and  changes  without  prior  notice  but  we  do  not  guarantee  that  the information  and  data  are  up  to  date  and  neither  do  we  undertake  to  provide  such  updates.  As property represents a serious commitment, all users are advised to seek professional advice before making  any  commitments  and  we  assume  no  responsibility  for  any  decision  that  the  user  may ultimately make.
            </p>
          </div>
        </Container>
      </>
    )
  }

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  const ModalContact = (props) => {
    const { className } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    const closeBtn = (
      <button className="close" onClick={toggle}>
        &times;
      </button>
    );

    return (
      <div className="agent-info-detail" >
        <Button className="contact-agent-button" onClick={toggle}>
          {t('contact-agent')}
        </Button>
        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle} close={closeBtn}>
            {t('contact-agent')}
          </ModalHeader>
          <ModalBody>
            <div className="btn-app">
              <Row>
                <Col sm="6" md="6" className="my-3">
                  <Button
                    className="nav-link contactlinks contactphone"
                    onClick={handlePhone}
                  >
                    {" "}
                    <IoMdPhonePortrait />
                    <br />
                    <small>{agents.mobile}</small>
                  </Button>
                </Col>
                <Col sm="6" md="6" className="my-3">
                  <Button
                    className="nav-link wassapGreen contactlinks"
                    onClick={handleWhatApps}
                  >
                    <IoLogoWhatsapp />
                    <br />
                    WhatsApp
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  const UrlNav = (props) => {
    const { property } = props;
    if (property?.for) {
      const urlFor = `/properties-for-${property.for === 'Rent' ? 'rent' : 'sale'}`;
      const urlState = `${urlFor}/${seoComponent.treatState(property.address?.state)}`;
      const urlArea = `${urlState}/${seoComponent.treatArea(property.address?.area)}`;
      const urlTitle = `${urlArea}/${seoComponent.treatTitleOrType(property?.title)}`;
      const urlType = `${urlArea}/${seoComponent.treatTitleOrType(property?.type)}`;

      const breadcrumbData = {
        home: {
          title: "Home",
          url: "/",
        },
        for: {
          title: `For ${property?.for === 'Rent' ? 'Rent' : 'Sale'}`,
          url: urlFor,
        },
        state: {
          title: property.address?.state,
          url: urlState,
        },
        area: {
          title: property.address?.area,
          url: urlArea,
        },
        title: {
          title: urlTitle !== urlType ? property?.title : undefined,
          url: urlTitle
        },
        type: {
          title: property?.type,
          url: urlType
        },
      }

      return <CustomBreadcrumb location={location} items={breadcrumbData} />

      // return <div className="url-path">
      //   <a href="/">Home</a>{' > '}
      //   <a href={`${urlFor}`}>For {property?.for === 'Rent' ? 'Rent' : 'Sale'}</a>{' > '}
      //   <a href={urlState}>{property.address?.state}</a>{' > '}
      //   <a href={urlArea}>{property.address?.area}</a>{' > '}
      //   {urlTitle !== urlType && <><a href={urlTitle}>{property?.title}</a>{' >  '}</>}
      //   <a href={urlType}>{property?.type}</a>
      //   {/* <a href={`${urlFor}/malaysia`}>{property.for === 'Rent' ? 'For Rent' : 'For Sale'}</a> */}
      //   {/* For {property.for === 'Rent' ? 'Rent' : 'Sale'} */}
      // </div>;

      // const titleOrType = seoComponent.treatTitleOrType(property?.type);
      // const state = seoComponent.treatState(property.address?.state);
      // const area = seoComponent.treatArea(property.address?.area);
      // const urlFor = `/properties-for-${property.for === 'Rent' ? 'rent' : 'sale'}`;
      // const urlType = `${urlFor}/malaysia/${titleOrType}`;
      // const urlState = `${urlFor}/${state}/${titleOrType}`;
      // const urlArea = `${urlFor}/${state}/${area}/${titleOrType}`;

      // return <div className="url-path">
      //   <a href="/">Home</a>{' > '}
      //   <a href={urlType}>{property?.type}</a>{' > '}
      //   <a href={urlState}>{property.address?.state}</a>{' > '}
      //   <a href={urlArea}>{property.address?.area}</a>{' > '}
      //   For {property.for === 'Rent' ? 'Rent' : 'Sale'}
      // </div>;
    } return null;
  };

  const propertyInfo = (
    <div className="cr-page property-detail-bg-color">
      <div className="back-to-search-div">
        <Container>
          <div className="back-url-div">
            <div className="back-to-search-span" onClick={() => window.history.back()}>
              <span><img loading="lazy" src={back} className="img-back-logo" alt="" />{t('back')} </span>
            </div>

            {/* <div className="url-path">
              {properties && (properties.address?.state + " > " + properties.address?.area + " > " + properties.type + " > ")}{properties.for == "Rent" ? "For Rent" : "For Sale"}
            </div> */}

            {/* {performance.navigation.type > 0 && <div className="back-to-search-span" onClick={() => window.history.back()}>
              <span><img src={back} className="img-back-logo" alt="" />{t('back')} </span>
            </div>} */}

            <UrlNav property={properties} />
          </div>
        </Container>
      </div>
      {properties.images && properties.images.length > 0 && (
        <>
          {/* <div className="bg-black"> */}
          <div>
            <Container className="property-detail-slider-image-container" >
              <SliderImg
                images={properties.images}
                vrLink={properties.vrLink}
                videoLink={properties.videoLink}
                originalAlt={properties && (properties.type + " " + (properties.for == "Rent" ? "For Rent" : "For Sale") + " in " + properties.address?.area + " " + properties.address?.state.replace(`Wilayah Persekutuan`, ``).trim())}
              />
            </Container>
            <Container className="property-detail-overlay-icon-bar">
              <div className="overlayicon">
                <div className="slideicons">
                  {properties.images && properties.images.length > 0 && (
                    <Gallery sources={properties.images} type="Image" />
                  )}
                  {properties.videoLink && (
                    <Gallery sources={properties.videoLink} type="Video" custom />
                  )}
                  {properties.vrLink && (
                    <Gallery sources={properties.vrLink} type="Vr" custom />
                  )}

                </div>
              </div>
            </Container>
          </div>
        </>
      )}

      <Container className="px-3 py-3">
        {/* className="property-detail-category-tab-div" */}
        <section ref={sectionRefs[0]} id="overview" className="property-detail-section" />
        <div className="property-detail-category-div">
          {properties &&
            <button onClick={(e) => onOverviewCategoryClick(e)} className={activeSection === 0 ? "property-detail-category-button-focus" : "property-detail-category-button"}
            >Overview</button>}
          {properties.for === "Buy" &&
            (properties.title === "Condo/Serviced Residence" ||
              properties.title === "Apartment/Flat") &&
            insight?.data?.transactionsHistory?.length !== 0 &&
            // {(properties.for == "Buy" || (properties.for == "Buy" && properties.categoryListing == "Residential")) &&
            <button onClick={(e) => onInsightCategoryClick(e)} className={activeSection === 1 ? "property-detail-category-button-focus" : "property-detail-category-button"}
            >Nextsix insight</button>}
          {((similarListing && similarListing.length > 0) || (otherListing && otherListing.length > 0)) &&
            <button onClick={(e) => onRecommendationCategoryClick(e)} className={activeSection === 2 ? "property-detail-category-button-focus" : "property-detail-category-button"}
            >Recommendation</button>
          }
          <hr className="property-detail-hr-line-under-button" />
        </div>
        <Card className="Card-PropertyDetail-Info-Styling">
          <Row>
            <Col xl="8" lg="8" md="12" sm="12" xs="12">
              <div className="property-row-iconsGroup2-wrapper property-row-iconsGroup2-wrapper-v2">
                {properties.isBestDeal &&
                  <div className="property-row-saleTag2 bestDeal">
                    <img loading="lazy" src={thumbup} className="bestDeal-icon" alt="" />
                    <span>Best Deal</span>
                  </div>
                }
                {properties.rentType === 'Room Only' && <div className="property-row-saleTag2 rent-type">
                  {properties.rentType}
                </div>}
              </div>
              <p className="lightgreytext fontxs property-detail-publishdate property-detail-publishdate-v2">
                <small>{`${t('last-updated-on')} ${moment(properties.updated).format("DD/MM/YYYY")}`}</small>
              </p>
              <div className="property-detail-pricelabel">
                {properties.for && properties.for.toLowerCase() === "rent" && (
                  <p>
                    RM{" "}
                    {parseFloat(properties.rent.monthlyRental).toLocaleString()}
                    /{t('month')}
                  </p>
                )}
                {properties.for && properties.for.toLowerCase() === "buy" && (
                  <p>RM {parseFloat(properties.buy.price).toLocaleString()}</p>
                )}
              </div>
              <p className="m-0 greytext property-detail-address">
                <img loading="lazy" src={gpsgrey} alt="" /> {propertyComponent.formFullAddress(properties.address)}
              </p>
              <Row>
                <Col xl="10" lg="10" md="10" sm="12" xs="12">
                  <h1 className="property-detail-h1">{properties.name}</h1>
                </Col>
                <Col>
                  <span className="mt-3 align-self-center eye-padding">{properties.views &&
                    <span><MdRemoveRedEye />{"  "} {properties.views}</span>
                  }</span>
                </Col>
              </Row>

              {agents && properties && (
                <>
                  <Row className="Property-Icon-Info-Padding">
                    <Col lg="12" md="12" sm="12" className="align-self-center">
                      <Row className="iconfacilities">
                        {properties.beds != "Bedrooms" && properties.beds && (
                          <Col lg="2" md="2" sm="3" xs="3" className="mt-2 iconfacilities-padding">
                            <p className="fontsm m-0">
                              <img loading="lazy" src={orangebed} alt="" />
                              &nbsp; {properties.beds}
                            </p>
                            <small className="m-0">
                              <p className="property-detail-logo-icon-description">{t('bedrooms')}</p>
                            </small>
                          </Col>
                        )}
                        {properties.baths != 0 && properties.baths && (
                          <Col lg="2" md="2" sm="3" xs="3" className="mt-2 iconfacilities-padding">
                            <p className="fontsm m-0">
                              <img loading="lazy" src={orangeshower} alt="" />
                              &nbsp; {properties.baths}
                            </p>
                            <small className="m-0">
                              <p className="property-detail-logo-icon-description">{t('bathrooms')}</p>
                            </small>
                          </Col>
                        )}
                        {properties.carParks !== null && properties.carParks !== undefined && (
                          <Col lg="2" md="2" sm="3" xs="3" className="mt-2 iconfacilities-padding">
                            <p className="fontsm m-0">
                              <img loading="lazy" src={orangecar} alt="" />
                              &nbsp; {properties.carParks}
                            </p>
                            <small className="m-0">
                              <p className="property-detail-logo-icon-description">{t('carparks')}</p>
                            </small>
                          </Col>
                        )}
                        {(() => {
                          if (properties.title === 'Agricultural Land' || properties.title === 'Commercial Land'
                            || properties.title === 'Industrial Land' || properties.title === 'Residential Land') {
                            if (properties.landArea) {
                              return <Col lg="3" md="3" sm="3" xs="3" className="mt-2 iconfacilities-padding">
                                <p className="fontsm m-0">
                                  <img loading="lazy" src={landOrange} alt="" />
                                  &nbsp;
                                  {parseFloat(properties.landArea).toLocaleString()}
                                </p>
                                <small className="m-0">
                                  <p className="property-detail-logo-icon-description">{t('square-feet')}</p>
                                </small>
                              </Col>
                            }
                          } else {
                            if (properties.buildup) {
                              return <Col lg="3" md="3" sm="3" xs="3" className="mt-2 iconfacilities-padding">
                                <p className="fontsm m-0">
                                  <img loading="lazy" src={orangehouse} alt="" />
                                  &nbsp;
                                  {parseFloat(properties.buildup).toLocaleString()}
                                </p>
                                <small className="m-0">
                                  <p className="property-detail-logo-icon-description">{t('square-feet')}</p>
                                </small>
                              </Col>
                            }
                          }
                        })()}

                      </Row>
                    </Col>
                  </Row>
                  <div>
                    {properties.hightlight && (
                      <div className="py-3 property-detail-bg-color description-border">
                        <Container>
                          <h2 className="property-detail-h2">{t('property-highlights')}:</h2>
                          <p className="m-0" style={{ whiteSpace: "break-spaces" }}>
                            {properties.hightlight}
                          </p>
                        </Container>
                      </div>
                    )}
                    {properties.floorplans && properties.floorplans.length > 0 && (
                      <div className="py-3">
                        <Container>
                          <h2 className="property-detail-h2">Storey Plan:</h2>
                          {properties.floorplans && properties.floorplans.length > 0 && (
                            <>
                              {/* <div className="bg-black"> */}
                              <div className="property-detail-floorplan">
                                <Container className="property-detail-slider-image-container" >
                                  <SliderImg
                                    images={properties.floorplans}
                                    originalAlt={properties && (properties.type + " " + (properties.for == "Rent" ? "For Rent" : "For Sale") + " in " + properties.address?.area + " " + properties.address?.state.replace(`Wilayah Persekutuan`, ``).trim())}
                                  />
                                </Container>
                                <Container className="property-detail-overlay-icon-bar">
                                  <div className="overlayicon">
                                    <div className="slideicons">
                                      {properties.floorplans && properties.floorplans.length > 0 && (
                                        <Gallery sources={properties.floorplans} type="Image" />
                                      )}
                                    </div>
                                  </div>
                                </Container>
                              </div>
                            </>
                          )}
                        </Container>
                      </div>
                    )}
                    <div className="py-5">
                      <Container >
                        <hr />
                        {properties.loc && <>
                          {displayMap ?
                            <div className="Property-Detail-Map-Warp">
                              <GoogleMapReact
                                bootstrapURLKeys={{
                                  key: process.env.REACT_APP_GOOGLE_API_KEY,
                                  libraries: ['drawing'],
                                }}
                                defaultCenter={{
                                  lat: properties.loc.coordinates[1],
                                  lng: properties.loc.coordinates[0],
                                }}
                                // options={{mapId: '1880c81018f8f541'}}
                                defaultZoom={15}
                                yesIWantToUseGoogleMapApiInternals//
                                onGoogleApiLoaded={({ map, maps }) => ModelsMap(map, maps)}
                                options={{ gestureHandling: "greedy" }}
                              >
                              </GoogleMapReact>
                            </div>
                            :
                            <div className="Property-Detail-Map-Warp">
                              <img loading="lazy" onClick={() => setDisplayMap(true)} src={mapPreview} className="Property-Detail-Map-Warp-map-preview"></img>
                            </div>
                          }

                        </>}
                      </Container>
                      {/* <div id="mapp" className="Property-Detail-Map-Warp"></div> */}
                    </div>
                    <PropertyInfo />
                    {properties.description && (
                      <div className="bg-white">
                        <hr />
                        <Container>
                          <h2 className="property-detail-h2">{t('description')}</h2>
                          <p className="m-0" style={{ whiteSpace: "break-spaces" }}>
                            {properties.description}
                          </p>
                        </Container>
                        <hr />
                      </div>
                    )}
                    {properties.facilities && properties.facilities.length > 0 && (
                      <Card className="noborder ">
                        <div className="py-3">
                          <Container>
                            <h2 className="property-detail-h2">{t('facilities-list')}</h2>
                            <hr />
                            <Row>
                              {properties.facilities &&
                                properties.facilities.length > 0 &&
                                properties.facilities.map((facility) => {
                                  return (
                                    <Col lg="4" md="4" sm="4" xs="12" key={facility}>
                                      <Media className="mt-2 mt-2">
                                        <Media left className="align-self-center">
                                          {propertyComponent.facilities(facility)}
                                        </Media>
                                        <Media body className="align-self-center">
                                          <p className="m-0">{t(propertyComponent.facilitiesTranslateKey(facility))}</p>
                                        </Media>
                                      </Media>
                                    </Col>
                                  );
                                })}
                            </Row>
                          </Container>
                        </div>
                      </Card>
                    )}
                  </div>
                </>
              )}
            </Col>

            <Col >
              <Card className="AgentCard-Detail-Info" style={{ "position": "sticky", "top": "150px" }}>
                <AgentInfoDraft agents={agents} />
                <div className="AgentCard-Button">
                  <ModalContact />
                  <div className="agent-info-detail">
                    <Button
                      className="view-agent-button"
                      color="dark"
                      onClick={() => goToAgentListing(agents)}
                    >
                      <IoIosListBox /> {t('view-my-listing')}
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Card>

        <section id="calculator" className="property-detail-section" />
        {(properties.buy && properties.for === "Buy") &&
          <Card className="Card-OtherDetail-Info-Styling">
            <div style={{ padding: "15px" }}>
              <h2 style={{ margin: "15px 0 45px", fontSize: "20px" }}>Mortgage Calculator</h2>
              <MortgageCalculator callback={calculatorCallback} properties={properties} />
            </div>
          </Card>
        }

        <section ref={sectionRefs[1]} id="insight" className="property-detail-section" />
        {properties.for === "Buy" &&
          (properties.title === "Condo/Serviced Residence" ||
            properties.title === "Apartment/Flat") &&
          insight?.data?.transactionsHistory?.length > 0 &&
          // {(properties.for == "Buy" || (properties.for == "Buy" && properties.categoryListing == "Residential")) &&
          <Card className="Card-OtherDetail-Info-Styling">
            {/* <Row>
                <Col md={6} sx={12}>
                  <h6 className="other-listing-styling">Nextsix Insights</h6>
                </Col>
                <Col md={6} sx={12}>
                  <div className="table-tab-bar-div">
                    <button
                      onClick={() => setTabSelected("History")}
                      className={tabSelected === "History" ? "table-tab-button active" : "table-tab-button"}
                      disabled={(levelOfAvg && isNaN(levelOfAvg.percent))}
                    >
                    // Comment this as well if using this tab navigation
                      {turnOver ?
                    <div style={{ "color": turnOver == "High" ? "green" : "red" }}>
                      {turnOver} Turnover Rate
                    </div>
                    :
                    <div>
                      -
                    </div>
                  }
                    // Until here comment this as well if using this tab navigation
                      Transaction History
                    </button>
                    <button
                      onClick={() => setTabSelected("Graft")}
                      className={tabSelected === "Graft" ? "table-tab-button active" : "table-tab-button"}
                      disabled={(levelOfAvg && isNaN(levelOfAvg.percent))}
                    >
                      Price Trend
                    </button>
                  </div>
                </Col>
              </Row> */}
            {/* {tabSelected == "Graft" && overviewInsight?.salesTrend && overviewInsight?.salesTrend?.periods?.length > 0 && <SaleTrend />}
              {tabSelected == "Graft" && (levelOfAvg && isNaN(levelOfAvg.percent)) && <DataNotFound />} */}
            {/* {tabSelected == "ROI" && overviewInsight?.roi && overviewInsight?.roi?.length > 0 && <RoiOverView roi={roiData} />}
              {tabSelected == "ROI" && ((!roiData && !isNaN(roiPercent)) || roiPercent == null || roiPercent == undefined) && <DataNotFound />} */}
            {/* {tabSelected == "History" && overviewInsight?.transactionHistory && overviewInsight?.transactionHistory?.length > 0 && <TransactionHistoryOverView />}
              {tabSelected == "History" && !turnOver && <DataNotFound />} */}
            <h6 className="other-listing-styling">Nextsix Insights</h6>
            <TransactionHistoryOverView />
          </Card>
        }

        <section ref={sectionRefs[2]} id="recommendation" className="property-detail-section" />
        {similarListing && similarListing.length > 0 &&
          <Card className="Card-OtherDetail-Info-Styling">
            <h2 className="other-listing-styling">{t('similar-properties')}</h2>
            <Carousel
              breakPoints={breakPoints}
              // enableAutoPlay
              // autoPlaySpeed={4000}
              enableSwipe
              enableMouseSwipe
              pagination={false}
              showArrows={true}
              className="property-detail-otherlist-carousel"
            >
              {similarListing.filter(other => other._id.toString() !== properties._id.toString()).map((list) => {
                return (
                  <div
                    key={list._id}
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    className="mb-3 corousel-card-styling"
                  >
                    <a href={handlePopulateData(list)} className="property-row-redirectLink2" onClick={() => localStorage.setItem('from', "listing")}>
                      <PropertyCard property={list} type={'properties'}
                        // callback={handlePopulateData} 
                        showAgent={true} />
                    </a>

                  </div>
                )
              })}
            </Carousel>
          </Card>
        }

        {otherListing && otherListing.length > 0 &&
          <Card className="Card-OtherDetail-Info-Styling">
            <div className="other-listing-row">
              <h2 className="other-listing-styling">
                {
                  properties.for == "Rent" ? t('other-listing-master-rent', { master: properties.masterName })
                    : t('other-listing-master-sale', { master: properties.masterName })
                }
                ({otherListing.length})
              </h2>
              <button className="property-row-seeMoreButton2" style={{ paddingRight: "3%" }} onClick={() => handleSeeMore(properties)}>{t('see-more')}</button>
            </div>
            <Carousel
              breakPoints={breakPoints}
              // enableAutoPlay
              // autoPlaySpeed={4000}
              enableSwipe
              enableMouseSwipe
              pagination={false}
              showArrows={true}
              className="property-detail-otherlist-carousel"
            >
              {otherListing.filter(other => other._id.toString() !== properties._id.toString()).map((list) => {
                return (
                  <div
                    key={list._id}
                    xl={4}
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    className="mb-3 corousel-card-styling">
                    <a href={handlePopulateData(list)} className="property-row-redirectLink2" onClick={() => localStorage.setItem('from', "listing")}>
                      <PropertyCard property={list} type={'properties'}
                        // callback={handlePopulateData} 
                        showAgent={true} />
                    </a>
                  </div>
                )
              })}
            </Carousel>
          </Card>
        }

        {otherSearches && otherSearches.length > 0 &&
          <Card className="Card-OtherDetail-Info-Styling">
            <h2 className="other-listing-styling">{t('other-searches')} in {properties?.address?.area}</h2>
            <div style={{ paddingTop: "30px", paddingLeft: "30px" }}>
              <div >
                <Row>
                  {otherSearches.map(item => (
                    <Col key={`${item.for}_${item.place}`} className="d-flex" style={{ flex: "0 0 calc(33.33%)", marginBottom: "15px" }}>
                      <h3 className="property-other-searches-h3">
                        <a id="otherSearches" href='#' onClick={(event) => changeHref(event, item)}>
                          {!item.place ?
                            <>Properties for {item.for === "Buy" ? "sale" : item.for.toLowerCase()} in {properties.address.area}</> :
                            <>{string.capitalize(item.place)} for {item.for === "Buy" ? "sale" : item.for.toLowerCase()} in {properties.address.area}</>}
                        </a>
                      </h3>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </Card>
        }
      </Container>
    </div>
  );

  // useEffect(() => {
  //   dispatch(close("open"));
  // }, [])

  const closeBanner = () => {
    dispatch(close("close"));
  }

  return (
    <>
      {/* <Navbar /> */}
      {
        getwidth < 768 && emblem === "open" &&
        <div className="emblem-wrapper">
          <div className="emblem-left-content">
            <Button className="emblem-close-btn" onClick={() => closeBanner()}><MdClose /></Button>
            <img loading="lazy" className="emblem-logo" src={nextsixAppEmblem} alt="" />
            <div className="emblem-left-textbox">
              <span className="emblem-left-text-top">NextSix App</span>
              <span className="emblem-left-text-btm">Find properties and agents faster</span>
            </div>
          </div>
          <div className="emblem-right-content">
            <a className="emblem-download-btn" href="https://apps.nextsix.com/users/reg">Use Now</a>
          </div>
        </div>
      }
      <Header2 />
      <LoadingIndicator />
      <Container fluid className="property-list-empty">
      </Container>
      {/* NXT-1876 */}
      {propertyInfo}
      {/* {mode ? propertyInfo : <PageError />} */}
      <Footer2 />
    </>
  );
};

export default PropertyDraftPage;
