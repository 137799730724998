import React, { useState, useEffect } from 'react';
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";
import agentComponent from '../businesses/agent'
import lib from '../businesses/lib';
import Highlighter from 'react-highlight-words';
import '../styles/components/agentSuggestion.scss';
import Avatar from "../components/Avatar";
import building from "../assets/img/newbuilding.svg"
import pointer from "../assets/img/newpointer.svg"

const AgentSuggestion = (props) => {
    const [suggest, setSuggest] = useState([]);
    const [suggestResponse, setSuggestResponse] = useState({});
    const [busy, setBusy] = useState(false);
    const [query, setQuery] = useState(props.defaultText || '');

    const highlightStyle = {
        color: 'black',
        padding: '0px',
        backgroundColor: 'transparent',
        fontWeight: '410'
    };

    useEffect(() => {
        getSuggestion();
    }, [query]);

    useEffect(() => {
        if (suggestResponse?.keyword == query) {
            setSuggest(suggestResponse.data);
        }
    }, [suggestResponse]);

    const getSuggestion = async () => {
        setBusy(true);
        setSuggest([]);
        let result;
        if (query?.length >= 2) {
            result = await agentComponent.agentSuggestion(query);
            lib.log("agent suggestion")
            lib.log(result)
            setSuggestResponse(result);
        }
        setBusy(false);
    };

    const handleChange = (val) => {
        lib.log('on change')
        lib.log(val)
        if (typeof val === 'string') {
            setQuery(val);
            if (props.callback) props.callback({
                id: props.callbackId,
                value: val
            });
        }
    };

    const handleSelect = (val) => {
        lib.log('on select');
        lib.log(val)
        if (suggest?.length > 0 && props.callback) {
            setQuery(val.nickname)
            props.callback({
                id: props.callbackId,
                value: val.nickname
            });
        };
    }

    const handleSearchEnter = (e) => {
        if (e.key.toLowerCase() === "enter" && props.searchEnter) {
            lib.log('Enter is pressed')
            props.searchEnter();
        }
    };

    return (
        <Combobox
            className="agentSuggestion"
            busy={busy}
            hideCaret={!busy}
            hideEmptyPopup
            data={suggest}
            placeholder={"Search by agent"}
            textField={() => (query)}
            filter={false}
            onChange={handleChange}
            onSelect={handleSelect}
            renderListItem={({ item }) => (
                <>
                    <Avatar src={item.image} className="mb-2" />{" "}
                    {/* hide name */}
                    {/* <Highlighter className="suggestion-title1" searchWords={[query]} textToHighlight={item.name} highlightStyle={highlightStyle} autoEscape={true} />{" "} */}
                    <Highlighter className="suggestion-title1" searchWords={[query]} textToHighlight={item.nickname} highlightStyle={highlightStyle} autoEscape={true} />
                    {item.areaSpecialists?.length > 0 && (
                        <div className="as-tag-info">
                            <span className="as-tag">Area Specialist</span>
                            <img className="" alt="" src={pointer} />{" "}
                            {item.areaSpecialists.map((obj, i) => {
                                return (
                                    <>
                                        <span className="agentPa" key={i}>{obj.area}</span>
                                    </>
                                )
                            })}
                        </div>
                    )}
                    {item.titledSpecialists?.length > 0 && (
                        <div className="ts-tag-info">
                            <span className="ts-tag">Title Specialist</span>
                            <img className="" alt="" src={building} />{" "}
                            {item.titledSpecialists.map((obj, i) => {
                                return (
                                    <>
                                        <span className="ts-tag-title" key={i}>{obj.title}{i === item.titledSpecialists.length - 1 ? "" : ", "}</span>
                                    </>
                                )
                            })}
                        </div>
                    )}
                </>
            )}

            onKeyUp={handleSearchEnter}
        />
    )
};

export default AgentSuggestion;