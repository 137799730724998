import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, InputGroup, Input, Col } from 'reactstrap';

const TextInput = (props) => {
    const generate = () => {
        const element = (
            <InputGroup className="d-flex align-items-center">
                <Label for={props.name} xl={{ size: 2, offset: 1 }} sm={12}>{(props.label) ? props.label : props.placeholder}{(props.required)? <span style={{color:"red"}}>*</span>:''} :</Label>
                <Col xl={8} sm={12}>
                    <Input type="text" className="form-control" name={props.name} placeholder={props.placeholder}
                        onChange={onInputChanged} value={props.value} readOnly={props.readOnly || false} disabled={props.disabled ? true : false}
                        style={props.style}
                    />
                </Col>
            </InputGroup>
        );

        if (!props.noDiv) {
            return (
                <FormGroup row>
                    {element}
                </FormGroup>
            );
        }

        return element;
    }

    const onInputChanged = (event) => {
        if (props.callback) props.callback({
            id: props.callbackId,
            value: event.target.value
        });
    };

    return (
        generate()
    );
};

TextInput.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    callback: PropTypes.func,
    callbackId: PropTypes.string,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    noDiv: PropTypes.bool
};

export default TextInput;