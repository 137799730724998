import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom'
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalFooter,
    Navbar,
    NavbarBrand,
    ModalBody,
    Container,
} from "reactstrap";
import "../styles/pages/aboutUs.scss";
import { trackPromise } from "react-promise-tracker";
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";
import LoadingIndicator from "../components/LoadingIndicator";
import checkWithCircle from "../assets/agentApplication/icon check with circle.svg";
import phone from "../assets/img/phone-about-us-normalsize.png";
import instantConnectionLogo from "../assets/img/instantconnection.svg";
// import bronzeKetupatLogo from "../assets/img/Bronze.svg";
import realTimeLogo from "../assets/img/realtime.svg";
import indepth from "../assets/img/indepth.svg";
import threedshow from "../assets/img/3dshow.svg";
import homepro from "../assets/img/homepro_homemall.svg";
import simplify from "../assets/img/about_desc.svg";
import appleapp from "../assets/img/store-icon-ios.png"
import andriodapp from "../assets/img/store-play.png"
import nextsixAppEmblem from "../assets/home/nextsix-app-emblem.png";
import { MdClose } from "react-icons/md";
import { close } from "../redux/actions/emblemActions";
import { useSelector, useDispatch } from 'react-redux';


const AboutUsPage = (props) => {
    const dispatch = useDispatch();
    const emblem = useSelector(state => state.emblem);
    const [getwidth, setGetWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setGetWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
    });

    const today = new Date()
    const etaStart = new Date('2023-04-25T16:00:00.000Z')
    const etaEnd = new Date('2023-04-30T16:00:00.000Z')
    // console.log(today,etaStart,etaEnd)
    // if((etaStart<=today)&&(today<=etaEnd)){
    //     console.log("yes line 75")
    // }

    // useEffect(() => {
    //     dispatch(close("open"));
    //   }, [])

    const closeBanner = () => {
        dispatch(close("close"));
    }

    return (
        <>
            <Header2 />
            {/* <LoadingIndicator /> */}
            {
                getwidth < 768 && emblem === "open" &&
                <div className="emblem-wrapper">
                    <div className="emblem-left-content">
                        <Button className="emblem-close-btn" onClick={() => closeBanner()}><MdClose /></Button>
                        <img loading="lazy" alt="" className="emblem-logo" src={nextsixAppEmblem} />
                        <div className="emblem-left-textbox">
                            <span className="emblem-left-text-top">NextSix App</span>
                            <span className="emblem-left-text-btm">Find properties and agents faster</span>
                        </div>
                    </div>
                    <div className="emblem-right-content">
                        <a className="emblem-download-btn" href="https://apps.nextsix.com/users/reg">Use Now</a>
                    </div>
                </div>
            }
            <Container className="about-us-warpper">
                <div className="about-us-content">
                    <div className="about-us-content-titleGroup">
                        <span className="about-us-content-firstTitle">
                            Nextsix, a comprehensive platform that connects property buyers/tenants to professional agents through <span className="about-us-content-firstTitle-orange">#GPSYourAgent</span>.
                        </span>
                    </div>

                </div>
            </Container>

            <Container className="about-us-description-section">
                <div className="left-wrapper">
                    <h1>Simplify Your Property<br />Search with Nextsix's<br />#GPSYourAgent Algorithm</h1>
                    <div className="bottom-wrapper">
                        <div className="image-container">
                            <img loading="lazy" alt="" src={simplify} />
                        </div>
                    </div>
                </div>

                <div className="right-wrapper">
                    <p className="subtitle">Finding the perfect property agent amidst a sea of options can be daunting. However, Nextsix offers a groundbreaking solution: introducing #GPSYourAgent. This innovative platform connects property buyers and tenants with the ideal agent that perfectly aligns with their needs.</p>
                    <p className="subtitle">Powered by a cutting-edge algorithm, #GPSYourAgent is a comprehensive platform that efficiently matches clients with agents based on their location, property type, and unique requirements. Once a suitable match is found, clients can easily connect with their designated property agents through the platform, streamlining the entire process and eliminating unnecessary hassle.</p>
                    <p className="subtitle">The advantages of utilizing #GPSYourAgent are manifold. It saves clients valuable time and effort and ensures they are paired with the most suitable property agent with the expertise and knowledge required for their property transaction. Consequently, this increases the likelihood of a smoother and more successful outcome.</p>
                    <p className="subtitle">As a leading property technology company, Nextsix has earned a stellar reputation for delivering exceptional customer service. Our user-friendly and intuitive #GPSYourAgent platform exemplifies our commitment to providing an unparalleled experience for individuals seeking to purchase or rent a property.</p>
                    <p className="subtitle m-0">In conclusion, searching for the right property agent can be stressful. However, Nextsix's revolutionary #GPSYourAgent platform simplifies this process like never before. With its groundbreaking algorithm and user-friendly interface, #GPSYourAgent emerges as the ultimate solution for individuals pursuing their ideal agent. Embark on your journey to discover remarkable houses for <Link to="properties-for-rent/kuala-lumpur/condo-serviced-residence" target="_blank" rel="noopener noreferrer">rent</Link> or <Link to="properties-for-sale/kuala-lumpur/terrace-link-townhouse" target="_blank" rel="noopener noreferrer">sale</Link> in <Link to={{ pathname: "https://mydigitalfeed.com/advantages-of-purchasing-a-sub-sale-house-in-kuala-lumpur/" }} target="_blank" rel="noopener noreferrer">Kuala Lumpur</Link> by leveraging the power of our exceptional #GPSYourAgent platform today.</p>
                </div>
            </Container>

            <Container className="about-us-info-row">
                <Row>
                    <Col xl="9" lg="9" md="9" sm="12" xs="12">
                        <div className="about-us-second-row-title">
                            “GPS your home, you deserve a better deal”
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xl="6" lg="6" md="6" sm="12" xs="12">

                        <div className="about-us-second-row-description">
                            is Nextsix’s motto since inception. The company hopes to elevate property seekers’ experience when searching for their dream homes. Be it to purchase, to rent or to invest.
                        </div>
                    </Col>
                    {/* for mobile ? use col 2? */}
                    <Col className="about-us-second-row-background">

                    </Col>
                </Row>

                <Row className="about-us-third-row">
                    <Col xl="12" lg="12" md="12" sm="12" xs="12">
                        <div className="about-us-third-row-title">The main feature</div>
                        <div className="about-us-third-row-description">GPS Your Agent</div>
                    </Col>

                    <Col xl="5" lg="6" md="6" sm="12" xs="12">
                        <Row>
                            {getwidth < 1200 &&
                                <Col xl="3" lg="3" md="3" className="about-us-third-row-logo-col">
                                    <div className="about-us-third-row-logo-div">
                                        <img loading="lazy" alt="" src={instantConnectionLogo} className="about-us-third-row-logo" />

                                    </div>
                                </Col>
                            }

                            <Col xl="9" lg="9" md="9">

                                <div className="about-us-third-row-info-left">
                                    <span className="about-us-third-row-info-left-title">
                                        Instant Connections
                                    </span>
                                    <br />

                                    <span className="about-us-third-row-info-left-description">
                                        instantly connects homebuyers with the suitable estate agents within preferred vicinity.
                                    </span>

                                </div>

                            </Col>

                            {getwidth > 1200 &&
                                <Col xl="3" lg="3" className="about-us-third-row-logo-col">
                                    <div className="about-us-third-row-logo-div">
                                        <img loading="lazy" alt="" src={instantConnectionLogo} className="about-us-third-row-logo" />

                                    </div>
                                </Col>
                            }
                        </Row>
                        <Row>
                            {getwidth < 1200 &&
                                <Col xl="3" lg="3" md="3" className="about-us-third-row-logo-col">
                                    <div className="about-us-third-row-logo-div">
                                        <img loading="lazy" alt="" src={realTimeLogo} className="about-us-third-row-logo" />
                                    </div>
                                </Col>
                            }
                            <Col xl="9" lg="9" md="9" >
                                <div className="about-us-third-row-info-left">
                                    <span className="about-us-third-row-info-left-title">
                                        Real Time Up-To-Date
                                    </span>
                                    <br />

                                    <span className="about-us-third-row-info-left-description">
                                        Homebuyers are able to now enquire first hand information regarding the properties they are looking for.
                                    </span>
                                </div>

                            </Col>
                            {getwidth > 1200 &&
                                <Col xl="3" lg="3" className="about-us-third-row-logo-col">
                                    <div className="about-us-third-row-logo-div">
                                        <img loading="lazy" alt="" src={realTimeLogo} className="about-us-third-row-logo" />
                                    </div>
                                </Col>
                            }
                        </Row>

                    </Col>
                    <Col xl="2" lg="2" md="12" sm="12" xs="12" className="about-us-third-row-phone-image">
                        <div className="about-us-third-row-phone-image-center">
                            <img loading="lazy" alt="" src={phone} />
                        </div>
                    </Col>
                    <Col xl="5" lg="6" md="6" sm="12" xs="12">

                        <Row>
                            <Col xl="3" lg="3" md="3" className="about-us-third-row-logo-col">
                                <div className="about-us-third-row-logo-div-right">
                                    <img loading="lazy" alt="" src={indepth} className="about-us-third-row-logo" />
                                </div>
                            </Col>
                            <Col xl="9" lg="9" md="9">

                                <div className="about-us-third-row-info-right">
                                    <span className="about-us-third-row-info-right-title">
                                        In-depth research
                                    </span>
                                    <br />

                                    <span className="about-us-third-row-info-right-description">
                                        This has proven to help homebuyers to do more in-depth research, before making THE decision to commit!
                                    </span>

                                </div>

                            </Col>

                        </Row>
                        <Row>
                            <Col xl="3" lg="3" md="3" className="about-us-third-row-logo-col">
                                <div className="about-us-third-row-logo-div-right">
                                    <img loading="lazy" alt="" src={threedshow} className="about-us-third-row-logo" />
                                </div>
                            </Col>
                            <Col xl="9" lg="9" md="9" >
                                <div className="about-us-third-row-info-right">
                                    <span className="about-us-third-row-info-right-title">
                                        Video Showcase & 3D Home Tour
                                    </span>
                                    <br />

                                    <span className="about-us-third-row-info-right-description">
                                        Within Nextsix App, property listings also include direct video showcase and 3D Home Tour viewing features.
                                    </span>
                                </div>

                            </Col>
                        </Row>

                    </Col>
                </Row>

                <Row className="about-us-row-fourth">
                    <Col xl="5" lg="6" md="6" sm="12" xs="12">
                        <div className="about-us-row-fourth-image-div">
                            <img loading="lazy" alt="" src={homepro} className="about-us-row-fourth-image" />
                        </div>
                    </Col>
                    <Col xl="7" lg="6" md="6" sm="12" xs="12">
                        <div className="about-us-row-fourth-title">
                            More Great Things Are Coming
                        </div>
                        <div className="about-us-row-fourth-description">
                            with the launch of HomeMall and HomePro, where Nextsix App users are now able to purchase property-related products (e.g. furniture, lightings, electrical appliances etc) through HomeMall, and property-related services (e.g. lawyer agreement services, bank mortgage loan, interior design, renovation etc) through HomePro.
                        </div>
                    </Col>
                </Row>

                <Row className="about-us-row-five" >
                    <Col xl="6" lg="6" md="12" sm="12" xs="12" className="about-us-row-five-video">

                        <iframe
                            className="responsive-iframe"
                            src="https://www.youtube.com/embed/qidABEqBwaA"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media;gyroscope; picture-in-picture"
                            allowFullScreen
                            title="about us"
                        ></iframe>
                    </Col>
                    <Col>
                        <div className="about-us-row-five-title">
                            Nextsix aims to provide an integrated experience to its users
                        </div>

                        <div className="about-us-row-five-description">
                            and make the journey of owning a property as enjoyable and easy as it gets. Because, that’s how it should be when purchasing a dream home!
                        </div>

                        <div className="about-us-row-five-app-logo">
                            <span><a href="https://apps.apple.com/my/app/nextsix/id1500782680" target="_blank" rel="noopener noreferrer"><img loading="lazy" alt="" src={appleapp} className="about-us-row-five-app-logo-size" /></a></span>
                            <span><a href="https://play.google.com/store/apps/details?id=com.nextsix.property" target="_blank" rel="noopener noreferrer"><img loading="lazy" alt="" src={andriodapp} className="about-us-row-five-app-logo-size" /></a></span>
                        </div>
                    </Col>
                </Row>
            </Container>



            <Footer2 />
        </>
    )
};

export default AboutUsPage;
