import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

let lang = 'en';
let root = localStorage.getItem('persist:root');
if (root) {
    const rootObj = JSON.parse(root);
    if (rootObj.lang) {
        const rootLang = rootObj.lang.replaceAll('"', '');
        const idxOf = 'en;bm;zh;'.indexOf(`${rootLang};`);
        //console.log(idxOf);
        if (idxOf >= 0) lang = rootLang;
    }
}
//console.log(`so we do have ${lang}`);

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: lang,
        backend: {
            /* translation file path */
            loadPath: '/{{ns}}/{{lng}}.json'
        },
        fallbackLng: 'en',
        debug: false,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        // react: {
        //     wait: true
        // }
    });

export default i18n;