import apiProject from '../api/ProjectAPI';
import regex from "../businesses/regex";
import lib from "../businesses/lib";

const id = async (id) => {
    const project = await apiProject.id(id);
    return project;
};

const projectWebSearch =  async (search, paging) => {
    const properties = await apiProject.projectWebSearch(search, paging);
    return properties;
};

const save = async (contactForm) => {
    //tbd
    return await apiProject.save(contactForm);
  };

const validate = async (contactForm) => {
    //tbd test
    lib.log(contactForm)

    try {
      if (!contactForm.name) return invalid("Please enter your name.");
      //tbd
      if (!regex.isAlphabet(contactForm.name))
        return invalid("Invalid name. Please input alphabet characters only.");
      if (!contactForm.email) return invalid("Please enter your email.");
      if (!regex.isValidEmail(contactForm.email))
        return invalid("Invalid email address entered.");
      if (!contactForm.mobile) return invalid("Please enter your contact number.");
      if (!regex.isValidMobile(contactForm.mobile))
        return invalid("Invalid mobile number format.");
      // if (!contactForm.identity) return invalid("Please select one identity")
      // if (!contactForm.title) return invalid("Please enter content you want to enquiry about")
    } catch (error) {
      lib.log(error);
    }
    return {
      ok: true,
    };
  };

  const invalid = (err) => {
    return {
      ok: false,
      err: err,
    };
  };

  const checktoken = async(token) =>{
     return await apiProject.checktoken(token);
  }

export default {
    id: id,
    projectWebSearch:projectWebSearch,
    save: save,
    validate: validate,
    checktoken:checktoken,
};