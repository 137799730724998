import React from 'react';
import SetupData from '../data/SetupData';
import { Media, Badge } from 'reactstrap';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css';
import { MdRemoveRedEye } from 'react-icons/md';
import "../styles/pages/propertyDetailPage.scss";
import star from "../assets/img/whiteStar.svg";
import agentComponent from '../businesses/agent';

const AgentInfoDraft = (props) => {
    const agents = props.agents

    const handleCancel = (id) => {
        if (props.callback) props.callback(id);
    }
    return (
        <div style={{maxWidth:"auto",minWidth:"200px"}}>
            <div  className="align-self-center">
                <div><img src={agents.image ? agents.image : SetupData.defaultPfp}  alt={agents && agents.area && agents.state && ((agents.nickname ? agents.nickname : agents.name) +" Property Agent in "+ agents?.area + " "+ agents?.state.replace(`Wilayah Persekutuan`, ``).trim() )} className="rounded-circle imgprofilefixed agent-info-image" style={{maxWidth:"90px",maxHeight:"90px"}}/></div><br/>
                <div className="agent-info-detail">
                    <a onClick={() => handleCancel(agents._id)} style={{textAlign:"center"}}><b style={{fontSize:"20px"}}>{agents.nickname?agents.nickname:agents.name}</b>
                    </a>
                    <br />
                    {/* {agents.nickname&&<a style={{maxWidth:"fit-content"}}>@{agents.nickname}<br /></a>} */}
                    {/* {agents.status === "online" ? <Badge color="success">Online</Badge> : <Badge style={{ backgroundColor: "grey" }}>Offline</Badge>} */}
                    {
                        agentComponent.anyRating(agents.rating) ?
                        <>
                            <span className="agent-info-detail-rating" style={{maxWidth:'fit-content'}}>{agents.rating}
                                <img src={star} className="agent-info-detail-ratingImg" alt="" />
                            </span>
                            <br />
                        </>
                        : null
                    }
                    {/* <span className='agent-info-detail-noRating'><small className="m-0 align-self-center">{agents.rating}</small></span> */}
                    <span style={{fontSize:"1.2rem"}}>{agents.views && <small className="m-0 align-self-center"><MdRemoveRedEye />{" "}{agents.views}</small>}</span>
                </div>
                <div className="agent-info-detail-mobile-renid">
                <p className="agent-info-detail">
                {agents.mobile  && <small className="m-0 align-self-center">{agents.mobile}</small>} 
                {agents.renId && agents.renId != "Private Advertiser" && <span><span className="agent-info-detail-vertical-line"></span><small className="m-0 align-self-center">{agents.renId}</small></span>}</p>
                <p className="agent-info-detail"><small className="m-0 align-self-center">{agents.companyName && agents.renId && agents.renId != "Private Advertiser" ? agents.companyName : "Private Advertiser"}</small></p>
                </div>
            </div>
        </div>
    );
}

export default AgentInfoDraft;