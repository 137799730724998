import apibase from './BaseAPI';

// const propertyInsight = async id => {
//     const url = `/insights/property/${id}`;
//     try {
//         const result = await apibase.get(url);
//         return result.data;
//     } catch (err) {
//         throw err;
//     }
// };

// const propertyAveragePriePsf = async id => {
//     const url = `/insights/property/${id}/averagePriePsf`;
//     try {
//         const result = await apibase.get(url);
//         return result.data;
//     } catch (err) {
//         throw err;
//     }
// };

// const overview = async id => {
//     const url = `/insights/property/${id}/overview`;
//     try {
//         const result = await apibase.get(url);
//         return result.data;
//     } catch (err) {
//         throw err;
//     }
// };

// Insight-v2 (bigData.transactions)
const propertyTransactions = async (id) => {
  const url = `/insights/property/${id}`;
  try {
    const result = await apibase.get(url);
    return result.data;
  } catch (err) {
    throw err;
  }
};

export default {
    // propertyInsight,
    // propertyAveragePriePsf,
    // overview,
    propertyTransactions
}