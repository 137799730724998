import React, { useState, useEffect, useRef } from 'react';
import propertyComponent from '../businesses/property'
import auctionPropertyComponent from '../businesses/auctionProperty'
import agentComponent from '../businesses/agent'
import { trackPromise } from "react-promise-tracker";
import LoadingIndicator from "../components/LoadingIndicator";
import lib from '../businesses/lib';
import SliderImg from '../components/SliderImg';
import Gallery from '../components/Gallery';
import AgentInfoDraft from "../components/AgentInfoDraft";
import GoogleMapReact from "google-map-react";
import gps from "../assets/img/locationgrey.svg";
import house from "../assets/img/housegrey.svg";
import car from "../assets/img/cargrey.svg";
import shower from "../assets/img/bathgrey.svg";
import bed from "../assets/img/bedgrey.svg";
import gpsgrey from "../assets/img/greypointer.svg";
import orangehouse from "../assets/img/orangehouse.svg";
import orangecar from "../assets/img/orangecar.svg";
import orangeshower from "../assets/img/orangebath.svg";
import orangebed from "../assets/img/orangebed.svg";

import back from "../assets/img/backarrowlogo.svg"
import SetupData from "../data/SetupData";
import Carousel from "react-elastic-carousel";
import moment from "moment";
import {
    Row, Col, Card, Button, Container,
    Media, Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import { IoLogoWhatsapp, IoMdPhonePortrait, IoIosListBox } from "react-icons/io";
import { MdRemoveRedEye } from 'react-icons/md';
import { useParams, useHistory } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "react-image-gallery/styles/css/image-gallery.css";
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";
import "../styles/pages/propertyDetailPage.scss";
import PageError from "../components/Layout/PageError";
import PropertyCard from "../components/Layout/PropertyCard";
import seoComponent from "../businesses/seoComponent";
import { useTranslation } from 'react-i18next';
import useScrollSpy from 'react-use-scrollspy';
// import global from '../businesses/global';
// import DocumentMeta from 'react-document-meta';
import nextsixAppEmblem from "../assets/home/nextsix-app-emblem.png";
import { MdClose } from "react-icons/md";
import { close } from "../redux/actions/emblemActions";
import { useSelector, useDispatch } from 'react-redux';
import mapPreview from "../assets/img/map-preview.png";
import string from '../businesses/string';
// import biUserEngagementComponent from "../businesses/biUserEngagementComponent";

const AuctionDraftPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const emblem = useSelector(state => state.emblem);
    const getwidth = window.innerWidth;
    const { t } = useTranslation();
    const [properties, setProperties] = useState([]);
    const [agents, setAgent] = useState([]);
    const [mode, setMode] = useState(true);
    let { auction_info } = useParams();
    let getUrlInfo = auction_info.split('-')
    let id = getUrlInfo[getUrlInfo.length - 1]//get last of array(property id)
    const [otherListing, setOtherListing] = useState([]);
    //const [meta, setMeta] = useState({});
    const [displayMap, setDisplayMap] = useState(false);


    useEffect(() => {
        fetchData();
    }, []);

    // const detectSource = () =>{
    //     let fromSource = localStorage.getItem('from');
    //     console.log(fromSource)

    //       if(fromSource && id){

    //         if(fromSource=="listing"){
    //            biUserEngagementComponent.biUserEngagement(fromSource,"property",id)
    //           //  console.log("success list")
    //         }
    //         else if (fromSource=="map"){
    //             biUserEngagementComponent.biUserEngagement(fromSource,"property",id)
    //           //  console.log("success map")
    //         }
    //       }
    //       else if (fromSource=="" && id) {
    //          biUserEngagementComponent.biUserEngagement("shared","property",id)
    //         //  console.log("success shared")
    //       }
    //       return localStorage.setItem('from', "");

    //   }


    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    const activeSection = useScrollSpy({
        sectionElementRefs: sectionRefs,
        offsetPx: -230,
    });

    // const processMetaData = (property) => {
    // };

    const fetchData = async () => {
        //NXT-1876
        if (!lib.isMongoDbObjectId(id)) return history.push('/error/404');
        const properties = await auctionPropertyComponent.id(id);
        if (!properties) return history.push('/error/404');

        // if (!lib.isMongoDbObjectId(id)) return setMode(false);
        // const properties = await auctionPropertyComponent.id(id);
        // if (!properties) return setMode(false);
        setProperties(properties);
        lib.log(properties)
        let result;
        if (properties.agent) {
            const agent = await agentComponent.getAgentInfo(properties.agent);
            setAgent(agent);

            result = await trackPromise(
                auctionPropertyComponent.search(properties.agent, "Approved", null, 0)
            );
        }
        else if (properties.referenceNo) {
            result = await trackPromise(
                auctionPropertyComponent.bidNowOtherAuction()
            );
        }
        if (result) setOtherListing(result.data);

        // processMetaData(properties, agent);//tbd
        //processMetaData(properties);//tbd
        // detectSource();
    }

    const handlePopulateData = (property) => {
        // localStorage.setItem('from', "listing");
        const newUrl = seoComponent.createAuctionDetailUrl(property);
        return newUrl;
    };

    const handlePhone = () => {
        const phone = parsePhoneNumberFromString(agents.mobile, "MY").nationalNumber;
        window.open(`tel:+60${phone}`)
    }

    const handleWhatApps = () => {
        const phone = parsePhoneNumberFromString(agents.mobile, "MY").nationalNumber;
        window.open(
            `https://wa.me/60${phone}?text=I'm%20inquiring%20about%20the%20apartment%20listing`,
            '_blank' // <- This is what makes it open in a new window.
        )
    }

    const goToAgentListing = (agent) => {
        const newUrl = seoComponent.createAgentDetailUrl(agent);
        window.location = newUrl;
    };

    const onOverviewCategoryClick = (e) => {
        e.preventDefault()
        document.getElementById("overview").scrollIntoView();
    }
    // const onInsightCategoryClick = (e) =>{
    // e.preventDefault()
    // document.getElementById("insight").scrollIntoView();
    // }
    const onRecommendationCategoryClick = (e) => {
        e.preventDefault()
        document.getElementById("recommendation").scrollIntoView();
    }

    const ModelsMap = (map, maps) => {
        //instantiate array that will hold your Json Data
        let dataArray = [properties];
        //push your Json Data in the array
        // {
        //   properties.map((markerJson) => dataArray.push(markerJson));
        // }
        // lib.log(properties)

        //Loop through the dataArray to create a marker per data using the coordinates in the json
        for (let i = 0; i < dataArray.length; i++) {
            lib.log(dataArray[i].loc)
            if (dataArray[i].loc) {
                if (dataArray[i].price) {
                    const label = "RM " + lib.currencyFormatter(dataArray[i].price)

                    // const labelimage =
                    //     "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2280%22%20height%3D%2280%22%20viewBox%3D%220%200%2080%2080%22%3E%3Cpath%20fill%3D%22%234AA665%22%20stroke%3D%22%232C633C%22%20stroke-width%3D%22.3%22%20d%3D%22M50%2030%20L80%2030%20L80%200%20L0%200%20L0%2030%20L32%2030%20L38%2038%20L44%2030%20Z%22%2F%3E%3Ctext%20transform%3D%22translate(19%2018.5)%22%20fill%3D%22%23fff%22%20style%3D%22font-family%3A%20Arial%2C%20sans-serif%3Bfont-weight%3Abold%3Btext-align%3Aright%22%20font-size%3D%2212%22%20text-anchor%3D%22start%22%3E" + label + "%3C%2Ftext%3E%3C%2Fsvg%3E%0A%0A";

                    const propertyPng = dataArray[i].images && dataArray[i].images.length > 0 ? dataArray[i].images[0] : SetupData.defaultHouse;
                    const propertyFor = "Auction";
                    const price = "RM " + parseFloat(dataArray[i].price).toLocaleString();
                    const numOfBed = dataArray[i].beds ? dataArray[i].beds : "-";
                    const numOfBath = dataArray[i].baths ? dataArray[i].baths : "-";
                    const numOfCarPark = dataArray[i].carParks ? dataArray[i].carParks : "-";
                    const numOfBuildUp = dataArray[i].buildup ? dataArray[i].buildup : "-";

                    const contentinfo = "<div class='property-list-card'> <div class='property-map-card' ><div>" +
                        "<img loading='lazy' src='" + propertyPng + "' class='info-card-image'/>" +
                        "</div>" +
                        "<br/>" +
                        "<div class='property-list-pricelabel' >" +
                        "<div class='property-list-statuslabel-slide'>" +
                        "<span class='m-0 property-list-statuslabel'>" +
                        propertyFor + "</span>" + "<span class='property-list-date'>" +
                        "<span class='lightgreytext m-1'>" +
                        "Published on " +
                        moment(dataArray[i].created).format("DD/MM/YYYY") + "</span></span>" +
                        "</div>" +
                        "<p class='mb-2 greytext property-list-address'>" + "<img loading='lazy' src='" + gps + "' class='locationlogo'/>" + dataArray[i].address.area + "," + dataArray[i].address.state + "</p>" +
                        "<div class='property-list-price'>" + price + "</div>" + "<b class='property-list-card-name'>" + dataArray[i].name + "</b><br/><br/>" +
                        "<span class='property-card-facilitylogo-padding'>" + "<img loading='lazy' src='" + bed + "' class='facilitylogo'/>" + " " + numOfBed + " "
                        + "<img loading='lazy' src='" + shower + "' class='facilitylogo'/>" + " " + numOfBath + " "
                        + "<img loading='lazy' src='" + car + "' class='facilitylogo'/>" + " " + numOfCarPark + " "
                        + "<img loading='lazy' src='" + house + "' class='facilitylogo'/>" + " " + numOfBuildUp + " sqft "
                        + "</span>" +
                        "</div>" +
                        "</div></div>";

                    const infowindow = new maps.InfoWindow({
                        content: contentinfo,
                        maxWidth: 370,
                        maxHeight: 800,
                        minWidth: 230
                    });

                    const size = string.getWidth(label)

                    let marker = new maps.Marker({
                        position: {
                            lat: dataArray[i].loc.coordinates[1],
                            lng: dataArray[i].loc.coordinates[0],
                        },
                        map,
                        label: {
                            text: label,
                            color: "#ffffff",
                            fontWeight: "bold",
                            className: "propertyDetail-list-Maplabel-infowindow"
                        },
                        // icon: {
                        //     path: maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                        //     strokeColor: "#00A82A",
                        //     scale: 6,
                        //     strokeWeight: 3,
                        //     origin: new maps.Point(0, 0),
                        //     scaledSize: new maps.Size(20, 20)

                        // },
                        icon: {
                            url: 'https://maps.gstatic.com/mapfiles/transparent.png',
                            origin: new maps.Point(0, 0),
                            scaledSize: new maps.Size(size, 28)
                        }
                    });

                    // marker.addListener("click", () => {
                    //     infowindow.open({
                    //         anchor: marker,
                    //         map,
                    //         shouldFocus: false,
                    //       });
                    // //   handlePopulateData(dataArray[i]._id, "properties")
                    // });

                    // marker.addListener("mouseover", () => {
                    //   infowindow.open({
                    //     anchor: marker,
                    //     map,
                    //     shouldFocus: false,
                    //   });
                    // });

                    // marker.addListener("mouseout", () => {
                    //   infowindow.close({
                    //     anchor: marker,
                    //     map,
                    //     shouldFocus: false,
                    //   });
                    // })

                }
            }
        }
    };

    const getFullAddress = () => {
        if (properties.referenceNo && properties.address) {
            return properties.address.line1; // BidNow auction full address 
        }
        else {
            return auctionPropertyComponent.formAuctionFullAddress(properties.address)
        }
    }


    const PropertyInfo = () => {
        return (
            <div className="noborder bg-white">
                <div>
                    <Container>
                        <h5>{t('property-details')}</h5>
                        <hr />
                        <>
                            {properties.categoryListing &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('listing-category')}</b></Col>
                                    <Col className="greytext">{properties.categoryListing}</Col>
                                </Row>}

                            {properties.propertyListing &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('property-listing')}</b> </Col>
                                    <Col className="greytext">{properties.propertyListing}</Col>
                                </Row>}

                            {properties.title &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('property-category')}</b> </Col>
                                    <Col className="greytext">{properties.title}</Col>
                                </Row>}

                            {properties.type &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('property-type')}</b> </Col>
                                    <Col className="greytext">{properties.type}</Col>
                                </Row>}

                            {properties.tenure &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('tenure')}</b> </Col>
                                    <Col className="greytext">{properties.tenure}</Col>
                                </Row>
                            }

                            {properties.titleType &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('title-type')}</b> </Col>
                                    <Col className="greytext">{properties.titleType}</Col>
                                </Row>
                            }

                            {properties.landTitleType &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('land-title-type')}</b> </Col>
                                    <Col className="greytext">{properties.landTitleType}</Col>
                                </Row>
                            }

                            {properties.direction &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('direction')}</b></Col>
                                    <Col className="greytext">{properties.direction}</Col>
                                </Row>
                            }

                            {properties.occupied &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('occupied')}</b></Col>
                                    <Col className="greytext">{properties.occupied}</Col>
                                </Row>
                            }

                            {properties.unitType &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('unit-type')}</b> </Col>
                                    <Col className="greytext">{properties.unitType}</Col>
                                </Row>
                            }

                            {properties.restriction &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('restriction')}</b></Col>
                                    <Col className="greytext">{properties.restriction}</Col>
                                </Row>
                            }

                            {properties.lot &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('lot')}</b></Col>
                                    <Col className="greytext">{properties.lot}</Col>
                                </Row>
                            }

                            {properties.furnishing &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('property-furnishing')}</b> </Col>
                                    <Col className="greytext">{properties.furnishing}</Col>
                                </Row>
                            }

                            {!properties.referenceNo &&
                                <Row className="mb-3" >
                                    <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('available-for-viewing')}</b> </Col>
                                    <Col className="greytext">{properties.isView ? t('yes') : t('no')}</Col>
                                </Row>
                            }
                        </>
                    </Container>
                </div>
            </div>
        )
    };

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 3 },
    ];

    const AuctionInfo = () => {
        return (
            <div className="noborder bg-white py-5">
                <div>
                    <Container>
                        <h5>{t('auction-details')}</h5>
                        <hr />
                        {properties.auctionDate &&
                            <Row className="mb-3" >
                                <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('date')}</b> </Col>
                                <Col className="greytext">{moment(properties.auctionDate).format('DD/MM/YYYY (dddd)')}</Col>
                            </Row>}

                        {properties.auctionTime &&
                            <Row className="mb-3" >
                                <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('time')} </b></Col>
                                <Col className="greytext">{properties.auctionTime}</Col>
                            </Row>}

                        {properties.venue &&
                            <Row className="mb-3" >
                                <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('venue')}</b> </Col>
                                <Col className="greytext">{properties.venue}</Col>
                            </Row>}

                        {properties.assignee &&
                            <Row className="mb-3" >
                                <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('plaintiff-assignee')}</b> </Col>
                                <Col className="greytext">{properties.assignee}</Col>
                            </Row>}

                        {properties.auctioneer &&
                            <Row className="mb-3" >
                                <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('auctioneer')}</b></Col>
                                <Col className="greytext">{properties.auctioneer}</Col>
                            </Row>}

                        {properties.lawyer &&
                            <Row className="mb-3" >
                                <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('lawyer')}</b> </Col>
                                <Col className="greytext">{properties.lawyer}</Col>
                            </Row>}

                        {properties.assignor &&
                            <Row className="mb-3" >
                                <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('defendant-assignor')}</b></Col>
                                <Col className="greytext">{properties.assignor}</Col>
                            </Row>}

                        {properties.deposit &&
                            <Row className="mb-3" >
                                <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('deposit')}</b> </Col>
                                <Col className="greytext">{properties.deposit}</Col>
                            </Row>}

                        {properties.actionUrl &&
                            <Row className="mb-3" >
                                <Col className="mb-0" lg={5} md={5} sm={6} xs={6}><b>{t('action-url')}</b> </Col>
                                <Col className="greytext"><a href={properties.actionUrl} target="_blank" rel="noopener noreferrer">{t('click-here')}</a></Col>
                            </Row>}
                    </Container>
                </div>
            </div>
        )
    };
    const ModalContact = (props) => {
        const {
            className
        } = props;

        const [modal, setModal] = useState(false);

        const toggle = () => setModal(!modal);
        const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

        return (
            <div className="agent-info-detail">
                <Button className="contact-agent-button" onClick={toggle}>{t('contact-agent')}</Button>
                <Modal isOpen={modal} toggle={toggle} className={className} >
                    <ModalHeader toggle={toggle} close={closeBtn}>{t('contact-agent')}</ModalHeader>
                    <ModalBody>
                        <div className="btn-app">
                            <Row>
                                <Col sm="6" md="6" className="my-3">
                                    <Button className="nav-link contactlinks contactphone" onClick={handlePhone}> <IoMdPhonePortrait /><br /><small>{agents.mobile}</small></Button>
                                </Col>
                                <Col sm="6" md="6" className="my-3">
                                    <Button className="nav-link wassapGreen contactlinks" onClick={handleWhatApps}><IoLogoWhatsapp /><br />WhatsApp</Button>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    const treatBidNowTitleOrType = titleOrType => {
        if (titleOrType === 'Apartment / Condo / SOHO' ||
            titleOrType === 'Factory / Warehouse' ||
            titleOrType === 'Flat' ||
            titleOrType === 'Hotel / Resort / Clubhouse' ||
            titleOrType === 'Land' ||
            titleOrType === 'Penthouse' ||
            titleOrType === 'Semi D / Bungalow / Villa' ||
            titleOrType === 'Service Suite' ||
            titleOrType === 'Shop Office / Retail Space / Office' ||
            titleOrType === 'Terrace / Link / Townhouse' ||
            titleOrType === 'Vegetable Stall' ||
            titleOrType === 'Water Villa') return titleOrType.toLowerCase()
                .split(' / ').join('-')
                .split(' ').join('-');

        else return null;
    };

    const UrlNav = (props) => {
        const { property } = props;
        if (property.address?.state) {
            const urlAuction = `/auction-properties-for-sale`;
            const urlState = `${urlAuction}/${seoComponent.treatState(property?.address?.state)}`;
            const urlArea = property?.address?.area ?? null;
            let isTyped = treatBidNowTitleOrType(property?.type);
            const urlType = isTyped ? `${urlState}/${isTyped}` : null;

            return <div className="url-path">
                <a href="/">Home</a>{' > '}
                <a href={`${urlAuction}`}>Auction</a>{' > '}
                <a href={urlState}>{property?.address?.state}</a>{' > '}
                {urlArea && `${property.address?.area} > `}
                {urlType && <><a href={urlType}>{property?.type}</a>{' >  '}</>}
                For Auction
            </div>;
        }

        return null;
    };

    //const area = properties && properties.address && properties.address.area ? properties.address.area + " > " : ""
    const propertyInfo = (
        <div className="cr-page property-detail-bg-color">
            <div className="back-to-search-div">
                <Container>
                    <div className="back-url-div">
                        <div className="back-to-search-span" onClick={() => window.history.back()}>
                            <span><img loading='lazy' src={back} className="img-back-logo" alt="" />{t('back')} </span>
                        </div>

                        {/* <div className="url-path">
                            {properties && (properties.address?.state + " > " + area + properties.type + " > For Auction")}
                        </div> */}

                        <UrlNav property={properties} />
                    </div>
                </Container>
            </div>
            {properties.images && properties.images.length > 0 &&
                <>
                    <div>
                        <Container className="property-detail-slider-image-container">
                            <SliderImg images={properties.images} vrLink={properties.vrLink} videoLink={properties.videoLink} />
                        </Container>
                        <Container className="property-detail-overlay-icon-bar">
                            <div className="overlayicon">
                                <div className="slideicons">
                                    {properties.images && properties.images.length > 0 && <Gallery sources={properties.images} type="Image" />}
                                    {properties.videoLink && <Gallery sources={properties.videoLink} type="Video" custom />}
                                    {properties.vrLink && <Gallery sources={properties.vrLink} type="Vr" custom />}
                                </div>
                            </div>
                        </Container>
                    </div>
                </>
            }
            <Container className="px-3 py-3">
                <section ref={sectionRefs[0]} id="overview" className="property-detail-section" />
                <div className="property-detail-category-div">
                    {properties &&
                        <a onClick={(e) => onOverviewCategoryClick(e)}><button className={activeSection === 0 ? "property-detail-category-button-focus" : "property-detail-category-button"}
                        >Overview</button></a>}

                    {otherListing && otherListing.length > 0 &&
                        <a onClick={(e) => onRecommendationCategoryClick(e)}><button className={activeSection === 1 ? "property-detail-category-button-focus" : "property-detail-category-button"}
                        >Recommendation</button></a>
                    }

                    <hr className="property-detail-hr-line-under-button" />
                </div>
                <Card className="Card-PropertyDetail-Info-Styling">
                    <Row>
                        <Col xl="8" lg="8" md="12" sm="12" xs="12">
                            <p className="lightgreytext fontxs property-detail-publishdate"><button className="property-detail-auctiondate"><small>{`${t('auction-date-time')}: ${moment(properties.auctionDate).format("DD/MM/YYYY")}`}</small></button></p>
                            {/* <p className="lightgreytext fontxs property-detail-publishdate"><small>{`${t('last-updated-on')}: ${moment(properties.created).format("DD/MM/YYYY")}`}</small></p> */}
                            {/* | ${properties.auctionTime} */}
                            <div className="property-detail-pricelabel">
                                <h3 style={{ color: "#E17303" }}>RM {parseFloat(properties.price).toLocaleString()}</h3>
                            </div>

                            <p className="m-0 greytext property-detail-address">
                                <img loading='lazy' src={gpsgrey} alt="" /> {getFullAddress()}
                            </p>
                            <Row>
                                <Col xl="10" lg="10" md="10" sm="12" xs="12">
                                    <h3>{properties.name}</h3>
                                </Col>
                                <Col>
                                    <span className="mt-3 align-self-center eye-padding">{properties.views &&
                                        <span><MdRemoveRedEye />{"  "} {properties.views}</span>
                                    }</span>
                                </Col>
                            </Row>
                            {properties && <>
                                <div className="cr-page bg-white">
                                    <Container>
                                        <Row className="Property-Icon-Info-Padding">
                                            <Col lg="12" md="12" sm="12" className="align-self-center">
                                                <Row className="iconfacilities">

                                                    {properties.beds != "Beds" && properties.beds && (
                                                        <Col lg="2" md="2" sm="4" xs="4" className="mt-2 iconfacilities-padding">
                                                            <p className="fontsm m-0">
                                                                <img loading='lazy' src={orangebed} alt="" />
                                                                &nbsp; {properties.beds}
                                                            </p>
                                                            <small className="m-0">
                                                                <p className="property-detail-logo-icon-description">{t('bedrooms')}</p>
                                                            </small>
                                                        </Col>
                                                    )}
                                                    {properties.baths != 0 && properties.baths && (
                                                        <Col lg="2" md="2" sm="4" xs="4" className="mt-2 iconfacilities-padding">
                                                            <p className="fontsm m-0">
                                                                <img loading='lazy' src={orangeshower} alt="" />
                                                                &nbsp; {properties.baths}
                                                            </p>
                                                            <small className="m-0">
                                                                <p className="property-detail-logo-icon-description">{t('bathrooms')}</p>
                                                            </small>
                                                        </Col>
                                                    )}
                                                    {properties.carParks != 0 && properties.carParks && (
                                                        <Col lg="2" md="2" sm="4" xs="4" className="mt-2 iconfacilities-padding">
                                                            <p className="fontsm m-0">
                                                                <img loading='lazy' src={orangecar} alt="orangecar" />
                                                                &nbsp; {properties.carParks}
                                                            </p>
                                                            <small className="m-0">
                                                                <p className="property-detail-logo-icon-description">{t('carparks')}</p>
                                                            </small>
                                                        </Col>
                                                    )}
                                                    {properties.buildup != 0 && properties.buildup && (
                                                        <Col lg="2" md="2" sm="4" xs="4" className="mt-2 iconfacilities-padding">
                                                            <p className="fontsm m-0">
                                                                <img loading='lazy' src={orangehouse} alt="" />
                                                                &nbsp;
                                                                {parseFloat(properties.buildup).toLocaleString()}
                                                            </p>
                                                            <small className="m-0">
                                                                <p className="property-detail-logo-icon-description">{t('square-feet')}</p>
                                                            </small>
                                                        </Col>
                                                    )}
                                                    {
                                                        properties.landArea != 0 && properties.landArea &&
                                                        <Col lg="2" md="2" sm="4" xs="4" className="mt-2 iconfacilities-padding">
                                                            <p className="fontsm m-0">
                                                                {parseFloat(properties.landArea).toLocaleString()}&nbsp;sqft
                                                            </p>
                                                            <small className="m-0">
                                                                <p className="property-detail-logo-icon-description">{t('land-area')}</p>
                                                            </small>
                                                        </Col>
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div>
                                    {properties.description && <div className="py-3 property-detail-bg-color description-border">
                                        <Container>
                                            <h5>{t('description')}:</h5>
                                            <p className="m-0" style={{ whiteSpace: "break-spaces" }}>{properties.description}</p>
                                        </Container>
                                    </div>}
                                    <div className="py-5">
                                        <Container>
                                            <hr />
                                            {properties.loc && <>
                                                {displayMap ?
                                                    <div className="Property-Detail-Map-Warp">
                                                        <GoogleMapReact
                                                            bootstrapURLKeys={{
                                                                key: process.env.REACT_APP_GOOGLE_API_KEY
                                                            }}
                                                            defaultCenter={{
                                                                lat: properties.loc.coordinates[1],
                                                                lng: properties.loc.coordinates[0],
                                                            }}
                                                            defaultZoom={15}
                                                            yesIWantToUseGoogleMapApiInternals//
                                                            onGoogleApiLoaded={({ map, maps }) => ModelsMap(map, maps)}
                                                            options={{ gestureHandling: "greedy" }}
                                                        >
                                                        </GoogleMapReact>
                                                    </div>
                                                    :
                                                    <div className="Property-Detail-Map-Warp">
                                                        <img loading='lazy' onClick={() => setDisplayMap(true)} src={mapPreview} className="Property-Detail-Map-Warp-map-preview"></img>
                                                    </div>
                                                }
                                            </>}
                                        </Container>
                                    </div>


                                    {properties.facilities && properties.facilities.length > 0 && <Card className="noborder ">
                                        <div className="py-5">
                                            <Container>
                                                <h5>{t('facilities-list')}</h5>
                                                <hr />
                                                <Row>
                                                    {properties.facilities && properties.facilities.length > 0 && properties.facilities.map(facility => {
                                                        return <Col lg="4" md="4" sm="4" xs="12" key={facility}>
                                                            <Media className="mt-2 mt-2">
                                                                <Media left className="align-self-center">
                                                                    {propertyComponent.facilities(facility)}
                                                                </Media>
                                                                <Media body className="align-self-center">
                                                                    <p className="m-0">{t(propertyComponent.facilitiesTranslateKey(facility))}</p>
                                                                </Media>
                                                            </Media>
                                                        </Col>
                                                    })}
                                                </Row>
                                            </Container>
                                        </div>
                                    </Card>}
                                    <PropertyInfo />
                                    <AuctionInfo />
                                </div>
                            </>
                            }
                        </Col>
                        {agents && agents.name &&
                            <Col>
                                <Card className="AgentCard-Detail-Info" style={{ "position": "sticky", "top": "100px" }}>
                                    <AgentInfoDraft agents={agents} />
                                    <div className="AgentCard-Button">
                                        <ModalContact />
                                        <div className="agent-info-detail">
                                            <Button
                                                className="view-agent-button"
                                                color="dark"
                                                onClick={() => goToAgentListing(agents)}
                                            >
                                                <IoIosListBox />{t('view-my-listing')}
                                            </Button>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        }
                    </Row>
                </Card>
                <>
                    <section ref={sectionRefs[1]} id="recommendation" className="property-detail-section" />
                    {otherListing && otherListing.length > 1 &&
                        <Card className="Card-OtherDetail-Info-Styling">
                            <h3 className="other-listing-styling">{t('other-listing')}</h3>
                            <Carousel
                                breakPoints={breakPoints}
                                // enableAutoPlay
                                // autoPlaySpeed={4000}
                                enableSwipe
                                enableMouseSwipe
                                pagination={false}
                                showArrows={true}
                                className="property-detail-otherlist-carousel"
                            >
                                {otherListing.filter(other => other._id.toString() !== properties._id.toString()).map((list) => {
                                    return (
                                        <div
                                            key={list._id}
                                            xl={4}
                                            lg={6}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className="mb-3 corousel-card-styling"
                                        >
                                            <a href={handlePopulateData(list)} className="property-row-redirectLink2" onClick={() => localStorage.setItem('from', "listing")}>
                                                <PropertyCard property={list} type="auctions" showAgent={agents?.name || agents?.nickname ? true : false} />
                                            </a>
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </Card>
                    }
                </>
            </Container>
        </div>
    )

    // useEffect(() => {
    //     dispatch(close("open"));
    // }, [])

    const closeBanner = () => {
        dispatch(close("close"));
    }

    return (
        <>
            {
                getwidth < 768 && emblem === "open" &&
                <div className="emblem-wrapper">
                    <div className="emblem-left-content">
                        <Button className="emblem-close-btn" onClick={() => closeBanner()}><MdClose /></Button>
                        <img loading='lazy' className="emblem-logo" src={nextsixAppEmblem} alt="" />
                        <div className="emblem-left-textbox">
                            <span className="emblem-left-text-top">NextSix App</span>
                            <span className="emblem-left-text-btm">Find properties and agents faster</span>
                        </div>
                    </div>
                    <div className="emblem-right-content">
                        <a className="emblem-download-btn" href="https://apps.nextsix.com/users/reg">Use Now</a>
                    </div>
                </div>
            }
            <Header2 />
            <LoadingIndicator />
            <Container fluid className="property-list-empty">
            </Container>
            {/* NXT-1876 */}
            {propertyInfo}
            {/* {mode ? propertyInfo : <PageError />} */}
            <Footer2 />
        </>
    );
}

export default AuctionDraftPage;
