import React, {
  forwardRef,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";
import { Button } from "reactstrap";

// Styles
import "../styles/pages/propertyLandingDetailPage.scss";
import lib from "../businesses/lib";
import clsx from "clsx";

// APIs
import propertyLanding from "../businesses/propertyLandingComponent";
import seoComponent from "../businesses/seoComponent";

import { useParams, useHistory } from "react-router-dom";

import STATIC from "../utils/stateAreaData";
import exploreArrow from "../assets/img/explore-arrow-right.svg";
import CustomBreadcrumb from "../components/Breadcrumb";

// const textLimit = 200;

const TabComponent = ({ stateSelected, areaSelected, refSection }) => {
  const history = useHistory();
  // Scroll to the specific section when a tab is clicked
  const [activeTab, setActiveTab] = useState(refSection.content);

  const currState = stateSelected
    ? lib.toTitleCase(seoComponent.seoToState(stateSelected))
    : "";

  const currArea = areaSelected
    ? lib.toTitleCase(seoComponent.seoToState(areaSelected))
    : "";

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleTabClick = (ref) => {
    setActiveTab(ref);
    scrollToSection(ref);
  };

  return (
    <div className="tabs-row">
      <Button
        className="btn-tab"
        color="black"
        active={areaSelected ? false : activeTab === refSection.content}
        onClick={() => {
          if (areaSelected) {
            history.push(`/properties/${stateSelected}`);
            setActiveTab(refSection.content);
          } else {
            handleTabClick(refSection.content);
          }
        }}
      >
        {currState}
      </Button>
      {areaSelected && (
        <Button
          className="btn-tab"
          color="black"
          active={activeTab === refSection.content}
          onClick={() => handleTabClick(refSection.content)}
        >
          {lib.toTitleCase(areaSelected)}
        </Button>
      )}
      <Button
        className="btn-tab"
        color="black"
        active={activeTab === refSection.property}
        onClick={() => handleTabClick(refSection.property)}
      >
        Property Types in {currArea ? currArea : currState}
      </Button>
      <Button
        className="btn-tab"
        color="black"
        active={activeTab === refSection.otherAreas}
        onClick={() => handleTabClick(refSection.otherAreas)}
      >
        Areas in {currState ? currState : ""}
      </Button>
    </div>
  );
};

const StatisticComponent = ({ stateSelected, areaSelected }) => {
  // const CardComponent = () => {
  //   return (
  //     <div className="stats-card">
  //       <h6 className="stats-h6">Median Price</h6>
  //       <div className="stats-small-div">
  //         <span>RM</span>
  //         <h3>553K</h3>
  //       </div>
  //       <span className="stats-span">
  //         Based on 15835 units sold preciding 12 months.
  //       </span>
  //     </div>
  //   );
  // };
  return (
    <div className="stats-row">
      <h1 className="stats-h1">
        {areaSelected
          ? lib.toTitleCase(seoComponent.beautifyArea(areaSelected))
          : stateSelected
          ? lib.toTitleCase(seoComponent.seoToState(stateSelected))
          : ""}
      </h1>
      <div style={{ width: "30%" }}></div>
      <div style={{ width: "30%" }}></div>
      {/* <CardComponent />
        <CardComponent /> */}
    </div>
  );
};

const ContentComponent = forwardRef(({ content }, ref) => {
  // const [showAll, setShowAll] = useState(false);

  // const truncateText = (text) => {
  //   const words = text.split(" ");
  //   if (words.length > textLimit) {
  //     return (
  //       words.slice(0, textLimit).join(" ") +
  //       "... " +
  //       `<span class="read-more" style="color: blue; cursor: pointer;">Show more</span>`
  //     );
  //   }
  //   return text;
  // };

  useEffect(() => {
    if (ref.current) {
      //   const finalContext =
      //     content +
      //     `<span class="read-more" style="color: blue; cursor: pointer;">Show less</span>`;
      //   contentRef.current.innerHTML = showAll
      //     ? finalContext
      //     : truncateText(content);

      //   //   Add click event listener for "Read More"
      //   const readMore = contentRef.current.querySelector(".read-more");
      //   if (readMore) {
      //     readMore.addEventListener("click", () => setShowAll((prev) => !prev));
      //   }
      if (content) {
        ref.current.innerHTML = content;
      } else {
        ref.current.innerHTML = "No content for this page.";
      }
    }
  }, [content, ref]);
  // }, [showAll, content]);

  return <section id="content" ref={ref}></section>;
});

const PropertySection = forwardRef(
  ({ stateSelected, areaSelected, propertyTypes }, ref) => {
    const PropertyCard = ({ propertyType }) => {
      return (
        <div
          className="prop-card"
          style={{
            backgroundImage: `url(${
              STATIC.types[propertyType._id]
                ? STATIC.types[propertyType._id]
                : "https://images.pexels.com/photos/302769/pexels-photo-302769.jpeg?cs=srgb&dl=pexels-pixabay-302769.jpg&fm=jpg"
            })`,
            objectFit: "cover",
          }}
        >
          <div className="prop-content">
            <h5 className="prop-content-h5">{propertyType._id}</h5>
            <span>RM 200K - RM900K</span>
            <div className="prop-content-btn-container">
              <a
                href={`/properties-for-rent/${stateSelected}/${
                  propertyType
                    ? seoComponent.treatTitleOrType(propertyType._id)
                    : ""
                }`}
                className="prop-content-btn"
              >
                Rent
              </a>
              <a
                href={`/properties-for-sale/${stateSelected}/${
                  propertyType
                    ? seoComponent.treatTitleOrType(propertyType._id)
                    : ""
                }`}
                className="prop-content-btn"
              >
                Buy
              </a>
            </div>
          </div>
        </div>
      );
    };

    return (
      <section id="property" ref={ref}>
        <h3 className="title-h3">
          Most Popular Property Types in{" "}
          {areaSelected
            ? lib.toTitleCase(seoComponent.beautifyArea(areaSelected))
            : stateSelected
            ? lib.toTitleCase(seoComponent.seoToState(stateSelected))
            : ""}
        </h3>
        <div className="prop-card-container">
          {propertyTypes &&
            propertyTypes.map((type, index) => (
              <PropertyCard key={index} propertyType={type} />
            ))}
        </div>
      </section>
    );
  }
);

const AreaSection = forwardRef(
  ({ otherAreas, currStateSelected, currAreaSelected }, ref) => {
    const showMore = {
      _id: "View More",
    };

    const SmallTag = ({ area, showMore = false }) => {
      return (
        <a
          href={
            area._id !== "View More"
              ? `/properties/${currStateSelected}/${seoComponent.treatArea(
                  area._id
                )}`
              : `/properties-for-sale`
          }
          className="area-small-tag-a"
        >
          <div
            className={clsx({
              "area-small-tag": !showMore,
              "area-small-tag-showmore": showMore,
            })}
            style={{
              color: clsx(
                currAreaSelected === area._id
                  ? "white"
                  : showMore
                  ? "#58595b"
                  : "#FB9600"
              ),
              background: clsx(
                currAreaSelected === area._id
                  ? "#FB9600"
                  : showMore
                  ? "transparent"
                  : "white"
              ),
            }}
          >
            {area._id}
            {showMore && <img loading="lazy" src={exploreArrow} alt="" />}
          </div>
        </a>
      );
    };
    return (
      <section id="area" ref={ref}>
        <h3 className="title-h3">
          Most Popular Areas in{" "}
          {currStateSelected
            ? lib.toTitleCase(seoComponent.seoToState(currStateSelected))
            : ""}
        </h3>
        <div className="area-small-tag-container">
          {otherAreas &&
            otherAreas.map((area, index) => (
              <Fragment key={index}>
                {index < 25 && <SmallTag area={area} />}
              </Fragment>
            ))}
        </div>
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px 0" }}
        >
          <SmallTag area={showMore} showMore={true} />
        </div>
      </section>
    );
  }
);

const PropertyLandingDetailPage = () => {
  const [stateSelected, setStateSelected] = useState(null);
  const [areaSelected, setAreaSelected] = useState(null);
  const [propertyTypes, setPropertyTypes] = useState(null);
  const [otherAreas, setOtherAres] = useState(null);
  const contentSection = useRef(null);
  const propertySection = useRef(null);
  const areaSection = useRef(null);

  const { state, area } = useParams();

  const fetchData = async () => {
    const data = await propertyLanding.propertyLanding(
      seoComponent.beautifyState(stateSelected),
      areaSelected ? seoComponent.beautifyArea(areaSelected) : ""
    );

    setPropertyTypes(data.propertyTypes);
    setOtherAres(data.otherAreas);
  };

  useEffect(() => {
    if (stateSelected) fetchData();
  }, [stateSelected]);

  useEffect(() => {
    const decodeSearchUrl = () => {
      if (state) setStateSelected(seoComponent.treatState(state));
      if (area) setAreaSelected(seoComponent.beautifyArea(area));
    };

    decodeSearchUrl();
  }, [state, area]);

  const NavURL = () => {
    const baseUrl = "/properties/";
    const breadcrumbData = {
      home: {
        title: "Home",
        url: "/",
      },
      state: {
        title: state ? seoComponent.beautifyState(state) : undefined,
        url: baseUrl + state,
      },
      area: {
        title: area ? seoComponent.beautifyArea(area) : undefined,
        url: baseUrl + state + "/" + area,
      },
    };
    return <CustomBreadcrumb items={breadcrumbData} />;
  };

  return (
    <>
      <Header2 />
      <section className="agentPage-empty2 container-fluid" />
      <div className="container landing-container">
        <NavURL />
        <TabComponent
          stateSelected={stateSelected}
          areaSelected={areaSelected}
          refSection={{
            content: contentSection,
            property: propertySection,
            otherAreas: areaSection,
          }}
        />
        <StatisticComponent
          stateSelected={stateSelected}
          areaSelected={areaSelected}
        />
        <ContentComponent
          content={
            areaSelected
              ? STATIC.content[seoComponent.beautifyArea(areaSelected)]
              : stateSelected
              ? STATIC.content[seoComponent.beautifyState(stateSelected)]
              : ""
          }
          ref={contentSection}
        />
        <PropertySection
          stateSelected={stateSelected}
          areaSelected={areaSelected}
          propertyTypes={propertyTypes}
          ref={propertySection}
        />
        <AreaSection
          otherAreas={otherAreas}
          ref={areaSection}
          currAreaSelected={areaSelected}
          currStateSelected={stateSelected}
          handleTagClicked={(areaSelected) => {
            setAreaSelected(areaSelected);
          }}
        />
      </div>
      <Footer2 />
    </>
  );
};

export default PropertyLandingDetailPage;
