import React from 'react';
import '../styles/pages/notFoundPage.scss'
import Footer2 from "../components/Layout/Footer2";
import Header2 from "../components/Layout/Header2"
import { useHistory } from "react-router-dom";
import img404 from '../assets/img/pg404.svg'

export default function NotFoundPage() {
    return (
        <>
            <Header2 />
            <Content />
            <Footer2 />
        </>
    );
}

const Content = () => {
    const history = useHistory();
    const links = [
        {
            text: "Read latest articles",
            extUrl: "https://blog.nextsix.com/",
        },
        {
            text: "Properties for Sale",
            url: "/properties-for-sale",
        },
        {
            text: "Properties for Rent",
            url: "/properties-for-rent",
        },
    ]

    const handleLinkClick = (linkObj) => {
        if (linkObj.extUrl) window.open(linkObj.extUrl, "_blank", "noopener, noreferrer");
        else history.replace(linkObj.url); //replace 404 pg in history, so when click back wont nav back to 404 pg
    }

    const backToHome = () => {
        history.replace("/"); //replace 404 pg in history, so when click back wont nav back to 404 pg
    }

    // const handleReport = () => {

    // }

    return (
        <div className='pg404-container'>
            <img loading="lazy" alt="404" className='pg404-img' src={img404} />
            <h1 className='pg404-h1'>Opps! Page not found</h1>
            <p className='pg404-desc'>Our apologies, the page you are looking for could not be found.</p>
            <button className='pg404-btn fill' onClick={backToHome}>Back to homepage</button>
            <div className='pg404-link-wrapper'>
                {links.map((link, index) =>
                    <p key={index} className='pg404-link' onClick={() => handleLinkClick(link)}>{link.text}</p>
                )}
            </div>
            {/* <button className='pg404-btn report' onClick={handleReport}>Report an issue</button> */}
        </div>
    )
}