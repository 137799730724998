const PROJECT = 'projects-for-sale';
const AUCTION = 'auction-properties-for-sale';
const BUY = 'properties-for-sale';
const RENT = 'properties-for-rent';

const MALAYSIA = 'malaysia';
const STATES_WITH_WP = 'kuala lumpur;labuan;putrajaya;';
const WILAYAH = 'Wilayah Persekutuan';

const NEXTSIX = 'NEXTSIX';
const Nextsix = 'Nextsix';
const THE_NEXT_SIX = 'The Next Six';
const THE_NEXT_SIX_SDN_BHD = 'The Next Six Sdn. Bhd.';
const COVER_PHOTO = 'https://iprofstore.blob.core.windows.net/general/nextsix.png';
const CROSS_STATE_AREAS = ['Ampang', 'Cheras'];
const SELANGOR_AND_KUALA_LUMPUR = 'Selangor/Wilayah Persekutuan Kuala Lumpur';

export default {
    MALAYSIA, STATES_WITH_WP, WILAYAH,
    BUY, RENT, PROJECT, AUCTION,
    NEXTSIX, Nextsix,
    THE_NEXT_SIX, THE_NEXT_SIX_SDN_BHD,
    COVER_PHOTO,
    CROSS_STATE_AREAS, SELANGOR_AND_KUALA_LUMPUR,
}