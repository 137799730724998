import apiBiEngagement from '../api/biUserEngagementAPI';

const biUserEngagement = async (from, to, tid) => {
    const result = await apiBiEngagement.biUserEngagement(from, to, tid);
    return result;
};

const biAgentLead = async id => {
    const result = await apiBiEngagement.biAgentLead(id);
    return result;
};

const biPropertyLead = async id => {
    const result = await apiBiEngagement.biPropertyLead(id);
    return result;
};

export default {
    biUserEngagement: biUserEngagement,
    biAgentLead, biPropertyLead
};