import React from "react";
import { Breadcrumb } from "react-bootstrap";

import "../styles/components/breadcrumbs.scss";

// Sample Data
// items = {
//   home: {
//     title: "Home",
//     url: "/",
//   },
//   for: {
//     title: "",
//     url: "/",
//   },
// };

// location -> location = useLocation() from "react-router-dom"

const CustomBreadcrumb = ({ location, items }) => {
  // const pathname = location.pathname;

  return (
    <Breadcrumb className="breadcrumb">
      {Object.keys(items).map((item, index) => {
        return (
          items[item].title !== undefined && (
            <Breadcrumb.Item
              key={index}
              href={items[item].url}
              // active={items[item].url === pathname} --> As required, every path is clickable
            >
              {items[item].title}
            </Breadcrumb.Item>
          )
        );
      })}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
