import React, { Component, useState } from "react";
// import logo from './assets/img/logo-white.png';
// import google from './assets/img/store-icon-googlestore.png';
// import apple from './assets/img/store-icon-ios.png';
import { Container, Row, Col } from "reactstrap";
import Logo from "../../assets/img/logoNextSixWhite.svg";
import fb from "../../assets/img/facebook-white.png";
import ios from "../../assets/img/ios-white.png";
import android from "../../assets/img/android-white.png";
import linked from "../../assets/img/linkedin-white.png";
import insta from "../../assets/img/instagram-white.png";
import triangle_right from "../../assets/home/triangle_right.svg";
import triangle_down from "../../assets/home/triangle_down.svg";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import "../../styles/components/footer2.scss";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import lib from "../../businesses/lib";

const Footer2 = (props) => {
    // lib.log(props)
    const { t } = useTranslation();
    const selectedLang = useSelector(state => state.lang);
    const showCurrentYear = () => {
        return new Date().getFullYear();
    };
    const [button, setButton] = useState({ popularSale: false, popularRent: false, popularTypeSale: false, popularTypeRent: false });

    const assignValue = (name, value) => {
        // lib.log(name, value);
        setButton((search) => ({
            ...search,
            [name]: value,
        }));
    };

    const detailsResidentialElement = document.querySelector('.Residential-Properties-for-Sale');
    if (detailsResidentialElement) {
        detailsResidentialElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsCondoElement = document.querySelector('.Condos-Service-Residences-for-Sale');
    if (detailsCondoElement) {
        detailsCondoElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsApartmentElement = document.querySelector('.Apartments-Flats-for-Sale');
    if (detailsApartmentElement) {
        detailsApartmentElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsTerraceElement = document.querySelector('.Terrace-Houses-for-Sale');
    if (detailsTerraceElement) {
        detailsTerraceElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsCommercialElement = document.querySelector('.Commercial-Properties-for-Sale');
    if (detailsCommercialElement) {
        detailsCommercialElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsFactoriesElement = document.querySelector('.Factories-for-Sale');
    if (detailsFactoriesElement) {
        detailsFactoriesElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsShopsElement = document.querySelector('.Shops-for-Sale');
    if (detailsShopsElement) {
        detailsShopsElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsResidentialLandElement = document.querySelector('.Residential-Land-for-Sale');
    if (detailsResidentialLandElement) {
        detailsResidentialLandElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsCommercialLandElement = document.querySelector('.Commercial-Land-for-Sale');
    if (detailsCommercialLandElement) {
        detailsCommercialLandElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }


    const detailsResidentialRentElement = document.querySelector('.Residential-Properties-for-Rent');
    if (detailsResidentialRentElement) {
        detailsResidentialRentElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsCondoRentElement = document.querySelector('.Condos-Service-Residences-for-Rent');
    if (detailsCondoRentElement) {
        detailsCondoRentElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsApartmentRentElement = document.querySelector('.Apartments-Flats-for-Rent');
    if (detailsApartmentRentElement) {
        detailsApartmentRentElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsTerraceRentElement = document.querySelector('.Terrace-Houses-for-Rent');
    if (detailsTerraceRentElement) {
        detailsTerraceRentElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsCommercialRentElement = document.querySelector('.Commercial-Properties-for-Rent');
    if (detailsCommercialRentElement) {
        detailsCommercialRentElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsFactoriesRentElement = document.querySelector('.Factories-for-Rent');
    if (detailsFactoriesRentElement) {
        detailsFactoriesRentElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsShopsRentElement = document.querySelector('.Shops-for-Rent');
    if (detailsShopsRentElement) {
        detailsShopsRentElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsResidentialLandRentElement = document.querySelector('.Residential-Land-for-Rent');
    if (detailsResidentialLandRentElement) {
        detailsResidentialLandRentElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }

    const detailsCommercialLandRentElement = document.querySelector('.Commercial-Land-for-Rent');
    if (detailsCommercialLandRentElement) {
        detailsCommercialLandRentElement.addEventListener('toggle', event => {
            if (event.target.open) {
                lib.log('open');
            } else {
                lib.log('closed');
            }
        });
    }


    const NormalFooter = (
        <>
            <div className="footer-Social2-listing footer-top-wrapper2">
                <Container>
                    <Row style={{ width: "99%" }}>
                        <Col lg={6} md={6} xs={12} className="footer-Social2-listing-col-left">
                            <span className="footer-social-text2">Try our Mobile Apps</span>
                            <span className="footer-title-socialLink2"><a href="https://play.google.com/store/apps/details?id=com.nextsix.property" target="_blank" className="footer-title-socialLink2"><img src={android} style={{ paddingLeft: "13px", height: "30px", width: "45px" }}></img>Android</a></span>
                            <span className="footer-title-socialLink2"><a href="https://apps.apple.com/my/app/nextsix/id1500782680" target="_blank" className="footer-title-socialLink2"><img src={ios} style={{ paddingLeft: "13px", height: "30px", width: "45px" }}></img>IOS</a></span>
                        </Col>
                        <Col className="footer-Social2-listing-col-right">
                            <span className="footer-social-text2">Follow Us </span>
                            <a href="https://www.facebook.com/NextsixMY/" target="_blank"><img src={fb} style={{ paddingLeft: "13px", height: "30px", width: "45px" }}></img></a>
                            <a href="https://www.instagram.com/nextsixmy/" target="_blank"><img src={insta} style={{ paddingLeft: "13px", height: "30px", width: "45px" }}></img></a>
                            <a href="https://www.linkedin.com/company/nextsix?trk=public_jobs_topcard-org-name" target="_blank"><img src={linked} style={{ paddingLeft: "13px", height: "30px", width: "45px" }}></img></a>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="footer-top-wrapper2">
                <Container className="footer-top-content">
                    <div className="footer-top-inner2">
                        <div className="footer-top-innerLeft2">
                            <Col className="footer-top-companyLogo2">
                                <a href="/">
                                    <img src={Logo} alt="Logo" />
                                </a>
                            </Col>
                        </div>
                        <div className="footer-top-innerRight2">
                            <Col className="footer-top-contact2" lg={2} sm={6} xs={12}>
                                <span className="footer-title2">{t('company')}</span>
                                <ul className="footer-title-list2">
                                    <ul className="footer-title-list2">
                                        <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/about">{t('about-us')}</a></li>
                                        <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/contact">{t('contact-us')}</a></li>
                                        {/* {selectedLang === "bm" ? (
                                            <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/privacy-policy/bm/index.html" target="_blank">{t('privacy-policy')}</a></li>
                                        ) : (
                                            <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/privacy-policy/en/index.html" target="_blank">{t('privacy-policy')}</a></li>
                                        )} */}

                                        <li className="footer-title-listItem2"><a className="footer-title-listItemLink2"
                                            href={`/privacy-policy/${selectedLang === 'bm' ? 'bm' : 'en'}/index.html`}
                                            target="_blank" rel="noopener noreferrer">{t('privacy-policy')}</a></li>
                                        <div className="seo-footer-description">
                                            <ul className="seo-footer-ul-nopadding">
                                                <li><button className="seo-footer-show-more-btn" style={{ textDecoration: 'none' }}
                                                    onClick={() => assignValue("blog", !button.blog)}>
                                                    {button.blog ? <><img src={triangle_down} style={{ width: 10 }} alt="<" /> Blog</> :
                                                        <><img src={triangle_right} style={{ width: 10 }} alt=">" /> Blog</>}
                                                </button></li>
                                                {button.blog && <>
                                                    <li><a href="https://blog.nextsix.com" className="footer-title-listItemLink2">All</a></li>
                                                    <li><a href="https://blog.nextsix.com/category/property-handbook/" className="seo-footer-a-tag">Property Handbook</a></li>
                                                    <li><a href="https://blog.nextsix.com/category/agent-guide-101/" className="footer-title-listItemLink2">Agent Guide 101</a></li>
                                                    <li><a href="https://blog.nextsix.com/category/lifestyle/" className="footer-title-listItemLink2">Lifestyle</a></li>
                                                </>}

                                            </ul>
                                        </div>

                                        <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/sitemap">{t('sitemap')}</a></li>
                                        {/* <div className="seo-footer-description">
                                            <ul className="seo-footer-ul-nopadding">
                                                <details>
                                                    <summary>Blog</summary>
                                                    <>
                                                        <li><a href="https://blog.nextsix.com/category/all/" className="footer-title-listItemLink2">All</a></li>
                                                        <li><a href="https://blog.nextsix.com/category/property-handbook/" className="seo-footer-a-tag">Property Handbook</a></li>
                                                        <li><a href="https://blog.nextsix.com/category/agent-guide-101/" className="footer-title-listItemLink2">Agent Guide 101</a></li>
                                                        <li><a href="https://blog.nextsix.com/category/lifestyle/" className="footer-title-listItemLink2">Life Style</a></li>
                                                    </>
                                                </details>
                                            </ul>
                                        </div> */}
                                    </ul>
                                </ul>
                            </Col>
                            <Col className="footer-top-office2" lg={3} sm={6} xs={12}>
                                <span className="footer-title2 office-hours2">{t('office-hours')}</span>
                                <ul className="footer-title-list2">
                                    <li className="footer-title-listItem2 public-holiday2">{t('monday-friday')}</li>
                                    <li className="footer-title-listItem2">9:00 am - 5:00 pm</li>
                                </ul>
                            </Col>
                            <Col className="footer-top-merchant2" lg={2} sm={6} xs={12}>
                                <span className="footer-title2">Merchant</span>
                                <ul className="footer-title-list2">
                                    <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/business">Sign Up</a></li>
                                </ul>
                            </Col>
                            <Col className="footer-top-term2" lg={3} sm={6} xs={12}>
                                <span className="footer-title2">{t('term-condition')}</span>
                                <ul className="footer-title-list2">
                                    <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/tnc/agent/index.html" target="_blank">{t('agent-tnc')}</a></li>
                                    {/* <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/tnc/luckydraw/index.html" target="_blank">{t('lucky-draw-tnc')}</a></li> */}
                                </ul>
                            </Col>
                            {/* <Col className="footer-top-event2" lg={2} sm={6} xs={12}>
                                <span className="footer-title2">{t('events')}</span>
                                <ul className="footer-title-list2">
                                    <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/luckydraw/index.html">{t('lucky-draw')}</a></li>
                                </ul>
                            </Col> */}
                        </div>
                    </div>
                    <div className="seo-footer">
                        <div className="seo-footer-header-name">
                            Popular Search
                        </div>

                        <div className="seo-footer-title-name">
                            Popular Locations
                        </div>

                        <div className="seo-footer-description">
                            <a href={"/properties-for-sale/kuala-lumpur"} className="seo-footer-a-tag">Kuala Lumpur Properties</a> <span className="seo-footer-seperator">|</span>
                            <a href={"/properties-for-sale/selangor/klang"} className="seo-footer-a-tag">Klang Properties</a> <span className="seo-footer-seperator">|</span>
                            <a href={"/properties-for-sale/selangor/puchong"} className="seo-footer-a-tag">Puchong Properties</a> <span className="seo-footer-seperator">|</span>
                            <a href={"/properties-for-sale/kuala-lumpur/cheras"} className="seo-footer-a-tag">Cheras Properties</a> <span className="seo-footer-seperator">|</span>
                            <a href={"/properties-for-sale/selangor/subang-jaya"} className="seo-footer-a-tag">Subang Jaya Properties</a> <span className="seo-footer-seperator">|</span>
                            <a href={"/properties-for-sale/johor/johor-bahru"} className="seo-footer-a-tag">Johor Bahru Properties</a> <span className="seo-footer-seperator">|</span>
                            <a href={"/properties-for-sale/johor/skudai"} className="seo-footer-a-tag">Skudai Properties</a> <span className="seo-footer-seperator">|</span>
                            <a href={"/properties-for-sale/johor/iskandar-puteri-nusajaya"} className="seo-footer-a-tag">Iskandar Puteri (Nusajaya) Properties</a> <span className="seo-footer-seperator">|</span>
                            <a href={"/properties-for-sale/sabah/kota-kinabalu"} className="seo-footer-a-tag">Kota Kinabalu Properties</a> <span className="seo-footer-seperator">|</span>
                            <a href={"/properties-for-sale/sarawak/kuching"} className="seo-footer-a-tag">Kuching Properties</a>
                        </div>
                        <div >
                            <Row className="seo-footer-row">
                                <Col lg={3} md={6} xs={12} className="seo-footer-col">
                                    <div className="seo-footer-title-name">
                                        Popular Properties for Sale
                                    </div>
                                    <div className="seo-footer-description">
                                        <ul className="seo-footer-ul-nopadding">
                                            <li><a href={"/properties-for-sale"} className="seo-footer-a-tag">Properties for Sale in Malaysia</a></li>
                                            <li><a href={"/properties-for-sale/selangor"} className="seo-footer-a-tag">Properties for Sale in Selangor</a></li>
                                            <li><a href={"/properties-for-sale/kuala-lumpur"} className="seo-footer-a-tag">Properties for Sale in Kuala Lumpur</a></li>


                                            {button.popularSale && <>
                                                <li><a href={"/properties-for-sale/johor"} className="seo-footer-a-tag">Properties for Sale in Johor</a></li>
                                                <li><a href={"/properties-for-sale/penang"} className="seo-footer-a-tag">Properties for Sale in Penang</a></li>
                                                <li><a href={"/properties-for-sale/putrajaya"} className="seo-footer-a-tag">Properties for Sale in Putrajaya</a></li>
                                                <li><a href={"/properties-for-sale/melaka"} className="seo-footer-a-tag">Properties for Sale in Melaka</a></li>
                                                <li><a href={"/properties-for-sale/negeri-sembilan"} className="seo-footer-a-tag">Properties for Sale in Negeri Sembilan</a></li>
                                                <li><a href={"/properties-for-sale/sarawak"} className="seo-footer-a-tag">Properties for Sale in Sarawak</a></li>
                                                <li><a href={"/properties-for-sale/kedah"} className="seo-footer-a-tag">Properties for Sale in Kedah</a></li>
                                                <li><a href={"/properties-for-sale/kelantan"} className="seo-footer-a-tag">Properties for Sale in Kelantan</a></li>
                                                <li><a href={"/properties-for-sale/pahang"} className="seo-footer-a-tag">Properties for Sale in Pahang</a></li>
                                                <li><a href={"/properties-for-sale/perak"} className="seo-footer-a-tag">Properties for Sale in Perak</a></li>
                                                <li><a href={"/properties-for-sale/perlis"} className="seo-footer-a-tag">Properties for Sale in Perlis</a></li>
                                                <li><a href={"/properties-for-sale/sabah"} className="seo-footer-a-tag">Properties for Sale in Sabah</a></li>
                                                <li><a href={"/properties-for-sale/terengganu"} className="seo-footer-a-tag">Properties for Sale in Terengganu</a></li>
                                                <li><a href={"/properties-for-sale/labuan"} className="seo-footer-a-tag">Properties for Sale in Labuan</a></li>
                                            </>}
                                            <li><button className="seo-footer-show-more-btn" onClick={() => assignValue("popularSale", !button.popularSale)}>{button.popularSale ? "Show Less" : "Show more"}</button></li>

                                        </ul>
                                    </div>
                                </Col>

                                <Col lg={3} md={6} xs={12} className="seo-footer-col">

                                    <div className="seo-footer-title-name">
                                        Popular Properties for Rent
                                    </div>
                                    <div className="seo-footer-description">
                                        <ul className="seo-footer-ul-nopadding">
                                            <li><a href={"/properties-for-rent"} className="seo-footer-a-tag">Properties for Rent in Malaysia</a></li>
                                            <li><a href={"/properties-for-rent/selangor"} className="seo-footer-a-tag">Properties for Rent in Selangor</a></li>
                                            <li><a href={"/properties-for-rent/kuala-lumpur"} className="seo-footer-a-tag">Properties for Rent in Kuala Lumpur</a></li>

                                            {button.popularRent && <>
                                                <li><a href={"/properties-for-rent/johor"} className="seo-footer-a-tag">Properties for Rent in Johor</a></li>
                                                <li><a href={"/properties-for-rent/penang"} className="seo-footer-a-tag">Properties for Rent in Penang</a></li>
                                                <li><a href={"/properties-for-rent/putrajaya"} className="seo-footer-a-tag">Properties for Rent in Putrajaya</a></li>
                                                <li><a href={"/properties-for-rent/melaka"} className="seo-footer-a-tag">Properties for Rent in Melaka</a></li>
                                                <li><a href={"/properties-for-rent/negeri-sembilan"} className="seo-footer-a-tag">Properties for Rent in Negeri Sembilan</a></li>
                                                <li><a href={"/properties-for-rent/sarawak"} className="seo-footer-a-tag">Properties for Rent in Sarawak</a></li>
                                                <li><a href={"/properties-for-rent/kedah"} className="seo-footer-a-tag">Properties for Rent in Kedah</a></li>
                                                <li><a href={"/properties-for-rent/kelantan"} className="seo-footer-a-tag">Properties for Rent in Kelantan</a></li>
                                                <li><a href={"/properties-for-rent/pahang"} className="seo-footer-a-tag">Properties for Rent in Pahang</a></li>
                                                <li><a href={"/properties-for-rent/perak"} className="seo-footer-a-tag">Properties for Rent in Perak</a></li>
                                                <li><a href={"/properties-for-rent/perlis"} className="seo-footer-a-tag">Properties for Rent in Perlis</a></li>
                                                <li><a href={"/properties-for-rent/sabah"} className="seo-footer-a-tag">Properties for Rent in Sabah</a></li>
                                                <li><a href={"/properties-for-rent/terengganu"} className="seo-footer-a-tag">Properties for Rent in Terengganu</a></li>
                                                <li><a href={"/properties-for-rent/labuan"} className="seo-footer-a-tag">Properties for Rent in Labuan</a></li>
                                            </>}
                                            <li><button className="seo-footer-show-more-btn" onClick={() => assignValue("popularRent", !button.popularRent)}>{button.popularRent ? "Show Less" : "Show more"}</button></li>

                                        </ul>
                                    </div>
                                </Col>

                                <Col lg={3} md={6} xs={12} className="seo-footer-col">
                                    <div className="seo-footer-title-name">
                                        Popular Property Types for Sale
                                    </div>

                                    <div className="seo-footer-description">
                                        <details className="Residential-Properties-for-Sale">
                                            <summary>Residential Properties for Sale <MdKeyboardArrowDown /></summary>
                                            <ul className="seo-footer-ul">
                                                <li>
                                                    <a href={"/properties-for-sale/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Malaysia</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/selangor/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Selangor</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/kuala-lumpur/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Kuala Lumpur</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/johor/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Johor</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/penang/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Penang</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/putrajaya/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Putrajaya</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/melaka/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Melaka</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/negeri-sembilan/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Negeri Sembilan</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/sarawak/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Sarawak</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/kedah/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Kedah</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/kelantan/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Kelantan</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/pahang/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Pahang</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/perak/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Perak</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/perlis/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Perlis</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/sabah/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Sabah</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/terengganu/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Terengganu</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/labuan/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Labuan</a>
                                                </li>
                                            </ul>
                                        </details>

                                        <details className="Condos-Service-Residences-for-Sale">
                                            <summary>Condos & Serviced Residences for Sale <MdKeyboardArrowDown /></summary>
                                            <ul className="seo-footer-ul">
                                                <li>
                                                    <a href={"/properties-for-sale/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Malaysia</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/selangor/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Selangor</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/kuala-lumpur/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Kuala Lumpur</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/johor/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Johor</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/penang/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Penang</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/putrajaya/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Putrajaya</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/melaka/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Melaka</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/negeri-sembilan/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Negeri Sembilan</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/sarawak/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Sarawak</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/kedah/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Kedah</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/kelantan/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Kelantan</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/pahang/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Pahang</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/perak/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Perak</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/perlis/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Perlis</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/sabah/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Sabah</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/terengganu/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Terengganu</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/labuan/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Labuan</a>
                                                </li>
                                            </ul>
                                        </details>

                                        <details className="Apartments-Flats-for-Sale">
                                            <summary>Apartments & Flats for Sale <MdKeyboardArrowDown /></summary>
                                            <ul className="seo-footer-ul">
                                                <li>
                                                    <a href={"/properties-for-sale/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Malaysia</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/selangor/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Selangor</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/kuala-lumpur/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Kuala Lumpur</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/johor/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Johor</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/penang/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Penang</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/putrajaya/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Putrajaya</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/melaka/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Melaka</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/negeri-sembilan/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Negeri Sembilan</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/sarawak/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Sarawak</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/kedah/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Kedah</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/kelantan/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Kelantan</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/pahang/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Pahang</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/perak/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Perak</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/perlis/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Perlis</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/sabah/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Sabah</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/terengganu/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Terengganu</a>
                                                </li>
                                                <li>
                                                    <a href={"/properties-for-sale/labuan/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Labuan</a>
                                                </li>
                                            </ul>
                                        </details>


                                        {button.popularTypeSale && <>

                                            <details className="Terrace-Houses-for-Sale">
                                                <summary>Terrace Houses for Sale <MdKeyboardArrowDown /></summary>
                                                <ul className="seo-footer-ul">
                                                    <li>
                                                        <a href={"/properties-for-sale/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Malaysia</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/selangor/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Selangor</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/kuala-lumpur/terrace-link-townhouse"} className="seo-footer-a-tag"> Terrace Houses for Sale in Kuala Lumpur</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/johor/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Johor</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/penang/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Penang</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/putrajaya/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Putrajaya</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/melaka/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Melaka</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/negeri-sembilan/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Negeri Sembilan</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/sarawak/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Sarawak</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/kedah/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Kedah</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/kelantan/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Kelantan</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/pahang/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Pahang</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/perak/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Perak</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/perlis/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Perlis</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/sabah/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Sabah</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/terengganu/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Terengganu</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/labuan/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Labuan</a>
                                                    </li>
                                                </ul>
                                            </details>

                                            <details className="Commercial-Properties-for-Sale">
                                                <summary>Commercial Properties for Sale <MdKeyboardArrowDown /></summary>
                                                <ul className="seo-footer-ul">
                                                    <li>
                                                        <a href={"/properties-for-sale/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Malaysia</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/selangor/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Selangor</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/kuala-lumpur/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Kuala Lumpur</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/johor/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Johor</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/penang/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Penang</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/putrajaya/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Putrajaya</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/melaka/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Melaka</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/negeri-sembilan/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Negeri Sembilan</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/sarawak/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Sarawak</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/kedah/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Kedah</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/kelantan/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Kelantan</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/pahang/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Pahang</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/perak/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Perak</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/perlis/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Perlis</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/sabah/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Sabah</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/terengganu/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Terengganu</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/labuan/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Labuan</a>
                                                    </li>
                                                </ul>
                                            </details>

                                            <details className="Factories-for-Sale">
                                                <summary>Factories for Sale <MdKeyboardArrowDown /></summary>
                                                <ul className="seo-footer-ul">
                                                    <li>
                                                        <a href={"/properties-for-sale/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Malaysia</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/selangor/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Selangor</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/kuala-lumpur/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Kuala Lumpur</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/johor/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Johor</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/penang/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Penang</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/putrajaya/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Putrajaya</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/melaka/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Melaka</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/negeri-sembilan/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Negeri Sembilan</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/sarawak/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Sarawak</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/kedah/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Kedah</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/kelantan/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Kelantan</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/pahang/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Pahang</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/perak/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Perak</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/perlis/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Perlis</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/sabah/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Sabah</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/terengganu/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Terengganu</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/labuan/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Labuan</a>
                                                    </li>
                                                </ul>
                                            </details>

                                            <details className="Shops-for-Sale">
                                                <summary>Shops for Sale <MdKeyboardArrowDown /></summary>
                                                <ul className="seo-footer-ul">
                                                    <li>
                                                        <a href={"/properties-for-sale/shop-office-retail-space"} className="seo-footer-a-tag">Shops for Sale in Malaysia</a>
                                                    </li>
                                                    <li>
                                                        <a href={"/properties-for-sale/selangor/shop-office-retail-space"} className="seo-footer-a-tag">Shops for Sale in Selangor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/kuala-lumpur/shop-office-retail-space"}>Shops for Sale in Kuala Lumpur</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/johor/shop-office-retail-space"}>Shops for Sale in Johor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/penang/shop-office-retail-space"}>Shops for Sale in Penang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/putrajaya/shop-office-retail-space"}>Shops for Sale in Putrajaya</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/melaka/shop-office-retail-space"}>Shops for Sale in Melaka</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/negeri-sembilan/shop-office-retail-space"}>Shops for Sale in Negeri Sembilan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/sarawak/shop-office-retail-space"}>Shops for Sale in Sarawak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/kedah/shop-office-retail-space"}>Shops for Sale in Kedah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/kelantan/shop-office-retail-space"}>Shops for Sale in Kelantan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/pahang/shop-office-retail-space"}>Shops for Sale in Pahang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/perak/shop-office-retail-space"}>Shops for Sale in Perak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/perlis/shop-office-retail-space"}>Shops for Sale in Perlis</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/sabah/shop-office-retail-space"}>Shops for Sale in Sabah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/terengganu/shop-office-retail-space"}>Shops for Sale in Terengganu</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/labuan/shop-office-retail-space"}>Shops for Sale in Labuan</a>
                                                    </li>
                                                </ul>
                                            </details>

                                            <details className="Residential-Land-for-Sale">
                                                <summary>Residential Land for Sale <MdKeyboardArrowDown /></summary>
                                                <ul className="seo-footer-ul">
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/residential-land"}>Residential Land for Sale in Malaysia</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/selangor/residential-land"}>Residential Land for Sale in Selangor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/kuala-lumpur/residential-land"}>Residential Land for Sale in Kuala Lumpur</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/johor/residential-land"}>Residential Land for Sale in Johor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/penang/residential-land"}>Residential Land for Sale in Penang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/putrajaya/residential-land"}>Residential Land for Sale in Putrajaya</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/melaka/residential-land"}>Residential Land for Sale in Melaka</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/negeri-sembilan/residential-land"}>Residential Land for Sale in Negeri Sembilan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/sarawak/residential-land"}>Residential Land for Sale in Sarawak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/kedah/residential-land"}>Residential Land for Sale in Kedah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/kelantan/residential-land"}>Residential Land for Sale in Kelantan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/pahang/residential-land"}>Residential Land for Sale in Pahang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/perak/residential-land"}>Residential Land for Sale in Perak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/perlis/residential-land"}>Residential Land for Sale in Perlis</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/sabah/residential-land"}>Residential Land for Sale in Sabah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/terengganu/residential-land"}>Residential Land for Sale in Terengganu</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/labuan/residential-land"}>Residential Land for Sale in Labuan</a>
                                                    </li>
                                                </ul>
                                            </details>

                                            <details className="Commercial-Land-for-Sale">
                                                <summary>Commercial Land for Sale <MdKeyboardArrowDown /></summary>
                                                <ul className="seo-footer-ul">
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/commercial-land"}>Commercial Land for Sale in Malaysia</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/selangor/commercial-land"}>Commercial Land for Sale in Selangor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/kuala-lumpur/commercial-land"}>Commercial Land for Sale in Kuala Lumpur</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/johor/commercial-land"}>Commercial Land for Sale in Johor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/penang/commercial-land"}>Commercial Land for Sale in Penang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/putrajaya/commercial-land"}>Commercial Land for Sale in Putrajaya</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/melaka/commercial-land"}>Commercial Land for Sale in Melaka</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/negeri-sembilan/commercial-land"}>Commercial Land for Sale in Negeri Sembilan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/sarawak/commercial-land"}>Commercial Land for Sale in Sarawak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/kedah/commercial-land"}>Commercial Land for Sale in Kedah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/kelantan/commercial-land"}>Commercial Land for Sale in Kelantan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/pahang/commercial-land"}>Commercial Land for Sale in Pahang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/perak/commercial-land"}>Commercial Land for Sale in Perak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/perlis/commercial-land"}>Commercial Land for Sale in Perlis</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/sabah/commercial-land"}>Commercial Land for Sale in Sabah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/terengganu/commercial-land"}>Commercial Land for Sale in Terengganu</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-sale/labuan/commercial-land"}>Commercial Land for Sale in Labuan</a>
                                                    </li>
                                                </ul>
                                            </details>
                                        </>}

                                        <div>
                                            <button className="seo-footer-show-more-btn" onClick={() => assignValue("popularTypeSale", !button.popularTypeSale)}>{button.popularTypeSale ? "Show Less" : "Show more"}</button>
                                        </div>

                                    </div>
                                </Col>

                                <Col lg={3} md={6} xs={12} className="seo-footer-col">

                                    <div className="seo-footer-title-name">
                                        Popular Property Types for Rent
                                    </div>

                                    <div className="seo-footer-description">
                                        <details className="Residential-Properties-for-Rent">
                                            <summary>Residential Properties for Rent <MdKeyboardArrowDown /></summary>
                                            <ul className="seo-footer-ul">
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/residential"}>Residential Properties for Rent in Malaysia</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/residential"}>Residential Properties for Rent in Selangor</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/residential"}>Residential Properties for Rent in Kuala Lumpur</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/residential"}>Residential Properties for Rent in Johor</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/residential"}>Residential Properties for Rent in Penang</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/residential"}>Residential Properties for Rent in Putrajaya</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/residential"}>Residential Properties for Rent in Melaka</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/residential"}>Residential Properties for Rent in Negeri Sembilan</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/residential"}>Residential Properties for Rent in Sarawak</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/residential"}>Residential Properties for Rent in Kedah</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/residential"}>Residential Properties for Rent in Kelantan</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/residential"}>Residential Properties for Rent in Pahang</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/residential"}>Residential Properties for Rent in Perak</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/residential"}>Residential Properties for Rent in Perlis</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/residential"}>Residential Properties for Rent in Sabah</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/residential"}>Residential Properties for Rent in Terengganu</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/residential"}>Residential Properties for Rent in Labuan</a>
                                                </li>
                                            </ul>
                                        </details>

                                        <details className="Condos-Service-Residences-for-Rent">
                                            <summary>Condos & Serviced Residences for Rent <MdKeyboardArrowDown /></summary>
                                            <ul className="seo-footer-ul">
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Malaysia</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Selangor</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Kuala Lumpur</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Johor</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Penang</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Putrajaya</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Melaka</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Negeri Sembilan</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Sarawak</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Kedah</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Kelantan</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Pahang</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Perak</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Perlis</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Sabah</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Terengganu</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Labuan</a>
                                                </li>
                                            </ul>
                                        </details>

                                        <details className="Apartments-Flats-for-Rent">
                                            <summary>Apartments & Flats for Rent <MdKeyboardArrowDown /></summary>
                                            <ul className="seo-footer-ul">
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/apartment-flat"}>Apartments & Flats for Rent in Malaysia</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/apartment-flat"}>Apartments & Flats for Rent in Selangor</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/apartment-flat"}>Apartments & Flats for Rent in Kuala Lumpur</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/apartment-flat"}>Apartments & Flats for Rent in Johor</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/apartment-flat"}>Apartments & Flats for Rent in Penang</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/apartment-flat"}>Apartments & Flats for Rent in Putrajaya</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/apartment-flat"}>Apartments & Flats for Rent in Melaka</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/apartment-flat"}>Apartments & Flats for Rent in Negeri Sembilan</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/apartment-flat"}>Apartments & Flats for Rent in Sarawak</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/apartment-flat"}>Apartments & Flats for Rent in Kedah</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/apartment-flat"}>Apartments & Flats for Rent in Kelantan</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/apartment-flat"}>Apartments & Flats for Rent in Pahang</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/apartment-flat"}>Apartments & Flats for Rent in Perak</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/apartment-flat"}>Apartments & Flats for Rent in Perlis</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/apartment-flat"}>Apartments & Flats for Rent in Sabah</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/apartment-flat"}>Apartments & Flats for Rent in Terengganu</a>
                                                </li>
                                                <li>
                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/apartment-flat"}>Apartments & Flats for Rent in Labuan</a>
                                                </li>
                                            </ul>
                                        </details>



                                        {button.popularTypeRent && <>

                                            <details className="Terrace-Houses-for-Rent">
                                                <summary>Terrace Houses for Rent <MdKeyboardArrowDown /></summary>
                                                <ul className="seo-footer-ul">
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/terrace-link-townhouse"}>Terrace Houses for Rent in Malaysia</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/terrace-link-townhouse"}>Terrace Houses for Rent in Selangor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/terrace-link-townhouse"}>Terrace Houses for Rent in Kuala Lumpur</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/terrace-link-townhouse"}>Terrace Houses for Rent in Johor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/terrace-link-townhouse"}>Terrace Houses for Rent in Penang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/terrace-link-townhouse"}>Terrace Houses for Rent in Putrajaya</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/terrace-link-townhouse"}>Terrace Houses for Rent in Melaka</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/terrace-link-townhouse"}>Terrace Houses for Rent in Negeri Sembilan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/terrace-link-townhouse"}>Terrace Houses for Rent in Sarawak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/terrace-link-townhouse"}>Terrace Houses for Rent in Kedah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/terrace-link-townhouse"}>Terrace Houses for Rent in Kelantan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/terrace-link-townhouse"}>Terrace Houses for Rent in Pahang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/terrace-link-townhouse"}>Terrace Houses for Rent in Perak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/terrace-link-townhouse"}>Terrace Houses for Rent in Perlis</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/terrace-link-townhouse"}>Terrace Houses for Rent in Sabah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/terrace-link-townhouse"}>Terrace Houses for Rent in Terengganu</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/terrace-link-townhouse"}>Terrace Houses for Rent in Labuan</a>
                                                    </li>
                                                </ul>
                                            </details>

                                            <details className="Commercial-Properties-for-Rent">
                                                <summary>Commercial Properties for Rent <MdKeyboardArrowDown /></summary>
                                                <ul className="seo-footer-ul">
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/commercial"}>Commercial Properties for Rent in Malaysia</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/commercial"}>Commercial Properties for Rent in Selangor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/commercial"}>Commercial Properties for Rent in Kuala Lumpur</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/commercial"}>Commercial Properties for Rent in Johor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/commercial"}>Commercial Properties for Rent in Penang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/commercial"}>Commercial Properties for Rent in Putrajaya</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/commercial"}>Commercial Properties for Rent in Melaka</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/commercial"}>Commercial Properties for Rent in Negeri Sembilan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/commercial"}>Commercial Properties for Rent in Sarawak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/commercial"}>Commercial Properties for Rent in Kedah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/commercial"}>Commercial Properties for Rent in Kelantan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/commercial"}>Commercial Properties for Rent in Pahang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/commercial"}>Commercial Properties for Rent in Perak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/commercial"}>Commercial Properties for Rent in Perlis</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/commercial"}>Commercial Properties for Rent in Sabah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/commercial"}>Commercial Properties for Rent in Terengganu</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/commercial"}>Commercial Properties for Rent in Labuan</a>
                                                    </li>
                                                </ul>
                                            </details>

                                            <details className="Factories-for-Rent">
                                                <summary>Factories for Rent <MdKeyboardArrowDown /></summary>
                                                <ul className="seo-footer-ul">
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/factory-warehouse"}>Factories for Rent in Malaysia</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/factory-warehouse"}>Factories for Rent in Selangor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/factory-warehouse"}>Factories for Rent in Kuala Lumpur</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/factory-warehouse"}>Factories for Rent in Johor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/factory-warehouse"}>Factories for Rent in Penang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/factory-warehouse"}>Factories for Rent in Putrajaya</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/factory-warehouse"}>Factories for Rent in Melaka</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/factory-warehouse"}>Factories for Rent in Negeri Sembilan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/factory-warehouse"}>Factories for Rent in Sarawak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/factory-warehouse"}>Factories for Rent in Kedah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/factory-warehouse"}>Factories for Rent in Kelantan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/factory-warehouse"}>Factories for Rent in Pahang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/factory-warehouse"}>Factories for Rent in Perak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/factory-warehouse"}>Factories for Rent in Perlis</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/factory-warehouse"}>Factories for Rent in Sabah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/factory-warehouse"}>Factories for Rent in Terengganu</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/factory-warehouse"}>Factories for Rent in Labuan</a>
                                                    </li>
                                                </ul>
                                            </details>

                                            <details className="Shops-for-Rent">
                                                <summary>Shops for Rent <MdKeyboardArrowDown /></summary>
                                                <ul className="seo-footer-ul">
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/shop-office-retail-space"}>Shops for Rent in Malaysia</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/shop-office-retail-space"}>Shops for Rent in Selangor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/shop-office-retail-space"}>Shops for Rent in Kuala Lumpur</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/shop-office-retail-space"}>Shops for Rent in Johor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/shop-office-retail-space"}>Shops for Rent in Penang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/shop-office-retail-space"}>Shops for Rent in Putrajaya</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/shop-office-retail-space"}>Shops for Rent in Melaka</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/shop-office-retail-space"}>Shops for Rent in Negeri Sembilan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/shop-office-retail-space"}>Shops for Rent in Sarawak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/shop-office-retail-space"}>Shops for Rent in Kedah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/shop-office-retail-space"}>Shops for Rent in Kelantan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/shop-office-retail-space"}>Shops for Rent in Pahang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/shop-office-retail-space"}>Shops for Rent in Perak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/shop-office-retail-space"}>Shops for Rent in Perlis</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/shop-office-retail-space"}>Shops for Rent in Sabah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/shop-office-retail-space"}>Shops for Rent in Terengganu</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/shop-office-retail-space"}>Shops for Rent in Labuan</a>
                                                    </li>
                                                </ul>
                                            </details>

                                            <details className="Residential-Land-for-Rent">
                                                <summary>Residential Land for Rent <MdKeyboardArrowDown /></summary>
                                                <ul className="seo-footer-ul">
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/residential-land"}>Residential Land for Rent in Malaysia</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/residential-land"}>Residential Land for Rent in Selangor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/residential-land"}>Residential Land for Rent in Kuala Lumpur</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/residential-land"}>Residential Land for Rent in Johor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/residential-land"}>Residential Land for Rent in Penang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/residential-land"}>Residential Land for Rent in Putrajaya</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/residential-land"}>Residential Land for Rent in Melaka</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/residential-land"}>Residential Land for Rent in Negeri Sembilan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/residential-land"}>Residential Land for Rent in Sarawak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/residential-land"}>Residential Land for Rent in Kedah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/residential-land"}>Residential Land for Rent in Kelantan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/residential-land"}>Residential Land for Rent in Pahang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/residential-land"}>Residential Land for Rent in Perak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/residential-land"}>Residential Land for Rent in Perlis</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/residential-land"}>Residential Land for Rent in Sabah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/residential-land"}>Residential Land for Rent in Terengganu</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/residential-land"}>Residential Land for Rent in Labuan</a>
                                                    </li>
                                                </ul>
                                            </details>

                                            <details className="Commercial-Land-for-Rent">
                                                <summary>Commercial Land for Rent <MdKeyboardArrowDown /></summary>
                                                <ul className="seo-footer-ul">
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/commercial-land"}>Commercial Land for Rent in Malaysia</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/commercial-land"}>Commercial Land for Rent in Selangor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/commercial-land"}>Commercial Land for Rent in Kuala Lumpur</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/commercial-land"}>Commercial Land for Rent in Johor</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/commercial-land"}>Commercial Land for Rent in Penang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/commercial-land"}>Commercial Land for Rent in Putrajaya</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/commercial-land"}>Commercial Land for Rent in Melaka</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/commercial-land"}>Commercial Land for Rent in Negeri Sembilan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/commercial-land"}>Commercial Land for Rent in Sarawak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/commercial-land"}>Commercial Land for Rent in Kedah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/commercial-land"}>Commercial Land for Rent in Kelantan</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/commercial-land"}>Commercial Land for Rent in Pahang</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/commercial-land"}>Commercial Land for Rent in Perak</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/commercial-land"}>Commercial Land for Rent in Perlis</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/commercial-land"}>Commercial Land for Rent in Sabah</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/commercial-land"}>Commercial Land for Rent in Terengganu</a>
                                                    </li>
                                                    <li>
                                                        <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/commercial-land"}>Commercial Land for Rent in Labuan</a>
                                                    </li>
                                                </ul>
                                            </details>
                                        </>}

                                        <div>
                                            <button className="seo-footer-show-more-btn" onClick={() => assignValue("popularTypeRent", !button.popularTypeRent)}>{button.popularTypeRent ? "Show Less" : "Show more"}</button>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="footer-copyright2">
                <span className="footer-copyright-text2">
                    Copyright &copy; 2020-{showCurrentYear()}. The Next Six Sdn. Bhd.
                    All rights reserved.
                </span>
            </div>
        </>
    )

    const ListingFooter = (
        <>
            <Container className="property-list-container" fluid>
                <Row>
                    <Col xl={6} lg={6} className="footer-top-Listing-wrapper2-col">
                        <div className="footer-Social2-listing">
                            <Row>
                                <Col>
                                    <span className="footer-social-text2">Try our Mobile Apps</span>
                                    <span className="footer-title-socialLink2"><a href="https://play.google.com/store/apps/details?id=com.nextsix.property" target="_blank" className="footer-title-socialLink2"><img src={android} style={{ paddingLeft: "13px", height: "30px", width: "45px" }}></img>Android</a></span>
                                    <span className="footer-title-socialLink2"><a href="https://apps.apple.com/my/app/nextsix/id1500782680" target="_blank" className="footer-title-socialLink2"><img src={ios} style={{ paddingLeft: "13px", height: "30px", width: "45px" }}></img>IOS</a></span>
                                </Col>
                                <Col>
                                    <span className="footer-social-text2">Follow Us </span>
                                    <a href="https://www.facebook.com/NextsixMY/" target="_blank"><img src={fb} style={{ paddingLeft: "13px", height: "30px", width: "45px" }}></img></a>
                                    <a href="https://www.instagram.com/nextsixmy/" target="_blank"><img src={insta} style={{ paddingLeft: "13px", height: "30px", width: "45px" }}></img></a>
                                    <a href="https://www.linkedin.com/company/nextsix?trk=public_jobs_topcard-org-name" target="_blank"><img src={linked} style={{ paddingLeft: "13px", height: "30px", width: "45px" }}></img></a>

                                </Col>
                            </Row>
                        </div>

                        <div className="footer-top-Listing-wrapper2">
                            <div className="footer-top-Listing-content">
                                <div className="footer-top-Listing-inner2">
                                    <div className="footer-top-innerLeft2">
                                        <Col className="footer-top-companyLogo2">
                                            <a href="/">
                                                <img src={Logo} alt="Logo" />
                                            </a>
                                        </Col>
                                    </div>
                                    <Row>
                                        <Col>
                                            <div className="footer-top-contact2-listing" >
                                                <span className="footer-title2">{t('company')}</span>
                                                <ul className="footer-title-list2">
                                                    <ul className="footer-title-list2">
                                                        <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/about">{t('about-us')}</a></li>
                                                        <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/contact">{t('contact-us')}</a></li>
                                                        {selectedLang && selectedLang === "bm" ? (
                                                            <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/privacy-policy/bm/index.html" target="_blank">{t('privacy-policy')}</a></li>
                                                        ) : (
                                                            <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/privacy-policy/en/index.html" target="_blank">{t('privacy-policy')}</a></li>
                                                        )}
                                                    </ul>
                                                </ul>
                                            </div>

                                            <div className="footer-top-term2-listing" >
                                                <span className="footer-title2">{t('term-condition')}</span>
                                                <ul className="footer-title-list2">
                                                    <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/tnc/agent/index.html" target="_blank">{t('agent-tnc')}</a></li>
                                                    {/* <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/tnc/luckydraw/index.html" target="_blank">{t('lucky-draw-tnc')}</a></li> */}
                                                </ul>
                                            </div>

                                        </Col>
                                        <Col>

                                            <div className="footer-top-office2-listing"  >
                                                <span className="footer-title2 office-hours2">{t('office-hours')}</span>
                                                <ul className="footer-title-list2">
                                                    <li className="footer-title-listItem2 public-holiday2">{t('monday-friday')}</li>
                                                    <li className="footer-title-listItem2">9:00 am - 5:00 pm</li>
                                                </ul>
                                            </div>
                                            <div className="footer-top-event2-listing"  >
                                                <span className="footer-title2">{t('events')}</span>
                                                <ul className="footer-title-list2">
                                                    <li className="footer-title-listItem2"><a className="footer-title-listItemLink2" href="/luckydraw/index.html">{t('lucky-draw')}</a></li>
                                                </ul>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div style={{ padding: "15px" }}>
                                <div className="seo-footer">
                                    <div className="seo-footer-header-name">
                                        Popular Search
                                    </div>

                                    <div className="seo-footer-title-name">
                                        Popular Locations
                                    </div>

                                    <div className="seo-footer-description">
                                        <a href={"/properties-for-sale/kuala-lumpur"} className="seo-footer-a-tag">Kuala Lumpur Properties</a> <span className="seo-footer-seperator">|</span>
                                        <a href={"/properties-for-sale/selangor/klang"} className="seo-footer-a-tag">Klang Properties</a> <span className="seo-footer-seperator">|</span>
                                        <a href={"/properties-for-sale/selangor/puchong"} className="seo-footer-a-tag">Puchong Properties</a> <span className="seo-footer-seperator">|</span>
                                        <a href={"/properties-for-sale/kuala-lumpur/cheras"} className="seo-footer-a-tag">Cheras Properties</a> <span className="seo-footer-seperator">|</span>
                                        <a href={"/properties-for-sale/selangor/subang-jaya"} className="seo-footer-a-tag">Subang Jaya Properties</a> <span className="seo-footer-seperator">|</span>
                                        <a href={"/properties-for-sale/johor/johor-bahru"} className="seo-footer-a-tag">Johor Bahru Properties</a> <span className="seo-footer-seperator">|</span>
                                        <a href={"/properties-for-sale/johor/skudai"} className="seo-footer-a-tag">Skudai Properties</a> <span className="seo-footer-seperator">|</span>
                                        <a href={"/properties-for-sale/johor/iskandar-puteri-nusajaya"} className="seo-footer-a-tag">Iskandar Puteri (Nusajaya) Properties</a> <span className="seo-footer-seperator">|</span>
                                        <a href={"/properties-for-sale/sabah/kota-kinabalu"} className="seo-footer-a-tag">Kota Kinabalu Properties</a> <span className="seo-footer-seperator">|</span>
                                        <a href={"/properties-for-sale/sarawak/kuching"} className="seo-footer-a-tag">Kuching Properties</a>
                                    </div>
                                    <div >
                                        <Row className="seo-footer-row">
                                            <Col lg={3} md={6} xs={12} className="seo-footer-col">
                                                <div className="seo-footer-title-name">
                                                    Popular Properties for Sale
                                                </div>
                                                <div className="seo-footer-description">
                                                    <ul className="seo-footer-ul-nopadding">
                                                        <li><a href={"/properties-for-sale"} className="seo-footer-a-tag">Properties for Sale in Malaysia</a></li>
                                                        <li><a href={"/properties-for-sale/selangor"} className="seo-footer-a-tag">Properties for Sale in Selangor</a></li>
                                                        <li><a href={"/properties-for-sale/kuala-lumpur"} className="seo-footer-a-tag">Properties for Sale in Kuala Lumpur</a></li>

                                                        {button.popularSale && <>
                                                            <li><a href={"/properties-for-sale/johor"} className="seo-footer-a-tag">Properties for Sale in Johor</a></li>
                                                            <li><a href={"/properties-for-sale/penang"} className="seo-footer-a-tag">Properties for Sale in Penang</a></li>
                                                            <li><a href={"/properties-for-sale/putrajaya"} className="seo-footer-a-tag">Properties for Sale in Putrajaya</a></li>
                                                            <li><a href={"/properties-for-sale/melaka"} className="seo-footer-a-tag">Properties for Sale in Melaka</a></li>
                                                            <li><a href={"/properties-for-sale/negeri-sembilan"} className="seo-footer-a-tag">Properties for Sale in Negeri Sembilan</a></li>
                                                            <li><a href={"/properties-for-sale/sarawak"} className="seo-footer-a-tag">Properties for Sale in Sarawak</a></li>
                                                            <li><a href={"/properties-for-sale/kedah"} className="seo-footer-a-tag">Properties for Sale in Kedah</a></li>
                                                            <li><a href={"/properties-for-sale/kelantan"} className="seo-footer-a-tag">Properties for Sale in Kelantan</a></li>
                                                            <li><a href={"/properties-for-sale/pahang"} className="seo-footer-a-tag">Properties for Sale in Pahang</a></li>
                                                            <li><a href={"/properties-for-sale/perak"} className="seo-footer-a-tag">Properties for Sale in Perak</a></li>
                                                            <li><a href={"/properties-for-sale/perlis"} className="seo-footer-a-tag">Properties for Sale in Perlis</a></li>
                                                            <li><a href={"/properties-for-sale/sabah"} className="seo-footer-a-tag">Properties for Sale in Sabah</a></li>
                                                            <li><a href={"/properties-for-sale/terengganu"} className="seo-footer-a-tag">Properties for Sale in Terengganu</a></li>
                                                            <li><a href={"/properties-for-sale/labuan"} className="seo-footer-a-tag">Properties for Sale in Labuan</a></li>
                                                        </>}
                                                        <li><button className="seo-footer-show-more-btn" onClick={() => assignValue("popularSale", !button.popularSale)}>{button.popularSale ? "Show Less" : "Show more"}</button></li>

                                                    </ul>
                                                </div>
                                            </Col>

                                            <Col lg={3} md={6} xs={12} className="seo-footer-col">

                                                <div className="seo-footer-title-name">
                                                    Popular Properties for Rent
                                                </div>
                                                <div className="seo-footer-description">
                                                    <ul className="seo-footer-ul-nopadding">
                                                        <li><a href={"/properties-for-rent"} className="seo-footer-a-tag">Properties for Rent in Malaysia</a></li>
                                                        <li><a href={"/properties-for-rent/selangor"} className="seo-footer-a-tag">Properties for Rent in Selangor</a></li>
                                                        <li><a href={"/properties-for-rent/kuala-lumpur"} className="seo-footer-a-tag">Properties for Rent in Kuala Lumpur</a></li>


                                                        {button.popularRent && <>
                                                            <li><a href={"/properties-for-rent/johor"} className="seo-footer-a-tag">Properties for Rent in Johor</a></li>
                                                            <li><a href={"/properties-for-rent/penang"} className="seo-footer-a-tag">Properties for Rent in Penang</a></li>
                                                            <li><a href={"/properties-for-rent/putrajaya"} className="seo-footer-a-tag">Properties for Rent in Putrajaya</a></li>
                                                            <li><a href={"/properties-for-rent/melaka"} className="seo-footer-a-tag">Properties for Rent in Melaka</a></li>
                                                            <li><a href={"/properties-for-rent/negeri-sembilan"} className="seo-footer-a-tag">Properties for Rent in Negeri Sembilan</a></li>
                                                            <li><a href={"/properties-for-rent/sarawak"} className="seo-footer-a-tag">Properties for Rent in Sarawak</a></li>
                                                            <li><a href={"/properties-for-rent/kedah"} className="seo-footer-a-tag">Properties for Rent in Kedah</a></li>
                                                            <li><a href={"/properties-for-rent/kelantan"} className="seo-footer-a-tag">Properties for Rent in Kelantan</a></li>
                                                            <li><a href={"/properties-for-rent/pahang"} className="seo-footer-a-tag">Properties for Rent in Pahang</a></li>
                                                            <li><a href={"/properties-for-rent/perak"} className="seo-footer-a-tag">Properties for Rent in Perak</a></li>
                                                            <li><a href={"/properties-for-rent/perlis"} className="seo-footer-a-tag">Properties for Rent in Perlis</a></li>
                                                            <li><a href={"/properties-for-rent/sabah"} className="seo-footer-a-tag">Properties for Rent in Sabah</a></li>
                                                            <li><a href={"/properties-for-rent/terengganu"} className="seo-footer-a-tag">Properties for Rent in Terengganu</a></li>
                                                            <li><a href={"/properties-for-rent/labuan"} className="seo-footer-a-tag">Properties for Rent in Labuan</a></li>
                                                        </>}
                                                        <li><button className="seo-footer-show-more-btn" onClick={() => assignValue("popularRent", !button.popularRent)}>{button.popularRent ? "Show Less" : "Show more"}</button></li>

                                                    </ul>
                                                </div>
                                            </Col>

                                            <Col lg={3} md={6} xs={12} className="seo-footer-col">
                                                <div className="seo-footer-title-name">
                                                    Popular Property Types for Sale
                                                </div>

                                                <div className="seo-footer-description">
                                                    <details className="Residential-Properties-for-Sale">
                                                        <summary>Residential Properties for Sale <MdKeyboardArrowDown /></summary>
                                                        <ul className="seo-footer-ul">
                                                            <li>
                                                                <a href={"/properties-for-sale/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Malaysia</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/selangor/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Selangor</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/kuala-lumpur/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Kuala Lumpur</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/johor/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Johor</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/penang/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Penang</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/putrajaya/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Putrajaya</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/melaka/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Melaka</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/negeri-sembilan/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Negeri Sembilan</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/sarawak/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Sarawak</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/kedah/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Kedah</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/kelantan/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Kelantan</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/pahang/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Pahang</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/perak/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Perak</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/perlis/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Perlis</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/sabah/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Sabah</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/terengganu/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Terengganu</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/labuan/residential"} className="seo-footer-a-tag">Residential Properties for Sale in Labuan</a>
                                                            </li>
                                                        </ul>
                                                    </details>

                                                    <details className="Condos-Service-Residences-for-Sale">
                                                        <summary>Condos & Serviced Residences for Sale <MdKeyboardArrowDown /></summary>
                                                        <ul className="seo-footer-ul">
                                                            <li>
                                                                <a href={"/properties-for-sale/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Malaysia</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/selangor/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Selangor</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/kuala-lumpur/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Kuala Lumpur</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/johor/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Johor</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/penang/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Penang</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/putrajaya/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Putrajaya</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/melaka/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Melaka</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/negeri-sembilan/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Negeri Sembilan</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/sarawak/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Sarawak</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/kedah/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Kedah</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/kelantan/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Kelantan</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/pahang/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Pahang</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/perak/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Perak</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/perlis/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Perlis</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/sabah/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Sabah</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/terengganu/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Terengganu</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/labuan/condo-serviced-residence"} className="seo-footer-a-tag">Condos & Serviced Residences for Sale in Labuan</a>
                                                            </li>
                                                        </ul>
                                                    </details>

                                                    <details className="Apartments-Flats-for-Sale">
                                                        <summary>Apartments & Flats for Sale <MdKeyboardArrowDown /></summary>
                                                        <ul className="seo-footer-ul">
                                                            <li>
                                                                <a href={"/properties-for-sale/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Malaysia</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/selangor/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Selangor</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/kuala-lumpur/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Kuala Lumpur</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/johor/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Johor</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/penang/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Penang</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/putrajaya/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Putrajaya</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/melaka/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Melaka</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/negeri-sembilan/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Negeri Sembilan</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/sarawak/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Sarawak</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/kedah/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Kedah</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/kelantan/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Kelantan</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/pahang/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Pahang</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/perak/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Perak</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/perlis/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Perlis</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/sabah/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Sabah</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/terengganu/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Terengganu</a>
                                                            </li>
                                                            <li>
                                                                <a href={"/properties-for-sale/labuan/apartment-flat"} className="seo-footer-a-tag">Apartments & Flats for Sale in Labuan</a>
                                                            </li>
                                                        </ul>
                                                    </details>


                                                    {button.popularTypeSale && <>

                                                        <details className="Terrace-Houses-for-Sale">
                                                            <summary>Terrace Houses for Sale <MdKeyboardArrowDown /></summary>
                                                            <ul className="seo-footer-ul">
                                                                <li>
                                                                    <a href={"/properties-for-sale/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Malaysia</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/selangor/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Selangor</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/kuala-lumpur/terrace-link-townhouse"} className="seo-footer-a-tag"> Terrace Houses for Sale in Kuala Lumpur</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/johor/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Johor</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/penang/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Penang</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/putrajaya/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Putrajaya</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/melaka/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Melaka</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/negeri-sembilan/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Negeri Sembilan</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/sarawak/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Sarawak</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/kedah/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Kedah</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/kelantan/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Kelantan</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/pahang/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Pahang</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/perak/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Perak</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/perlis/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Perlis</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/sabah/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Sabah</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/terengganu/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Terengganu</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/labuan/terrace-link-townhouse"} className="seo-footer-a-tag">Terrace Houses for Sale in Labuan</a>
                                                                </li>
                                                            </ul>
                                                        </details>

                                                        <details className="Commercial-Properties-for-Sale">
                                                            <summary>Commercial Properties for Sale <MdKeyboardArrowDown /></summary>
                                                            <ul className="seo-footer-ul">
                                                                <li>
                                                                    <a href={"/properties-for-sale/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Malaysia</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/selangor/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Selangor</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/kuala-lumpur/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Kuala Lumpur</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/johor/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Johor</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/penang/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Penang</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/putrajaya/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Putrajaya</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/melaka/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Melaka</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/negeri-sembilan/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Negeri Sembilan</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/sarawak/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Sarawak</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/kedah/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Kedah</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/kelantan/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Kelantan</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/pahang/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Pahang</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/perak/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Perak</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/perlis/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Perlis</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/sabah/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Sabah</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/terengganu/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Terengganu</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/labuan/commercial"} className="seo-footer-a-tag">Commercial Properties for Sale in Labuan</a>
                                                                </li>
                                                            </ul>
                                                        </details>

                                                        <details className="Factories-for-Sale">
                                                            <summary>Factories for Sale <MdKeyboardArrowDown /></summary>
                                                            <ul className="seo-footer-ul">
                                                                <li>
                                                                    <a href={"/properties-for-sale/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Malaysia</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/selangor/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Selangor</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/kuala-lumpur/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Kuala Lumpur</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/johor/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Johor</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/penang/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Penang</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/putrajaya/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Putrajaya</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/melaka/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Melaka</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/negeri-sembilan/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Negeri Sembilan</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/sarawak/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Sarawak</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/kedah/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Kedah</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/kelantan/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Kelantan</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/pahang/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Pahang</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/perak/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Perak</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/perlis/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Perlis</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/sabah/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Sabah</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/terengganu/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Terengganu</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/labuan/factory-warehouse"} className="seo-footer-a-tag">Factories for Sale in Labuan</a>
                                                                </li>
                                                            </ul>
                                                        </details>

                                                        <details className="Shops-for-Sale">
                                                            <summary>Shops for Sale <MdKeyboardArrowDown /></summary>
                                                            <ul className="seo-footer-ul">
                                                                <li>
                                                                    <a href={"/properties-for-sale/shop-office-retail-space"} className="seo-footer-a-tag">Shops for Sale in Malaysia</a>
                                                                </li>
                                                                <li>
                                                                    <a href={"/properties-for-sale/selangor/shop-office-retail-space"} className="seo-footer-a-tag">Shops for Sale in Selangor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/kuala-lumpur/shop-office-retail-space"}>Shops for Sale in Kuala Lumpur</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/johor/shop-office-retail-space"}>Shops for Sale in Johor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/penang/shop-office-retail-space"}>Shops for Sale in Penang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/putrajaya/shop-office-retail-space"}>Shops for Sale in Putrajaya</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/melaka/shop-office-retail-space"}>Shops for Sale in Melaka</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/negeri-sembilan/shop-office-retail-space"}>Shops for Sale in Negeri Sembilan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/sarawak/shop-office-retail-space"}>Shops for Sale in Sarawak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/kedah/shop-office-retail-space"}>Shops for Sale in Kedah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/kelantan/shop-office-retail-space"}>Shops for Sale in Kelantan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/pahang/shop-office-retail-space"}>Shops for Sale in Pahang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/perak/shop-office-retail-space"}>Shops for Sale in Perak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/perlis/shop-office-retail-space"}>Shops for Sale in Perlis</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/sabah/shop-office-retail-space"}>Shops for Sale in Sabah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/terengganu/shop-office-retail-space"}>Shops for Sale in Terengganu</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/labuan/shop-office-retail-space"}>Shops for Sale in Labuan</a>
                                                                </li>
                                                            </ul>
                                                        </details>

                                                        <details className="Residential-Land-for-Sale">
                                                            <summary>Residential Land for Sale <MdKeyboardArrowDown /></summary>
                                                            <ul className="seo-footer-ul">
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/residential-land"}>Residential Land for Sale in Malaysia</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/selangor/residential-land"}>Residential Land for Sale in Selangor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/kuala-lumpur/residential-land"}>Residential Land for Sale in Kuala Lumpur</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/johor/residential-land"}>Residential Land for Sale in Johor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/penang/residential-land"}>Residential Land for Sale in Penang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/putrajaya/residential-land"}>Residential Land for Sale in Putrajaya</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/melaka/residential-land"}>Residential Land for Sale in Melaka</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/negeri-sembilan/residential-land"}>Residential Land for Sale in Negeri Sembilan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/sarawak/residential-land"}>Residential Land for Sale in Sarawak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/kedah/residential-land"}>Residential Land for Sale in Kedah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/kelantan/residential-land"}>Residential Land for Sale in Kelantan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/pahang/residential-land"}>Residential Land for Sale in Pahang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/perak/residential-land"}>Residential Land for Sale in Perak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/perlis/residential-land"}>Residential Land for Sale in Perlis</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/sabah/residential-land"}>Residential Land for Sale in Sabah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/terengganu/residential-land"}>Residential Land for Sale in Terengganu</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/labuan/residential-land"}>Residential Land for Sale in Labuan</a>
                                                                </li>
                                                            </ul>
                                                        </details>

                                                        <details className="Commercial-Land-for-Sale">
                                                            <summary>Commercial Land for Sale <MdKeyboardArrowDown /></summary>
                                                            <ul className="seo-footer-ul">
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/commercial-land"}>Commercial Land for Sale in Malaysia</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/selangor/commercial-land"}>Commercial Land for Sale in Selangor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/kuala-lumpur/commercial-land"}>Commercial Land for Sale in Kuala Lumpur</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/johor/commercial-land"}>Commercial Land for Sale in Johor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/penang/commercial-land"}>Commercial Land for Sale in Penang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/putrajaya/commercial-land"}>Commercial Land for Sale in Putrajaya</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/melaka/commercial-land"}>Commercial Land for Sale in Melaka</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/negeri-sembilan/commercial-land"}>Commercial Land for Sale in Negeri Sembilan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/sarawak/commercial-land"}>Commercial Land for Sale in Sarawak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/kedah/commercial-land"}>Commercial Land for Sale in Kedah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/kelantan/commercial-land"}>Commercial Land for Sale in Kelantan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/pahang/commercial-land"}>Commercial Land for Sale in Pahang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/perak/commercial-land"}>Commercial Land for Sale in Perak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/perlis/commercial-land"}>Commercial Land for Sale in Perlis</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/sabah/commercial-land"}>Commercial Land for Sale in Sabah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/terengganu/commercial-land"}>Commercial Land for Sale in Terengganu</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-sale/labuan/commercial-land"}>Commercial Land for Sale in Labuan</a>
                                                                </li>
                                                            </ul>
                                                        </details>
                                                    </>}

                                                    <div>
                                                        <button className="seo-footer-show-more-btn" onClick={() => assignValue("popularTypeSale", !button.popularTypeSale)}>{button.popularTypeSale ? "Show Less" : "Show more"}</button>
                                                    </div>

                                                </div>
                                            </Col>

                                            <Col lg={3} md={6} xs={12} className="seo-footer-col">

                                                <div className="seo-footer-title-name">
                                                    Popular Property Types for Rent
                                                </div>

                                                <div className="seo-footer-description">
                                                    <details className="Residential-Properties-for-Rent">
                                                        <summary>Residential Properties for Rent <MdKeyboardArrowDown /></summary>
                                                        <ul className="seo-footer-ul">
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/residential"}>Residential Properties for Rent in Malaysia</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/residential"}>Residential Properties for Rent in Selangor</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/residential"}>Residential Properties for Rent in Kuala Lumpur</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/residential"}>Residential Properties for Rent in Johor</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/residential"}>Residential Properties for Rent in Penang</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/residential"}>Residential Properties for Rent in Putrajaya</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/residential"}>Residential Properties for Rent in Melaka</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/residential"}>Residential Properties for Rent in Negeri Sembilan</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/residential"}>Residential Properties for Rent in Sarawak</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/residential"}>Residential Properties for Rent in Kedah</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/residential"}>Residential Properties for Rent in Kelantan</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/residential"}>Residential Properties for Rent in Pahang</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/residential"}>Residential Properties for Rent in Perak</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/residential"}>Residential Properties for Rent in Perlis</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/residential"}>Residential Properties for Rent in Sabah</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/residential"}>Residential Properties for Rent in Terengganu</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/residential"}>Residential Properties for Rent in Labuan</a>
                                                            </li>
                                                        </ul>
                                                    </details>

                                                    <details className="Condos-Service-Residences-for-Rent">
                                                        <summary>Condos & Serviced Residences for Rent <MdKeyboardArrowDown /></summary>
                                                        <ul className="seo-footer-ul">
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Malaysia</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Selangor</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Kuala Lumpur</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Johor</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Penang</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Putrajaya</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Melaka</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Negeri Sembilan</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Sarawak</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Kedah</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Kelantan</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Pahang</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Perak</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Perlis</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Sabah</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Terengganu</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/condo-serviced-residence"}>Condos & Serviced Residences for Rent in Labuan</a>
                                                            </li>
                                                        </ul>
                                                    </details>

                                                    <details className="Apartments-Flats-for-Rent">
                                                        <summary>Apartments & Flats for Rent <MdKeyboardArrowDown /></summary>
                                                        <ul className="seo-footer-ul">
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/apartment-flat"}>Apartments & Flats for Rent in Malaysia</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/apartment-flat"}>Apartments & Flats for Rent in Selangor</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/apartment-flat"}>Apartments & Flats for Rent in Kuala Lumpur</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/apartment-flat"}>Apartments & Flats for Rent in Johor</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/apartment-flat"}>Apartments & Flats for Rent in Penang</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/apartment-flat"}>Apartments & Flats for Rent in Putrajaya</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/apartment-flat"}>Apartments & Flats for Rent in Melaka</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/apartment-flat"}>Apartments & Flats for Rent in Negeri Sembilan</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/apartment-flat"}>Apartments & Flats for Rent in Sarawak</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/apartment-flat"}>Apartments & Flats for Rent in Kedah</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/apartment-flat"}>Apartments & Flats for Rent in Kelantan</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/apartment-flat"}>Apartments & Flats for Rent in Pahang</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/apartment-flat"}>Apartments & Flats for Rent in Perak</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/apartment-flat"}>Apartments & Flats for Rent in Perlis</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/apartment-flat"}>Apartments & Flats for Rent in Sabah</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/apartment-flat"}>Apartments & Flats for Rent in Terengganu</a>
                                                            </li>
                                                            <li>
                                                                <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/apartment-flat"}>Apartments & Flats for Rent in Labuan</a>
                                                            </li>
                                                        </ul>
                                                    </details>



                                                    {button.popularTypeRent && <>

                                                        <details className="Terrace-Houses-for-Rent">
                                                            <summary>Terrace Houses for Rent <MdKeyboardArrowDown /></summary>
                                                            <ul className="seo-footer-ul">
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/terrace-link-townhouse"}>Terrace Houses for Rent in Malaysia</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/terrace-link-townhouse"}>Terrace Houses for Rent in Selangor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/terrace-link-townhouse"}>Terrace Houses for Rent in Kuala Lumpur</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/terrace-link-townhouse"}>Terrace Houses for Rent in Johor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/terrace-link-townhouse"}>Terrace Houses for Rent in Penang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/terrace-link-townhouse"}>Terrace Houses for Rent in Putrajaya</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/terrace-link-townhouse"}>Terrace Houses for Rent in Melaka</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/terrace-link-townhouse"}>Terrace Houses for Rent in Negeri Sembilan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/terrace-link-townhouse"}>Terrace Houses for Rent in Sarawak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/terrace-link-townhouse"}>Terrace Houses for Rent in Kedah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/terrace-link-townhouse"}>Terrace Houses for Rent in Kelantan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/terrace-link-townhouse"}>Terrace Houses for Rent in Pahang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/terrace-link-townhouse"}>Terrace Houses for Rent in Perak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/terrace-link-townhouse"}>Terrace Houses for Rent in Perlis</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/terrace-link-townhouse"}>Terrace Houses for Rent in Sabah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/terrace-link-townhouse"}>Terrace Houses for Rent in Terengganu</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/terrace-link-townhouse"}>Terrace Houses for Rent in Labuan</a>
                                                                </li>
                                                            </ul>
                                                        </details>

                                                        <details className="Commercial-Properties-for-Rent">
                                                            <summary>Commercial Properties for Rent <MdKeyboardArrowDown /></summary>
                                                            <ul className="seo-footer-ul">
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/commercial"}>Commercial Properties for Rent in Malaysia</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/commercial"}>Commercial Properties for Rent in Selangor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/commercial"}>Commercial Properties for Rent in Kuala Lumpur</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/commercial"}>Commercial Properties for Rent in Johor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/commercial"}>Commercial Properties for Rent in Penang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/commercial"}>Commercial Properties for Rent in Putrajaya</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/commercial"}>Commercial Properties for Rent in Melaka</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/commercial"}>Commercial Properties for Rent in Negeri Sembilan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/commercial"}>Commercial Properties for Rent in Sarawak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/commercial"}>Commercial Properties for Rent in Kedah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/commercial"}>Commercial Properties for Rent in Kelantan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/commercial"}>Commercial Properties for Rent in Pahang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/commercial"}>Commercial Properties for Rent in Perak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/commercial"}>Commercial Properties for Rent in Perlis</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/commercial"}>Commercial Properties for Rent in Sabah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/commercial"}>Commercial Properties for Rent in Terengganu</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/commercial"}>Commercial Properties for Rent in Labuan</a>
                                                                </li>
                                                            </ul>
                                                        </details>

                                                        <details className="Factories-for-Rent">
                                                            <summary>Factories for Rent <MdKeyboardArrowDown /></summary>
                                                            <ul className="seo-footer-ul">
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/factory-warehouse"}>Factories for Rent in Malaysia</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/factory-warehouse"}>Factories for Rent in Selangor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/factory-warehouse"}>Factories for Rent in Kuala Lumpur</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/factory-warehouse"}>Factories for Rent in Johor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/factory-warehouse"}>Factories for Rent in Penang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/factory-warehouse"}>Factories for Rent in Putrajaya</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/factory-warehouse"}>Factories for Rent in Melaka</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/factory-warehouse"}>Factories for Rent in Negeri Sembilan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/factory-warehouse"}>Factories for Rent in Sarawak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/factory-warehouse"}>Factories for Rent in Kedah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/factory-warehouse"}>Factories for Rent in Kelantan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/factory-warehouse"}>Factories for Rent in Pahang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/factory-warehouse"}>Factories for Rent in Perak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/factory-warehouse"}>Factories for Rent in Perlis</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/factory-warehouse"}>Factories for Rent in Sabah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/factory-warehouse"}>Factories for Rent in Terengganu</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/factory-warehouse"}>Factories for Rent in Labuan</a>
                                                                </li>
                                                            </ul>
                                                        </details>

                                                        <details className="Shops-for-Rent">
                                                            <summary>Shops for Rent <MdKeyboardArrowDown /></summary>
                                                            <ul className="seo-footer-ul">
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/shop-office-retail-space"}>Shops for Rent in Malaysia</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/shop-office-retail-space"}>Shops for Rent in Selangor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/shop-office-retail-space"}>Shops for Rent in Kuala Lumpur</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/shop-office-retail-space"}>Shops for Rent in Johor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/shop-office-retail-space"}>Shops for Rent in Penang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/shop-office-retail-space"}>Shops for Rent in Putrajaya</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/shop-office-retail-space"}>Shops for Rent in Melaka</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/shop-office-retail-space"}>Shops for Rent in Negeri Sembilan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/shop-office-retail-space"}>Shops for Rent in Sarawak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/shop-office-retail-space"}>Shops for Rent in Kedah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/shop-office-retail-space"}>Shops for Rent in Kelantan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/shop-office-retail-space"}>Shops for Rent in Pahang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/shop-office-retail-space"}>Shops for Rent in Perak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/shop-office-retail-space"}>Shops for Rent in Perlis</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/shop-office-retail-space"}>Shops for Rent in Sabah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/shop-office-retail-space"}>Shops for Rent in Terengganu</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/shop-office-retail-space"}>Shops for Rent in Labuan</a>
                                                                </li>
                                                            </ul>
                                                        </details>

                                                        <details className="Residential-Land-for-Rent">
                                                            <summary>Residential Land for Rent <MdKeyboardArrowDown /></summary>
                                                            <ul className="seo-footer-ul">
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/residential-land"}>Residential Land for Rent in Malaysia</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/residential-land"}>Residential Land for Rent in Selangor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/residential-land"}>Residential Land for Rent in Kuala Lumpur</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/residential-land"}>Residential Land for Rent in Johor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/residential-land"}>Residential Land for Rent in Penang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/residential-land"}>Residential Land for Rent in Putrajaya</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/residential-land"}>Residential Land for Rent in Melaka</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/residential-land"}>Residential Land for Rent in Negeri Sembilan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/residential-land"}>Residential Land for Rent in Sarawak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/residential-land"}>Residential Land for Rent in Kedah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/residential-land"}>Residential Land for Rent in Kelantan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/residential-land"}>Residential Land for Rent in Pahang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/residential-land"}>Residential Land for Rent in Perak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/residential-land"}>Residential Land for Rent in Perlis</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/residential-land"}>Residential Land for Rent in Sabah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/residential-land"}>Residential Land for Rent in Terengganu</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/residential-land"}>Residential Land for Rent in Labuan</a>
                                                                </li>
                                                            </ul>
                                                        </details>

                                                        <details className="Commercial-Land-for-Rent">
                                                            <summary>Commercial Land for Rent <MdKeyboardArrowDown /></summary>
                                                            <ul className="seo-footer-ul">
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/commercial-land"}>Commercial Land for Rent in Malaysia</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/selangor/commercial-land"}>Commercial Land for Rent in Selangor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kuala-lumpur/commercial-land"}>Commercial Land for Rent in Kuala Lumpur</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/johor/commercial-land"}>Commercial Land for Rent in Johor</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/penang/commercial-land"}>Commercial Land for Rent in Penang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/putrajaya/commercial-land"}>Commercial Land for Rent in Putrajaya</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/melaka/commercial-land"}>Commercial Land for Rent in Melaka</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/negeri-sembilan/commercial-land"}>Commercial Land for Rent in Negeri Sembilan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sarawak/commercial-land"}>Commercial Land for Rent in Sarawak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kedah/commercial-land"}>Commercial Land for Rent in Kedah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/kelantan/commercial-land"}>Commercial Land for Rent in Kelantan</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/pahang/commercial-land"}>Commercial Land for Rent in Pahang</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perak/commercial-land"}>Commercial Land for Rent in Perak</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/perlis/commercial-land"}>Commercial Land for Rent in Perlis</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/sabah/commercial-land"}>Commercial Land for Rent in Sabah</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/terengganu/commercial-land"}>Commercial Land for Rent in Terengganu</a>
                                                                </li>
                                                                <li>
                                                                    <a className="seo-footer-a-tag" href={"/properties-for-rent/labuan/commercial-land"}>Commercial Land for Rent in Labuan</a>
                                                                </li>
                                                            </ul>
                                                        </details>
                                                    </>}

                                                    <div>
                                                        <button className="seo-footer-show-more-btn" onClick={() => assignValue("popularTypeRent", !button.popularTypeRent)}>{button.popularTypeRent ? "Show Less" : "Show more"}</button>
                                                    </div>

                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-copyright2-listing">
                            <span className="footer-copyright-text2">
                                Copyright &copy; 2020-{showCurrentYear()}. The Next Six Sdn. Bhd.
                                All rights reserved.
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )

    return (
        <>
            {props && props.footerType ? ListingFooter : NormalFooter}
            {/* {normalFooter} */}
        </>
    );
};

export default Footer2;
