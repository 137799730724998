import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, InputGroupText, InputGroupAddon, Col, FormGroup, Label } from 'reactstrap';

const InputGroupLabel = (props) => {

    const handleChange = (event) => {
        if (props.callback) props.callback({
            id: props.callbackId,
            value: event.target.value
        });
    };

    const leftSide = (
        <Col xl={4} sm={12}>
            <InputGroup>
                <InputGroupAddon addonType="prepend" >
                    <InputGroupText>{props.label}</InputGroupText>
                </InputGroupAddon>
                <Input type="number" name={props.name} placeholder={props.placeholder} value={props.value}
                    onChange={handleChange} readOnly={props.readOnly} />
            </InputGroup>
        </Col>
    );


    const rightSide = (
        <Col xl={4} sm={12}>
            <InputGroup>
                <Input type="number" name={props.name} placeholder={props.placeholder} value={props.value}
                    onChange={handleChange} readOnly={props.readOnly} />
                <InputGroupAddon addonType="append">
                    <InputGroupText>{props.label}</InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </Col>
    );


    return (
        props.direction === 'left' ? leftSide : rightSide
    );
};


InputGroupLabel.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    callback: PropTypes.func,
    callbackId: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    direction: PropTypes.oneOf(['left', 'right'])
};

export default InputGroupLabel;
