import apiSearchLog from '../api/SearchLogAPI';

const log = async (searchTextbox) => {  
    const result = await apiSearchLog.log(searchTextbox);
    return result;
};

const propertySearchLog = (newSearch) => {
    let searchTextbox;
    if (newSearch.area) searchTextbox = newSearch.area;
    if (newSearch.state) searchTextbox = newSearch.state;
    if (newSearch.freeText) searchTextbox = newSearch.freeText;
    if (newSearch.propertyName) searchTextbox = newSearch.propertyName;
    if (newSearch.aiSearchDisplay) searchTextbox = newSearch.aiSearchDisplay;

    if(searchTextbox) log(searchTextbox);
};

export default {
    propertySearchLog: propertySearchLog
};