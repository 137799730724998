import React from "react";
import SetupData from "../data/SetupData";
import lib from "../businesses/lib";
import { Badge, Button } from "reactstrap";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import { MdRemoveRedEye } from "react-icons/md";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import star from "../assets/img/whiteStar.svg";
import whatsApp from "../assets/img/whatsapp.png";
import phone from "../assets/img/newphoneicon.svg";
import agentComponent from "../businesses/agent";

const AgentInfoDetail = (props) => {
    const agents = props.agents;

    const handleCancel = (id) => {
        if (props.callback) props.callback(id);
    };

    const handlePhone = (mobile) => {
        const phone = parsePhoneNumberFromString(mobile, "MY").nationalNumber;
        window.open(`tel:+60${phone}`);
    };

    const handleWhatApps = (mobile) => {
        let wsText;
        const url = encodeURIComponent(window.location.href);

        if (agents) {
            wsText = `Hi ${agents?.nickname}, I discovered your profile on NextSix%0A%0A${url}%0A%0Aand I would like to request your assistance`;
        } else {
            wsText =
                "Hi, I would like to seek your assistance in searching for properties";
        }

        const phone = parsePhoneNumberFromString(mobile, "MY").nationalNumber;
        window.open(
            `https://wa.me/60${phone}?text=${wsText}`,
            "_blank" // <- This is what makes it open in a new window.
        );
        lib.log("Send WhatApps to " + agents.mobile);
    };

    return (
        <div className="agentPage-infoAvatar-wrapper2">
            <div className="agentPage-infoAvatar2">
                <img loading='lazy'
                    src={agents.image ? agents.image : SetupData.defaultPfp}
                    className="agentPage-infoAvatarImg2"
                    alt={
                        agents &&
                        agents.area &&
                        agents.state &&
                        (agents.nickname ? agents.nickname : agents.name) +
                        " Property Agent in " +
                        agents?.area +
                        " " +
                        agents?.state
                            .replace(`Wilayah Persekutuan`, ``)
                            .trim()
                    }
                />
            </div>
            <div className="agentPage-infoAvatar-right2">
                <span className="agentPage-infoAvatar-name2">
                    {agents.nickname ? agents.nickname : agents.name}
                </span>
                <div className="agentPage-infoAvatar-starSection2">
                    {agentComponent.anyRating(agents.rating) ? (
                        <span className="agentPage-infoAvatar-starText2">
                            {agents.rating}
                            <img loading='lazy'
                                src={star}
                                className="agentPage-infoAvatar-starImg2"
                                alt=""
                            />
                        </span>
                    ) : null}
                    {/* <span className="agentPage-infoAvatar-noRatingText">{agents.rating}</span> */}
                    {agents.views && (
                        <>
                            <MdRemoveRedEye
                                className={`agentPage-infoAvatar-viewEye2
                                ${agentComponent.anyRating(agents.rating) ? "" : "ml-0"}`}
                            />
                            <span className="agentPage-infoAvatar-view2">
                                {agents.views}
                            </span>
                        </>
                    )}
                </div>
                <div className="agentPage-infoAvatar-companySection2">
                    <span className="agentPage-infoAvatar-companyName2">
                        {agents.companyName &&
                            agents.renId &&
                            agents.renId !== "Private Advertiser"
                            ? agents.companyName
                            : "Private Advertiser"}{" "}
                    </span>
                    {agents.renId && agents.renId !== "Private Advertiser" && (
                        <span className="agentPage-infoAvatar-renId2">
                            {agents.renId}
                        </span>
                    )}
                </div>
                <div className="agentPage-infoAvatar-contactSection2">
                    <Button
                        className="agentPage-infoAvatar-phoneButton2"
                        onClick={() => handlePhone(agents.mobile)}
                    >
                        <img loading='lazy'
                            src={phone}
                            className="agentPage-infoAvatar-phoneIcon2"
                            alt=""
                        />
                        <span className="agentPage-infoAvatar-contactText2">
                            {agents.mobile}
                        </span>
                    </Button>
                    <Button
                        className="agentPage-infoAvatar-whatsappButton2"
                        onClick={() => handleWhatApps(agents.mobile)}
                    >
                        <img loading='lazy'
                            src={whatsApp}
                            className="agentPage-infoAvatar-whatsappIcon2"
                            alt=""
                        />
                        <span className="agentPage-infoAvatar-contactText2">
                            WhatsApp
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AgentInfoDetail;