import React, { useEffect } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import "../styles/components/loadingOverlay.scss";

const LoadingOverlay = () => {
    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => {
        if (promiseInProgress) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [promiseInProgress]);

    return (
        promiseInProgress &&
        <div className="modal loading-modal fade show" role="dialog" tabIndex="-1">
            <Loader type="ThreeDots" color="#F09108" height={60} width={100} />
        </div>
    );
};

export default LoadingOverlay;
