import apiFeatureProperty from '../api/FeaturePropertyAPI';

const newId = async () => {
    const newId = await apiFeatureProperty.newId();
    return newId.id;
};

const search = async (search, active, currentLength) => {
    return await apiFeatureProperty.search(search, active, currentLength);
}

const save = async (property) => {
    return await apiFeatureProperty.save(property);
};

const id = async (id) => {
    return await apiFeatureProperty.id(id);
};

const validate = async (id) => {
    return await apiFeatureProperty.validate(id);
};

const remove = async (id) => {
    return await apiFeatureProperty.remove(id);
};

const webSearch = async (paging) => {
    return await apiFeatureProperty.webSearch(paging);
};

export default {
    search: search,
    save: save,
    id: id,
    validate: validate,
    newId: newId,
    remove: remove,
    webSearch: webSearch,
}