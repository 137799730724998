import React, { Fragment } from 'react';
import PropTypes, { object } from 'prop-types';
import { FormGroup, Label, Input, Col, Button, FormText } from 'reactstrap';

function FileInput(props) {
    const handleFileChange = (e) => {
        if (props.callback) props.callback({
            id: props.name,
            value: e.target.files
        });
    }
    
    return (
        <Col xl={3} lg={4} md={6} sm={12}>
            <FormGroup>
                <Input type="file" name={props.name} accept={props.accept} multiple onChange={(e) => { handleFileChange(e) }} />
                <FormText color="muted">{props.label}</FormText>
            </FormGroup>
        </Col>
    );
}
FileInput.propTypes = {
    title: PropTypes.string,
    value: PropTypes.array,
    values: PropTypes.array,
    callbackId: PropTypes.string,
    callback: PropTypes.func
};

export default FileInput;