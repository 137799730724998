import React, { useState, useRef, useEffect } from 'react';
import lib from "../businesses/lib";
import AuctionRequestForm from './AuctionRequestForm';
import propertyComponent from '../businesses/property';
import auctionPropertyComponent from '../businesses/auctionProperty';
import NotificationSystem from 'react-notification-system';
import { MdInfo, MdPhotoCamera, MdRefresh } from 'react-icons/md';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import { trackPromise } from 'react-promise-tracker';
import LoadingIndicator from '../components/LoadingIndicator';
import auctionComponent from '../businesses/auctionProperty';
// import {
//     Row, Col, Card, Button,
//     CardImg, CardBody, CardTitle, CardText, Badge, CardSubtitle, CardImgOverlay,
//     Media 
// } from 'reactstrap';

const AuctionRequestPage = (parentProps) => {
    const [key, setKey] = useState(parentProps.match.params.key || '');
    const [isValidKey, setIsValidKey] = useState(false);
    const [submitted, isSubmitted] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const notificationAuctionPropertyPage = useRef(null);
    const [countries, setCountries] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedMasterOption, setSelectedMasterOption] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (key) {
            validateKey();
            getCountriesOption();
            getCategoriesOption();
        }
    }, []);


    const validateKey = async () => {
        const result = await auctionComponent.getAuctionRequestByKey(key);
        lib.log(result)
        if (result) {
            setSelectedAgent(result.agent);
            setSelectedProperty(result.auctionProp);
            setIsValidKey(true);
        }
    };

    const getCountriesOption = async () => {
        const countriesOption = await propertyComponent.countries();
        setCountries(countriesOption);
    };

    const getCategoriesOption = async () => {
        const categoriesOption = await propertyComponent.categories();
        setCategories(categoriesOption);
    };

    const handleSubmit = async (property) => {
        const result = await auctionComponent.getAuctionRequestByKey(key);

        if (result) {
            await trackPromise(auctionPropertyComponent.save(property));
            window.scroll(0, 0)
            //performSearch();
            isSubmitted(true);
        } else {
            notificationAuctionPropertyPage.current.addNotification({
                title: <MdInfo />,
                message: "The URL is not valid now",
                level: 'error',
            });
        }

    };

    const handleCancel = () => {
        //performSearch();
    };

    // const styleDarkMode = {
    //     minWidth: '300px',
    //     //backgroundColor: 'black',
    //     //color: 'white',
    // };
    const form = (
        <AuctionRequestForm title="Auction Property"
            property={selectedProperty}
            submitCallback={handleSubmit}
            cancelCallback={handleCancel}
            agent={selectedAgent}
            countries={countries}
            categories={categories}
            masterOption={selectedMasterOption}
        />
    )

    return (
        <div style={{ minWidth: '300px' }} className="cr-page px-3">
            {
                isValidKey ?
                    <>
                        {submitted ?
                            <>
                                <h2>
                                    <img alt="nextsix" src="https://iprofstore.blob.core.windows.net/general/nextsix.png" style={{ width: "120px", height: "120px" }} />
                                </h2>
                                <h2>The auction form has saved or submitted to agent</h2>
                            </>
                            : form}
                        <LoadingIndicator />
                        <NotificationSystem ref={notificationAuctionPropertyPage} style={NOTIFICATION_SYSTEM_STYLE} />
                    </>
                    :
                    <>
                        <h2>
                            <img alt="nextsix" src="https://iprofstore.blob.core.windows.net/general/nextsix.png" style={{ width: "120px", height: "120px" }} />
                        </h2>
                        <h2>Sorry, this link is no longer valid.</h2>
                    </>
            }
        </div>
    );
};

export default AuctionRequestPage;