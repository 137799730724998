import apiProperty from '../api/propertyAPI';
import setupData from '../data/SetupData'
import lib from "./lib";

const search = async (agentId, search, paging) => {
    const properties = await apiProperty.search(agentId, search, paging);
    return properties;
};

const searchHome = async (search, paging, propertyFor, type, beds) => {
    const properties = await apiProperty.searchHome(search, paging, propertyFor, type, beds);
    return properties;
};

const searchHomeLatest = async () => {
    const properties = await apiProperty.searchHomeLatest();
    return properties;
};

const webQuickSearch = async (search, propertyFor) => {
    const properties = await apiProperty.webQuickSearch(search, propertyFor);
    return properties
}

const countSearch = async (agentId, search) => {
    const result = await apiProperty.countSearch(agentId, search);
    let totalRecord = 0;
    if (result && result.length === 1) {
        totalRecord = result[0].total;
    }
    return totalRecord;
};

const totalBuyRent = async (agentId) => {
    const result = await apiProperty.totalBuyRent(agentId);
    return result;
};

const countBuyRent = (properties) => {
    const total = { buy: 0, rent: 0 }
    properties.map(property => {
        if (property.for === "Buy") { total.buy += 1 } else { total.rent += 1 }
    })
    return total;
}

const id = async (id) => {
    const property = await apiProperty.id(id);
    return property;
};

const formFullAddress = address => {
    let fullAddress = '';
    if (address) {
        if (address.line1) fullAddress += (address.line1 + ', ');
        // if (address.line2) fullAddress += (address.line2 + ', ');
        // if (address.city) fullAddress += (address.city + ', ');
        if (address.postcode) fullAddress += (address.postcode + ', ');
        if (address.area) fullAddress += (address.area + ', ');
        if (address.state) fullAddress += (address.state);
        // if (address.country) fullAddress += address.country;
    }

    return (fullAddress.length > 15 ? fullAddress : undefined);
};

const formProjectFullAddress = address => {
    let fullAddress = '';
    if (address) {
        if (address.line1) fullAddress += (address.line1 + ', ');
        if (address.line2) fullAddress += (address.line2 + ', ');
        // if (address.city) fullAddress += (address.city + ', ');
        if (address.postcode) fullAddress += (address.postcode + ', ');
        if (address.area) fullAddress += (address.area + ', ');
        if (address.state) fullAddress += (address.state);
        // if (address.country) fullAddress += address.country;
    }

    return (fullAddress.length > 15 ? fullAddress : undefined);
};

const facilities = facility => {
    const icon = setupData.facilities.filter(f => f.name == facility)
    if (icon[0]) return icon[0].icon
    // return icon[0]
};

const facilitiesTranslateKey = facility => {
    const found = setupData.facilities.filter(f => f.name == facility)
    if (found[0]) return found[0].translateKey
};

const email = async (agent, url, property) => {
    window.open(`mailto:${agent}?subject=Property%20Enquiry%20-%20${property}&body=Hi%2C%0D%0AI%20am%20interested%20in%20this%20property.%0D%0A%0D%0A${url}%0D%0A%0D%0A%20Please%20contact%20me%2C%20thank%20you`)
}

const countries = async () => {
    const result = await apiProperty.countries();
    if (result) {
        return result.countries;
    }
    else return [];
};

const categories = async () => {
    const result = await apiProperty.categories();
    if (result) {
        lib.log(result.categories);
        return result.categories;
    }
    else return [];
};

const webRevampQuickSearch = async (search, target) => {
    const properties = await apiProperty.webRevampQuickSearch(search, target);
    return properties;
};

const getFilter = async () => {
    const result = await apiProperty.getFilter();
    return result;
};

const webRevampSearch = async (search, paging) => {
    const properties = await apiProperty.webRevampSearch(search, paging);
    return properties;
};

const getAdvertisement = async (search, paging) => {
    const result = await apiProperty.getAdvertisement(search, paging);
    return result;
};

const getWebsiteBanner = async () => {
    const result = await apiProperty.getWebsiteBanner();
    return result;
};

const similarProperties = async (propertyId) => {
    const result = await apiProperty.similarProperties(propertyId);
    return result;
};

const otherProperties = async (propertyId) => {
    const result = await apiProperty.otherProperties(propertyId);
    return result;
};

const otherSearches = async (addressState, addressArea) => {
    const result = await apiProperty.otherSearches(addressState, addressArea);
    return result;
}

export default {
    search: search,
    searchHome: searchHome,
    searchHomeLatest: searchHomeLatest,
    webQuickSearch: webQuickSearch,
    countSearch: countSearch,
    totalBuyRent: totalBuyRent,
    countBuyRent: countBuyRent,
    id: id,
    formFullAddress: formFullAddress,
    formProjectFullAddress: formProjectFullAddress,
    facilities: facilities,
    email: email,
    countries: countries,
    categories: categories,
    webRevampQuickSearch: webRevampQuickSearch,
    getFilter: getFilter,
    webRevampSearch: webRevampSearch,
    getAdvertisement: getAdvertisement,
    getWebsiteBanner: getWebsiteBanner,
    similarProperties: similarProperties,
    otherProperties: otherProperties,
    facilitiesTranslateKey: facilitiesTranslateKey,
    otherSearches: otherSearches,
};