
import regex from './regex';
import lib from "./lib";
import apiAuctionProperty from '../api/AuctionPropertyAPI';


const invalid = (err) => {
    return {
        ok: false,
        err: err
    }
};

const validate = (property, isSign) => {
    if (!property.agent) return invalid('Please select an agent');
    if (!property.ownerName) return invalid('Owner name is required');
    if (!property.name) return invalid('Property name is required');

    if ((!property.address.line1) || (!property.address.state || property.address.state === 'State')
        || (!property.address.postcode) || (!property.address.country || property.address.country === 'Country') || (!property.address.area || property.address.area === 'Area'))
        return invalid('Incomplete property address');

    if (!property.title || property.title === 'Property Category') return invalid('Please select property category');
    if (!property.type || property.type === 'Property Type') return invalid('Please select property type');
    if (!property.tenure || property.tenure === 'Tenure') return invalid('Please select tenure');
    if (!property.price) return invalid("Price is required")

    if (isSign) return invalid('Owner signature is required');
    if (property.baths && !regex.isNumeric(property.baths)) return invalid('Number of bathroom field contain numeric only');
    if (property.carParks && !regex.isNumeric(property.carParks)) return invalid('Number of car park field contain numeric only');

    return {
        ok: true
    };
};

const id = async (id) => {
    const property = await apiAuctionProperty.id(id);
    return property;
};

const newId = async () => {
    const newId = await apiAuctionProperty.newId();
    return newId.id;
};

const save = async (property) => {
    const updatedProperty = await apiAuctionProperty.save(property);
    return updatedProperty;
};

const search = async (agentId, status, val, currentLength) => {
    const properties = await apiAuctionProperty.search(agentId, status, val, currentLength);
    return properties;
};

const uploadImages = async (property, newImages) => {
    let imageUris = [];
    const images = property.images;
    if (newImages && newImages.length > 0) {
        let defaultFileNames = [];//align to newImages   
        let idx = 0;
        for (let newImage = 0; newImage < newImages.length; newImage++) {
            while (true) {
                idx++;
                let defaultFileName = `auction${idx}`;
                let found = false;
                if (images && images.length > 0) {
                    for (let img = 0; img < images.length; img++) {
                        if (images[img].indexOf(defaultFileName) >= 0) {
                            found = true;
                            break;
                        }
                    }
                }

                if (!found) {
                    defaultFileNames.push(defaultFileName);
                    break;
                }
            }
        }

        lib.log('will upload images as below .. ');
        lib.log(defaultFileNames);

        for (let img = 0; img < newImages.length; img++) {
            const newImage = newImages[img];
            const defaultFileName = defaultFileNames[img];
            const params = {
                id: property._id,
                codebase64: newImage,
                fileName: defaultFileName
            };
            lib.log(params);
            const imageUri = await apiAuctionProperty.uploadImage(params);
            lib.log(`> ${imageUri}`);
            if (imageUri) imageUris.push(imageUri);
        }
    }

    return imageUris;
};

const newAuctionRequestUrl = async (agentId, auctionPropId) => {
    const url = await apiAuctionProperty.newAuctionRequestUrl(agentId, auctionPropId);
    return url;
};

const getAuctionRequestByKey = async (key) => {
    const property = await apiAuctionProperty.getAuctionRequestByKey(key);
    return property;
};

const findAuctionRequestUrl = async (agentId, auctionPropId) => {
    const url = await apiAuctionProperty.findAuctionRequestUrl(agentId, auctionPropId);
    return url;
};

const updateCoverPhoto = async (auctionPropId, images) => {
    const property = await apiAuctionProperty.updateCoverPhoto(auctionPropId, images);
    return property;
};

const formAuctionFullAddress = address => {
    let fullAddress = '';
    if (address) {
        if (address.line1) fullAddress += (address.line1 + ', ');
        if (address.line2) fullAddress += (address.line2 + ', ');
        if (address.postcode) fullAddress += (address.postcode + ', ');
        if (address.city) fullAddress += (address.city + ', ');
        if (address.state) fullAddress += (address.state);
    }

    return (fullAddress.length > 15 ? fullAddress : undefined);
};


const auctionWebSearch =  async (search, paging) => {
    const properties = await apiAuctionProperty.auctionWebSearch(search, paging);
    return properties;
};

const bidNowOtherAuction = async () => {
    const properties = await apiAuctionProperty.bidNowOtherAuction();
    return properties;
}

export default {
    validate: validate,
    id: id,
    newId: newId,
    save: save,
    search: search,
    uploadImages: uploadImages,
    newAuctionRequestUrl: newAuctionRequestUrl,
    getAuctionRequestByKey: getAuctionRequestByKey,
    findAuctionRequestUrl: findAuctionRequestUrl,
    updateCoverPhoto: updateCoverPhoto,
    formAuctionFullAddress: formAuctionFullAddress,
    auctionWebSearch:auctionWebSearch,
    bidNowOtherAuction:bidNowOtherAuction,
};