import SiteMapAPI from "../api/SiteMapAPI";

const siteMap = async () => {
  const result = await SiteMapAPI.siteMapData();
  return result;
};

export default {
  siteMap: siteMap,
};
