const STATIC = {
  content: {
    Johor: `
      <h2 class="stats-h2">The Historical Background and Development of Johor</h2>
      <p>Johor, Malaysia's southernmost state, boasts a rich cultural heritage deeply rooted in tradition. Beyond the vibrant dances of Zapin and Kuda Kepang, the state is renowned for its exquisite traditional crafts such as batik, songket and silverwork as well as a rich culinary scene of multicultural flavours. Its history is deeply intertwined with the legacy of Melaka, as it was founded by the royal family who sought refuge after the kingdom fell to Portuguese influence.</p>
      <p>Fast forward to today, and Johor stands as a thriving economic powerhouse. Its strategic location bordering Singapore has been instrumental in driving Johor's development. As a result, the state has become a magnet for both domestic and international residents, contributing significantly to the growth of Johor's real estate market.</p>
      <h2 class="stats-h2">Amenities and Top Shopping Destinations in Johor</h2>
      <p>Johor's strategic location and robust infrastructure have positioned it as a thriving state. Its extensive highway network encompassing Bukit Indah, Iskandar Coastal, Johor Bahru-Kota Tinggi, Kempas, Kota Iskandar, Pasir Gudang, Senai Airport and Skudai Highways ensures seamless transportation options for residents and visitors alike. For those preferring public transit Johor offers comprehensive bus services even connecting to neighbouring Singapore.</p>
      <p>Beyond its connectivity, Johor has cultivated a modern and dynamic lifestyle, offering a wide array of amenities to meet the needs of its residents. A myriad of shopping destinations from upscale malls like Johor Bahru City Square and The Mall Mid Valley Southkey, to convenient neighbourhood shopping centres like AEON and several Mydin Malls cater to diverse preferences. Additionally the presence of IKEA Tebrau adds to the state's appeal for those furnishing their newly invested or rented property in Johor.</p>
      <p>The development of Johor is driven not only by economic growth and property investment but also a strong emphasis on education. Johor boasts a diverse range of educational institutions, catering to various academic pursuits. From public universities like the University of Technology Malaysia (UTM), Multimedia University (MMU) and Universiti Tun Hussein Onn Malaysia (UTHM), to colleges such as Johor Matriculation College, Politeknik Sultan Ibrahim and Crescendo International College, there are ample options for higher education. The state also offers a wide selection of schools, ranging from private international schools to religious schools. </p>
      <p>Johor's commitment to education and healthcare further solidifies its position as a desirable place to live, work and study. The state boasts world-class medical facilities such as Gleneagles Medini Hospital and Johor Specialist Hospital. Additionally, each district offers convenient access to government hospitals ensuring quality healthcare for all residents.
      <h2 class="stats-h2">Property Investment in Johor</h2>
      <p>Johor's strategic location bordering Singapore makes it an attractive choice for those seeking a balance of work, family life and lifestyle. The state’s unique blend of modernity and culture creates a welcoming environment for residents and visitors alike. This appeal has contributed significantly to Johor’s real estate market and makes it an ideal location for property investment.</p>
      <p>Johor offers a diverse range of neighbourhoods each with its own distinct character. Whether you are a professional seeking a property to match your lifestyle and investment goals or a student looking for suitable shared accommodation, finding the perfect place to <a href="https://nextsix.com/properties-for-rent/johor">rent a property</a> is straightforward.</p>
      <p>Due to its advantageous location, strong infrastructure and first-rate connectivity, Johor has emerged as a dynamic and attractive destination for investors, tenants and families looking for a mix of urban elegance and cultural diversity. This bustling metropolis offers a diverse range of real estate options catering to varied tastes and preferences. The city's real estate market is vibrant and diverse, offering a range of properties from high-rise apartments with breathtaking views of the entire Johor cityscape, culminating at Singapore, to quaint heritage houses nestled amidst lush suburbs.</p>
      <p>Investors are drawn to Johor due to its strong rental yields and comparatively affordable property prices compared to other Asian cities. The city provides a variety of investment opportunities including leasehold and freehold properties. Johor’s ongoing development projects and stable economy make it an appealing choice for both homeowners and property investors seeking long-term growth.</p>
      <h2 class="stats-h2">Attractions in Johor</h2>
      <p>Johor is renowned for its diverse range of attractions that appeal to a wide range of interests. The city's captivating blend of modernity and heritage ensures an unforgettable experience for visitors. Explore the rich cultural tapestry of Johor by visiting Istana Bukit Serene and Tan Hiok Nee Heritage Street.</p>
      <p>For those seeking natural beauty, Gunung Ledang and Desaru Beach offer serene escapes within the city. Adventure seekers can also indulge in thrilling experiences at Legoland Malaysia, tour Danga Bay or have a blast at the Adventure Waterpark Desaru Coast.</p>
      <p><a href="https://nextsix.com/properties-for-sale/johor">Buying a property</a> or investing in real estate in Johor offers an exceptional opportunity to be part of the city's dynamic lifestyle and exciting development. Discover your ideal space among our diverse range of Johor property listings and embark on your journey in this thriving metropolis today!</p>
    `,
    Selangor: `
      <h2 class="stats-h2">The Historical Background and Development of Selangor</h2>
      <p>Encircling the federal territories of Kuala Lumpur and Putrajaya, both previously part of its expanse, Selangor is a state on Peninsular Malaysia's west coast comprising nine districts: Sabak Bernam, Kuala Selangor, Hulu Selangor, Petaling, Klang, Kuala Langat, Hulu Langat and Sepang. Though the origin of its name remains unclear, the Selangor River, a vital waterway, played a crucial role in the state's early economic development, transforming it into a thriving trading hub.</p>
      <p>The discovery of tin in the 19th century attracted a diverse influx of immigrants, notably Chinese and Indian labourers, enriching Selangor's multicultural tapestry. As a pivotal player during British colonial times, the state became a founding member of the Federation of Malaya in 1948, laying the groundwork for Malaysia's formation. Today, Selangor's history is celebrated through its cultural and historical sites, drawing visitors eager to explore its heritage.</p>
      <h2 class="stats-h2">Amenities and Top Shopping Destinations in Selangor</h2><p>As the former home to the Malaysian capital Kuala Lumpur, Selangor offers a wealth of shopping, entertainment and lifestyle amenities catering to both locals and tourists. Its proximity to the city ensures easy access to a wide range of options with no shortage of world-class shopping malls and supermarkets. </p>
      <p>From 1 Utama Shopping Centre, a retail behemoth that once held the title of the largest shopping malls in Malaysia before IOI City Mall in Putrajaya surpassed it in 2022, to the iconic Egyptian-inspired architecture of Sunway Pyramid, Selangor has no shortage of entertainment and retail hubs. Whether you're simply looking to stock up on household necessities or do some shopping, you'll be spoilt for choice with malls and supermarkets aplenty such as AEON Shah Alam, AEON Bukit Tinggi, IOI City Mall, Tropicana Gardens Mall, The Mines Shopping Mall and more. </p>
      <p>Selangor also boasts excellent connectivity with the Kuala Lumpur International Airport (KLIA) in Sepang offering global access, while the Subang Airport caters to domestic and private flights. Port Klang, Malaysia's busiest seaport, drives economic activity. As for land travel, the KTM Komuter, Rapid KL Light Rail Transit and MRT lines provide extensive coverage, including key destinations like Kajang, Shah Alam and Petaling Jaya. This robust infrastructure network ensures efficient and hassle-free travel within Selangor and beyond, making it an ideal location for residents, businesses and investors alike.</p>
      <p>Renowned medical facilities, including Sunway Medical Centre, Columbia Asia Hospital, Kajang Hospital, Sungai Buloh Hospital and Tengku Ampuan Rahimah Hospital in Klang, cater to the community's healthcare needs. Selangor also boasts a diverse educational landscape, ranging from primary schools to esteemed universities. Public institutions like Universiti Kebangsaan Malaysia, Universiti Putra Malaysia and the International Islamic University of Malaysia are complemented by private universities and colleges such as INTI International College Subang, UCSI University, Multimedia University, Taylor's University and Universiti Tunku Abdul Rahman.</p>
      <p>The abundance of amenities and conveniences contributes to Selangor's high quality of life, making it a prime location if you are considering <a href="https://nextsix.com/properties-for-sale/selangor">buying a property in Selangor</a> or investing in real estate.</p>
      <h2 class="stats-h2">Property Investment in Selangor</h2><p>Selangor has emerged as a sought-after destination for property investment, captivating both domestic and international investors. The state's robust economy, strategic location and ongoing development initiatives make it an ideal choice for those considering investing in property.</p>
      <p>Selangor's real estate market is diverse, offering a spectrum of options from luxurious condominiums and gated communities to more affordable housing. Key areas such as Petaling Jaya, Subang Jaya and Shah Alam have become property investment hotspots with a plethora of options available, no matter whether you're planning to buy or <a href="https://nextsix.com/properties-for-rent/selangor">rent a property in Selangor</a>. </p><p>The Selangor state government has implemented investor-friendly policies including tax incentives and streamlined property transaction processes. This, coupled with the presence of multinational corporations and thriving industries, has significantly boosted demand for both residential and commercial properties. On top of that, ongoing infrastructure projects, such as expanded public transportation networks and new township developments, are also enhancing Selangor's appeal as an investment destination. These factors collectively contribute to the state's continuous growth, making property investment in Selangor a promising venture.</p>
      <h2 class="stats-h2">Attractions in Selangor</h2>
      <p>Selangor is home to a variety of attractions that cater to diverse interests, from nature enthusiasts to history buffs. Nature enthusiasts can explore the vast tropical rainforest at the Forest Research Institute Malaysia (FRIM) or embark on thrilling adventures at Sunway Lagoon. History and culture buffs will appreciate the iconic Batu Caves temple and the Royal Selangor Visitor Centre, showcasing the art of pewter. For a unique experience, i-City's dazzling LED light displays and interactive exhibits offer a captivating escape. These are just a few of the many attractions in Selangor that make the state a captivating destination that's ripe for property investment or even as a forever home.</p>
      <p>Selangor is a dynamic state with a rich history, excellent amenities and numerous attractions. With a thriving economy and continuous development, Selangor is an ideal location for property investment. Whether you're exploring its historical sites, shopping at top destinations, or considering a real estate venture, Selangor offers abundant opportunities and experiences. So don't hesitate, check out our Selangor property listings and invest in the place of your dreams today! </p>
    `,
    Penang: "",
    "Wilayah Persekutuan Kuala Lumpur": `
          <h2 class="stats-h2">The Historical Background and Development of Kuala Lumpur</h2>
          <p>The history of Wilayah Persekutuan Kuala Lumpur dates back to 1857. At that point in time, the now-metropolitan city was but a muddy confluence of the Gombak and Klang rivers, which resulted in it being named “Kuala Lumpur” in the Malay language.</p>
          <p>A century after its founding, Malaysia gained independence in 1957. Kuala Lumpur, initially the capital of the Federation of Malaya, transitioned to become the capital of the newly formed Federation of Malaysia in 1963. Since then, it has blossomed into a bustling metropolis, attracting a diverse population from across the globe. People come to Kuala Lumpur to visit, work, study, and make it their home.</p>
          
          <h2 class="stats-h2">Amenities and Top Shopping Destinations in Kuala Lumpur</h2>
          <p>Kuala Lumpur offers seamless travel with its extensive highway network (NSE, Duke, Kesas) and Kuala Lumpur International Airport. For those who don't drive, a comprehensive public transport system awaits, including rapid rail (KTM Komuter, MRT, KL Monorail) and airport connections (ERL & Skypark Link). Affordable shuttle buses further enhance accessibility, ensuring easy navigation across the city.</p>
          <p>Kuala Lumpur thrives as a modern metropolis, boasting a comprehensive range of amenities that cater to its residents' needs. From world-class shopping malls like Suria KLCC and Pavilion KL to a network of supermarkets including Giant and Village Grocer, the city offers convenient options for everyday essentials and international brands. Traditional wet markets like Pudu Market deliver a variety of fresh produce, while night markets and farmers' markets add a vibrant touch to various neighbourhoods.</p>
          
          <p>Education also flourishes in Kuala Lumpur, with a plethora of institutions catering to all levels. Several prestigious options like Victoria Institution and Convent Bukit Nenas stand alongside international schools like Garden International and Alice Smith. There are also plenty of higher education opportunities such as the esteemed Universiti of Malaya (UM), the nation's oldest university. The International Islamic University (IIU) and Universiti Tunku Abdul Rahman (UTAR) are just a few of the many other notable institutions available as well.</p>
          <p>Healthcare is also top-notch, with world-class facilities like the National Heart Institute (IJN) and Prince Court Medical Centre leading the pack. The Kuala Lumpur General Hospital offers public healthcare options, while the University Malaya Medical Centre (UMMC) serves as a university hospital.</p>
          <p>Craving a nature escape amidst Kuala Lumpur's bustling energy? Fear not, the city boasts a selection of stunning and tranquil recreational parks. From the well-known KLCC Park to the expansive Perdana Botanical Garden and serene Taman Tasik Titiwangsa, these green havens are all conveniently accessible by public transport.</p>
          
          <h2 class="stats-h2">Property Investment in Kuala Lumpur</h2>
          <p>Kuala Lumpur stands out as a strategic location not only within Malaysia but across Southeast Asia. Its vibrant mix of cultures and booming business scene fosters personal and professional growth. This, coupled with its multicultural charm, makes it an ideal location for property investment.</p>
          <p>Kuala Lumpur is a vibrant city with a thriving property market, making it an attractive location for those considering a move, whether for work, study, or simply a change of scenery. <a href="https://nextsix.com/properties-for-rent/kuala-lumpur">Renting a property in Kuala Lumpur</a> is easy thanks to the diverse range of neighbourhoods available, each with its own unique character. Students can find suitable shared accommodations, while professionals can explore options that fit their lifestyle and investment goals.</p>
          <p>Kuala Lumpur’s strategic location, paired with excellent connectivity and robust infrastructure, makes it an attractive destination for investors, renters, or new families to seek for a blend of urban sophistication and cultural richness. This city of wonder offers a diverse range of property options that cater to various lifestyles and preferences. From high-rise condominiums with stunning views of the whole Kuala Lumpur city skyline to charming heritage houses in lush suburbs, the city’s real estate market is both dynamic and varied.</p>
          <p>Investors are particularly drawn to Kuala Lumpur for its relatively affordable property prices compared to other major Asian cities, coupled with strong rental yields. If you’re planning on <a href="https://nextsix.com/properties-for-sale/kuala-lumpur">buying a property in Kuala Lumpur</a>, you’re in luck as the city also offers a range of options from freehold to leasehold properties, catering to different investment needs. With ongoing development projects and a stable economic environment, Kuala Lumpur continues to be a prime location for both homebuyers and property investors seeking long-term growth and vibrant city living.</p>
          
          <h2 class="stats-h2">Attractions in Kuala Lumpur</h2>
          <p>Kuala Lumpur is renowned for its diverse and captivating blend of modern marvels and age-old traditions, ensuring an unforgettable experience for all. Immerse yourself in the city's rich cultural tapestry at vibrant bazaars like Central Market and bustling Chinatown. Escape the urban energy and find serenity at the picturesque Lake Gardens or marvel at the diverse avian wonders housed in the impressive KL Bird Park. Ascend the iconic Petronas Twin Towers Skybridge for breathtaking panoramic views, then indulge in a retail therapy session at the luxurious Suria KLCC and Pavilion Kuala Lumpur. From cultural immersion to awe-inspiring architecture and world-class shopping, Kuala Lumpur offers a kaleidoscope of experiences for everyone.</p>
          Investing in or renting a property in Kuala Lumpur offers a unique opportunity to experience the energetic lifestyle and promising growth of this vibrant city. Ready to experience Kuala Lumpur's charm for yourself? Explore our wide array of property listings and find your perfect place in this thriving city today! 
      `,
    Sarawak: "",
    Sabah: "",
    "Johor Bahru": "",
    Skudai: "",
    Cheras: "",
    Ampang: "",
    Klang: "",
    Puchong: "",
    Kuching: "",
    "Bayan Lepas": "",
    Perak: "",
    Perlis: "",
    Melaka: "",
    Kedah: "",
    "Negeri Sembilan": "",
    Kelantan: "",
    Pahang: "",
    Labuan: "",
  },
  types: {
    "1-sty Terrace/Link House": require("../assets/landing/1StoreyTerrace.jpg"),
    "1.5-sty Terrace/Link House": require("../assets/landing/1-5storeyTerrace.jpg"),
    "2-sty Superlink House": require("../assets/landing/2storeySuperLink.jpg"),
    "2-sty Terrace/Link House": require("../assets/landing/2StoreyTerrace.jpg"),
    "2.5-sty Terrace/Link House": require("../assets/landing/2-5storeyTerrace.jpg"),
    "3 Storey Semi-detached Homes": require("../assets/landing/3storeySemiD.jpg"),
    "3-sty Superlink House": require("../assets/landing/3storeySuperLink.jpg"),
    "3-sty Terrace/Link House": require("../assets/landing/3storeyTerrace.jpg"),
    "3.5-sty Terrace/Link House": require("../assets/landing/3-5storeyTerrace.jpg"),
    "4-sty Terrace/Link House": require("../assets/landing/4storeyTerrace.jpg"),
    "4.5-sty Terrace/Link House": require("../assets/landing/4-5storeyTerrace.jpg"),
    "Agricultural Land": require("../assets/landing/agricultureLand.jpg"),
    Apartment: require("../assets/landing/apartment.jpg"),
    Bungalow: require("../assets/landing/bungalow.jpg"),
    "Bungalow Land": require("../assets/landing/bungalowLand.jpg"),
    "Business Center": require("../assets/landing/businessCenter.jpg"),
    "Cluster House": require("../assets/landing/clusterHouse.jpg"),
    "Commercial Bumgalow": require("../assets/landing/commercialBungalow.jpg"),
    "Commercial Land": require("../assets/landing/commercialLand.jpg"),
    "Commercial Semi-D": require("../assets/landing/commercialSemiD.jpg"),
    Condominium: require("../assets/landing/condo.jpg"),
    "Designer Suites": require("../assets/landing/designerSuite.jpg"),
    "Detached Factory": require("../assets/landing/detachedFactory.jpg"),
    Factory: require("../assets/landing/factory.jpg"),
    Flat: require("../assets/landing/flat.jpg"),
    "Hotel/Resort": require("../assets/landing/hotelResort.jpg"),
    "Industrial Land": require("../assets/landing/industrialLand.jpg"),
    "Light Industrial": require("../assets/landing/lightIndustrial.jpg"),
    "Link Factory": require("../assets/landing/linkFactory.jpg"),
    "Luxury Suites": require("../assets/landing/luxurySuite.jpg"),
    Office: require("../assets/landing/office.jpg"),
    "Residential Land": require("../assets/landing/residentialLand.jpg"),
    "Retail Office": require("../assets/landing/retailOffice.jpg"),
    "Retail Space": require("../assets/landing/retailSpace.jpg"),
    SOFO: require("../assets/landing/sofo.jpg"),
    SOVO: require("../assets/landing/sovo.jpg"),
    SOVO: require("../assets/landing/sovo.jpg"),
    "Semi-D Factory": require("../assets/landing/semiDFactory.jpg"),
    "Semi-detached House": require("../assets/landing/semiDHouse.jpg"),
    "Serviced Residence": require("../assets/landing/servicedResidence.jpg"),
    Shop: require("../assets/landing/shop.jpg"),
    "Shop Office": require("../assets/landing/shopOffice.jpg"),
    Suites: require("../assets/landing/luxurySuite.jpg"),
    Townhouse: require("../assets/landing/townHouse.jpg"),
    Villa: require("../assets/landing/villa.jpg"),
    Warehouse: require("../assets/landing/warehouse.jpg"),
  },
  landing: {
    "Wilayah Persekutuan Kuala Lumpur": require("../assets/landmark/landmark_kualalumpur.jpg"),
    Perak: require("../assets/landmark/landmark_perak.jpg"),
    Perlis: require("../assets/landmark/landmark_perlis.jpg"),
    Melaka: require("../assets/landmark/landmark_melaka.jpg"),
    Kedah: require("../assets/landmark/landmark_kedah.jpg"),
    "Negeri Sembilan": require("../assets/landmark/landmark_negerisembilan.jpg"),
    Johor: require("../assets/landmark/landmark_johor.jpg"),
    Kelantan: require("../assets/landmark/landmark_kelantan.jpg"),
    Pahang: require("../assets/landmark/landmark_pahang.jpg"),
    "Wilayah Persekutuan Labuan": require("../assets/landmark/landmark_labuan.jpg"),
    Selangor: require("../assets/landmark/landmark_selangor.jpg"),
    Terengganu: require("../assets/landmark/landmark_terengganu.jpg"),
    "Wilayah Persekutuan Putrajaya": require("../assets/landmark/landmark_putrajaya.jpg"),
    Sabah: require("../assets/landmark/landmark_sabah.jpg"),
    Sarawak: require("../assets/landmark/landmark_sarawak.jpg"),
    Penang: require("../assets/landmark/landmark_penang.jpg"),
  },
};

export default STATIC;
