import apibase from './BaseAPI';

const log = async (searchTextbox) => {  
    const param = {
        searchFor: searchTextbox
    }
    try {
        const result = await apibase.post(`/web/search/log`, param);
        return result.data;
    } catch (err) {
        throw err;
    }
}

export default {
    log: log
};