import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom'
import lib from "../businesses/lib";
import global from '../businesses/global';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Navbar,
  NavbarBrand,
  ModalBody,
  Container,
} from "reactstrap";
import "../styles/pages/contactUs.scss";
import { trackPromise } from "react-promise-tracker";
import TextInput from "../components/TextInput";
import TextareaInput from "../components/TextareaInput";
import DataSelectionInput from "../components/DataSelectionInput";
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";
import LoadingIndicator from "../components/LoadingIndicator";
import contactUsComponent from "../businesses/ContactUsComponent";
import checkWithCircle from "../assets/agentApplication/icon check with circle.svg";
import ReCAPTCHA from "react-google-recaptcha";
import GoogleMapReact from "google-map-react";
import SetupData from "../data/SetupData";
import mapicon from "../assets/img/contact-us-map-icon.svg";
import facebook from "../assets/img/facebook.svg";
import linkedin from "../assets/img/linkedin.svg"
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setRefresh2 } from "../redux/actions/chartActions";
import nextsixAppEmblem from "../assets/home/nextsix-app-emblem.png";
import { MdClose } from "react-icons/md";
import { close } from "../redux/actions/emblemActions";

const ContactUsPage = (props) => {
  const dispatch = useDispatch();
  const selectedLang = useSelector(state => state.lang);
  const refreshLang = useSelector(state => state.chart);
  const emblem = useSelector(state => state.emblem);
  const getwidth = window.innerWidth;
  const { t } = useTranslation();
  const [contactForm, setContactForm] = useState({});
  const [modal, setModal] = useState(false);
  const [modaltitle, setModalTitle] = useState("");
  const [errorPrompt, setErrorPrompt] = useState("");
  const [defaultZoom, setDefaultZoom] = useState(17);
  const [defaultLocation, setDefaultLocation] = useState({ lat: 3.0855270088021305, lng: 101.69284884723596, });

  // const title = `Contact Us for Our #GPSYourAgent App or Property Enquiries | The Next Six`
  // const description = `Have property enquiries? Contact us from Mon-Fri, from 9am-5pm or submit an enquiry form here. We’re here to connect buyers or tenants to property agents.`

  // document.title = title;
  // document.getElementsByTagName("meta")["description"].content = description;
  // const meta = {
  //   canonical: global.WEBSITE + "/contact",
  //   meta: {
  //     charset: 'utf-8',
  //     property: {
  //       'og:locale': 'en_MY',
  //       'og:type': 'website',
  //       'og:title': `[:en]Contact Our Property Agent for Your Property Enquiries | The Next Six[:]`,
  //       'og:description': `{:en}Have property enquiries? Contact us from Mon-Fri at 9am to 5pm or send an email to hello@nextsix.com. Our property agents will get back to you within 24 hours.{:}`,
  //       'og:url': global.WEBSITE + "/contact",
  //       'og:site_name': global.THE_NEXT_SIX_SDN_BHD,

  //     },
  //   }
  // };

  useEffect(() => {
    if (refreshLang) {
      dispatch(setRefresh2(false));
    }
  }, [refreshLang]);

  const recaptchaLocalize = () => {
    let hl = 'en';
    if (selectedLang === 'bm') hl = 'ms';
    else if (selectedLang === 'zh') hl = 'zh-CN';
    return hl;
  };

  const inputCallback = (cb) => {
    assignValue(cb.id, cb.value);
  };

  const assignValue = (name, value) => {
    setContactForm((contactForm) => ({
      ...contactForm,
      [name]: value,
    }));
  };

  const toggle = () => {
    setModal(!modal);
  };

  const toggleModal = (modaltitle) => {
    setModal(!modal);
    setModalTitle(modaltitle);
  };

  const onOkClick = async (event) => {
    event.preventDefault();
    setModal(false);
    toggle();
  };


  const onSubmitClick = async (event) => {
    event.preventDefault();
    if (contactForm) {
      const res = await contactUsComponent.validate(contactForm);
      if (res.ok) {
        lib.log("validation OK");
        const checkToken = await contactUsComponent.checktoken(contactForm.humankey);
        if (checkToken) {
          await trackPromise(contactUsComponent.save(contactForm));//here return send success

          //one if check 
          setContactForm("");
          setErrorPrompt(null);
          toggleModal("Thank You! ");
          toggle();
        }
        else {
          setErrorPrompt("Detected You Are Not Human. Please Try To ReCaptcha Again");
        }


      } else {
        // toggleModal(res.err);
        setErrorPrompt(res.err);
      }
    } else {
      setErrorPrompt("Form Cannot Be Empty");
    }
  };

  const ModelsMap = (map, maps) => {

    let marker = new maps.Marker({
      position: {
        lat: defaultLocation.lat,
        lng: defaultLocation.lng,
      },
      map,
      icon: mapicon
    });

    marker.addListener("click", () => {
      // handlePopulateData(dataArray[i])//goto googlemap can be refine?
    });

  };

  function verifyCaptcha(res) {
    if (res) {
      assignValue("human", true)
      assignValue("humankey", res)
      //  assignValue("disabled", true)

    }
  }

  // ReCAPTCHA Expired
  function expireCaptcha() {
    assignValue("human", false)
    assignValue("humankey", null)
    // assignValue("disabled", true)

  }

  // useEffect(() => {
  //     dispatch(close("open"));
  // }, [])

  const closeBanner = () => {
    dispatch(close("close"));
  }

  return (
    <>
      {
        getwidth < 768 && emblem === "open" &&
        <div className="emblem-wrapper">
          <div className="emblem-left-content">
            <Button className="emblem-close-btn" onClick={() => closeBanner()}><MdClose /></Button>
            <img loading="lazy" className="emblem-logo" src={nextsixAppEmblem} alt="" />
            <div className="emblem-left-textbox">
              <span className="emblem-left-text-top">NextSix App</span>
              <span className="emblem-left-text-btm">Find properties and agents faster</span>
            </div>
          </div>
          <div className="emblem-right-content">
            <a className="emblem-download-btn" href="https://apps.nextsix.com/users/reg">Use Now</a>
          </div>
        </div>
      }
      <Header2 />
      <LoadingIndicator />
      <Container className="contact-us-empty">
      </Container>
      <Container className="contact-us-container">
        <div className="contact-us-card">
          <Row>
            <Col xl="7" lg="7" md="12" sm="12" xs="12">
              <div className="contact-us-title">
                {`${t('need-help')}?`}
              </div>
              <div className="contact-us-description">
                {t('contact-us-desc')}
              </div>

              <div className="contact-us-form-info">
                <div className="contact-us-form-text">
                  <div className="contact-us-form-text-detail">
                    {`${t('your-name')}*`}
                  </div>
                  <TextInput
                    name="name"
                    // placeholder="Full Name*"
                    callbackId="name"
                    callback={inputCallback}
                    readOnly={false}
                    value={contactForm.name || ""}
                  />
                </div>

                <div className="contact-us-form-text">
                  <div className="contact-us-form-text-detail">
                    {`${t('your-email')}*`}
                  </div>
                  <TextInput
                    name="email"
                    // placeholder="Email*"
                    callbackId="email"
                    callback={inputCallback}
                    readOnly={false}
                    value={contactForm.email || ""}
                  />
                </div>

                <div className="contact-us-form-text">
                  <div className="contact-us-form-text-detail">
                    {`${t('your-contact-number')}*`}
                  </div>
                  <TextInput
                    name="mobile"
                    // placeholder="Mobile Number*"
                    callbackId="mobile"
                    callback={inputCallback}
                    readOnly={false}
                    value={contactForm.mobile || ""}
                  />
                </div>

                <div className="contact-us-form-text">
                  <div className="contact-us-form-text-detail">
                    {`${t('your-identity')}*`}
                  </div>
                  <DataSelectionInput
                    title={null}
                    values={SetupData.ContactUsIdentity}//tbd on setupdata
                    value={contactForm.identity || ""}
                    callbackId={"identity"}
                    callback={inputCallback}
                    translate={true}
                  />
                </div>

                <div className="contact-us-form-text">
                  <div className="contact-us-form-text-detail">
                    {`${t('your-enquiry-about')}*`}
                  </div>
                  <TextInput
                    name="title"
                    // placeholder="EnquiryAbout*"
                    callbackId="title"
                    callback={inputCallback}
                    readOnly={false}
                    value={contactForm.title || ""}
                  />
                </div>

                <div className="contact-us-form-text">
                  <div className="contact-us-form-text-detail">
                    {t('more-detail')}
                  </div>

                  <TextareaInput
                    name="detail"
                    value={contactForm.detail || ""}
                    callbackId="detail"
                    callback={inputCallback}
                  />
                </div>

                <div className="errprompt-contact-us">
                  <code>
                    <small>
                      {errorPrompt ? (
                        errorPrompt
                      ) : (
                        <pre className="errprompt-spacing"></pre>
                      )}
                    </small>
                  </code>
                </div>

                <div className="contact-us-form-text">
                  <p className="contact-us-term-condition">
                    {t('contact-us-tnc')}
                  </p>

                </div>

                {
                  !refreshLang &&
                  <div className="contact-us-recaptcha">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
                      onChange={verifyCaptcha}
                      onExpired={expireCaptcha}
                      hl={recaptchaLocalize()}
                    />
                  </div>
                }

                <div>
                  <Button onClick={onSubmitClick} className="contact-us-submit-button">
                    {t('submit')}
                  </Button>
                </div>
              </div>
            </Col>

            <Col>
              <div className="contact-us-map-card">

                <Row className="row-map-contact-us">
                  <Col className="contact-us-map-warp">
                    <div className="contact-us-map">
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: process.env.REACT_APP_GOOGLE_API_KEY
                        }}
                        defaultCenter={defaultLocation}
                        defaultZoom={defaultZoom}
                        options={{ gestureHandling: "greedy" }}
                        yesIWantToUseGoogleMapApiInternals//
                        onGoogleApiLoaded={({ map, maps }) => ModelsMap(map, maps)}
                        style={{ borderRadius: "15px" }}
                      >
                      </GoogleMapReact>
                    </div>
                  </Col>
                </Row>

                <div className="contact-us-company-detail">
                  <div className="contact-us-company-detail-title">
                    {t('nextsix-company')}
                    <p className="contact-us-company-detail-description">
                      Blk C-3-7, Kuchai Exchange No 43, <br />Jalan Kuchai Maju 13, Off Jalan Kuchai Lama, <br />58200 Kuala Lumpur
                    </p>
                  </div>

                  <div className="contact-us-company-detail-title">
                    {t('working-hours')}
                    <p className="contact-us-company-detail-description">
                      {t('monday-friday')}<br />
                      9:00 am - 5:00 pm
                    </p>
                  </div>

                  <div className="contact-us-company-detail-title">
                    {t('general-enquiries')}
                    <p className="contact-us-company-detail-description">
                      hello@nextsix.com
                    </p>
                  </div>
                </div>

              </div>

              <div className="contact-us-contact-card">
                <div className="contact-us-contact-info">
                  <span>{t('contact-with-us')}</span> <span className="contact-us-social-media">
                    <a href="https://www.facebook.com/NextsixMY" target="_blank" rel="noopener noreferrer">
                      <img loading="lazy" alt="Facebook" src={facebook}
                        className="logo-media" /> </a>

                    <a href="https://www.linkedin.com/company/nextsix/about/" target="_blank" rel="noopener noreferrer">
                      <img loading="lazy" alt="Linkedin" src={linkedin}
                        className="logo-media" /> </a>

                  </span>
                </div>

              </div>

              <div className="contact-us-description-card">
                <div className="contact-us-description-title">
                  <h1>Find Your Dream Property in Seconds<br />with Our Prop-Tech GPS Search Function</h1>

                  <div className="subtitle">
                    <p>Our prop-tech company is changing the game with our innovative GPS search feature. Gone are the days of aimlessly scrolling through countless property listings and websites. Our platform connects you with professional real estate agents and available properties in your desired area instantly.</p>
                    <p>Our team of experts has worked tirelessly to ensure that our GPS search function is user-friendly and efficient. Whether you're looking to buy or rent, we have the resources and technology to streamline your search and make the process seamless.</p>
                    <p>At our prop-tech company, we understand that finding the perfect property can be a daunting task. That's why we're committed to providing you with the best possible service and support throughout your property search journey. We'll work with you every step of the way to ensure that you find a property that meets all of your needs and exceeds your expectations.</p>
                    <p className="m-0">Don't waste any more time or energy on an endless search. Let our prop-tech company revolutionize the way you find your dream property. Contact us today to get started on the <Link to={{ pathname: 'https://mydigitalfeed.com/a-hidden-gem-for-real-estate-investor-in-penang/' }} target="_blank" rel="noopener noreferrer">Penang</Link>  <Link to="properties-for-sale/penang/residential" target="_blank" rel="noopener noreferrer">property</Link>.</p>
                  </div>
                </div>
              </div>


            </Col>
          </Row>
        </div>
      </Container>


      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <div className="checkLogoWithCircle">
            <img loading="lazy" src={checkWithCircle} alt="circle"></img>
          </div>
          <h4 className="checkTitle">{modaltitle}</h4>
          <p className="checkParagraph">
            You have submitted the enquiry successfully!
          </p>
        </ModalBody>
        <ModalFooter>
          <div className="contact-us-modal-ok">
            <Button className="btn-orange" onClick={onOkClick}>
              Okay
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      <Footer2 />
    </>
  )
};

export default ContactUsPage;
