import apiAgentPackage from '../api/AgentPackageAPI';

const agentPackage = async () => {
    const result = await apiAgentPackage.agentPackage();
    return result;
}

const agentPackages = async(hidePackage) => {
    const result = await apiAgentPackage.agentPackages(hidePackage);
    return result;
}

export default {
    agentPackage: agentPackage,
    agentPackages: agentPackages,
}