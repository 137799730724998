import React from 'react';
// import PageSpinner from '../components/PageSpinner';
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';

const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();

    return (
        promiseInProgress && //<PageSpinner />
        <div className="modal fade show" role="dialog" tabIndex="-1" style={{ display: "block" }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Working.. Please wait</h5>
                        {/* <button type="button" className="close" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button> */}
                    </div>
                    <div className="modal-body">
                        <Loader type="ThreeDots" color="#2BAD60" height={80} width={100} />
                    </div>
                    {/* <div className="modal-footer">
                        <button type="button" className="btn btn-primary">Do Something</button>
                        <button type="button" className="btn btn-secondary">Cancel</button>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default LoadingIndicator;
