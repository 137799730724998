import lang from '../reducers/lang';
import chart from '../reducers/chart';
import emblem from '../reducers/emblem';
import seoData from './seoData';
import { combineReducers } from 'redux';

const allReducers = combineReducers({
    lang, chart, emblem, seoData,
});

export default allReducers;
