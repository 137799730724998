import React, { useState, useEffect } from 'react';
import { Col, Row, Card } from 'reactstrap';
import { useParams } from "react-router-dom";
import agentComponent from '../businesses/agent';
import lib from '../businesses/lib';
import 'react-rater/lib/react-rater.css';
import '../styles/pages/businessCard.scss'

const BusinessCard = (props) => {
    const MODE_VERIFIED = "verified"
    const MODE_UNATHORIZED = "unathorized"
    const [mode, setMode] = useState(null);
    let { id } = useParams();
    const previewUrl = `${process.env.REACT_APP_NEXTSIX_OP}/ebc/trr/agent/${id}/preview`;

    useEffect(() => {
        verifyAgentId();
    }, []);

    const verifyAgentId = async () => {
        if (!lib.isMongoDbObjectId(id)) {
            setMode(MODE_UNATHORIZED)
            return;
        }
        const agentFound = await agentComponent.getAgentInfo(id);
        if (agentFound) setMode(MODE_VERIFIED)
        else setMode(MODE_UNATHORIZED)
    }

    const EnameCard = () => {
        return (
            <object style={{ height: '100%', width: '100%' }} type="text/html" data={previewUrl}><span></span></object>
        )
    }

    const EcardNotFound = () => {
        return (
            <div className='business-card-not-found'>
                <img className='business-card-nextsix-logo' alt="nextsix logo" src="https://iprofstore.blob.core.windows.net/general/nextsix.png" style={{ width: "120px", height: "120px" }} />
                <h4>404. Agent Business Card Not Found</h4>
            </div>
        )
    }

    const getSection = () => {
        if (mode === MODE_VERIFIED) return <EnameCard />
        if (mode === MODE_UNATHORIZED) return <EcardNotFound />
        return null
    }

    return (
        <div className='business-card-content'>
            {getSection()}
        </div>
    )
}

export default BusinessCard;

