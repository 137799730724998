import propertyMasterAPI from "../api/propertyMasterAPI";

const id = async (masterId) => {
    try {
        const result = await propertyMasterAPI.id(masterId);
        return result;
    } catch (err) {
        console.log(err);
    }
};

export default {
    id,
}