import free from "../assets/agentApplication/icon free.svg";
import essential from "../assets/agentApplication/icon essential.svg";
import pro from "../assets/agentApplication/icon pro.svg";
import proplus from "../assets/agentApplication/icon proplus.svg";

const MAPPING = {
    AgentPackages:{
        imageMap:{
            free: free,
            essential: essential,
            pro: pro,
            proplus: proplus,
        },
    },
    HeaderSiteNavigation: {
        buy:{
            element: "nav-buy",
            link: "/properties-for-sale"
        },
        rent:{
            element: "nav-rent",
            link: "/properties-for-rent"
        },
        findAgent:{
            element: "nav-find-agent",
            link: "/agent/all-states/all-areas/all-types"
        },
        blog:{
            element: "nav-blog",
            link: "https://blog.nextsix.com/"
        }
    },
    InternalLinkings: [
        {
            name: "apartment / flat",
            link: "apartment-flat"
        },
        {
            name: "condominium / serviced residence",
            link: "condo-serviced-residence"
        },
        {
            name: "terrace houses / linked houses / town houses",
            link: "terrace-link-townhouse"
        },
        {
            name: "semi-d / bungalow",
            link: "semi-d-bungalow"
        },
        {
            name: "and more {{state}} properties",
            link: "/"
        }
    ]
}

export default MAPPING