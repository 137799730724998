import React
// , { useState, useEffect } 
from 'react';
import SelectionInput from './SelectionInput';
import PropTypes from 'prop-types';
import TextInput from './TextInput'
import { FormGroup, Col } from 'reactstrap';

function AddressTemplateInput(props) {
    const readOnly = props.readOnly;
    const required = props.required;

    const onChanged = (cb) => {
        handleCallback(cb.id, cb.value);
    };

    const inputAreaCallback = (cb) => {
        handleCallback(cb.id, cb.value);
    };

    const inputStateCallback = (cb) => {
        if (props.stateCallback) props.stateCallback(cb);
    };

    const inputCountryCallback = (cb) => {
        if (props.countryCallback) props.countryCallback(cb);
    };

    const handleCallback = (id, value) => {
        if (props.callback) props.callback({
            id: id,
            value: value
        });
    };

    return (
        <div>
            <strong>{props.title}</strong><br />
            <TextInput name="line1" callbackId="line1" placeholder="Address Line 1" value={(props.address && props.address.line1) || ''} callback={onChanged} readOnly={readOnly} required={required} />
            <TextInput name="line2" callbackId="line2" placeholder="Address Line 2" value={(props.address && props.address.line2) || ''} callback={onChanged} readOnly={readOnly} />
            <TextInput name="city" callbackId="city" placeholder="City or Town" value={(props.address && props.address.city) || ''} callback={onChanged} readOnly={readOnly} />
            <TextInput name="postcode" callbackId="postcode" placeholder="Postcode" value={(props.address && props.address.postcode) || ''} callback={onChanged} readOnly={readOnly} required={required} />
            <FormGroup row>
                <Col sm={1} />
                <SelectionInput title="Country" values={props.countries} name="country" value={(props.address && props.address.country) || ''}
                    callbackId={'country'} callback={inputCountryCallback} readOnly={readOnly} required={required} />
                <SelectionInput title="State" values={props.states} name="state" value={(props.address && props.address.state) || ''}
                    callbackId={'state'} callback={inputStateCallback} readOnly={readOnly} required={required} />
            </FormGroup>
            <FormGroup row>
                <Col sm={1} />
                <SelectionInput title="Area" values={props.areas} name="area" value={(props.address && props.address.area) || ''}
                    callbackId={'area'} callback={inputAreaCallback} readOnly={readOnly} required={required} />
            </FormGroup>
        </div>
    );
}

AddressTemplateInput.propTypes = {
    title: PropTypes.string,
    address: PropTypes.object,
    countries: PropTypes.array,
    callback: PropTypes.func,
};

export default AddressTemplateInput;