import apiShared from '../api/SharedAPI';

const getAreas = async () => {
    const result = await apiShared.getAreas();
    return result;
};

const validStateArea = async (stateArea) => {
    const result = await apiShared.validStateArea(stateArea);
    return result;
}

export default {
    getAreas: getAreas,
    validStateArea,
}