// for SEO usage
// only use this component if schema markup is static
import React from "react"

export default function SchemaMarkup({ children }) {
    return (
        <script type="application/ld+json">
            {children}
        </script>
    )
}