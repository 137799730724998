import React, { useState, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col, Modal, ModalHeader, ModalFooter, ModalBody, Input, Button } from 'reactstrap';
import agentPackageComponent from '../businesses/AgentPackageComponent';
import agentApplicationComponent from '../businesses/AgentApplicationComponent';
import '../styles/pages/agentSignUpPage.scss';
import signUpLogo from '../assets/agentApplication/sign-up-logo.png'
import { useHistory } from "react-router-dom";
import LoadingOverlay from "../components/LoadingOverlay";
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import lib from '../businesses/lib';
import SetupData from '../data/SetupData';

const AgentSignUpPage = () => {
    const { tag } = useParams()
    const [currentPackages, setCurrentPackages] = useState([]);
    const [sourceFound, setSourceFound] = useState({});
    const history = useHistory()
    const { promiseInProgress } = usePromiseTracker();

    useEffect(() => {
        getCurrentPackages();
        validateSource()
    }, [])

    const getCurrentPackages = async () => {
        const currentPackages = await agentPackageComponent.agentPackage();
        setCurrentPackages(currentPackages);
    }

    const validateSource = async () => {
        const source = await agentApplicationComponent.validateSource(tag.toUpperCase());
        if(source) {
            setSourceFound(source);
        } else {
            history.push('/error/404');
        }
    }

    const SignUpForm = () => {
        const [signUpInfo, setSignUpInfo] = useState({});
        const [confirmModal, setConfirmModal] = useState(false)
        const [promptError, setPromptError] = useState("");
        const agentNameRef = useRef();
        const agentEmailRef = useRef();
        const mobileCodeRef = useRef();
        const agentMobileRef = useRef();
        const agentPackageRef = useRef();
        const agentRenIdRef = useRef();

        const handleSubmitForm = async () => {
            const countryCode = mobileCodeRef.current.value;
            const mobileNum = agentMobileRef.current.value.replace(/\s+/g, "");
            const combinedMobile = lib.combineMobileWithCountryCode(countryCode, mobileNum)
            let application = {
                name: agentNameRef.current.value,
                email: agentEmailRef.current.value.replace(/\s+/g, ""),
                mobile: combinedMobile,
                renId: agentRenIdRef.current.value,
                package: agentPackageRef.current.value,
                source: sourceFound ? sourceFound.urlId : undefined,
                reassigned: sourceFound ? sourceFound.urlId : undefined,
                category: sourceFound ? sourceFound.category : "General",
            }

            const validate = await agentApplicationComponent.validate(application)
            if (validate.ok) {
                // const newPhone = lib.addDashToPhoneNumber(application.mobile)
                // application.mobile = newPhone
                setSignUpInfo(application)
                toggleConfirmModal()
                setPromptError("")
            } else {
                setPromptError(validate.err)
            }
        }

        const toggleConfirmModal = () => {
            setConfirmModal(!confirmModal)
        }

        return (
            <>
                <div className='agentSignUp-section-one'>
                    <div className='agentSignUp-content'>
                        <img className='agentSignUp-logo' src={signUpLogo} alt='Nextsix Agent Logo' />
                        <p className='agentSignUp-headline'>How to get started?</p>
                        <p className='agentSignUp-headline'> Just one easy step away.</p>
                        <p className='agentSignUp-textline'>Drop us your contact details and agent info here, and let our team do the rest of the heavy lifting.</p>
                    </div>
                    <div className='agentSignUp-form'>
                        <input className='agentSignUp-input' placeholder='Full Name*' ref={agentNameRef} name='name' />
                        <input className='agentSignUp-input' placeholder='Email*' ref={agentEmailRef} name='email' />
                        <div className='agentSignUp-form-group'>
                            <select className='agentSignUp-select' ref={mobileCodeRef}>
                                {SetupData.mobileCountryCodes.map((item, i) => <option key={i} value={item.value}>{item.value}</option>)}
                            </select>
                            <input className='agentSignUp-input' placeholder='Mobile Number*' ref={agentMobileRef} name='mobile' />
                        </div>
                        <select className='agentSignUp-select' ref={agentPackageRef} name='package' >
                            {currentPackages?.map((data, i) => {
                                return <option key={i} value={data.value} >{data.label}</option>
                            })}
                        </select>
                        <input className='agentSignUp-input' placeholder='Ren ID' ref={agentRenIdRef} name='renId' />
                        <button className='agentSignUp-submit' onClick={handleSubmitForm}>Submit</button>
                        <p className="agentSignUp-errorPrompt">{promptError}</p>
                    </div>
                </div>
                <ConfirmModal toggleConfirmModal={toggleConfirmModal} confirmModal={confirmModal} signUpInfo={signUpInfo} />
            </>
        )
    }

    const ConfirmModal = ({ toggleConfirmModal, confirmModal, signUpInfo }) => {
        const handleConfirmForm = async () => {
            toggleConfirmModal()
            const res = await trackPromise(agentApplicationComponent.save(signUpInfo))

            if (!promiseInProgress && res) {
                window.scroll({ top: 0, behavior: 'instant' });
                history.push('/thankyou', { fromSignUp: true })
            }

        }

        return (
            <Modal isOpen={confirmModal} toggle={toggleConfirmModal}>
                <ModalHeader toggle={toggleConfirmModal}>Confirm Submit Form?</ModalHeader>
                <ModalFooter>
                    <Button color="success" onClick={handleConfirmForm}>
                        Yes
                    </Button>
                    <Button color="danger" onClick={toggleConfirmModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    return (
        <div className='agentSignUp-main'>
            <SignUpForm />
            <LoadingOverlay />
        </div>
    )
}

export default AgentSignUpPage;

