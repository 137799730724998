import apibase from "./BaseAPI";

const propertyLandingData = async (state, area) => {
  const url = `/property?state=${state}&area=${area}`;

  try {
    const result = await apibase.get(url);
    return result.data;
  } catch (err) {
    throw err;
  }
};

const activeState = async () => {
  const url = `/activeState`;

  try {
    const result = await apibase.get(url);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export default {
  propertyLandingData,
  activeState,
};
